import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { updatePublishDate } from "../../../Feature/Admin/ChannelManagement/ChannelDeleteSlice";

function PublishDate({ show, toggle, id }) {
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState("");

  const handleChange = (selected) => {
    setSelectedDate(selected);
  };

  const submit = () => {
    if (selectedDate) {
      const obj = {
        channel_id: id,
        date: selectedDate,
      };
      dispatch(updatePublishDate(obj));
      toggle();
    }
  };

  return (
    <Modal show={show} onHide={toggle} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Publish Date</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row modal-form-main p-4">
          <div className="col-md-12">
            <label>Enter published date</label>
            <input
              type="date"
              className="date form-control"
              id="date"
              onChange={(e) => handleChange(e.target.value)}
              onFocus={(e) => {
                e.currentTarget.type = "date";
                e.currentTarget.focus();
              }}
              value={selectedDate}
            />
          </div>
          <div className="col-md text-center mt-4">
            <button className="apply-button" onClick={submit}>
              Update
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default PublishDate;
