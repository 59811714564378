import React from "react";
import Card from "react-bootstrap/Card";

function DashboardCards(props) {
  const { data } = props;
  return (
    <>
      <Card style={{ width: "290px" }} className="mb-3">
        <Card.Body>
          <div className="d-flex justify-content-around align-items-center">
            <div className="outter-icon">
              <div className="icon">{data.icon}</div>
            </div>
            <div>
              <div className="count-text">{data.name}</div>

              <hr></hr>
              <div className="count-value">{data.value}</div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

export default DashboardCards;
