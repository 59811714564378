import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  isAuth: false,
  error: "",
  token: localStorage.getItem("token")
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    loginPending: (state) => {
      state.isLoading = true;
    },
    loginSuccess: (state, {payload}) => {
      state.isLoading = false;
      state.isAuth = true;
      state.error = "";
      state.token = payload
    },
    loginFail: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
    clearState:(state) =>{
      state.isLoading = false;
      state.isAuth = false;
      state.error = false;
    }
  },
});

const { reducer, actions } = loginSlice;

export const { loginPending, loginSuccess, loginFail, clearState } = actions;

export default reducer;
