import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPayments } from "../../../Feature/Admin/Subscription/SubscriptionSlice";
import { dateFormat } from "../../../Common/dateFormat";
import { UilFileDownload } from "@iconscout/react-unicons";

const PaymentHistory = () => {
  const dispatch = useDispatch();
  const { payments } = useSelector((state) => state.subscription);
  const business_id = localStorage.getItem("business_id");
  useEffect(() => {
    if (business_id) {
      dispatch(fetchPayments(business_id));
    }
    // eslint-disable-next-line
  }, [business_id]);
  console.log(payments);
  return (
    <>
      <div className="payment-history">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>#</th>
                <th>Plan</th>
                <th>Amount</th>
                <th>Date</th>
                <th>Invoice</th>
              </tr>
            </thead>
            <tbody>
              {payments.map((item, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{item.package_name}</td>
                  <td>${item.price}</td>
                  <td className="d-flex">
                    {dateFormat(item.payment_date)}
                    {item.status === "1" && (
                      <span className="ms-3 plan">Current Plan</span>
                    )}
                  </td>
                  <td>
                    <a
                      href={item.invoice}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <UilFileDownload color="#7b68ee" />
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default PaymentHistory;
