import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import UserForm from "./UserForm";
import UserTable from "./UserTable";
import Select from "react-select";
import { UilPlus } from "@iconscout/react-unicons";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchActiveUsers,
  fetchInactiveUsers,
} from "../../../Feature/Admin/userManagement/userSlice";
import { AlertToast } from "../../../Common/Toast";
import { fetchRoles } from "../../../Feature/Admin/RoleManagement/roleSlice";
import {
  searchUserEmail,
  searchUserName,
  searchUserRole,
} from "../../../Feature/Admin/userManagement/userFilterSlice";
import Loaders from "../../../Components/Loaders/Loaders";
import ReactPaginate from "react-paginate";
import UpgradeModal from "../../../Common/UpgradeModal";
import DowngradeModal from "../../../Components/Modal/DowngradeModal";

function UserManagement() {
  const [show, setShow] = useState(false);
  const [key, setKey] = useState("activeTab");
  const [alert, setAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [data, setData] = useState([]);

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + Number(itemsPerPage);
  const currentItems = data.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(data.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    setItemOffset(newOffset);
  };
  // console.log(itemsPerPage, itemOffset, endOffset)
  const [selected, setSelected] = useState(null);
  const [selectedName, setSelectedName] = useState("");
  const [selectedEmail, setSelectedEmail] = useState("");
  const [upgrade, setUpgrade] = useState(false);

  const [showDowngradeModal, setShowDowngradeModal] = useState(false);
  const [deleteList, setDeleteList] = useState(false);

  const dispatch = useDispatch();
  const { activeUsers, inactiveUsers, isLoading, activeError } = useSelector(
    (state) => state.userData
  );
  const { isSuccess, isError, isEditSuccess, isEditError, userUpgrade } =
    useSelector((state) => state.userData);
  const { isDeleteSuccess } = useSelector((state) => state.userAction);
  const { roles } = useSelector((state) => state.role);
  const { userRoleSuccess, userNameSuccess, userEmailSuccess, isLoadingData } =
    useSelector((state) => state.userFilter);
  const { bulkInactivateUsersSuccess, bulkInactivateUsersError } = useSelector(
    (state) => state.channelDelete
  );

  const openModal = () => {
    setShow(true);
  };
  const toggleModal = () => {
    setShow(!show);
  };
  const closeToast = () => {
    setAlert(false);
  };

  // const openDowngradeModal = () => {
  //   setShowDowngradeModal(true);
  // };
  const toggleDowngradeModal = () => {
    setShowDowngradeModal(!showDowngradeModal);
  };

  useEffect(() => {
    if (key === "activeTab") {
      dispatch(fetchActiveUsers());
      setItemOffset(0);
    } else if (key === "inactiveTab") {
      dispatch(fetchInactiveUsers());
      setItemOffset(0);
    }
    // eslint-disable-next-line
  }, [key]);

  useEffect(() => {
    if (isDeleteSuccess) {
      setAlert(true);
      setAlertMsg("User deleted Successfully");
      if (key === "activeTab" && isDeleteSuccess) {
        dispatch(fetchActiveUsers());
      } else if (key === "inactiveTab" && isDeleteSuccess) {
        dispatch(fetchInactiveUsers());
      }
    }
    // eslint-disable-next-line
  }, [key, isDeleteSuccess]);

  useEffect(() => {
    console.log(bulkInactivateUsersSuccess)
    if (bulkInactivateUsersSuccess) {
      setAlert(true);
      setAlertMsg("User Updated Successfully");
      if (key === "activeTab" && bulkInactivateUsersSuccess) {
        dispatch(fetchActiveUsers());
      } else if (key === "inactiveTab" && bulkInactivateUsersSuccess) {
        dispatch(fetchInactiveUsers());
      }
    }
    // eslint-disable-next-line
  }, [key, bulkInactivateUsersSuccess]);

  useEffect(() => {
    setData(activeUsers);
  }, [activeUsers]);

  useEffect(() => {
    setData(inactiveUsers);
  }, [inactiveUsers]);

  useEffect(() => {
    if (isSuccess.status === 200) {
      setAlert(true);
      setAlertMsg("Successfully added User !");
      if (key === "activeTab") {
        dispatch(fetchActiveUsers());
      } else {
        dispatch(fetchInactiveUsers());
      }
    }
    if (isError) {
      setAlert(true);
      setAlertMsg(isError);
      if (key === "activeTab") {
        dispatch(fetchActiveUsers());
      } else {
        dispatch(fetchInactiveUsers());
      }
    }
    // eslint-disable-next-line
  }, [isSuccess, isError]);

  useEffect(() => {
    if (isEditSuccess) {
      setAlert(true);
      setAlertMsg("Successfully updated User !");
      if (key === "activeTab") {
        dispatch(fetchActiveUsers());
      } else {
        dispatch(fetchInactiveUsers());
      }
    }
    if (isEditError) {
      setAlert(true);
      setAlertMsg(isEditError);
      if (key === "activeTab") {
        dispatch(fetchActiveUsers());
      } else {
        dispatch(fetchInactiveUsers());
      }
    }
    // eslint-disable-next-line
  }, [isEditSuccess, isEditError]);

  useEffect(() => {
    dispatch(fetchRoles());
    // eslint-disable-next-line
  }, []);

  // Filters(Start):
  const handleChange = (selectedOption) => {
    setSelected(selectedOption.role);
    setSelectedName("");
    setSelectedEmail("");
  };

  const handleNameChange = (selected) => {
    setSelectedName(selected);
    setSelected(null);
    setSelectedEmail("");
  };

  const handleEmailChange = (selected) => {
    setSelectedEmail(selected);
    setSelected(null);
    setSelectedName("");
  };

  const getData = () => {
    // console.log("role=>",selected, "name=>"+selectedName, "email=>"+selectedEmail)
    if (selected) {
      const data = {
        regx: selected,
        status: key === "activeTab" ? "active" : "inactive",
      };
      dispatch(searchUserRole(data));
    }
    if (selectedName) {
      const data = {
        regx: selectedName,
        status: key === "activeTab" ? "active" : "inactive",
      };
      dispatch(searchUserName(data));
    }
    if (selectedEmail) {
      const data = {
        regx: selectedEmail,
        status: key === "activeTab" ? "active" : "inactive",
      };
      dispatch(searchUserEmail(data));
    }
  };

  const clearData = () => {
    if (selected || selectedName || selectedEmail) {
      setSelected(null);
      setSelectedName("");
      setSelectedEmail("");
      if (key === "activeTab") {
        dispatch(fetchActiveUsers());
      } else {
        dispatch(fetchInactiveUsers());
      }
    }
  };

  useEffect(() => {
    if (userRoleSuccess) {
      setData(userRoleSuccess);
    }
  }, [userRoleSuccess]);

  useEffect(() => {
    if (userNameSuccess) {
      setData(userNameSuccess);
    }
  }, [userNameSuccess]);

  useEffect(() => {
    if (userEmailSuccess) {
      setData(userEmailSuccess);
    }
  }, [userEmailSuccess]);
  //Filters(End):
  useEffect(() => {
    if (userUpgrade) {
      setUpgrade(true);
    }
  }, [userUpgrade]);
  const handleClose = () => setUpgrade(false);

  useEffect(() => {
    if (activeError) {
      setShowDowngradeModal(true);
      if (activeError.response.data) {
        setDeleteList(activeError.response.data);
      }
    }
  }, [activeError]);

  return (
    <>
      <div className="user">
        <AlertToast
          show={alert}
          alert={
            isSuccess.status === 200 ||
            isEditSuccess ||
            isDeleteSuccess ||
            bulkInactivateUsersSuccess.status === "success"
              ? "success"
              : "fail"
          }
          msg={alertMsg}
          close={closeToast}
        />
        <UpgradeModal show={upgrade} handleClose={handleClose} />
        <DowngradeModal
          show={showDowngradeModal}
          handleClose={toggleDowngradeModal}
          data={deleteList}
          name="users"
        />
        <div className="content-title">User Management</div>
        {/* <button
          type="button"
          className="apply-button me-3"
          onClick={openDowngradeModal}
        >
          Apply
        </button> */}
        <div className="row mb-4">
          <div className="col-md-3 mb-2">
            <input
              type="text"
              placeholder="Search Name"
              className="searchBox"
              value={selectedName}
              onChange={(e) => handleNameChange(e.target.value)}
            />
          </div>
          <div className="col-md-3 mb-2">
            <input
              type="text"
              placeholder="Search Email"
              className="searchBox"
              value={selectedEmail}
              onChange={(e) => handleEmailChange(e.target.value)}
            />
          </div>
          <div className="col-md-3 mb-2">
            <Select
              options={roles}
              className="react-select-container"
              classNamePrefix="react-select"
              placeholder="Select Role"
              getOptionLabel={(option) => option.role}
              getOptionValue={(option) => option.role}
              onChange={handleChange}
              value={roles.filter((op) => {
                return op.role === selected;
              })}
            />
          </div>
          <div className="col-md-3 mb-2">
            <button
              type="button"
              className="apply-button me-3"
              onClick={getData}
            >
              Apply
            </button>
            <button type="cancel" className="cancel-button" onClick={clearData}>
              Clear
            </button>
          </div>
        </div>

        <div className="d-flex">
          <button type="button" className="add-button" onClick={openModal}>
            <UilPlus size="16" />
            Add User
          </button>
        </div>

        <div className="tabs-section mt-3">
          <Tabs
            defaultActiveKey="activeTab"
            id="uncontrolled-tab-example"
            className="mb-3"
            transition={true}
            activeKey={key}
            onSelect={(key) => {
              setKey(key);
              setSelected(null);
              setSelectedName("");
              setSelectedEmail("");
            }}
          >
            <Tab eventKey="activeTab" title="Active Users">
              {isLoading || isLoadingData ? (
                <div className="d-flex justify-content-center">
                  <Loaders color="#5f48ea" />
                </div>
              ) : (
                <>
                  <div className="d-flex justify-content-end select-form-control">
                    Show
                    <select
                      className="select-enteries ms-2 me-2 mb-2"
                      value={itemsPerPage}
                      onChange={(e) => setItemsPerPage(e.target.value)}
                    >
                      <option value="10">10</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                    Entries
                  </div>
                  <UserTable data={currentItems} />
                  <ReactPaginate
                    previousLabel={"<<"}
                    nextLabel={">>"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-center"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </>
              )}
            </Tab>
            <Tab eventKey="inactiveTab" title="Inactive Users">
              {isLoading || isLoadingData ? (
                <div className="d-flex justify-content-center">
                  <Loaders color="#5f48ea" />
                </div>
              ) : (
                <>
                  <div className="d-flex justify-content-end select-form-control">
                    Show
                    <select
                      className="select-enteries ms-2 me-2 mb-2"
                      value={itemsPerPage}
                      onChange={(e) => setItemsPerPage(e.target.value)}
                    >
                      <option value="10">10</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                    Entries
                  </div>
                  <UserTable data={currentItems} />
                  <ReactPaginate
                    previousLabel={"<<"}
                    nextLabel={">>"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-center"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </>
              )}
            </Tab>
          </Tabs>
        </div>

        <UserForm
          show={show}
          toggle={toggleModal}
          form={"isAdd"}
          roles={roles}
        />
      </div>
    </>
  );
}

export default UserManagement;
