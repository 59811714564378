import React, { useEffect } from "react";
import { userName } from "../../Common/CommonFunction";
import DashboardCards from "../../Components/Cards/DashboardCards";
import {
  UilYoutube,
  UilSpinnerAlt,
  UilCheckCircle,
  UilUploadAlt,
  UilArchive,
} from "@iconscout/react-unicons";
import { useDispatch, useSelector } from "react-redux";
import { getL2RCount } from "../../Feature/LevelTwoResource/dashboardSlice";

const Dashboard = () => {
  var token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const { getL2RDashboardCount } = useSelector((state) => state.L2RDashboard);

  //useeffects:
  useEffect(() =>{
    dispatch(getL2RCount());
    // eslint-disable-next-line
  },[])

  const data = [
    {
      name: "Not Started Projects",
      value: getL2RDashboardCount.getnotstarted
        ? getL2RDashboardCount.getnotstarted[0].count
        : 0,
      icon: <UilYoutube size="30" color="#fff" />,
    },
    {
      name: "Inprogress Projects",
      value: getL2RDashboardCount.inprogress
        ? getL2RDashboardCount.inprogress[0].count
        : 0,
      icon: <UilSpinnerAlt size="30" color="#fff" />,
    },
    {
      name: "Ready To Upload",
      value: getL2RDashboardCount.readyupload
        ? getL2RDashboardCount.readyupload[0].count
        : 0,
      icon: <UilCheckCircle size="30" color="#fff" />,
    },
    {
      name: "Uploaded Projects",
      value: getL2RDashboardCount.uploaded
        ? getL2RDashboardCount.uploaded[0].count
        : 0,
      icon: <UilUploadAlt size="30" color="#fff" />,
    },
    {
      name: "Archive Projects",
      value: getL2RDashboardCount.archive
        ? getL2RDashboardCount.archive[0].count
        : 0,
      icon: <UilArchive size="30" color="#fff" />,
    },
  ];
  return (
    <>
      <div className="dashboard">
        <div className="dashboard-title">Welcome {userName(token)}</div>
        <div className="row justify-content-evenly">
          {data.map((item, index) => (
            <DashboardCards data={item} key={index} />
          ))}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
