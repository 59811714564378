import React, { useEffect, useState } from "react";
import { dateFormat } from "../../Common/dateFormat";
import { useDispatch } from "react-redux";
import { updatePmReportPaid } from "../../Feature/ProjectManager/TeamReport/reportPaidSlice";
import { v4 as uuidv4 } from "uuid";

function TeamReportTable({ state, data }) {
  const [newData, setNewData] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (data) {
      setNewData(data.map((item) => Object.assign({}, item, { id: uuidv4() })));
    }
  }, [data]);
  const handleChange = (e) => {
    const { name, checked } = e.target;
    console.log(name, checked);
    if (name === "selectAll") {
      let tempUser = newData.map((item) => {
        return { ...item, isChecked: checked };
      });
      setNewData(tempUser);
    } else {
      let tempUser = newData.map((item) =>
        item.id === name ? { ...item, isChecked: checked } : item
      );
      setNewData(tempUser);
    }
  };

  const pay = () => {
    console.log(newData);
    for (var i = 0; i < newData.length; i++) {
      if (newData[i].isChecked) {
        const obj = {
          project_id: newData[i].project_id,
          user_id: newData[i].user_id,
          paid_by: localStorage.getItem("user_id"),
        };
        dispatch(updatePmReportPaid(obj));
      }
    }
  };

  return (
    <>
      {state === "1" && (
        <button type="button" className="apply-button" onClick={pay}>
          Mark as Paid
        </button>
      )}
      <div className="team-report-table mt-3">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>#</th>
                <th>User</th>
                <th>Email</th>
                <th>Project</th>
                <th>Drive Link</th>
                {state === "0" || state === "1" ? (
                  <th>{state === "0" ? "Start Date" : "Submitted Date"}</th>
                ) : null}
                {state === "1" ? (
                  <th>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      name="selectAll"
                      checked={
                        !newData.some((user) => user?.isChecked !== true)
                      }
                      onChange={handleChange}
                    />
                  </th>
                ) : null}
                {state === "2" ? <th>Paid Date</th> : null}
                {state === "2" ? <th>Paid By</th> : null}
              </tr>
            </thead>
            <tbody>
              {newData.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item?.first_name + " " + item?.last_name}</td>
                  <td>{item?.email}</td>
                  <td>{item?.project_name}</td>
                  <td><a href={item?.project_link} target="_blank" rel="noreferrer">View Link</a></td>
                  {item?.updated_at !== null ? (
                    <td>{dateFormat(item?.updated_at)}</td>
                  ) : (
                    <td>{dateFormat(item?.created_at)}</td>
                  )}
                  {state === "1" ? (
                    <td>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        name={item.id}
                        checked={item?.isChecked || false}
                        onChange={handleChange}
                      />
                    </td>
                  ) : null}
                  {state === "2" ? (
                    <td>{item?.paid_first + " " + item?.paid_last}</td>
                  ) : null}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default TeamReportTable;
