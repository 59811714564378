import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../Utils/Api";
import {
  assignedScriptProjectPath,
  assignedVoiceProjectPath,
  assignedVideoProjectPath,
  assignedThumbProjectPath,
  assignedDescProjectPath,
  uploadScriptProjectPath,
  // uploadScriptCompletedPath,
  uploadVoiceProjectPath,
  // uploadVoiceCompletedPath,
  uploadVideoProjectPath,
  // uploadVideoCompletedPath,
  uploadThumbCompletedPath,
  uploadDescCompletedPath,
} from "../../Utils/ApiPathResources";

const initialState = {
  isLoading: false,
  assignedProjects: [],
  assignedProjectFail: "",
  uploadProject: false,
  uploadProjectFail: "",
};

export const getAssignedProjects = createAsyncThunk(
  "/resources/getAssignedProject",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(assignedProjectPending());
      var role_id = Number(localStorage.getItem("role_id"));
      var user_id = Number(localStorage.getItem("user_id"));
      if (role_id === 3) {
        const res = await api.get(assignedScriptProjectPath + user_id);
        if (res.data) {
          thunkAPI.dispatch(assignedProjectSuccess(res.data));
        }
      }
      if (role_id === 4) {
        const res = await api.get(assignedVoiceProjectPath + user_id);
        if (res.data) {
          thunkAPI.dispatch(assignedProjectSuccess(res.data));
        }
      }
      if (role_id === 5) {
        const res = await api.get(assignedVideoProjectPath + user_id);
        if (res.data) {
          thunkAPI.dispatch(assignedProjectSuccess(res.data));
        }
      }
      if (role_id === 7) {
        const res = await api.get(assignedThumbProjectPath + user_id);
        if (res.data) {
          thunkAPI.dispatch(assignedProjectSuccess(res.data));
        }
      }
      if (role_id === 8) {
        const res = await api.get(assignedDescProjectPath + user_id);
        if (res.data) {
          thunkAPI.dispatch(assignedProjectSuccess(res.data));
        }
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(assignedProjectFail(err));
    }
  }
);

export const uploadProject = createAsyncThunk(
  "/resources/uploadProject",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(assignedProjectPending());
      var role_id = Number(localStorage.getItem("role_id"));
      // const obj2 = {
      //   state: 1,
      // };
      if (role_id === 3) {
        const obj = {
          stage_id: 3,
          state: 1,
        };
        const res = await api.put(uploadScriptProjectPath + _, obj);
        // const data = await api.put(uploadScriptCompletedPath + _, obj2);
        if (res.data) {
          thunkAPI.dispatch(uploadProjectSuccess());
          setTimeout(() => {
            thunkAPI.dispatch(clearUploadProject());
          }, 3000);
        }
      }
      if (role_id === 4) {
        const obj = {
          stage_id: 4,
          state: 1,
        };
        const res = await api.put(uploadVoiceProjectPath + _, obj);
        // const data = await api.put(uploadVoiceCompletedPath + _, obj2);
        if (res.data) {
          thunkAPI.dispatch(uploadProjectSuccess());
          setTimeout(() => {
            thunkAPI.dispatch(clearUploadProject());
          }, 3000);
        }
      }
      if (role_id === 5) {
        const obj = {
          stage_id: 5,
          state: 1,
        };
        const res = await api.put(uploadVideoProjectPath + _, obj);
        // const data = await api.put(uploadVideoCompletedPath + _, obj2);
        if (res.data) {
          thunkAPI.dispatch(uploadProjectSuccess());
          setTimeout(() => {
            thunkAPI.dispatch(clearUploadProject());
          }, 3000);
        }
      }
      if (role_id === 7) {
        const obj = {
          state: 1,
        };
        const res = await api.put(uploadThumbCompletedPath + _, obj);
        if (res.data) {
          thunkAPI.dispatch(uploadProjectSuccess(res.data));
          setTimeout(() => {
            thunkAPI.dispatch(clearUploadProject());
          }, 3000);
        }
      }
      if (role_id === 8) {
        const obj = {
          state: 1,
        };
        const res = await api.put(uploadDescCompletedPath + _, obj);
        if (res.data) {
          thunkAPI.dispatch(uploadProjectSuccess(res.data));
          setTimeout(() => {
            thunkAPI.dispatch(clearUploadProject());
          }, 3000);
        }
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(uploadProjectFail(err.response.data.error));
      setTimeout(() => {
        thunkAPI.dispatch(clearUploadProject());
      }, 3000);
    }
  }
);

const L1RAssignedProject = createSlice({
  name: "L1R",
  initialState,
  reducers: {
    assignedProjectPending: (state) => {
      state.isLoading = true;
    },
    assignedProjectSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.assignedProjects = payload;
      state.assignedProjectFail = "";
    },
    assignedProjectFail: (state, { payload }) => {
      state.isLoading = false;
      state.assignedProjects = [];
      state.assignedProjectFail = payload;
    },
    uploadProjectPending: (state) => {
      state.isLoading = true;
    },
    uploadProjectSuccess: (state) => {
      state.isLoading = false;
      state.uploadProject = true;
      state.uploadProjectFail = "";
    },
    uploadProjectFail: (state, { payload }) => {
      state.isLoading = false;
      state.uploadProject = false;
      state.uploadProjectFail = payload;
    },
    clearUploadProject: (state) => {
      state.isLoading = false;
      state.uploadProject = false;
      state.uploadProjectFail = "";
    },
  },
});

export const {
  assignedProjectPending,
  assignedProjectSuccess,
  assignedProjectFail,
  uploadProjectPending,
  uploadProjectSuccess,
  uploadProjectFail,
  clearUploadProject,
} = L1RAssignedProject.actions;
export default L1RAssignedProject.reducer;
