import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import PaymentHistory from "./PaymentHistory";
import { useDispatch, useSelector } from "react-redux";
import { fetchSubscription } from "../../../Feature/Admin/Subscription/SubscriptionSlice";
import { dateFormat } from "../../../Common/dateFormat";
import {customerPortalPath } from "../../../Config/RequestPath";

const Subscriptions = () => {
  const business_id = localStorage.getItem("business_id");
  const dispatch = useDispatch();
  const { subscription } = useSelector((state) => state.subscription);
  useEffect(() => {
    if (business_id) {
      dispatch(fetchSubscription(business_id));
    }
    // eslint-disable-next-line
  }, [business_id]);

  const [plan, setPlan] = useState("Plan");
  const [price, setPrice] = useState(0);
  const [type, setType] = useState("");
  const [nextDate, setNextDate] = useState("");
  const [payments, setPayments] = useState([]);
  const [user, setUser] = useState(0);
  const [channel, setChannel] = useState(0);
  const [project, setProject] = useState(0);
  const [totalUser, setTotalUser] = useState(0);
  const [totalChannel, setTotalChanel] = useState(0);
  const [totalproject, setTotalProject] = useState(0);
  const [freePlan, setFreePlan] = useState(true);
  // console.log(subscription.packageData[0].package_name);
  useEffect(() => {
    if (subscription) {
      if (subscription.packageData) {
        setPlan(subscription.packageData[0].package_name);
      }
      if (subscription.packageTypeData) {
        setPrice(subscription.packageTypeData[0].price);
      }
      if (subscription.packageTypeData) {
        if (subscription.packageTypeData[0].package_type === 2) {
          setType("month");
        } else if (subscription.packageTypeData[0].package_type === 1) {
          setType("year");
        }
        if(subscription.packageTypeData[0].price !== 0){
          setFreePlan(false);
        }
      }
      if (subscription.subscriptionData) {
        // setNextDate(subscription.subscriptionData[0].end_date);
        setPayments(subscription.subscriptionData);
      }
      if (subscription.subscriptionData) {
        for (var j = 0; j < subscription.subscriptionData.length; j++) {
          if (subscription.subscriptionData[j].status === "1") {
            setNextDate(subscription.subscriptionData[j].end_date);
          }
        }
      }
      if (subscription.packagePropertyData) {
        for (var i = 0; i < subscription.packagePropertyData.length; i++) {
          if (subscription.packagePropertyData[i].property_name === "Users") {
            setTotalUser(subscription.packagePropertyData[i].property_value);
          }
          if (subscription.packagePropertyData[i].property_name === "Channel") {
            setTotalChanel(subscription.packagePropertyData[i].property_value);
          }
          if (
            subscription.packagePropertyData[i].property_name === "Projects"
          ) {
            setTotalProject(subscription.packagePropertyData[i].property_value);
          }
        }
      }
      if (subscription.channelcount) {
        setChannel(subscription.channelcount);
      }
      if (subscription.projectcount) {
        setProject(subscription.projectcount);
      }
      if (subscription.usercount) {
        setUser(subscription.usercount);
      }
    }
  }, [subscription]);

  return (
    <>
      <div className="subscription">
        <div className="content-title">Subscription</div>
        <div className="wrapper row g-0 d-flex justify-content-between">
          <div className="col-md-5">
            <Card>
              <Card.Body className="d-flex">
                <div className="col-md-6">
                  <div className="plan">{plan}</div>
                  <div className="mt-3">
                    <div style={{ fontSize: "12px" }}>
                      {user} of {totalUser} Users
                    </div>
                    <ProgressBar now={user} max={totalUser} className="mb-2"/>
                    <div style={{ fontSize: "12px" }}>
                      {channel} of {totalChannel} Channels
                    </div>
                    <ProgressBar now={channel} max={totalChannel} className="mb-2"/>
                    <div style={{ fontSize: "12px" }}>
                      {project} of {totalproject} Projects
                    </div>
                    <ProgressBar now={project} max={totalproject} className="mb-2"/>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="d-flex justify-content-end align-items-center">
                    <div className="dollar">$</div>
                    <div className="price"> {price}</div> /
                    <div className="per"> {type}</div>
                  </div>
                  <div className="text-end mt-3">
                    <button
                      className="apply-button"
                      onClick={() =>
                        window.open(
                          customerPortalPath,
                          "_blank"
                        )
                      }
                    >
                      Change Subscription
                    </button>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-4">
           {freePlan === false && <Card>
              <Card.Body>
                <div style={{ fontSize: "12px", color: "#7c828d" }}>
                  Plan Renewal{freePlan === true}
                </div>
                <div>on {dateFormat(nextDate)}</div>
                {/* <button className="cancel-button mt-4">Manage Payments</button> */}
              </Card.Body>
            </Card>}
          </div>
          <div className="mt-5">
            <div className="content-title">Payment History</div>
            <PaymentHistory payments={payments} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscriptions;
