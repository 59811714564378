import React, { useState } from "react";
import { Card } from "react-bootstrap";
import ProjectForm from "./ProjectForm";
import {
  UilUserMd,
  UilShieldCheck,
  UilImage,
  UilFileEditAlt,
} from "@iconscout/react-unicons";
import { dateFormat } from "../../../../Common/dateFormat";
import Dropdown from "react-bootstrap/Dropdown";

function ArchiveCard({ project, index, color, stage, state }) {
  const [show, setShow] = useState(false);
  const openModal = () => {
    setShow(true);
  };
  const toggleModal = () => {
    setShow(!show);
  };
  return (
    <>
    <div className="project-card" style={{cursor:'pointer'}}>
      <Card style={{ minHeight: "90px" }}>
        <div className="row">
          <div className="col-md-10" onClick={openModal}>
            {project?.project_name}
            <div style={{ fontSize: "11px" }} className="mb-2 mt-2">
              {dateFormat(project.due_date) === "Invalid Date"
                ? "-"
                : dateFormat(project.due_date)}
            </div>
            <div>
              {project?.project_state === 1 ? (
                <UilShieldCheck color="#6bc950" size="20" />
              ) : project.project_state === 2 ? (
                <UilShieldCheck color="#f7ce51" size="20" />
              ) : (
                <UilShieldCheck color="#b9bec7" size="20" />
              )}
            </div>
          </div>
          <div className="col-md-2 g-0">
            <Dropdown drop="down">
              <Dropdown.Toggle id="dropdown-basic">
                <div
                  className="user-icon"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  title="Assigned Users"
                >
                  <UilUserMd color="#b9bec7" />
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item>
                  <div className="d-flex">
                    <div style={{ color: "#fd7171" }}>Script writer:</div>
                    {project?.scriptdata !== 0 ? (
                      <div className="ms-2">
                        {project?.scriptdata?.first_name +
                          " " +
                          project?.scriptdata?.last_name}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Dropdown.Item>
                <Dropdown.Item>
                  <div className="d-flex">
                    <div style={{ color: "#5bc5fa" }}>Voice artist:</div>
                    {project?.voicedata !== 0 ? (
                      <div className="ms-2">
                        {project?.voicedata?.first_name +
                          " " +
                          project?.voicedata?.last_name}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Dropdown.Item>
                <Dropdown.Item>
                  <div className="d-flex">
                    <div style={{ color: "#6bc950" }}>Video editor:</div>
                    {project?.videodata !== 0 ? (
                      <div className="ms-2">
                        {project?.videodata?.first_name +
                          " " +
                          project?.videodata?.last_name}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Dropdown.Item>
                <Dropdown.Item>
                  <div className="d-flex">
                    <div style={{ color: "#f5cd51" }}>Thumbnail artist:</div>
                    {project?.thumbnailUser !== 0 ? (
                      <div className="ms-2">
                        {project?.thumbnailUser?.first_name +
                          " " +
                          project?.thumbnailUser?.last_name}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Dropdown.Item>
                <Dropdown.Item>
                  <div className="d-flex">
                    <div style={{ color: "#8041d0" }}>Description writer:</div>
                    {project?.descriptionUser !== 0 ? (
                      <div className="ms-2">
                        {project?.descriptionUser?.first_name +
                          " " +
                          project?.descriptionUser?.last_name}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <div className="resource-icon mt-3">
              <div>
                {project?.thumbnail === 0 ? (
                  <UilImage color="#b9bec7" size="20" />
                ) : (
                  <div
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title={
                      project?.thumbnailUser?.first_name +
                      " " +
                      project?.thumbnailUser?.last_name
                    }
                  >
                    <UilImage color="#7b68ee" size="20" />
                  </div>
                )}
              </div>
              <div>
                {project?.description === 0 ? (
                  <UilFileEditAlt color="#b9bec7" size="20" />
                ) : (
                  <div
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title={
                      project?.descriptionUser?.first_name +
                      " " +
                      project?.descriptionUser?.last_name
                    }
                  >
                    <UilFileEditAlt color="#7b68ee" size="20" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Card>
      </div>
      <ProjectForm
        show={show}
        toggle={toggleModal}
        color="#0231E8"
        stage="Archive"
        formData={project}
        state = {state}
      />
    </>
  );
}

export default ArchiveCard;
