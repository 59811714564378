import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import Select from "react-select";
import { ClientChannelValidationSchema } from "../../Common/Validations";
import { UilExclamationTriangle } from "@iconscout/react-unicons";
import { useDispatch } from "react-redux";
import {
  addClientChannel,
  editClientChannel,
} from "../../Feature/Client/channelSlice";
import { editClientChannelStatus } from "../../Feature/Client/channelSlice";

const ChannelForm = ({ show, toggle, formType, data }) => {
  const [formValues, setFormValues] = useState({
    channel: "",
    description: "",
    status: "",
  });
  const [selectedStatus, setSelectedStatus] = useState("");
  const options = [
    { label: "active", status: "active" },
    { label: "inactive", status: "inactive" },
  ];
  const dispatch = useDispatch();
  //useEffects:
  useEffect(() => {
    if (formType === "isEdit" && data) {
      setFormValues({
        channel: data.channel,
        description: data.description,
        status: data.status,
      });
      setSelectedStatus({ label: data.status, value: data.status });
    } else if(formType === "isAdd" && show){
      setFormValues({
        channel: "",
        description: "",
        status: "",
      });
      setSelectedStatus({ label: "Select status", value: "" });
    }
  }, [formType, data, show]);

  //handlers:
  const handleSubmit = (values) => {
    if (formType === "isAdd") {
      dispatch(addClientChannel(values));
      toggle();
    } else {
      const obj = {
        channel_id: data.channel_id,
        channel: values.channel,
        description: values.description,
      };
      dispatch(editClientChannel(obj));
      toggle();
    }
  };

  return (
    <>
      <Modal
        size="md"
        aria-labelledby="example-modal-sizes-title-lg"
        show={show}
        onHide={toggle}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="content-title">
            {formType === "isAdd" ? "Add Channel" : "Edit Channel"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <Formik
            enableReinitialize={true}
            initialValues={formValues}
            validationSchema={ClientChannelValidationSchema}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {({ errors, touched, setFieldValue }) => (
              <Form>
                <div className="row modal-form-main g-0">
                  <div className="col-md">
                    <label>
                      Channel name<span className="asterisk">*</span> :
                    </label>
                    <Field
                      type="text"
                      id="channel"
                      name="channel"
                      className={
                        errors.channel && touched.channel
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      placeholder="Enter channel name"
                    />
                    {/* error */}
                    {touched.channel && errors.channel ? (
                      <div className="invalid-feedback d-flex">
                        <UilExclamationTriangle size="15" className="me-1" />
                        {errors.channel}
                      </div>
                    ) : null}
                  </div>
                  <div className="row modal-form-main g-0 mt-4">
                    <div className="col-md">
                      <label>Channel description:</label>
                      <Field
                        type="text"
                        id="description"
                        name="description"
                        className="form-control"
                        placeholder="Enter description"
                      />
                    </div>
                  </div>
                  <div className="row modal-form-main g-0 mt-4">
                    <div className="col-md">
                      <label>Status</label>
                      <Select
                        name="status"
                        options={options}
                        className={
                          errors.status && touched.status
                            ? "react-select-container is-invalid"
                            : "react-select-container"
                        }
                        classNamePrefix="react-select"
                        placeholder="Select Status"
                        onChange={(value) => {
                          setSelectedStatus({
                            label: value.label,
                            value: value.status,
                          });
                          setFieldValue("status", value.status);
                          if (formType === "isEdit") {
                            const obj = {
                              channel_id: data.channel_id,
                              status: value.status,
                            };
                            dispatch(editClientChannelStatus(obj));
                          }
                        }}
                        value={selectedStatus}
                      />
                      {/* error */}
                      {touched.status && errors.status ? (
                        <div className="invalid-feedback d-flex">
                          <UilExclamationTriangle size="15" className="me-1" />
                          {errors.status}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="text-center mt-5">
                  <button className="apply-button" type="submit">
                    {formType === "isAdd" ? "Add channel" : "Update Channel"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ChannelForm;
