import {
  getCountPending,
  getCountSuccess,
  getCountFail,
  getProjectDueSucess,
  getProjectDueFail,
} from "./dashboardSlice";
import api from "../../../Utils/Api";
import { getCountPath, dueProjectPath } from "../../../Utils/ApiPath";

export const getCount = () => async (dispatch) => {
  try {
    dispatch(getCountPending());
    const res = await api.get(getCountPath);
    if (res) {
      dispatch(getCountSuccess(res.data));
      return;
    }
  } catch (err) {
    console.log(err);
    dispatch(getCountFail(err));
    return;
  }
};

export const getProjectDue = () => async (dispatch) => {
  try {
    dispatch(getCountPending());
    const res = await api.get(dueProjectPath);
    if (res) {
      dispatch(getProjectDueSucess(res.data));
      return;
    }
  } catch (err) {
    console.log(err);
    dispatch(getProjectDueFail(err));
    return;
  }
};
