import React, { useState, useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import Select from "react-select";
import { UilPlus } from "@iconscout/react-unicons";
import ChannelTable from "./ChannelTable";
import { useDispatch, useSelector } from "react-redux";
import {
  getActiveChannel,
  getChannel,
  getChannelByClient,
  getInactiveChannel,
} from "../../../Feature/Admin/ChannelManagement/channelAction";
import Loaders from "../../../Components/Loaders/Loaders";
import ChannelForm from "./ChannelForm";
import { fetchUser } from "../../../Feature/Admin/Dropdown/getUserSlice";
import { getChannelPending } from "../../../Feature/Admin/ChannelManagement/channelSlice";
import { AlertToast } from "../../../Common/Toast";
import ReactPaginate from "react-paginate";
import UpgradeModal from "../../../Common/UpgradeModal";
import DowngradeModal from "../../../Components/Modal/DowngradeModal";

function ChannelManagement() {
  //Pagination hooks
  const [data, setData] = useState([]);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [recordsPerPage, setRecordsPerPage] = useState(10);
  // const indexOfLastRecord = currentPage * recordsPerPage;
  // const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  // const currentRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);
  // const nPages = Math.ceil(data.length / recordsPerPage);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + Number(itemsPerPage);
  const currentItems = data.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(data.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    setItemOffset(newOffset);
  };

  const [alert, setAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");

  const [upgrade, setUpgrade] = useState(false);

  const [key, setKey] = useState("activeTab");
  const [show, setShow] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [channelName, setChannelName] = useState("");

  const [showDowngradeModal, setShowDowngradeModal] = useState(false);
  const [deleteList, setDeleteList] = useState(false);

  const dispatch = useDispatch();

  const {
    activeChannels,
    isLoading,
    inactiveChannels,
    searchChannelByClient,
    searchChannel,
    activeError,
  } = useSelector((state) => state.channel);
  const { getUserSuccess } = useSelector((state) => state.dropdown);
  const { addChannel, addChannelError, channelUpgrade } = useSelector(
    (state) => state.channelForm
  );
  const { editChannelSuccess } = useSelector((state) => state.channelEdit);
  const { deleteChannelSuccess, updateDateSuccess, updateChannelModeSuccess } =
    useSelector((state) => state.channelDelete);
  const { bulkInactivateChannelSuccess, bulkInactivateChannelError } =
    useSelector((state) => state.channelDelete);

  useEffect(() => {
    dispatch(fetchUser());
    // eslint-disable-next-line
  }, []);

  // //UseEffect hooks
  useEffect(() => {
    if (key === "activeTab") {
      dispatch(getChannelPending());
      setTimeout(() => {
        dispatch(getActiveChannel());
      }, 100);
      setItemOffset(0);
    } else if (key === "inactiveTab") {
      setTimeout(() => {
        dispatch(getInactiveChannel());
      }, 100);
      setItemOffset(0);
    }
    // eslint-disable-next-line
  }, [key]);

  useEffect(() => {
    if (activeChannels) {
      setData(activeChannels);
    }
  }, [activeChannels]);

  useEffect(() => {
    if (inactiveChannels) {
      setData(inactiveChannels);
    }
  }, [inactiveChannels]);

  useEffect(() => {
    if (searchChannelByClient) {
      setData(searchChannelByClient);
    }
  }, [searchChannelByClient]);

  useEffect(() => {
    if (searchChannel) {
      setData(searchChannel);
    }
  }, [searchChannel]);

  useEffect(() => {
    if (key === "activeTab" && deleteChannelSuccess) {
      setAlert(true);
      setAlertMsg("Channel deleted Successfully");
      dispatch(getChannelPending());
      setTimeout(() => {
        dispatch(getActiveChannel());
      }, 100);
    } else if (key === "inactiveTab" && deleteChannelSuccess) {
      setTimeout(() => {
        dispatch(getInactiveChannel());
      }, 100);
    }
    // eslint-disable-next-line
  }, [key, deleteChannelSuccess]);

  useEffect(() => {
    if (key === "activeTab" && bulkInactivateChannelSuccess) {
      setAlert(true);
      setAlertMsg("Channel Updated Successfully");
      dispatch(getChannelPending());
      setTimeout(() => {
        dispatch(getActiveChannel());
      }, 100);
    } else if (key === "inactiveTab" && bulkInactivateChannelSuccess) {
      setTimeout(() => {
        dispatch(getInactiveChannel());
      }, 100);
    }
    // eslint-disable-next-line
  }, [key, bulkInactivateChannelSuccess]);

  //Handlers
  const handleShow = () => setShow(true);
  const toggleModal = () => setShow(!show);

  const closeToast = () => {
    setAlert(false);
  };

  useEffect(() => {
    if (addChannel) {
      setAlert(true);
      setAlertMsg("Added Channel successfully");
      if (key === "activeTab") {
        dispatch(getChannelPending());
        setTimeout(() => {
          dispatch(getActiveChannel());
        }, 100);
      } else if (key === "inactiveTab") {
        setTimeout(() => {
          dispatch(getInactiveChannel());
        }, 100);
      }
    }
    if (addChannelError) {
      setAlert(true);
      setAlertMsg(addChannelError);
      if (key === "activeTab") {
        dispatch(getChannelPending());
        setTimeout(() => {
          dispatch(getActiveChannel());
        }, 100);
      } else if (key === "inactiveTab") {
        setTimeout(() => {
          dispatch(getInactiveChannel());
        }, 100);
      }
    }
    // eslint-disable-next-line
  }, [addChannel, addChannelError]);

  useEffect(() => {
    if (editChannelSuccess) {
      setAlert(true);
      setAlertMsg("Updated Channel successfully");
      if (key === "activeTab") {
        dispatch(getChannelPending());
        setTimeout(() => {
          dispatch(getActiveChannel());
        }, 100);
      } else if (key === "inactiveTab") {
        setTimeout(() => {
          dispatch(getInactiveChannel());
        }, 100);
      }
    }
    // eslint-disable-next-line
  }, [editChannelSuccess]);

  useEffect(() => {
    if (updateDateSuccess) {
      if (key === "activeTab") {
        dispatch(getChannelPending());
        setTimeout(() => {
          dispatch(getActiveChannel());
        }, 100);
      } else if (key === "inactiveTab") {
        setTimeout(() => {
          dispatch(getInactiveChannel());
        }, 100);
      }
    }
    // eslint-disable-next-line
  }, [updateDateSuccess]);

  useEffect(() => {
    if (updateChannelModeSuccess) {
      if (key === "activeTab") {
        dispatch(getChannelPending());
        setTimeout(() => {
          dispatch(getActiveChannel());
        }, 100);
      } else if (key === "inactiveTab") {
        setTimeout(() => {
          dispatch(getInactiveChannel());
        }, 100);
      }
    }
    // eslint-disable-next-line
  }, [updateChannelModeSuccess]);

  const onSearchByClient = (value) => {
    setSelectedValue(value);
    if (value !== null) {
      const obj = {
        client_id: value.user_id,
        status: key === "activeTab" ? "active" : "inactive",
      };
      dispatch(getChannelByClient(obj));
    } else {
      if (key === "activeTab") {
        dispatch(getChannelPending());
        setTimeout(() => {
          dispatch(getActiveChannel());
        }, 100);
      } else if (key === "inactiveTab") {
        setTimeout(() => {
          dispatch(getInactiveChannel());
        }, 100);
      }
    }
  };

  const handleChannelChange = (selected) => {
    setChannelName(selected);
    if (selected) {
      const obj = {
        regx: selected,
        status: key === "activeTab" ? "active" : "inactive",
      };
      dispatch(getChannel(obj));
    } else {
      if (key === "activeTab") {
        dispatch(getChannelPending());
        setTimeout(() => {
          dispatch(getActiveChannel());
        }, 100);
      } else if (key === "inactiveTab") {
        setTimeout(() => {
          dispatch(getInactiveChannel());
        }, 100);
      }
    }
  };
  useEffect(() => {
    if (channelUpgrade) {
      setUpgrade(true);
    }
  }, [channelUpgrade]);
  const handleClose = () => setUpgrade(false);

  const toggleDowngradeModal = () => {
    setShowDowngradeModal(!showDowngradeModal);
  };

  useEffect(() => {
    console.log(activeError)
    if (activeError) {
      if(activeError.response.status === 401){
        if (activeError.response.data) {
          setShowDowngradeModal(true);
          setDeleteList(activeError.response.data);
        }
      }
    }
  }, [activeError]);

  return (
    <>
      <div className="channel">
        <AlertToast
          show={alert}
          alert={
            addChannel ||
            editChannelSuccess ||
            deleteChannelSuccess ||
            bulkInactivateChannelSuccess.status === "success"
              ? "success"
              : "fail"
          }
          msg={alertMsg}
          close={closeToast}
        />
        <UpgradeModal show={upgrade} handleClose={handleClose} />
        <DowngradeModal
          show={showDowngradeModal}
          handleClose={toggleDowngradeModal}
          data={deleteList}
          name="Channels"
        />
        <div className="content-title">Channel Management</div>
        <div className="row">
          <div className="col-md mb-2">
            <button type="button" className="add-button" onClick={handleShow}>
              <UilPlus size="16" /> &nbsp; Add Channel
            </button>
          </div>
          <div className="col-md row mb-2">
            <div className="col-md mb-3">
              <Select
                options={getUserSuccess?.clientData}
                className="react-select-container"
                classNamePrefix="react-select"
                placeholder="Select Client"
                getOptionLabel={(option) =>
                  option.first_name + " " + option.last_name
                }
                getOptionValue={(option) =>
                  option.first_name + " " + option.last_name
                }
                value={selectedValue}
                onChange={(value) => onSearchByClient(value)}
                isClearable={true}
              />
            </div>
            <div className="col-md mb-3">
              <input
                type="text"
                className="searchBox"
                placeholder="Search Channel Name"
                value={channelName}
                onChange={(e) => handleChannelChange(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="tabs-section mt-2">
          <Tabs
            defaultActiveKey="activeTab"
            id="uncontrolled-tab-example"
            className="mb-3"
            transition={true}
            activeKey={key}
            onSelect={(key) => setKey(key)}
          >
            <Tab eventKey="activeTab" title="Active Channels">
              {isLoading ? (
                <div className="d-flex justify-content-center">
                  <Loaders color="#5f48ea" />
                </div>
              ) : (
                <div>
                  <div className="d-flex justify-content-end select-form-control">
                    Show
                    <select
                      className="select-enteries ms-2 me-2 mb-2"
                      value={itemsPerPage}
                      onChange={(e) => setItemsPerPage(e.target.value)}
                    >
                      <option value="10">10</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                    Entries
                  </div>
                  <ChannelTable
                    tableData={currentItems}
                    data={getUserSuccess}
                    state={key}
                  />
                  <ReactPaginate
                    previousLabel={"<<"}
                    nextLabel={">>"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-center"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              )}
            </Tab>
            <Tab eventKey="inactiveTab" title="Inactive Channels">
              {isLoading ? (
                <div className="d-flex justify-content-center">
                  <Loaders color="#5f48ea" />
                </div>
              ) : (
                <div>
                  <div className="d-flex justify-content-end select-form-control">
                    Show
                    <select
                      className="select-enteries ms-2 me-2 mb-2"
                      value={itemsPerPage}
                      onChange={(e) => setItemsPerPage(e.target.value)}
                    >
                      <option value="10">10</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                    Entries
                  </div>
                  <ChannelTable
                    tableData={currentItems}
                    data={getUserSuccess}
                    state={key}
                  />
                  <ReactPaginate
                    previousLabel={"<<"}
                    nextLabel={">>"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-center"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              )}
            </Tab>
          </Tabs>
        </div>
        <ChannelForm
          show={show}
          toggle={toggleModal}
          data={getUserSuccess}
          form={"isAdd"}
        />
      </div>
    </>
  );
}

export default ChannelManagement;
