import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    count:{},
    dueProjects : [],
    isLoading:false,
    error:''
  };
  
  const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
      getCountPending: (state) => {
        state.isLoading = true;
      },
      getCountSuccess: (state,{payload}) => {
        state.count = payload;
        state.error = '';
        state.isLoading = false;
      },
      getCountFail: (state,{payload}) => {
        state.count = {};
        state.error = payload;
        state.isLoading = false;
      },
      getProjectDueSucess: (state,{payload}) =>{
        state.isLoading = false;
        state.dueProjects = payload;
        state.error = '';
      },
      getProjectDueFail: (state,{payload})=>{
        state.isLoading = false;
        state.dueProjects = [];
        state.error = payload;
      }
    },
  });
  
  const { reducer, actions } = dashboardSlice;
  
  export const { getCountPending, getCountSuccess, getCountFail, getProjectDueSucess, getProjectDueFail } = actions;
  
  export default reducer;