import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Utils/Api";
import {
  deleteChatPath,
  editChatPath,
  getChatsPath,
  getReadCouthPath,
  postChatPath,
  updateReadStatusPath,
} from "../../../Utils/ApiPath";
import axios from "axios";
import { baseAppURL } from "../../../Config/RequestPath";

const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: "",
  getChats: [],
  isEditSuccess: false,
  isEditFail: "",
  isDeleteSuccess: false,
  isDeleteFail: "",
  getReadCount: [],
  getReadCountFail: "",
  updateReadStatus: false,
  updateReadStatusFail: "",
};

export const sendChat = createAsyncThunk(
  "/chat/sendChat",
  async (_, thunkAPI) => {
    try {
      // console.log(_)
      let formData = new FormData();
      formData.append("comments", _.comments);
      formData.append("file", _.file);
      // console.log(formData.get("file"), formData.get("comments"));
      const user_id = Number(localStorage.getItem("user_id"));
      thunkAPI.dispatch(postChatPending());
      axios
        .post(baseAppURL + postChatPath + "/" + user_id, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          thunkAPI.dispatch(postChatSuccess(res.data));
        })
        .catch((err) => {
          console.log(err);
          thunkAPI.dispatch(postChatFail(err));
        });
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(postChatFail(err));
    }
  }
);

export const getChat = createAsyncThunk(
  "/chat/getChat",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getChatPending());
      const res = await api.get(getChatsPath);
      if (res) {
        thunkAPI.dispatch(getChatSuccess(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getChatFail(err));
    }
  }
);

export const updateChat = createAsyncThunk(
  "/chat/editChat",
  async (_, thunkAPI) => {
    try {
      var obj = {
        comments: _.comments,
      };
      thunkAPI.dispatch(editChatPending());
      const res = await api.put(editChatPath + _.id, obj);
      if (res) {
        thunkAPI.dispatch(editChatSuccess(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(editChatFail(err));
    }
  }
);

export const deleteChat = createAsyncThunk(
  "/chat/deleteChat",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(deleteChatPending());
      const res = await api.put(deleteChatPath + _);
      if (res) {
        thunkAPI.dispatch(deleteChatSuccess(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(deleteChatFail(err));
    }
  }
);

export const readCount = createAsyncThunk(
  "/chat/readCount",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(readCountPending());
      const res = await api.get(getReadCouthPath + _);
      if (res) {
        thunkAPI.dispatch(readCountSuccess(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(readCountFail(err));
    }
  }
);

export const updateReadStatus = createAsyncThunk(
  "/chat/updateReadStatus",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(updateReadStatusPending());
      const res = await api.put(updateReadStatusPath + _);
      if (res) {
        thunkAPI.dispatch(updateReadStatusSuccess(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(updateReadStatusFail(err));
    }
  }
);

const chatSlice = createSlice({
  name: "chatSlice",
  initialState,
  reducers: {
    postChatPending: (state) => {
      state.isLoading = true;
    },
    postChatSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = payload;
      state.isError = "";
    },
    postChatFail: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = "";
      state.isError = payload;
    },
    getChatPending: (state) => {
      state.isLoading = true;
    },
    getChatSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.getChats = payload;
      state.isError = "";
    },
    getChatFail: (state, { payload }) => {
      state.isLoading = false;
      state.getChats = [];
      state.isError = payload;
    },
    editChatPending: (state) => {
      state.isLoading = true;
    },
    editChatSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.isEditSuccess = payload;
      state.isEditFail = "";
    },
    editChatFail: (state, { payload }) => {
      state.isLoading = false;
      state.isEditSuccess = "";
      state.isEditFail = payload;
    },
    deleteChatPending: (state) => {
      state.isLoading = true;
    },
    deleteChatSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.isDeleteSuccess = payload;
      state.isDeleteFail = "";
    },
    deleteChatFail: (state, { payload }) => {
      state.isLoading = false;
      state.isDeleteSuccess = "";
      state.isDeleteFail = payload;
    },
    readCountPending: (state) => {
      state.isLoading = true;
    },
    readCountSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.getReadCount = payload;
      state.getReadCountFail = "";
    },
    readCountFail: (state, { payload }) => {
      state.isLoading = false;
      state.getReadCount = "";
      state.getReadCountFail = payload;
    },
    updateReadStatusPending: (state) => {
      state.isLoading = true;
    },
    updateReadStatusSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.updateReadStatus = payload;
      state.updateReadStatusFail = "";
    },
    updateReadStatusFail: (state, { payload }) => {
      state.isLoading = false;
      state.updateReadStatus = "";
      state.updateReadStatusFail = payload;
    },
  },
});

export const {
  postChatPending,
  postChatSuccess,
  postChatFail,
  getChatPending,
  getChatSuccess,
  getChatFail,
  editChatPending,
  editChatSuccess,
  editChatFail,
  deleteChatPending,
  deleteChatSuccess,
  deleteChatFail,
  readCountPending,
  readCountSuccess,
  readCountFail,
  updateReadStatusPending,
  updateReadStatusSuccess,
  updateReadStatusFail,
} = chatSlice.actions;
export default chatSlice.reducer;
