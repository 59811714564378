import React, { useEffect, useRef, useState, memo } from "react";
import { Modal } from "react-bootstrap";
import {
  UilShieldCheck,
  UilCalender,
  UilEllipsisH,
  UilTrash,
  // UilImage,
  // UilFileEditAlt,
  UilPen,
  UilFileGraph,
  UilArchive,
} from "@iconscout/react-unicons";
import Select from "react-select";
import { SendComment, ReadComment } from "./Comment";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUserOfChannel,
  fetchUserOfProject,
} from "../../../../Feature/Admin/Dropdown/getUserEditSlice";
import DropdownList from "./DropdownList";
import { Dropdown } from "react-bootstrap";
import { fetchProjectUsers } from "../../../../Feature/Admin/ChannelManagement/kanban/projectInfoSlice";
import { fetchVideoUser } from "../../../../Feature/Admin/Dropdown/getUserSlice";
import Revision from "./Revision";
import { dateFormat } from "../../../../Common/dateFormat";
import {
  deleteProjectData,
  deleteProjectScriptData,
  deleteProjectVideoData,
  deleteProjectVoiceData,
  updateProjectData,
  updateProjectScript,
  updateProjectState,
  updateProjectVideo,
  updateProjectVoice,
} from "../../../../Feature/Admin/ChannelManagement/kanban/updateProjectSlice";
import DeleteModal from "../../../../Common/DeleteModal";
import {
  updateDescState,
  updateThumbState,
} from "../../../../Feature/Admin/ChannelManagement/kanban/updateState";
import { getInitials } from "../../../../Common/CommonFunction";
import { useLocation } from "react-router-dom";
import { updateProjectStatus } from "../../../../Feature/Admin/ChannelManagement/kanban/updateSlice";
import { updateProjectPhase } from "../../../../Feature/Admin/ChannelManagement/kanban/updateSlice";
import jwtDecode from "jwt-decode";

function ProjectForm(props) {
  const { show, toggle, color, stage, formData, state } = props;

  const location = useLocation();
  const data = location.state?.channel_id;

  const dispatch = useDispatch();
  const { userOfChannelSuccess, userOfProjectSuccess } = useSelector(
    (state) => state.getUserEdit
  );
  const { getProjectInfoSuccess } = useSelector((state) => state.projectInfo);
  const { getVideoSuccess } = useSelector((state) => state.dropdown);
  const {
    // deleteProjectScriptSuccess,
    // deleteProjectVoiceSuccess,
    // deleteProjectVideoSuccess,
    updateProjectScriptFail,
    updateProjectVoiceFail,
    updateProjectVideoFail,
  } = useSelector((state) => state.updateProjectData);
  const { updateProjectStateFail } = useSelector(
    (state) => state.updateProjectData
  );
  const { updateThumbStateError, updateDescStateError } = useSelector(
    (state) => state.updateState
  );

  const [projectName, setProjectName] = useState("");
  const [projectDesc, setProjectDesc] = useState("");
  // const [initials, setInitials] = useState("");
  // const [clientName, setClientName] = useState("");
  const [status, setStatus] = useState("");
  const [projectLink, setProjectLink] = useState(
    "https://drive.google.com/-your-google-drive-or-dropbox-or-any-other-storage-link"
  );
  const [channelName, setChannelName] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [dueDate, setDueDate] = useState(null);
  const [script, setScript] = useState("");
  const [voice, setVoice] = useState("");
  const [video, setVideo] = useState("");
  const [scriptoptions, setScriptOptions] = useState([]);
  const [voiceoptions, setVoiceOptions] = useState([]);
  const [videooptions, setVideoOptions] = useState([]);
  const [thumbOptions, setThumbOptions] = useState([]);
  const [descOptions, setDescOptions] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState("");
  const [thumb, setThumb] = useState("");
  const [desc, setDesc] = useState("");
  const [hidden, setHidden] = useState(false);
  const [projectStatus, setProjectStatus] = useState(0);

  useEffect(() => {
    if (formData.project_name) {
      setProjectName(formData.project_name);
    }
    if (formData.project_description) {
      setProjectDesc(formData.project_description);
    }
    if (formData.project_state) {
      setStatus(formData.project_state);
    }
  }, [
    formData.project_state,
    formData.project_name,
    formData.project_description,
  ]);
  useEffect(() => {
    if (formData.project_link) {
      setProjectLink(formData.project_link);
    }
  }, [formData.project_link]);
  useEffect(() => {
    if (formData.getScriptStatus) {
      if (formData.getScriptStatus.project_status === 1) {
        setProjectStatus(1);
      }
    }
    if (formData.getVoiceStatus) {
      if (formData.getVoiceStatus.project_status === 1) {
        setProjectStatus(1);
      }
    }
    if (formData.getVideoStatus) {
      if (formData.getVideoStatus.project_status === 1) {
        setProjectStatus(1);
      }
    }
  }, [
    formData.getScriptStatus,
    formData.getVoiceStatus,
    formData.getVideoStatus,
  ]);
  useEffect(() => {
    if (formData.created_at) {
      const options = { year: "numeric", month: "short", day: "numeric" };
      const inputDate = new Date(formData.created_at).toLocaleDateString(
        "en",
        options
      );
      setCreatedAt(inputDate);
    }
  }, [formData.created_at]);
  useEffect(() => {
    if (formData.due_date) {
      setDueDate(formData.due_date);
    }
  }, [formData.due_date]);
  useEffect(() => {
    if (show) {
      dispatch(fetchUserOfChannel(data));
      dispatch(fetchUserOfProject(data));
      dispatch(fetchProjectUsers(formData?.project_id));
      dispatch(fetchVideoUser());
    }
    // eslint-disable-next-line
  }, [show]);
  useEffect(() => {
    if (getProjectInfoSuccess) {
      setScript("");
      setVideo("");
      setVoice("");
      setThumb("");
      setDesc("");
      if (
        getProjectInfoSuccess.getScriptWriter ||
        getProjectInfoSuccess.getVoiceArtist ||
        getProjectInfoSuccess.getVideoEditor ||
        getProjectInfoSuccess.getThumbnail ||
        getProjectInfoSuccess.getDescription
      ) {
        for (var i = 0; i < getProjectInfoSuccess.getScriptWriter.length; i++) {
          setScript({
            user_id: getProjectInfoSuccess.getScriptWriter[i].user_id,
            first_name: getProjectInfoSuccess.getScriptWriter[i].first_name,
            last_name: getProjectInfoSuccess.getScriptWriter[i].last_name,
          });
        }
        for (var j = 0; j < getProjectInfoSuccess.getVoiceArtist.length; j++) {
          setVoice({
            user_id: getProjectInfoSuccess.getVoiceArtist[j].user_id,
            first_name: getProjectInfoSuccess.getVoiceArtist[j].first_name,
            last_name: getProjectInfoSuccess.getVoiceArtist[j].last_name,
          });
        }
        for (var k = 0; k < getProjectInfoSuccess.getVideoEditor.length; k++) {
          setVideo({
            user_id: getProjectInfoSuccess.getVideoEditor[k].user_id,
            first_name: getProjectInfoSuccess.getVideoEditor[k].first_name,
            last_name: getProjectInfoSuccess.getVideoEditor[k].last_name,
          });
        }
        for (var l = 0; l < getProjectInfoSuccess.getThumbnail.length; l++) {
          setThumb({
            user_id: getProjectInfoSuccess.getThumbnail[l].user_id,
            first_name: getProjectInfoSuccess.getThumbnail[l].first_name,
            last_name: getProjectInfoSuccess.getThumbnail[l].last_name,
          });
        }
        for (var m = 0; m < getProjectInfoSuccess.getDescription.length; m++) {
          setDesc({
            user_id: getProjectInfoSuccess.getDescription[m].user_id,
            first_name: getProjectInfoSuccess.getDescription[m].first_name,
            last_name: getProjectInfoSuccess.getDescription[m].last_name,
          });
        }
      }
    }
  }, [getProjectInfoSuccess, formData?.project_id]);
  useEffect(() => {
    var decodeToken = jwtDecode(localStorage.getItem("token"));
    const admindata = [
      {
        user_id: Number(localStorage.getItem("user_id")),
        first_name: decodeToken.user[0].first_name,
        last_name: decodeToken.user[0].last_name,
      },
    ];
    setThumbOptions(admindata);
    setDescOptions(admindata);
    if (userOfChannelSuccess || userOfProjectSuccess) {
      if (
        userOfProjectSuccess.getScriptWriter &&
        userOfProjectSuccess.getScriptWriter.length === 0
      ) {
        setScriptOptions(userOfChannelSuccess.writer);
      } else {
        setScriptOptions(userOfProjectSuccess.getScriptWriter);
      }
      if (
        userOfProjectSuccess.getVoiceArtist &&
        userOfProjectSuccess.getVoiceArtist.length === 0
      ) {
        setVoiceOptions(userOfChannelSuccess.voice);
      } else {
        setVoiceOptions(userOfProjectSuccess.getVoiceArtist);
      }
      if (
        userOfProjectSuccess.getVideoEditor &&
        userOfProjectSuccess.getVideoEditor.length === 0
      ) {
        setVideoOptions(userOfChannelSuccess.video);
      } else {
        setVideoOptions(userOfProjectSuccess.getVideoEditor);
      }
      if (
        userOfProjectSuccess.getThumbnail &&
        userOfProjectSuccess.getThumbnail.length === 0
      ) {
        setThumbOptions(admindata);
      } else {
        if (userOfProjectSuccess.getThumbnail) {
          const newThumb = [];
          newThumb.push(...userOfProjectSuccess.getThumbnail);
          setThumbOptions((prev) => [...prev, ...newThumb]);
        }
      }
      if (
        userOfProjectSuccess.getDescriptionWriter &&
        userOfProjectSuccess.getDescriptionWriter.length === 0
      ) {
        setDescOptions(admindata);
      } else {
        if(userOfProjectSuccess.getDescriptionWriter){
          const newDesc = [];
          newDesc.push(...userOfProjectSuccess.getDescriptionWriter);
          setDescOptions((prev) => [...prev, ...newDesc]);
        }
      }
    }
  }, [userOfChannelSuccess, userOfProjectSuccess]);
  useEffect(() => {
    if (getProjectInfoSuccess) {
      if (getProjectInfoSuccess.getChannel) {
        if (getProjectInfoSuccess.getChannel[0]) {
          setChannelName(getProjectInfoSuccess.getChannel[0].channel);
        }
      }
    }
  }, [getProjectInfoSuccess]);
  const updateStatus = (value) => {
    setStatus(value);
    const obj = {
      project_id: formData.project_id,
      project_state: value,
    };
    dispatch(updateProjectState(obj));
  };
  const updateProject = (value) => {
    setProjectName(value);
  };
  const updateProjectLink = (value) => {
    setProjectLink(value);
  };
  const updateProjectDesc = (value) => {
    setProjectDesc(value);
  };
  const dateRef = useRef();
  const handleDate = () => {
    // console.log(dateRef.current);
    dateRef.current.showPicker();
  };
  const datetHandleChange = (item) => {
    setDueDate(item);
  };
  const submit = () => {
    if (updateProjectStateFail) {
      return;
    } else {
      // console.log(status, projectName, projectLink, dueDate, script);
      const obj = {
        project_id: formData.project_id,
        project_name: projectName,
        description: projectDesc,
        project_link: projectLink,
        project_state: status,
        due_date: dueDate,
        script_id: script,
      };
      dispatch(updateProjectData(obj));
      toggle();
    }
  };
  const handleScript = (value) => {
    const obj = {
      channel_id: data,
      project_id: formData?.project_id,
      user_id: value.user_id,
    };
    dispatch(updateProjectScript(obj));
  };
  const handleVoice = (value) => {
    const obj = {
      channel_id: data,
      project_id: formData?.project_id,
      user_id: value.user_id,
    };
    dispatch(updateProjectVoice(obj));
  };
  const handleVideo = (value) => {
    const obj = {
      channel_id: data,
      project_id: formData?.project_id,
      user_id: value.user_id,
    };
    dispatch(updateProjectVideo(obj));
  };
  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };
  const sendDeleteData = () => {
    toggle();
    setShowDeleteModal(true);
    setDeleteData(formData.project_name);
  };
  const deleteProject = () => {
    dispatch(deleteProjectData(formData.project_id));
  };

  const autoGrow = (element) => {
    //console.log(element);
    element.target.style.height = "5px";
    element.target.style.height = element.target.scrollHeight + "px";
  };

  const updateThumb = (value) => {
    var obj;
    if (value.value !== 0) {
      obj = {
        project_id: formData.project_id,
        user_id: value.user_id,
        value: 1,
      };
    } else if (value.value === 0) {
      obj = {
        project_id: formData.project_id,
        user_id: value.user_id,
        value: 0,
      };
    }
    dispatch(updateThumbState(obj));
  };

  const updateDesc = (value) => {
    var obj;
    if (value.value !== 0) {
      obj = {
        project_id: formData.project_id,
        user_id: value.user_id,
        value: 1,
      };
    } else if (value.value === 0) {
      obj = {
        project_id: formData.project_id,
        user_id: value.user_id,
        value: 0,
      };
    }
    dispatch(updateDescState(obj));
  };

  const toggleHidden = () => {
    setHidden(!hidden);
  };

  const update_Project_Status = (value) => {
    console.log(stage);
    var stage_id;
    if (stage === "Script") {
      stage_id = 3;
    } else if (stage === "Voice") {
      stage_id = 4;
    } else if (stage === "Video") {
      stage_id = 5;
    }
    const obj = {
      project_status: value,
      stage_id: stage_id,
      project_id: formData.project_id,
    };
    dispatch(updateProjectStatus(obj));
  };
  const updateProjectPhaseData = () => {
    const obj = {
      project_id: formData.project_id,
      flag: "subchannel",
    };
    if (state === "activeTab") {
      obj.stage_id = 8;
    } else if (state === "inactiveTab") {
      obj.stage_id = 1;
    }
    dispatch(updateProjectPhase(obj));
  };

  return (
    <>
      <DeleteModal
        show={showDeleteModal}
        toggle={toggleDeleteModal}
        head={"Project"}
        data={deleteData}
        trigger={deleteProject}
      />
      <Modal
        show={show}
        onHide={toggle}
        size="xl"
        className="project-form"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="row g-0">
            <div className="col-md-7 ">
              <div className="col-md horizontal-bottom-line top-header p-3">
                <div className="col-md">
                  <button
                    className="btn stage-btn"
                    style={{ backgroundColor: `${color}` }}
                  >
                    {stage}
                  </button>
                </div>
                <div
                  style={{
                    color: "#e04f44",
                    fontSize: "12px",
                    padding: "10px",
                  }}
                >
                  {updateProjectStateFail ||
                    updateProjectScriptFail ||
                    updateProjectVoiceFail ||
                    updateProjectVideoFail ||
                    updateThumbStateError ||
                    updateDescStateError}
                </div>
                <div className="col-md-6 d-flex justify-content-end">
                  <div
                    className="client-icon"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title={formData.clientName ? formData.clientName[0] : ""}
                  >
                    {getInitials(
                      formData.clientName ? formData.clientName[0] : ""
                    )}
                  </div>
                  <div
                    className="status-icon"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title={
                      status === 1
                        ? "Active"
                        : status === 2
                        ? "Inactive"
                        : "Status"
                    }
                  >
                    <Dropdown drop="start">
                      <Dropdown.Toggle id="dropdown-basic">
                        {status === 1 ? (
                          <UilShieldCheck color="#6bc950" />
                        ) : status === 2 ? (
                          <UilShieldCheck color="#f7ce51" />
                        ) : (
                          <UilShieldCheck color="#b9bec7" />
                        )}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => updateStatus(1)}>
                          Active
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => updateStatus(2)}>
                          Pause
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  {projectStatus === 1 && (
                    <div
                      className="actions-icon"
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      title="Project Status"
                    >
                      <Dropdown drop="start">
                        <Dropdown.Toggle id="dropdown-basic">
                          <UilFileGraph color="#5f48ea" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => update_Project_Status(1)}
                          >
                            Approve Project
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => update_Project_Status(2)}
                          >
                            Disapprove Project
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  )}

                  <div
                    className="actions-icon"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title="Settings"
                  >
                    <Dropdown drop="start">
                      <Dropdown.Toggle id="dropdown-basic">
                        <UilEllipsisH color="#5f48ea" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          style={{
                            color: "#e04f44",
                            display: "flex",
                            alignItems: "center",
                          }}
                          onClick={sendDeleteData}
                        >
                          <UilTrash
                            size="18"
                            color="#e04f44"
                            className="me-2"
                          />
                          Delete
                        </Dropdown.Item>
                        <Dropdown.Item
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                          onClick={updateProjectPhaseData}
                        >
                          <UilArchive size="18" className="me-2" />
                          {state === "activeTab" ? "Archive" : "Unarchive"}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
              <div className="col-md main-content">
                <div className="row p-3">
                  <div className="col-md-12 mb-4">
                    <textarea
                      type="text"
                      className="form-control"
                      placeholder="Enter project title"
                      value={projectName}
                      onChange={(e) => updateProject(e.target.value)}
                      onInput={(e) => autoGrow(e)}
                      style={{ height: "59px", overflow: "hidden" }}
                    ></textarea>
                  </div>
                  <div className="col-md-12 mb-4">
                    <textarea
                      type="text"
                      className="form-control desc_textarea"
                      placeholder="Enter Project outline"
                      onChange={(e) => updateProjectDesc(e.target.value)}
                      value={projectDesc}
                    ></textarea>
                  </div>
                  <div className="col-md-6 mb-3">
                    {/* <label>Channel name:</label> */}
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter channel name"
                      disabled={true}
                      value={channelName}
                    />
                  </div>

                  <div className="col-md-6 mb-3">
                    {/* <label>Project link:</label> */}
                    {!hidden && (
                      <>
                        <UilPen
                          className="edit-icon"
                          onClick={toggleHidden}
                          color="#5f48ea"
                        />
                        {/* <input
                          type="url"
                          className="form-control project-link"
                          placeholder="Enter channel name"
                          value={projectLink}
                          disabled={true}
                          onClick={window.open(projectLink, "_blank")}
                        /> */}
                        <div className="form-control project-link">
                          <a
                            href={projectLink}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {projectLink}
                          </a>
                        </div>
                      </>
                    )}
                    {!!hidden && (
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Project link"
                        value={projectLink}
                        onChange={(e) => updateProjectLink(e.target.value)}
                      />
                    )}
                  </div>

                  <div className="col-md mb-3">
                    <label className="mb-3">Visible to:</label>
                    <div className="d-flex">
                      <div className="d-flex align-items-center">
                        <div className="sm-label">Script Writer:</div>
                        <DropdownList
                          data={userOfProjectSuccess.getScriptWriter}
                        />
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="sm-label">Voice Artist:</div>
                        <DropdownList
                          data={userOfProjectSuccess.getVoiceArtist}
                        />
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="sm-label">Video Editor:</div>
                        <DropdownList
                          data={userOfProjectSuccess.getVideoEditor}
                        />
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="sm-label">Thumbnail Artist:</div>
                        <DropdownList
                          data={userOfProjectSuccess.getThumbnail}
                        />
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="sm-label">Description Writer:</div>
                        <DropdownList
                          data={userOfProjectSuccess.getDescriptionWriter}
                        />
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-md-6 mb-3">
                    <label className="mb-3">Assign State:</label>
                    <div className="d-flex">
                      <div className="d-flex align-items-center">
                        <div className="sm-label">Thumbnail Artist:</div>
                        <div
                          className="status-icon"
                          data-bs-toggle="tooltip"
                          data-bs-placement="bottom"
                          title={thumb}
                        >
                          <Dropdown drop="start">
                            <Dropdown.Toggle id="dropdown-basic">
                              {thumb.length > 1 ? (
                                <UilImage color="#7b68ee" />
                              ) : (
                                <UilImage color="#b9bec7" />
                              )}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item onClick={() => updateThumb(1)}>
                                Yes
                              </Dropdown.Item>
                              <Dropdown.Item onClick={() => updateThumb(2)}>
                                No
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="sm-label">Description Writer:</div>
                        <div
                          className="status-icon"
                          data-bs-toggle="tooltip"
                          data-bs-placement="bottom"
                          title={desc}
                        >
                          <Dropdown drop="start">
                            <Dropdown.Toggle id="dropdown-basic">
                              {desc.length > 1 ? (
                                <UilFileEditAlt color="#7b68ee" />
                              ) : (
                                <UilFileEditAlt color="#b9bec7" />
                              )}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item onClick={() => updateDesc(1)}>
                                Yes
                              </Dropdown.Item>
                              <Dropdown.Item onClick={() => updateDesc(2)}>
                                No
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  <div className="col-md-12 mb-4">
                    <label className="mb-3">Assigned to:</label>
                    <div className="row">
                      <div className="col-md">
                        <div className="sm-label mb-2">Script Writer:</div>
                        <Select
                          options={scriptoptions}
                          onChange={(value) => {
                            if (value !== null) {
                              setScript({
                                user_id: value.user_id,
                                first_name: value.first_name,
                                last_name: value.last_name,
                              });
                              handleScript(value);
                            } else {
                              var obj = {
                                project_id: formData.project_id,
                                user_id: null,
                              };
                              dispatch(deleteProjectScriptData(obj));
                              setScript({
                                user_id: "",
                                first_name: "Select Script",
                                last_name: "writer",
                              });
                            }
                          }}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          placeholder="Select Script Writer"
                          getOptionLabel={(option) =>
                            option.first_name + " " + option.last_name
                          }
                          getOptionValue={(option) =>
                            option.first_name + " " + option.last_name
                          }
                          value={script}
                          isClearable={true}
                        />
                      </div>
                      <div className="col-md">
                        <div className="sm-label mb-2">voice Artist:</div>
                        <Select
                          options={voiceoptions}
                          onChange={(value) => {
                            if (value !== null) {
                              setVoice({
                                user_id: value.user_id,
                                first_name: value.first_name,
                                last_name: value.last_name,
                              });
                              handleVoice(value);
                            } else {
                              var obj = {
                                project_id: formData.project_id,
                                user_id: null,
                              };
                              dispatch(deleteProjectVoiceData(obj));
                              setVoice({
                                user_id: "",
                                first_name: "Select Voice",
                                last_name: "Artist",
                              });
                            }
                          }}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          placeholder="Select Voice Artist"
                          getOptionLabel={(option) =>
                            option.first_name + " " + option.last_name
                          }
                          getOptionValue={(option) =>
                            option.first_name + " " + option.last_name
                          }
                          value={voice}
                          isClearable={true}
                        />
                      </div>
                      <div className="col-md">
                        <div className="sm-label mb-2">Video Editor:</div>
                        <Select
                          options={videooptions}
                          onChange={(value) => {
                            if (value !== null) {
                              setVideo({
                                user_id: value.user_id,
                                first_name: value.first_name,
                                last_name: value.last_name,
                              });
                              handleVideo(value);
                            } else {
                              var obj = {
                                project_id: formData.project_id,
                                user_id: null,
                              };
                              dispatch(deleteProjectVideoData(obj));
                              setVideo({
                                user_id: "",
                                first_name: "Select Video",
                                last_name: "Editor",
                              });
                            }
                          }}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          placeholder="Select Video Editor"
                          getOptionLabel={(option) =>
                            option.first_name + " " + option.last_name
                          }
                          getOptionValue={(option) =>
                            option.first_name + " " + option.last_name
                          }
                          value={video}
                          isClearable={true}
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md">
                        <div className="sm-label mb-2">Thumbnail Artist:</div>
                        <Select
                          options={thumbOptions}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          placeholder="Select Thumbnail artist"
                          getOptionLabel={(option) =>
                            option.first_name + " " + option.last_name
                          }
                          getOptionValue={(option) =>
                            option.first_name + " " + option.last_name
                          }
                          onChange={(value) => {
                            if (value !== null) {
                              setThumb({
                                user_id: value.user_id,
                                first_name: value.first_name,
                                last_name: value.last_name,
                              });
                              updateThumb(value);
                            } else {
                              var obj = {
                                project_id: formData.project_id,
                                user_id: null,
                                value: 0,
                              };
                              updateThumb(obj);
                              setThumb({
                                user_id: "",
                                first_name: "Select Thumbnail",
                                last_name: "artist",
                              });
                            }
                          }}
                          value={thumb}
                          isClearable={true}
                        />
                      </div>
                      <div className="col-md">
                        <div className="sm-label mb-2">Description Writer:</div>
                        <Select
                          options={descOptions}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          placeholder="Select Description Writer"
                          getOptionLabel={(option) =>
                            option.first_name + " " + option.last_name
                          }
                          getOptionValue={(option) =>
                            option.first_name + " " + option.last_name
                          }
                          onChange={(value) => {
                            if (value !== null) {
                              setDesc({
                                user_id: value.user_id,
                                first_name: value.first_name,
                                last_name: value.last_name,
                              });
                              updateDesc(value);
                            } else {
                              var obj = {
                                project_id: formData.project_id,
                                user_id: null,
                                value: 0,
                              };
                              updateDesc(obj);
                              setDesc({
                                user_id: "",
                                first_name: "Select Description",
                                last_name: "writer",
                              });
                            }
                          }}
                          value={desc}
                          isClearable={true}
                        />
                      </div>
                    </div>
                  </div>
                  <Revision
                    getVideoSuccess={getVideoSuccess}
                    project_id={formData?.project_id}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-5 vertical-line">
              <div className="col-md horizontal-bottom-line top-header p-3 align-items-center">
                <div className="col-md">
                  <div className="sm-label">Created</div>
                  <div className="sm-text">{createdAt}</div>
                </div>
                <div className="col-md d-flex justify-content-end">
                  <div
                    onClick={handleDate}
                    className="status-icon"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title={dateFormat(dueDate)}
                  >
                    <UilCalender htmlFor="date" color="#b9bec7" />
                    <input
                      id="date"
                      type="date"
                      ref={dateRef}
                      hidden={true}
                      onFocus={(e) => {
                        e.currentTarget.type = "date";
                        e.currentTarget.focus();
                      }}
                      onChange={(e) => datetHandleChange(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md main-content-1">
                <div className="row">
                  <ReadComment id={formData.project_id} />
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <div className="col-md horizontal-top-line p-3 text-center bottom-footer">
                <button type="button" className="apply-button" onClick={submit}>
                  Submit
                </button>
              </div>
            </div>
            <div className="col-md-5 vertical-line bottom-footer-1 p-3">
              <SendComment id={formData.project_id} />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default memo(ProjectForm);
