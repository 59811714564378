import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Utils/Api";
import {
  activeUserPath,
  addRolePath,
  addUserPath,
  editUserPath,
  inactiveUserPath,
  updateRolePath,
} from "../../../Utils/ApiPath";

const initialState = {
  isLoading: false,
  activeUsers: [],
  activeError: "",
  inactiveUsers: [],
  inactiveError: "",
  isSuccess: "",
  isError: "",
  isEditSuccess: "",
  isEditError: "",
  userUpgrade: false,
};

export const fetchActiveUsers = createAsyncThunk(
  "/user/fetchActiveUsers",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getUsersPending());
      const res = await api.get(activeUserPath);
      if (res) {
        thunkAPI.dispatch(getActiveUsersSuccess(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getActiveUsersFail(err));
    }
  }
);

export const fetchInactiveUsers = createAsyncThunk(
  "/user/fetchInactiveUsers",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getUsersPending());
      const res = await api.get(inactiveUserPath);
      if (res) {
        thunkAPI.dispatch(getInactiveUsersSuccess(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getInactiveUsersFail(err));
    }
  }
);

export const addUser = createAsyncThunk(
  "/user/addUser",
  async (_, thunkAPI) => {
    try {
      const userFormData = {
        email: _.email,
        password: _.password,
        first_name: _.first_name,
        last_name: _.last_name,
        status: _.status,
        project_limit:
          _.role === 3 ||
          _.role === 4 ||
          _.role === 5 ||
          _.role === 7 ||
          _.role === 8
            ? _.project_limit
            : 0,
        take_project:
          (_.role === 3 || _.role === 4 || _.role === 5) &&
          _.take_project === true
            ? 1
            : 0,
      };
      thunkAPI.dispatch(addUserPending());
      const res = await api.post(addUserPath, userFormData);
      if (res.data) {
        var role = {
          role_id: _.role,
          user_id: res.data.user_id,
        };
        const roleres = await api.post(addRolePath, role);
        if (roleres.data) {
          thunkAPI.dispatch(addUserSuccess({ status: 200 }));
          setTimeout(() => {
            thunkAPI.dispatch(clearAddUserState());
          }, 4000);
        }
      }
      return;
    } catch (err) {
      console.log(err);
      if (err.response.data.error === "Reached Limit") {
        thunkAPI.dispatch(userUpgrade());
        setTimeout(() => {
          thunkAPI.dispatch(clearUserUpgrade());
        }, 3100);
      } else {
        thunkAPI.dispatch(addUserFail(err.response.data.error));
        setTimeout(() => {
          thunkAPI.dispatch(clearAddUserState());
        }, 4000);
      }
    }
  }
);

export const editUser = createAsyncThunk(
  "/user/editUser",
  async (_, thunkAPI) => {
    try {
      const userFormData = {
        email: _.email,
        first_name: _.first_name,
        last_name: _.last_name,
        status: _.status,
        project_limit:
          _.role === "script writer" ||
          _.role === "voice artist" ||
          _.role === "video editor" ||
          _.role === "thumbnail artist" ||
          _.role === "description writer"
            ? _.project_limit
            : 0,
        take_project:
          (_.role === "script writer" ||
            _.role === "voice artist" ||
            _.role === "video editor") &&
          _.take_project === true
            ? 1
            : 0,
      };
      const role = {
        role_id: _.role_id,
      };
      thunkAPI.dispatch(editUserPending());
      const res = await api.put(
        editUserPath + "/" + _.user_id + "/" + _.role,
        userFormData
      );
      const roleRes = await api.put(updateRolePath + "/" + _.user_id, role);
      if (res.data && roleRes.data) {
        thunkAPI.dispatch(editUserSuccess(res.data));
        setTimeout(() => {
          thunkAPI.dispatch(clearEditUserState());
        }, 4000);
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(editUserFail(err.response.data));
      setTimeout(() => {
        thunkAPI.dispatch(clearEditUserState());
      }, 3000);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    getUsersPending: (state) => {
      state.isLoading = true;
    },
    getActiveUsersSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.activeUsers = payload;
      state.activeError = "";
    },
    getActiveUsersFail: (state, { payload }) => {
      state.isLoading = false;
      state.activeUsers = [];
      state.activeError = payload;
    },
    getInactiveUsersSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.inactiveUsers = payload;
      state.inactiveError = "";
    },
    getInactiveUsersFail: (state, { payload }) => {
      state.isLoading = false;
      state.inactiveUsers = [];
      state.inactiveError = payload;
    },
    addUserPending: (state) => {
      state.isLoading = true;
    },
    addUserSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = payload;
      state.isError = "";
    },
    addUserFail: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = "";
      state.isError = payload;
    },
    editUserPending: (state) => {
      state.isLoading = true;
    },
    editUserSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.isEditSuccess = payload;
      state.isEditError = "";
    },
    editUserFail: (state, { payload }) => {
      state.isLoading = false;
      state.isEditSuccess = "";
      state.isEditError = payload;
    },
    clearAddUserState: (state) => {
      state.isLoading = false;
      state.isSuccess = "";
      state.isError = "";
    },
    clearEditUserState: (state) => {
      state.isLoading = false;
      state.isEditSuccess = "";
      state.isEditError = "";
    },
    userUpgrade: (state) => {
      state.isLoading = false;
      state.isEditSuccess = "";
      state.isEditError = "";
      state.userUpgrade = true;
    },
    clearUserUpgrade: (state) => {
      state.isLoading = false;
      state.isEditSuccess = "";
      state.isEditError = "";
      state.userUpgrade = false;
    },
  },
});

export const {
  getUsersPending,
  getActiveUsersSuccess,
  getActiveUsersFail,
  getInactiveUsersSuccess,
  getInactiveUsersFail,
  addUserPending,
  addUserSuccess,
  addUserFail,
  editUserPending,
  editUserSuccess,
  editUserFail,
  clearAddUserState,
  clearEditUserState,
  userUpgrade,
  clearUserUpgrade,
} = userSlice.actions;
export default userSlice.reducer;
