import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../Utils/Api";
import {
  addProjectChannelPath,
  addProjectClientPath,
  addProjectPath,
  getProjectClientPath,
  getProjectCountPath,
  getProjectPath,
} from "../../../../Utils/ApiPath";
import { v4 as uuidv4 } from "uuid";

const initialState = {
  isLoading: false,
  backlogProjects: [],
  backlogProjectsError: "",
  scriptProjects: [],
  scriptProjectsError: "",
  voiceProjects: [],
  voiceProjectsError: "",
  videoProjects: [],
  videoProjectsError: "",
  compProjects: [],
  compProjectsError: "",
  revisionProjects: [],
  revisionProjectsError: "",
  readyProjects: [],
  readyProjectsError: "",
  publishedProjects: [],
  publishedProjectsError: "",
  isArchiveLoading: false,
  archiveProjects: [],
  archiveProjectsError: "",
  count: {},
  countError: "",
  addProjectLoading: false,
  addProjectSuccess: {},
  addProjectError: "",
};

export const getAllProject = createAsyncThunk(
  "/projects/getAllProjects",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(projectsPending());
      const res1 = await api.get(getProjectPath + _ + "/" + 1);
      const res2 = await api.get(getProjectPath + _ + "/" + 2);
      const res3 = await api.get(getProjectPath + _ + "/" + 3);
      const res4 = await api.get(getProjectPath + _ + "/" + 4);
      const res5 = await api.get(getProjectPath + _ + "/" + 5);
      const res6 = await api.get(getProjectPath + _ + "/" + 6);
      const res7 = await api.get(getProjectPath + _ + "/" + 7);
      // const res8 = await api.get(getProjectPath + _ + "/" + 8);
      const res9 = await api.get(getProjectPath + _ + "/" + 9);
      if (res1) {
        var newData1 = res1.data.map((item) =>
          Object.assign({}, item, { id: uuidv4() })
        );
      }
      if (res2) {
        var newData2 = res2.data.map((item) =>
          Object.assign({}, item, { id: uuidv4() })
        );
      }
      if (res3) {
        var newData3 = res3.data.map((item) =>
          Object.assign({}, item, { id: uuidv4() })
        );
      }
      if (res4) {
        var newData4 = res4.data.map((item) =>
          Object.assign({}, item, { id: uuidv4() })
        );
      }
      if (res5) {
        var newData5 = res5.data.map((item) =>
          Object.assign({}, item, { id: uuidv4() })
        );
      }
      if (res6) {
        var newData6 = res6.data.map((item) =>
          Object.assign({}, item, { id: uuidv4() })
        );
      }
      if (res7) {
        var newData7 = res7.data.map((item) =>
          Object.assign({}, item, { id: uuidv4() })
        );
      }
      // if (res8) {
      //   var newData8 = res8.data.map((item) =>
      //     Object.assign({}, item, { id: uuidv4() })
      //   );
      // }
      if (res9) {
        var newData9 = res9.data.map((item) =>
          Object.assign({}, item, { id: uuidv4() })
        );
      }
      if (
        newData1 &&
        newData2 &&
        newData3 &&
        newData4 &&
        newData5 &&
        newData6 &&
        newData7 &&
        // newData8 &&
        newData9
      ) {
        thunkAPI.dispatch(backlogProjectsSuccess(newData1));
        thunkAPI.dispatch(scriptProjectsSuccess(newData2));
        thunkAPI.dispatch(voiceProjectsSuccess(newData3));
        thunkAPI.dispatch(videoProjectsSuccess(newData4));
        thunkAPI.dispatch(compProjectsSuccess(newData5));
        thunkAPI.dispatch(revisionProjectsSuccess(newData9));
        thunkAPI.dispatch(readyProjectsSuccess(newData6));
        thunkAPI.dispatch(publishedProjectsSuccess(newData7));
        // thunkAPI.dispatch(archiveProjectsSuccess(newData8));
        thunkAPI.dispatch(projectPendingEnd());
        return (
          res1.data,
          res2.data,
          res3.data,
          res4.data,
          res5.data,
          res6.data,
          res7.data,
          res9.data
        );
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(backlogProjectsFail(err));
      thunkAPI.dispatch(scriptProjectsFail(err));
      thunkAPI.dispatch(voiceProjectsFail(err));
      thunkAPI.dispatch(videoProjectsFail(err));
      thunkAPI.dispatch(compProjectsFail(err));
      thunkAPI.dispatch(revisionProjectsFail(err));
      // thunkAPI.dispatch(archiveProjectsFail(err));
      thunkAPI.dispatch(publishedProjectsFail(err));
      thunkAPI.dispatch(readyProjectsFail(err));
    }
  }
);

export const getBacklogProjects = createAsyncThunk(
  "/projects/getBacklogProjects",
  async (_, thunkAPI) => {
    try {
      // thunkAPI.dispatch(projectsPending());
      const res = await api.get(getProjectPath + _ + "/" + 1);
      if (res) {
        var newData = res.data.map((item) =>
          Object.assign({}, item, { id: uuidv4() })
        );
        thunkAPI.dispatch(backlogProjectsSuccess(newData));
        return res.data;
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(backlogProjectsFail(err));
    }
  }
);

export const getScriptProjects = createAsyncThunk(
  "/projects/getScriptProjects",
  async (_, thunkAPI) => {
    try {
      // thunkAPI.dispatch(projectsPending());
      const res = await api.get(getProjectPath + _ + "/" + 2);
      if (res) {
        var newData = res.data.map((item) =>
          Object.assign({}, item, { id: uuidv4() })
        );
        thunkAPI.dispatch(scriptProjectsSuccess(newData));
        return res.data;
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(scriptProjectsFail(err));
    }
  }
);

export const getVoiceProjects = createAsyncThunk(
  "/projects/getVoiceProjects",
  async (_, thunkAPI) => {
    try {
      // thunkAPI.dispatch(projectsPending());
      const res = await api.get(getProjectPath + _ + "/" + 3);
      if (res) {
        var newData = res.data.map((item) =>
          Object.assign({}, item, { id: uuidv4() })
        );
        thunkAPI.dispatch(voiceProjectsSuccess(newData));
        return res.data;
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(voiceProjectsFail(err));
    }
  }
);

export const getVideoProjects = createAsyncThunk(
  "/projects/getVideoProjects",
  async (_, thunkAPI) => {
    try {
      // thunkAPI.dispatch(projectsPending());
      const res = await api.get(getProjectPath + _ + "/" + 4);
      if (res) {
        var newData = res.data.map((item) =>
          Object.assign({}, item, { id: uuidv4() })
        );
        thunkAPI.dispatch(videoProjectsSuccess(newData));
        return res.data;
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(videoProjectsFail(err));
    }
  }
);

export const getCompProjects = createAsyncThunk(
  "/projects/getCompProjects",
  async (_, thunkAPI) => {
    try {
      // thunkAPI.dispatch(projectsPending());
      const res = await api.get(getProjectPath + _ + "/" + 5);
      if (res) {
        var newData = res.data.map((item) =>
          Object.assign({}, item, { id: uuidv4() })
        );
        thunkAPI.dispatch(compProjectsSuccess(newData));
        return res.data;
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(compProjectsFail(err));
    }
  }
);

export const getRevisionProjects = createAsyncThunk(
  "/projects/getRevisionProjects",
  async (_, thunkAPI) => {
    try {
      // thunkAPI.dispatch(projectsPending());
      const res = await api.get(getProjectPath + _ + "/" + 9);
      if (res) {
        var newData = res.data.map((item) =>
          Object.assign({}, item, { id: uuidv4() })
        );
        thunkAPI.dispatch(revisionProjectsSuccess(newData));
        return res.data;
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(revisionProjectsFail(err));
    }
  }
);

export const getReadyProjects = createAsyncThunk(
  "/projects/getReadyProjects",
  async (_, thunkAPI) => {
    try {
      // thunkAPI.dispatch(projectsPending());
      const res = await api.get(getProjectPath + _ + "/" + 6);
      if (res) {
        var newData = res.data.map((item) =>
          Object.assign({}, item, { id: uuidv4() })
        );
        thunkAPI.dispatch(readyProjectsSuccess(newData));
        return res.data;
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(readyProjectsFail(err));
    }
  }
);

export const getPublishedProjects = createAsyncThunk(
  "/projects/getPublishedProjects",
  async (_, thunkAPI) => {
    try {
      // thunkAPI.dispatch(projectsPending());
      const res = await api.get(getProjectPath + _ + "/" + 7);
      if (res) {
        var newData = res.data.map((item) =>
          Object.assign({}, item, { id: uuidv4() })
        );
        thunkAPI.dispatch(publishedProjectsSuccess(newData));
        return res.data;
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(publishedProjectsFail(err));
    }
  }
);

export const getArchiveProjects = createAsyncThunk(
  "/projects/getArchiveProjects",
  async (_, thunkAPI) => {
    try {
      // thunkAPI.dispatch(projectsPending());
      const res = await api.get(getProjectPath + _ + "/" + 8);
      if (res) {
        var newData = res.data.map((item) =>
          Object.assign({}, item, { id: uuidv4() })
        );
        thunkAPI.dispatch(archiveProjectsSuccess(newData));
        return res.data;
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(archiveProjectsFail(err));
    }
  }
);

export const getProjectCount = createAsyncThunk(
  "/projects/getProjectCount",
  async (_, thunkAPI) => {
    try {
      // thunkAPI.dispatch(projectsPending());
      const res = await api.get(getProjectCountPath + _);
      if (res) {
        thunkAPI.dispatch(countSuccess(res.data));
        return res.data;
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(countFail(err));
    }
  }
);

export const addNewProject = createAsyncThunk(
  "/projects/addNewProject",
  async (_, thunkAPI) => {
    try {
      let obj = {
        project_name: _.project_name,
        stage_id: 1,
      };
      // thunkAPI.dispatch(addProjectLoading());
      const res = await api.post(addProjectPath, obj);
      thunkAPI.dispatch(addProjectSuccess(res.data));
      setTimeout(() => {
        thunkAPI.dispatch(clearAddProject());
      }, 100);
      if (res) {
        var project_id = res.data.data.project_id;
        let obj1 = {
          project_id: project_id,
          channel_id: _.channel_id,
        };
        const res1 = await api.post(addProjectChannelPath, obj1);
        if (res1) {
          var client = await api.get(getProjectClientPath + _.channel_id);
          if (client.data.length) {
            for (let i = 0; i < client.data.length; i++) {
              let obj2 = {
                channel_id: _.channel_id,
                project_id: project_id,
                client_id: client.data[i].user_id,
              };
              var finalData = await api.post(addProjectClientPath, obj2);
            }
            if (res && res1 && finalData) {
              return res.data;
            }
          } else {
            // if (res && res1) {
            //   thunkAPI.dispatch(addProjectSuccess(res.data));
            //   setTimeout(() => {
            //     thunkAPI.dispatch(clearAddProject());
            //   }, 100);
            //   return res.data;
            // }
          }
        }
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(addProjectError(err));
      setTimeout(() => {
        thunkAPI.dispatch(clearAddProject());
      }, 100);
    }
  }
);

const proejctSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    projectsPending: (state) => {
      state.isLoading = true;
    },
    projectPendingEnd: (state) => {
      state.isLoading = false;
    },
    backlogProjectsSuccess: (state, { payload }) => {
      // state.isLoading = false;
      state.backlogProjects = payload;
      state.backlogProjectsError = "";
    },
    backlogProjectsFail: (state, { payload }) => {
      // state.isLoading = false;
      state.backlogProjects = [];
      state.backlogProjectsError = payload;
    },
    scriptProjectsSuccess: (state, { payload }) => {
      // state.isLoading = false;
      state.scriptProjects = payload;
      state.scriptProjectsError = "";
    },
    scriptProjectsFail: (state, { payload }) => {
      // state.isLoading = false;
      state.scriptProjects = [];
      state.scriptProjectsError = payload;
    },
    voiceProjectsSuccess: (state, { payload }) => {
      // state.isLoading = false;
      state.voiceProjects = payload;
      state.voiceProjectsError = "";
    },
    voiceProjectsFail: (state, { payload }) => {
      // state.isLoading = false;
      state.voiceProjects = [];
      state.voiceProjectsError = payload;
    },
    videoProjectsSuccess: (state, { payload }) => {
      // state.isLoading = false;
      state.videoProjects = payload;
      state.videoProjectsError = "";
    },
    videoProjectsFail: (state, { payload }) => {
      // state.isLoading = false;
      state.videoProjects = [];
      state.videoProjectsError = payload;
    },
    compProjectsSuccess: (state, { payload }) => {
      // state.isLoading = false;
      state.compProjects = payload;
      state.compProjectsError = "";
    },
    compProjectsFail: (state, { payload }) => {
      // state.isLoading = false;
      state.compProjects = [];
      state.compProjectsError = payload;
    },
    revisionProjectsSuccess: (state, { payload }) => {
      // state.isLoading = false;
      state.revisionProjects = payload;
      state.revisionProjectsError = "";
    },
    revisionProjectsFail: (state, { payload }) => {
      // state.isLoading = false;
      state.revisionProjects = [];
      state.revisionProjectsError = payload;
    },
    readyProjectsSuccess: (state, { payload }) => {
      // state.isLoading = false;
      state.readyProjects = payload;
      state.readyProjectsError = "";
    },
    readyProjectsFail: (state, { payload }) => {
      // state.isLoading = false;
      state.readyProjects = [];
      state.readyProjectsError = payload;
    },
    publishedProjectsSuccess: (state, { payload }) => {
      // state.isLoading = false;
      state.publishedProjects = payload;
      state.publishedProjectsError = "";
    },
    publishedProjectsFail: (state, { payload }) => {
      // state.isLoading = false;
      state.publishedProjects = [];
      state.publishedProjectsError = payload;
    },
    archiveProjectsLoading: (state) => {
      state.isArchiveLoading = true;
    },
    archiveProjectsSuccess: (state, { payload }) => {
      state.isArchiveLoading = false;
      state.archiveProjects = payload;
      state.archiveProjectsError = "";
    },
    archiveProjectsFail: (state, { payload }) => {
      state.isArchiveLoading = false;
      state.archiveProjects = [];
      state.archiveProjectsError = payload;
    },
    countSuccess: (state, { payload }) => {
      // state.isLoading = false;
      state.count = payload;
      state.countError = "";
    },
    countFail: (state, { payload }) => {
      // state.isLoading = false;
      state.count = {};
      state.countError = payload;
    },
    addProjectLoading: (state) => {
      state.addProjectLoading = true;
    },
    addProjectSuccess: (state, { payload }) => {
      state.addProjectLoading = false;
      state.addProjectSuccess = payload;
      state.addProjectError = "";
    },
    addProjectError: (state, { payload }) => {
      state.addProjectLoading = false;
      state.addProjectSuccess = {};
      state.addProjectError = payload;
    },
    clearAddProject: (state) => {
      state.addProjectSuccess = false;
      state.addProjectError = ""
    },
  },
});

export const {
  projectsPending,
  backlogProjectsSuccess,
  backlogProjectsFail,
  scriptProjectsSuccess,
  scriptProjectsFail,
  voiceProjectsSuccess,
  voiceProjectsFail,
  videoProjectsSuccess,
  videoProjectsFail,
  compProjectsSuccess,
  compProjectsFail,
  revisionProjectsSuccess,
  revisionProjectsFail,
  readyProjectsSuccess,
  readyProjectsFail,
  publishedProjectsSuccess,
  publishedProjectsFail,
  archiveProjectsSuccess,
  archiveProjectsFail,
  countSuccess,
  countFail,
  addProjectLoading,
  addProjectSuccess,
  addProjectError,
  clearAddProject,
  projectPendingEnd,
} = proejctSlice.actions;
export default proejctSlice.reducer;
