import React from "react";
import { UilRocket } from "@iconscout/react-unicons";
import Modal from "react-bootstrap/Modal";
import { customerPortalPath } from "../Config/RequestPath";

function UpgradeModal({ show, handleClose }) {
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="p-4" style={{ textAlign: "center" }}>
          <UilRocket size="100" color="#7b68ee" />
          <div
            className="mb-4"
            style={{ fontSize: "36px", fontWeight: "bolder" }}
          >
            Upgrade your plan!
          </div>
          <div className="mb-4">
            You have reached the limit of the plan. Please upgrade your account
            to continue.
          </div>
          <a
            rel="noopener noreferrer"
            href={customerPortalPath}
            target="_blank"
          >
            <button type="button" className="login-button mt-5 mb-3">
              Upgrade
            </button>
          </a>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default UpgradeModal;
