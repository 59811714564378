import { UilPen } from "@iconscout/react-unicons";

export const DueProjectHeader = [
  { field: "id", header: "#" },
  { field: "project_name", header: "Project" },
  { field: "channel", header: "Channel" },
  { field: "stage_id", header: "Stage" },
  { field: "due_date", header: "Due Date" },
];

export const channelHeader = [
  { accessor: "id", Header: "#" },
  { accessor: "channel", Header: "Channel" },
  { accessor: "backlog", Header: "Backlog" },
  { accessor: "script", Header: "script" },
  { accessor: "voice", Header: "video" },
  { accessor: "completed", Header: "Completed" },
  { accessor: "upload", Header: "Upload" },
  { accessor: "published", Header: "Published" },
  { accessor: "project", Header: "Project" },
];

export const channelData = [
  {
    id: 1,
    project: "10 things to learn in 2022",
    channel: "political viewsded",
    backlog: 0,
    script: 2,
    voice: 3,
    video: 1,
    completed: 0,
    upload: 0,
    published: 0,
  },
  {
    id: 1,
    project: "10 things to learn in 2022",
    channel: "political views",
    backlog: 0,
    script: 2,
    voice: 3,
    video: 1,
    completed: 0,
    upload: 0,
    published: 0,
  },
  {
    id: 1,
    project: "10 things to learn in 2022",
    channel: "political views",
    backlog: 0,
    script: 2,
    voice: 3,
    video: 1,
    completed: 0,
    upload: 0,
    published: 0,
  },
  {
    id: 1,
    project: "10 things to learn in 2022",
    channel: "political views",
    backlog: 0,
    script: 2,
    voice: 3,
    video: 1,
    completed: 0,
    upload: 0,
    published: 0,
  },
  {
    id: 1,
    project: "10 things to learn in 2022",
    channel: "political views",
    backlog: 0,
    script: 2,
    voice: 3,
    video: 1,
    completed: 0,
    upload: 0,
    published: 0,
  },
  {
    id: 1,
    project: "10 things to learn in 2022",
    channel: "political views",
    backlog: 0,
    script: 2,
    voice: 3,
    video: 1,
    completed: 0,
    upload: 0,
    published: 0,
  },
  {
    id: 1,
    project: "10 things to learn in 2022",
    channel: "political views",
    backlog: 0,
    script: 2,
    voice: 3,
    video: 1,
    completed: 0,
    upload: 0,
    published: 0,
  },
  {
    id: 1,
    project: "10 things to learn in 2022",
    channel: "political views",
    backlog: 0,
    script: 2,
    voice: 3,
    video: 1,
    completed: 0,
    upload: 0,
    published: 0,
  },
  {
    id: 1,
    project: "10 things to learn in 2022",
    channel: "political views",
    backlog: 0,
    script: 2,
    voice: 3,
    video: 1,
    completed: 0,
    upload: 0,
    published: 0,
  },
  {
    id: 1,
    project: "10 things to learn in 2022",
    channel: "political views",
    backlog: 0,
    script: 2,
    voice: 3,
    video: 1,
    completed: 0,
    upload: 0,
    published: 0,
  },
  {
    id: 1,
    project: "10 things to learn in 2022",
    channel: "political views",
    backlog: 0,
    script: 2,
    voice: 3,
    video: 1,
    completed: 0,
    upload: 0,
    published: 0,
  },
  {
    id: 1,
    project: "10 things to learn in 2022",
    channel: "political views",
    backlog: 0,
    script: 2,
    voice: 3,
    video: 1,
    completed: 0,
    upload: 0,
    published: 0,
  },
  {
    id: 1,
    project: "10 things to learn in 2022",
    channel: "political views",
    backlog: 0,
    script: 2,
    voice: 3,
    video: 1,
    completed: 0,
    upload: 0,
    published: 0,
  },
  {
    id: 100,
    project: "10 things to learn in 2022 100",
    channel: "political views 100",
    backlog: 0,
    script: 2,
    voice: 3,
    video: 1,
    completed: 0,
    upload: 0,
    published: 0,
  },
];

export const roleHeader = [
  { field: "role_id", header: "#" },
  { field: "role", header: "Role" },
];


export const settingsHeader = [
  { field: "id", header: "#" },
  { field: "stage", header: "stage" },
  { field: "allocatedTime", header: "Allocated time" },
  { field: "deadline", header: "Deadline notification time" },
  { field: "action", header: "Action" },
];

export const settingsData = [
  {
    id: 1,
    stage: "script writing",
    allocatedTime: "48:00",
    deadline: "48:00",
    action: <UilPen size="14" />,
  },
  {
    id: 1,
    stage: "voice over",
    allocatedTime: "48:00",
    deadline: "48:00",
    action: <UilPen size="14" />,
  },
  {
    id: 1,
    stage: "video editing",
    allocatedTime: "48:00",
    deadline: "48:00",
    action: <UilPen size="14" />,
  },
];
