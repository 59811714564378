import { NavLink } from "react-router-dom";
import Logo from "../../Assets/svg/logo.svg";
import {
  AdminMenuItem,
  Client,
  LevelOneResources,
  Pm,
  Resource2,
} from "./SidebarItems";
import {
  UilSignout,
  UilRefresh,
  UilBill,
  UilMeetingBoard,
  UilHipchat,
  UilCreateDashboard,
} from "@iconscout/react-unicons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearState } from "../../Feature/Entry/login/loginSlice";
import { useEffect } from "react";
import { readCount } from "../../Feature/Admin/Chat/ChatSlice";

function Sidebar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const logout = () => {
    localStorage.clear();
    dispatch(clearState());
    navigate("/");
  };
  const role_id = localStorage.getItem("role_id");
  const user_id = localStorage.getItem("user_id");
  const { getReadCount } = useSelector((state) => state.chat);

  useEffect(() => {
    if (Number(role_id) === 1 && user_id) {
      dispatch(readCount(user_id));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="sidebar">
        <div className="sidebar-container">
          <div className="sidebar-logo">
            <img src={Logo} alt="logo" width="30" />
          </div>
          <div className="sidebar-links">
            {/* admin && sub admin */}
            {(Number(role_id) === 1 || Number(role_id) === 12) &&
              AdminMenuItem.map((item, index) => (
                <NavLink
                  to={item.path}
                  key={index}
                  className="sidebar-link"
                  activeclassname="active"
                  data-bs-toggle="tooltip"
                  title={item.name}
                >
                  <div className="sidebar-icon">{item.icon}</div>
                </NavLink>
              ))}
            {Number(role_id) === 1 && (
              <NavLink
                to="/admin/subscription"
                className="sidebar-link"
                activeclassname="active"
                data-bs-toggle="tooltip"
                title="Subscription"
              >
                <div className="sidebar-icon">
                  <UilBill color="#2a2e34" />
                </div>
              </NavLink>
            )}

            {/*sw,va,ve,tw,dw*/}
            {(Number(role_id) === 3 ||
              Number(role_id) === 4 ||
              Number(role_id) === 5 ||
              Number(role_id) === 7 ||
              Number(role_id) === 8) &&
              LevelOneResources.map((item, index) => (
                <NavLink
                  to={item.path}
                  key={index}
                  className="sidebar-link"
                  activeclassname="active"
                  data-bs-toggle="tooltip"
                  title={item.name}
                >
                  <div className="sidebar-icon">{item.icon}</div>
                </NavLink>
              ))}
            {Number(role_id) === 5 && (
              <NavLink
                to="/resource/my-revisions"
                className="sidebar-link"
                activeclassname="active"
                data-bs-toggle="tooltip"
                title="My Revisions"
              >
                <div className="sidebar-icon">
                  <UilRefresh color="#2a2e34" />
                </div>
              </NavLink>
            )}
            {/* client */}
            {Number(role_id) === 2 &&
              Client.map((item, index) => (
                <NavLink
                  to={item.path}
                  key={index}
                  className="sidebar-link"
                  activeclassname="active"
                  data-bs-toggle="tooltip"
                  title={item.name}
                >
                  <div className="sidebar-icon">{item.icon}</div>
                </NavLink>
              ))}
            {/* project manager */}
            {Number(role_id) === 6 &&
              Pm.map((item, index) => (
                <NavLink
                  to={item.path}
                  key={index}
                  className="sidebar-link"
                  activeclassname="active"
                  data-bs-toggle="tooltip"
                  title={item.name}
                >
                  <div className="sidebar-icon">{item.icon}</div>
                </NavLink>
              ))}
            {/* QC and video uploader */}
            {(Number(role_id) === 9 || Number(role_id) === 10) &&
              Resource2.map((item, index) => (
                <NavLink
                  to={item.path}
                  key={index}
                  className="sidebar-link"
                  activeclassname="active"
                  data-bs-toggle="tooltip"
                  title={item.name}
                >
                  <div className="sidebar-icon">{item.icon}</div>
                </NavLink>
              ))}
            {/* live Training */}
            <a
              href="https://www.youtube.com/channel/UC-7chNzByUOCVD_lxhqrDyg"
              className="sidebar-a"
              activeclassname="active"
              data-bs-toggle="tooltip"
              title="Live Training"
              target={"_blank"}
              rel="noopener noreferrer"
            >
              <div
                className="sidebar-icon"
                style={{ margin: "10px", padding: "8px" }}
              >
                <UilMeetingBoard color="#2a2e34" />
              </div>
            </a>
            {Number(role_id) === 1 && (
              <>
                <NavLink
                  to="/admin/chat"
                  className="sidebar-link"
                  activeclassname="active"
                  data-bs-toggle="tooltip"
                  title="Chat with us"
                >
                  <div className="sidebar-icon">
                    <UilHipchat color="#2a2e34" />
                  </div>
                  <div className="badge-icon">{getReadCount.length}</div>
                </NavLink>
              </>
            )}

            {/* {Number(role_id) === 1 && (
              <>
                <NavLink
                  to="/admin/request"
                  className="sidebar-link"
                  activeclassname="active"
                  data-bs-toggle="tooltip"
                  title="Request a new feature"
                >
                  <div className="sidebar-icon">
                    <UilCreateDashboard color="#2a2e34" />
                  </div>
                </NavLink>
              </>
            )} */}

            <div className="sidebar-link" style={{ cursor: "pointer" }}>
              <a href="/#" onClick={logout}>
                <div className="sidebar-icon">
                  <UilSignout color="#2a2e34" />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
