import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getRevisionListData,
  updateRevisionDataById,
} from "../../Feature/LevelOneResource/RevisionSlice";

const ViewRevisions = ({ show, toggle, id }) => {
  const dispatch = useDispatch();
  const { getRevisionList } = useSelector((state) => state.L1RRevision);
  const [checked, setChecked] = useState([]);

  //useEffect
  useEffect(() => {
    if (show) {
      dispatch(getRevisionListData(id));
    }
    // eslint-disable-next-line
  }, [show]);
  const handleChange = (e, index) => {
    let prev = checked;
    let itemIndex = prev.indexOf(index);
    if (itemIndex !== -1) {
      prev.splice(itemIndex, 1);
    } else {
      prev.push(index);
    }
    setChecked([...prev]);
  };
  const submit = () => {
    if (checked.length > 0) {
      for (var i = 0; i < checked.length; i++) {
        const obj = {
          id: checked[i],
        };
        dispatch(updateRevisionDataById(obj));
      }
      toggle();
    }
  };
  return (
    <>
      <Modal
        show={show}
        onHide={toggle}
        className="re-invite"
        backdrop="static"
        keyboard={false}
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Revisions</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Timestamp</th>
                  <th>Explanation</th>
                  <th>Reference Link</th>
                  <th>Added By</th>
                  <th>Role</th>
                  <th>Fixed</th>
                </tr>
              </thead>
              <tbody>
                {getRevisionList &&
                  getRevisionList.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.timestamp}</td>
                      <td>{item.explanation}</td>
                      <td>{item.link}</td>
                      <td>{item.first_name + " " + item.last_name}</td>
                      <td>{item.role}</td>
                      <td>
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={checked.includes(item.id_revision_data)}
                          onChange={(e) =>
                            handleChange(e, item.id_revision_data)
                          }
                        ></input>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="footer d-flex justify-content-center">
            <button className="delete-btn" onClick={submit}>
              Save
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewRevisions;
