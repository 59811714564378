import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Utils/Api";
import { getPaymentsPath, getSubscriptionPath } from "../../../Utils/ApiPath";

const initialState = {
  isLoading: false,
  subscription: [],
  payments: [],
  error: "",
};

export const fetchSubscription = createAsyncThunk(
  "/subscription/getData",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getSubscriptionPending());
      const res = await api.get(getSubscriptionPath + _);
      if (res) {
        thunkAPI.dispatch(getSubscriptionSuccess(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getSubscriptionFail(err));
    }
  }
);

export const fetchPayments = createAsyncThunk(
  "/subscription/getPayments",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getpaymentsPending());
      const res = await api.get(getPaymentsPath + _);
      if (res) {
        thunkAPI.dispatch(getpaymentsSuccess(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getpaymentsFail(err));
    }
  }
);

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    getSubscriptionPending: (state) => {
      state.isLoading = true;
    },
    getSubscriptionSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.subscription = payload;
      state.error = "";
    },
    getSubscriptionFail: (state, { payload }) => {
      state.isLoading = false;
      state.subscription = [];
      state.error = payload;
    },
    getpaymentsPending: (state) => {
      state.isLoading = true;
    },
    getpaymentsSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.payments = payload;
      state.error = "";
    },
    getpaymentsFail: (state, { payload }) => {
      state.isLoading = false;
      state.payments = [];
      state.error = payload;
    },
  },
});

export const {
  getSubscriptionPending,
  getSubscriptionSuccess,
  getSubscriptionFail,
  getpaymentsSuccess,
  getpaymentsPending,
  getpaymentsFail,
} = subscriptionSlice.actions;
export default subscriptionSlice.reducer;
