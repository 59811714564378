import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import {
  getArchiveProjects,
  getProjectCount,
} from "../../../../Feature/Admin/ChannelManagement/kanban/projectSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ArchiveCard from "./ArchiveCard";
import Loaders from "../../../../Components/Loaders/Loaders";

const Archive = ({ state }) => {
  const location = useLocation();
  const data = location.state?.channel_id;
  const dispatch = useDispatch();
  const { archiveProjects, isArchiveLoading, count, isLoading } = useSelector(
    (state) => state.projects
  );
  const {searchArchiveProjectSuccess } = useSelector(
    (state) => state.updateProject
  );
  const { updateProjectSuccess, deleteProjectSuccess } = useSelector(
    (state) => state.updateProjectData
  );
  const [archiveCount, setArchiveCount] = useState(0);
  const [archiveData, setArchiveData] = useState([]);

  useEffect(() => {
    if (state === "inactiveTab") {
      dispatch(getArchiveProjects(data));
      dispatch(getProjectCount(data));
    }
    // eslint-disable-next-line
  }, [state]);

  useEffect(() => {
    if (count && state === "inactiveTab") {
      if (count.archive) {
        if (count.archive[0]) {
          setArchiveCount(count.archive[0].count);
        }
      }
    }
  }, [count, state]);

  useEffect(() => {
    if (archiveProjects) {
      setArchiveData(archiveProjects);
    }
  }, [archiveProjects]);

  useEffect(() => {
    if (searchArchiveProjectSuccess) {
      setArchiveData(searchArchiveProjectSuccess);
    }
  }, [searchArchiveProjectSuccess]);

  useEffect(() => {
    if (
      (updateProjectSuccess || deleteProjectSuccess) &&
      state === "inactiveTab"
    ) {
      dispatch(getArchiveProjects(data));
      dispatch(getProjectCount(data));
    }
    // eslint-disable-next-line
  }, [updateProjectSuccess, deleteProjectSuccess,state]);
  console.log(archiveData)
  return (
    <>
      <div className="archive-tab">
        <div className="board-container">
          <div className="column">
            <div className="column-header">
              <Card
                className="card-header mb-4"
                style={{ borderTop: `2px solid #0231E8` }}
              >
                <Card.Text>
                  Archive
                  <span className="widget ms-3">{archiveCount}</span>
                </Card.Text>
              </Card>
            </div>
            {isArchiveLoading === true ? (
              <>
                <div style={{ marginLeft: "20%" }}>
                  <Loaders color="#5f48ea" />
                </div>
              </>
            ) : (
              <div className="column-content">
                <div className="colum-container" style={{ minHeight: "400px" }}>
                  {isLoading ? (
                    <>
                      <div style={{ marginLeft: "20%" }}>
                        <Loaders color="#5f48ea" />
                      </div>
                    </>
                  ) : (
                    archiveData.map((item, index) => {
                      return (
                        <ArchiveCard
                          project={item}
                          key={index + 1}
                          index={index}
                          // color={colors.color}
                          // stage={archiveProjects.name}
                          state={state}
                        />
                      );
                    })
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Archive;
