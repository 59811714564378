import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../Utils/Api";
import {
  updateProjectPath,
  updateProjectStatePath,
  updateProjectScriptPath,
  deleteProjectScriptPath,
  updateProjectVoicePath,
  deleteProjectVoicePath,
  updateProjectVideoPath,
  deleteProjectVideoPath,
  deleteProjectPath,
} from "../../../../Utils/ApiPath";

const initialState = {
  updateProjectLoading: false,
  updateProjectSuccess: false,
  updateProjectFail: "",
  updateProjectStateLoading: false,
  updateProjectStateSuccess: false,
  updateProjectStateFail: "",
  updateProjectScriptLoading: false,
  updateProjectScriptSuccess: false,
  updateProjectScriptFail: "",
  deleteProjectScriptLoading: false,
  deleteProjectScriptSuccess: false,
  deleteProjectScriptFail: "",
  updateProjectVoiceLoading: false,
  updateProjectVoiceSuccess: false,
  updateProjectVoiceFail: "",
  deleteProjectVoiceLoading: false,
  deleteProjectVoiceSuccess: false,
  deleteProjectVoiceFail: "",
  updateProjectVideoLoading: false,
  updateProjectVideoSuccess: false,
  updateProjectVideoFail: "",
  deleteProjectVideoLoading: false,
  deleteProjectVideoSuccess: false,
  deleteProjectVideoFail: "",
  deleteProjectLoading: false,
  deleteProjectSuccess: false,
  deleteProjectFail: "",
};

export const updateProjectData = createAsyncThunk(
  "/user/updateProjectData",
  async (_, thunkAPI) => {
    try {
      var obj = {
        project_name: _.project_name,
        project_link: _.project_link,
        project_state: _.project_state,
        flag: "subchannel",
        due_date: _.due_date,
        description: _.description,
      };
      thunkAPI.dispatch(updateProjectLoading());
      const res = await api.put(updateProjectPath + _.project_id, obj);
      if (res) {
        if (_.script_id === "") {
          await api.delete(deleteProjectScriptPath + _.project_id + "/" + 0);
        }
        thunkAPI.dispatch(updateProjectSuccess(res.data));
        setTimeout(() => {
          thunkAPI.dispatch(clearUpdateState());
        }, 100);
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(updateProjectFail(err));
    }
  }
);

export const updateProjectState = createAsyncThunk(
  "/user/updateProjectState",
  async (_, thunkAPI) => {
    try {
      var obj = {
        project_state: _.project_state,
      };
      thunkAPI.dispatch(updateProjectStateLoading());
      const res = await api.put(updateProjectStatePath + _.project_id, obj);
      if (res) {
        thunkAPI.dispatch(updateProjectStateSuccess(res.data));
        setTimeout(() => {
          thunkAPI.dispatch(clearUpdateStateStatus());
        }, 3000);
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(updateProjectStateFail(err.response.data.error));
    }
  }
);

export const updateProjectScript = createAsyncThunk(
  "/user/updateProjectScript",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(updateProjectScriptLoading());
      const res = await api.delete(
        deleteProjectScriptPath + _.project_id + "/" + _.user_id
      );
      const obj = {
        assigned_status: 1,
        channel_id: _.channel_id,
        channel_script_id: _.user_id,
        project_id: _.project_id,
      };
      if (res) {
        if (_.user_id !== "") {
          await api.post(updateProjectScriptPath, obj);
          thunkAPI.dispatch(updateProjectScriptSuccess(res.data));
          return res.data;
        } else if (_.user_id === "") {
          await api.delete(
            deleteProjectScriptPath + _.project_id + "/" + _.user_id
          );
          thunkAPI.dispatch(updateProjectScriptSuccess(res.data));
          return res.data;
        }
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(updateProjectScriptFail(err.response.data.error));
      setTimeout(() => {
        thunkAPI.dispatch(clearProjectScript());
      }, 3000);
    }
  }
);

export const deleteProjectScriptData = createAsyncThunk(
  "/user/deleteProjectScriptData",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(deleteProjectScriptLoading());
      const res = await api.delete(
        deleteProjectScriptPath + _.project_id + "/" + _.user_id
      );
      if (res) {
        thunkAPI.dispatch(deleteProjectScriptSuccess(res.data));
        return res.data;
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(deleteProjectScriptFail(err));
    }
  }
);

export const updateProjectVoice = createAsyncThunk(
  "/user/updateProjectVoice",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(updateProjectVoiceLoading());
      const res = await api.delete(
        deleteProjectVoicePath + _.project_id + "/" + _.user_id
      );
      const obj = {
        assigned_status: 1,
        channel_id: _.channel_id,
        channel_voice_id: _.user_id,
        project_id: _.project_id,
      };
      if (res) {
        if (_.user_id !== "") {
          await api.post(updateProjectVoicePath, obj);
          thunkAPI.dispatch(updateProjectVoiceSuccess(res.data));
          return res.data;
        } else if (_.user_id === "") {
          await api.delete(
            deleteProjectVoicePath + _.project_id + "/" + _.user_id
          );
          thunkAPI.dispatch(updateProjectVoiceSuccess(res.data));
          return res.data;
        }
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(updateProjectVoiceFail(err.response.data.error));
      setTimeout(() => {
        thunkAPI.dispatch(clearProjectVoice());
      }, 3000);
    }
  }
);

export const deleteProjectVoiceData = createAsyncThunk(
  "/user/deleteProjectVoiceData",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(deleteProjectVoiceLoading());
      const res = await api.delete(
        deleteProjectVoicePath + _.project_id + "/" + _.user_id
      );
      if (res) {
        thunkAPI.dispatch(deleteProjectVoiceSuccess(res.data));
        return res.data;
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(deleteProjectVoiceFail(err));
    }
  }
);

export const updateProjectVideo = createAsyncThunk(
  "/user/updateProjectVideo",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(updateProjectVideoLoading());
      const res = await api.delete(
        deleteProjectVideoPath + _.project_id + "/" + _.user_id
      );
      const obj = {
        assigned_status: 1,
        channel_id: _.channel_id,
        channel_video_id: _.user_id,
        project_id: _.project_id,
      };
      if (res) {
        if (_.user_id !== "") {
          await api.post(updateProjectVideoPath, obj);
          thunkAPI.dispatch(updateProjectVideoSuccess(res.data));
          return res.data;
        } else if (_.user_id === "") {
          await api.delete(
            deleteProjectVideoPath + _.project_id + "/" + _.user_id
          );
          thunkAPI.dispatch(updateProjectVideoSuccess(res.data));
          return res.data;
        }
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(updateProjectVideoFail(err.response.data.error));
      setTimeout(() => {
        thunkAPI.dispatch(clearProjectVideo());
      }, 3000);
    }
  }
);

export const deleteProjectVideoData = createAsyncThunk(
  "/user/deleteProjectVideoData",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(deleteProjectVideoLoading());
      const res = await api.delete(
        deleteProjectVideoPath + _.project_id + "/" + _.user_id
      );
      if (res) {
        thunkAPI.dispatch(deleteProjectVideoSuccess(res.data));
        return res.data;
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(deleteProjectVideoFail(err));
    }
  }
);

export const deleteProjectData = createAsyncThunk(
  "/user/deleteProjectData",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(deleteProjectLoading());
      const res = await api.delete(deleteProjectPath + _);
      if (res) {
        thunkAPI.dispatch(deleteProjectSuccess(res.data));
        setTimeout(() => {
          thunkAPI.dispatch(clearDeleteProjectState());
        }, 100);
        return res.data;
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(deleteProjectFail(err));
    }
  }
);

const updateProjectDataSlice = createSlice({
  name: "updateProjectDataSlice",
  initialState,
  reducers: {
    updateProjectLoading: (state) => {
      state.updateProjectLoading = true;
    },
    updateProjectSuccess: (state) => {
      state.updateProjectLoading = false;
      state.updateProjectSuccess = true;
      state.updateProjectFail = "";
    },
    updateProjectFail: (state, { payload }) => {
      state.updateProjectLoading = false;
      state.updateProjectSuccess = false;
      state.updateProjectFail = payload;
    },
    clearUpdateState: (state) => {
      state.updateProjectSuccess = false;
    },
    updateProjectStateLoading: (state) => {
      state.updateProjectStateLoading = true;
    },
    updateProjectStateSuccess: (state) => {
      state.updateProjectStateLoading = false;
      state.updateProjectStateSuccess = true;
      state.updateProjectStateFail = "";
    },
    updateProjectStateFail: (state, { payload }) => {
      state.updateProjectStateLoading = false;
      state.updateProjectStateSuccess = false;
      state.updateProjectStateFail = payload;
    },
    clearUpdateStateStatus: (state) => {
      state.updateProjectStateSuccess = false;
      state.updateProjectStateFail = "";
    },
    updateProjectScriptLoading: (state) => {
      state.updateProjectScriptLoading = true;
    },
    updateProjectScriptSuccess: (state) => {
      state.updateProjectScriptLoading = false;
      state.updateProjectScriptSuccess = true;
      state.updateProjectScriptFail = "";
    },
    updateProjectScriptFail: (state, { payload }) => {
      state.updateProjectScriptLoading = false;
      state.updateProjectScriptSuccess = false;
      state.updateProjectScriptFail = payload;
    },
    clearProjectScript: (state) => {
      state.updateProjectScriptLoading = false;
      state.updateProjectScriptSuccess = "";
      state.updateProjectScriptFail = "";
    },
    deleteProjectScriptLoading: (state) => {
      state.deleteProjectScriptLoading = true;
    },
    deleteProjectScriptSuccess: (state) => {
      state.deleteProjectScriptLoading = false;
      state.deleteProjectScriptSuccess = true;
      state.deleteProjectScriptFail = "";
    },
    deleteProjectScriptFail: (state, { payload }) => {
      state.deleteProjectScriptLoading = false;
      state.deleteProjectScriptSuccess = false;
      state.deleteProjectScriptFail = payload;
    },
    updateProjectVoiceLoading: (state) => {
      state.updateProjectVoiceLoading = true;
    },
    updateProjectVoiceSuccess: (state) => {
      state.updateProjectVoiceLoading = false;
      state.updateProjectVoiceSuccess = true;
      state.updateProjectVoiceFail = "";
    },
    updateProjectVoiceFail: (state, { payload }) => {
      state.updateProjectVoiceLoading = false;
      state.updateProjectVoiceSuccess = false;
      state.updateProjectVoiceFail = payload;
    },
    clearProjectVoice: (state) => {
      state.updateProjectVoiceLoading = false;
      state.updateProjectVoiceSuccess = "";
      state.updateProjectVoiceFail = "";
    },
    deleteProjectVoiceLoading: (state) => {
      state.deleteProjectVoiceLoading = true;
    },
    deleteProjectVoiceSuccess: (state) => {
      state.deleteProjectVoiceLoading = false;
      state.deleteProjectVoiceSuccess = true;
      state.deleteProjectVoiceFail = "";
    },
    deleteProjectVoiceFail: (state, { payload }) => {
      state.deleteProjectVoiceLoading = false;
      state.deleteProjectVoiceSuccess = false;
      state.deleteProjectVoiceFail = payload;
    },
    updateProjectVideoLoading: (state) => {
      state.updateProjectVideoLoading = true;
    },
    updateProjectVideoSuccess: (state) => {
      state.updateProjectVideoLoading = false;
      state.updateProjectVideoSuccess = true;
      state.updateProjectVideoFail = "";
    },
    updateProjectVideoFail: (state, { payload }) => {
      state.updateProjectVideoLoading = false;
      state.updateProjectVideoSuccess = false;
      state.updateProjectVideoFail = payload;
    },
    clearProjectVideo: (state) => {
      state.updateProjectVideoLoading = false;
      state.updateProjectVideoSuccess = "";
      state.updateProjectVideoFail = "";
    },
    deleteProjectVideoLoading: (state) => {
      state.deleteProjectVideoLoading = true;
    },
    deleteProjectVideoSuccess: (state) => {
      state.deleteProjectVideoLoading = false;
      state.deleteProjectVideoSuccess = true;
      state.deleteProjectVideoFail = "";
    },
    deleteProjectVideoFail: (state, { payload }) => {
      state.deleteProjectVideoLoading = false;
      state.deleteProjectVideoSuccess = false;
      state.deleteProjectVideoFail = payload;
    },
    deleteProjectLoading: (state) => {
      state.deleteProjectLoading = true;
    },
    deleteProjectSuccess: (state) => {
      state.deleteProjectLoading = false;
      state.deleteProjectSuccess = true;
      state.deleteProjectFail = "";
    },
    deleteProjectFail: (state, { payload }) => {
      state.deleteProjectLoading = false;
      state.deleteProjectSuccess = false;
      state.deleteProjectFail = payload;
    },
    clearDeleteProjectState: (state) => {
      state.deleteProjectSuccess = false;
    },
  },
});

export const {
  updateProjectLoading,
  updateProjectSuccess,
  updateProjectFail,
  clearUpdateState,
  updateProjectStateLoading,
  updateProjectStateSuccess,
  updateProjectStateFail,
  clearUpdateStateStatus,
  updateProjectScriptLoading,
  updateProjectScriptSuccess,
  updateProjectScriptFail,
  deleteProjectScriptLoading,
  deleteProjectScriptSuccess,
  deleteProjectScriptFail,
  updateProjectVoiceLoading,
  updateProjectVoiceSuccess,
  updateProjectVoiceFail,
  deleteProjectVoiceLoading,
  deleteProjectVoiceSuccess,
  deleteProjectVoiceFail,
  updateProjectVideoLoading,
  updateProjectVideoSuccess,
  updateProjectVideoFail,
  deleteProjectVideoLoading,
  deleteProjectVideoSuccess,
  deleteProjectVideoFail,
  deleteProjectLoading,
  deleteProjectSuccess,
  deleteProjectFail,
  clearDeleteProjectState,
  clearProjectScript,
  clearProjectVoice,
  clearProjectVideo,
} = updateProjectDataSlice.actions;
export default updateProjectDataSlice.reducer;
