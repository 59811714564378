import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Utils/Api";
import {
  channelUserRestrictPath,
  deleteChannelPath,
  userRestrictPath,
  deleteScriptRestrictPath,
  deleteVoiceRestrictPath,
  deleteVideoRestrictPath,
  addScriptRestrictPath,
  addVoiceRestrictPath,
  addVideoRestrictPath,
  updatePublishDatePath,
  updateChannelModePath,
  bulkInactivateUsersPath,
  bulkInactivateChannelPath,
} from "../../../Utils/ApiPath";

const initialState = {
  deleteChannelPending: false,
  deleteChannelSuccess: false,
  deleteChannelError: "",
  userRestrictPending: false,
  userRestrictSuccess: {},
  userRestrictError: "",
  channelUserRestrictPending: false,
  channelUserRestrictSuccess: {},
  channelUserRestrictError: "",
  addScriptRestrictPending: false,
  addScriptRestrictSuccess: false,
  addScriptRestrictError: "",
  addVoiceRestrictPending: false,
  addVoiceRestrictSuccess: false,
  addVoiceRestrictError: "",
  addVideoRestrictPending: false,
  addVideoRestrictSuccess: false,
  addVideoRestrictError: "",
  updateDatePending: false,
  updateDateSuccess: false,
  updateDateError: "",
  updateChannelModePending: false,
  updateChannelModeSuccess: false,
  updateChannelModeError: "",
  bulkInactivateUsersPending: false,
  bulkInactivateUsersSuccess: "",
  bulkInactivateUsersError: "",
  bulkInactivateChannelPending: false,
  bulkInactivateChannelSuccess: "",
  bulkInactivateChannelError: "",
};

export const deleteChannelId = createAsyncThunk(
  "/delete/deleteChannel",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(deleteChannelPending());
      const res = await api.delete(deleteChannelPath + _);
      if (res) {
        thunkAPI.dispatch(deleteChannelSuccess(res.data));
        setTimeout(() => {
          thunkAPI.dispatch(clearState());
        }, 4000);
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(deleteChannelError(err));
    }
  }
);

export const getUserRestrict = createAsyncThunk(
  "/delete/getUserRestrict",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(userRestrictPending());
      const res = await api.get(userRestrictPath + _);
      if (res) {
        thunkAPI.dispatch(userRestrictSuccess(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(userRestrictError(err));
    }
  }
);

export const getChannelUserRestrict = createAsyncThunk(
  "/delete/getChannelUserRestrict",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(channelUserRestrictPending());
      const res = await api.get(channelUserRestrictPath + _);
      if (res) {
        thunkAPI.dispatch(channelUserRestrictSuccess(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(channelUserRestrictError(err));
    }
  }
);

export const restrictScript = createAsyncThunk(
  "/channelEdit/restrictScript",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(addScriptRestrictPending());
      const res = await api.delete(deleteScriptRestrictPath + _.channel_id);
      if (res) {
        if (_.script_writer.length !== 0) {
          for (let i = 0; i < _.script_writer.length; i++) {
            const obj = {
              channel_id: _.channel_id,
              user_id: _.script_writer[i].user_id,
            };
            await api.post(addScriptRestrictPath, obj);
          }
        }
        thunkAPI.dispatch(addScriptRestrictSuccess(res.data));
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(addScriptRestrictError(err));
    }
  }
);

export const restrictVoice = createAsyncThunk(
  "/channelEdit/restrictVoice",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(addVoiceRestrictPending());
      const res = await api.delete(deleteVoiceRestrictPath + _.channel_id);
      if (res) {
        if (_.voice_artist.length !== 0) {
          for (let i = 0; i < _.voice_artist.length; i++) {
            const obj = {
              channel_id: _.channel_id,
              user_id: _.voice_artist[i].user_id,
            };
            await api.post(addVoiceRestrictPath, obj);
          }
        }
        thunkAPI.dispatch(addVoiceRestrictSuccess(res.data));
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(addVoiceRestrictError(err));
    }
  }
);

export const restrictVideo = createAsyncThunk(
  "/channelEdit/restrictVideo",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(addVideoRestrictPending());
      const res = await api.delete(deleteVideoRestrictPath + _.channel_id);
      if (res) {
        if (_.video_editor.length !== 0) {
          for (let i = 0; i < _.video_editor.length; i++) {
            const obj = {
              channel_id: _.channel_id,
              user_id: _.video_editor[i].user_id,
            };
            await api.post(addVideoRestrictPath, obj);
          }
        }
        thunkAPI.dispatch(addVideoRestrictSuccess(res.data));
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(addVideoRestrictError(err));
    }
  }
);

export const updatePublishDate = createAsyncThunk(
  "/delete/updatePublishDate",
  async (_, thunkAPI) => {
    try {
      const obj = {
        published_date: _.date,
      };
      thunkAPI.dispatch(updateDatePending());
      const res = await api.put(updatePublishDatePath + _.channel_id, obj);
      if (res) {
        thunkAPI.dispatch(updateDateSuccess(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(updateDateError(err));
    }
  }
);

export const updateChannelMode = createAsyncThunk(
  "/delete/updateChannelMode",
  async (_, thunkAPI) => {
    try {
      const obj = {
        channel_mode: _.mode,
      };
      thunkAPI.dispatch(updateChannelModePending());
      const res = await api.put(updateChannelModePath + _.channel_id, obj);
      if (res) {
        thunkAPI.dispatch(updateChannelModeSuccess(res.data));
        setTimeout(() => {
          thunkAPI.dispatch(clearChannelMode());
        }, 100);
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(updateChannelModeError(err));
      setTimeout(() => {
        thunkAPI.dispatch(clearChannelMode());
      }, 100);
    }
  }
);

export const bulkInactivateUsers = createAsyncThunk(
  "/users/bulkinactivate",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(bulkInactivateUsersPending());
      const obj = {
        userList: _,
      };
      const res = await api.put(bulkInactivateUsersPath, obj);
      if (res) {
        thunkAPI.dispatch(bulkInactivateUsersSuccess(res.data));
        setTimeout(() => {
          thunkAPI.dispatch(clearbulkInactivateUsers());
        }, 3000);
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(bulkInactivateUsersError(err));
    }
  }
);

export const bulkInactivateChannel = createAsyncThunk(
  "/Channel/bulkinactivate",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(bulkInactivateChannelPending());
      const obj = {
        id: _,
      };
      const res = await api.put(bulkInactivateChannelPath, obj);
      if (res) {
        thunkAPI.dispatch(bulkInactivateChannelSuccess(res.data));
        setTimeout(() => {
          thunkAPI.dispatch(clearbulkInactivateChannel());
        }, 3000);
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(bulkInactivateChannelError(err));
    }
  }
);

const channelDeleteSlice = createSlice({
  name: "channelDelete",
  initialState,
  reducers: {
    deleteChannelPending: (state) => {
      state.deleteChannelPending = true;
    },
    deleteChannelSuccess: (state) => {
      state.deleteChannelPending = false;
      state.deleteChannelSuccess = true;
      state.deleteChannelError = "";
    },
    deleteChannelError: (state, { payload }) => {
      state.deleteChannelPending = false;
      state.deleteChannelSuccess = false;
      state.deleteChannelError = payload;
    },
    clearState: (state) => {
      state.deleteChannelPending = false;
      state.deleteChannelSuccess = false;
      state.deleteChannelError = "";
    },
    userRestrictPending: (state) => {
      state.userRestrictPending = true;
    },
    userRestrictSuccess: (state, { payload }) => {
      state.userRestrictPending = false;
      state.userRestrictSuccess = payload;
      state.userRestrictError = "";
    },
    userRestrictError: (state, { payload }) => {
      state.userRestrictPending = false;
      state.userRestrictSuccess = {};
      state.userRestrictError = payload;
    },
    channelUserRestrictPending: (state) => {
      state.channelUserRestrictPending = true;
    },
    channelUserRestrictSuccess: (state, { payload }) => {
      state.channelUserRestrictPending = false;
      state.channelUserRestrictSuccess = payload;
      state.channelUserRestrictError = "";
    },
    channelUserRestrictError: (state, { payload }) => {
      state.channelUserRestrictPending = false;
      state.channelUserRestrictSuccess = {};
      state.channelUserRestrictError = payload;
    },
    addScriptRestrictPending: (state) => {
      state.addScriptRestrictPending = true;
    },
    addScriptRestrictSuccess: (state, { payload }) => {
      state.addScriptRestrictPending = false;
      state.addScriptRestrictSuccess = payload;
      state.addScriptRestrictError = "";
    },
    addScriptRestrictError: (state, { payload }) => {
      state.addScriptRestrictPending = false;
      state.addScriptRestrictSuccess = {};
      state.addScriptRestrictError = payload;
    },
    addVoiceRestrictPending: (state) => {
      state.addVoiceRestrictPending = true;
    },
    addVoiceRestrictSuccess: (state, { payload }) => {
      state.addVoiceRestrictPending = false;
      state.addVoiceRestrictSuccess = payload;
      state.addVoiceRestrictError = "";
    },
    addVoiceRestrictError: (state, { payload }) => {
      state.addVoiceRestrictPending = false;
      state.addVoiceRestrictSuccess = {};
      state.addVoiceRestrictError = payload;
    },
    addVideoRestrictPending: (state) => {
      state.addVideoRestrictPending = true;
    },
    addVideoRestrictSuccess: (state, { payload }) => {
      state.addVideoRestrictPending = false;
      state.addVideoRestrictSuccess = payload;
      state.addVideoRestrictError = "";
    },
    addVideoRestrictError: (state, { payload }) => {
      state.addVideoRestrictPending = false;
      state.addVideoRestrictSuccess = {};
      state.addVideoRestrictError = payload;
    },
    updateDatePending: (state) => {
      state.updateDatePending = true;
    },
    updateDateSuccess: (state) => {
      state.updateDatePending = false;
      state.updateDateSuccess = true;
      state.updateDateError = "";
    },
    updateDateError: (state, { payload }) => {
      state.updateDatePending = false;
      state.updateDateSuccess = {};
      state.updateDateError = payload;
    },
    updateChannelModePending: (state) => {
      state.updateChannelModePending = true;
    },
    updateChannelModeSuccess: (state) => {
      state.updateChannelModePending = false;
      state.updateChannelModeSuccess = true;
      state.updateChannelModeError = "";
    },
    updateChannelModeError: (state, { payload }) => {
      state.updateChannelModePending = false;
      state.updateChannelModeSuccess = false;
      state.updateChannelModeError = payload;
    },
    clearChannelMode: (state) => {
      state.updateChannelModePending = false;
      state.updateChannelModeSuccess = false;
      state.updateChannelModeError = "";
    },
    bulkInactivateUsersPending: (state) => {
      state.bulkInactivateUsersPending = true;
    },
    bulkInactivateUsersSuccess: (state, { payload }) => {
      state.bulkInactivateUsersPending = false;
      state.bulkInactivateUsersSuccess = payload;
      state.bulkInactivateUsersError = "";
    },
    bulkInactivateUsersError: (state, { payload }) => {
      state.bulkInactivateUsersPending = false;
      state.bulkInactivateUsersSuccess = false;
      state.bulkInactivateUsersError = payload;
    },
    clearbulkInactivateUsers: (state) => {
      state.bulkInactivateUsersPending = false;
      state.bulkInactivateUsersSuccess = false;
      state.bulkInactivateUsersError = "";
    },
    bulkInactivateChannelPending: (state) => {
      state.bulkInactivateChannelPending = true;
    },
    bulkInactivateChannelSuccess: (state, { payload }) => {
      state.bulkInactivateChannelPending = false;
      state.bulkInactivateChannelSuccess = payload;
      state.bulkInactivateChannelError = "";
    },
    bulkInactivateChannelError: (state, { payload }) => {
      state.bulkInactivateChannelPending = false;
      state.bulkInactivateChannelSuccess = false;
      state.bulkInactivateChannelError = payload;
    },
    clearbulkInactivateChannel: (state) => {
      state.bulkInactivateChannelPending = false;
      state.bulkInactivateChannelSuccess = false;
      state.bulkInactivateChannelError = "";
    },
  },
});

const { reducer, actions } = channelDeleteSlice;

export const {
  deleteChannelPending,
  deleteChannelSuccess,
  deleteChannelError,
  clearState,
  userRestrictPending,
  userRestrictSuccess,
  userRestrictError,
  channelUserRestrictPending,
  channelUserRestrictSuccess,
  channelUserRestrictError,
  addScriptRestrictPending,
  addScriptRestrictSuccess,
  addScriptRestrictError,
  addVoiceRestrictPending,
  addVoiceRestrictSuccess,
  addVoiceRestrictError,
  addVideoRestrictPending,
  addVideoRestrictSuccess,
  addVideoRestrictError,
  updateDatePending,
  updateDateSuccess,
  updateDateError,
  updateChannelModePending,
  updateChannelModeSuccess,
  updateChannelModeError,
  clearChannelMode,
  bulkInactivateUsersPending,
  bulkInactivateUsersSuccess,
  bulkInactivateUsersError,
  clearbulkInactivateUsers,
  bulkInactivateChannelPending,
  bulkInactivateChannelSuccess,
  bulkInactivateChannelError,
  clearbulkInactivateChannel,
} = actions;

export default reducer;
