import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Utils/Api";
import {
  getReportPath,
  getReportScriptPath,
  getReportVoicePath,
  getReportVideoPath,
  getReportThumbnailPath,
  getReportDescPath,
  getReportDatePath,
} from "../../../Utils/ApiPath";

const initialState = {
  getReportPending: false,
  getReportSuccess: [],
  getReportFail: "",
  getReportScript: [],
  getReportScriptFail: "",
  getReportVoice: [],
  getReportVoiceFail: "",
  getReportVideo: [],
  getReportVideoFail: "",
  getReportThumbnail: [],
  getReportThumbnailFail: "",
  getReportDesc: [],
  getReportDescFail: "",
  getReportDate: [],
  getReportDateFail: "",
};

export const getUser = createAsyncThunk(
  "/user/getUser",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getReportPending());
      const res = await api.get(getReportPath + "/" + _);
      if (res) {
        thunkAPI.dispatch(getReportSuccess(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getReportFail(err));
    }
  }
);

export const searchReportScript = createAsyncThunk(
  "/user/getUserScript",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getReportPending());
      const res = await api.get(
        getReportScriptPath + "/" + _.id + "/" + Number(_.state)
      );
      if (res) {
        thunkAPI.dispatch(getReportScript(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getReportScriptFail(err));
    }
  }
);

export const searchReportVoice = createAsyncThunk(
  "/user/getUserVoice",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getReportPending());
      const res = await api.get(
        getReportVoicePath + "/" + _.id + "/" + Number(_.state)
      );
      if (res) {
        thunkAPI.dispatch(getReportVoice(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getReportVoiceFail(err));
    }
  }
);

export const searchReportVideo = createAsyncThunk(
  "/user/getUserVideo",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getReportPending());
      const res = await api.get(
        getReportVideoPath + "/" + _.id + "/" + Number(_.state)
      );
      if (res) {
        thunkAPI.dispatch(getReportVideo(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getReportVideoFail(err));
    }
  }
);

export const searchReportThumbnail = createAsyncThunk(
  "/user/getUserThumbnail",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getReportPending());
      const res = await api.get(
        getReportThumbnailPath + "/" + _.id + "/" + Number(_.state)
      );
      if (res) {
        thunkAPI.dispatch(getReportThumbnail(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getReportThumbnailFail(err));
    }
  }
);

export const searchReportDesc = createAsyncThunk(
  "/user/getUserDesc",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getReportPending());
      const res = await api.get(
        getReportDescPath + "/" + _.id + "/" + Number(_.state)
      );
      if (res) {
        thunkAPI.dispatch(getReportDesc(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getReportDescFail(err));
    }
  }
);

export const searchReportDate = createAsyncThunk(
  "/user/getUserDate",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getReportPending());
      const res = await api.get(
        getReportDatePath +
          "/" +
          Number(_.state) +
          "/" +
          _.start_date +
          "/" +
          _.end_date
      );
      if (res) {
        thunkAPI.dispatch(getReportDate(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getReportDateFail(err));
    }
  }
);

const reportSlice = createSlice({
  name: "reportSlice",
  initialState,
  reducers: {
    getReportPending: (state) => {
      state.getReportPending = true;
    },
    getReportSuccess: (state, { payload }) => {
      state.getReportPending = false;
      state.getReportSuccess = payload;
      state.getReportFail = "";
    },
    getReportFail: (state, payload) => {
      state.getReportPending = false;
      state.getReportSuccess = [];
      state.getReportFail = payload;
    },
    getReportScript: (state, { payload }) => {
      state.getReportPending = false;
      state.getReportScript = payload;
      state.getReportScriptFail = "";
    },
    getReportScriptFail: (state, payload) => {
      state.getReportPending = false;
      state.getReportScript = [];
      state.getReportScriptFail = payload;
    },
    getReportVoice: (state, { payload }) => {
      state.getReportPending = false;
      state.getReportVoice = payload;
      state.getReportVoiceFail = "";
    },
    getReportVoiceFail: (state, payload) => {
      state.getReportPending = false;
      state.getReportVoice = [];
      state.getReportVoiceFail = payload;
    },
    getReportVideo: (state, { payload }) => {
      state.getReportPending = false;
      state.getReportVideo = payload;
      state.getReportVideoFail = "";
    },
    getReportVideoFail: (state, payload) => {
      state.getReportPending = false;
      state.getReportVideo = [];
      state.getReportVideoFail = payload;
    },
    getReportThumbnail: (state, { payload }) => {
      state.getReportPending = false;
      state.getReportThumbnail = payload;
      state.getReportThumbnailFail = "";
    },
    getReportThumbnailFail: (state, payload) => {
      state.getReportPending = false;
      state.getReportThumbnail = [];
      state.getReportThumbnailFail = payload;
    },
    getReportDesc: (state, { payload }) => {
      state.getReportPending = false;
      state.getReportDesc = payload;
      state.getReportDescFail = "";
    },
    getReportDescFail: (state, payload) => {
      state.getReportPending = false;
      state.getReportDesc = [];
      state.getReportDescFail = payload;
    },
    getReportDate: (state, { payload }) => {
      state.getReportPending = false;
      state.getReportDate = payload;
      state.getReportDateFail = "";
    },
    getReportDateFail: (state, payload) => {
      state.getReportPending = false;
      state.getReportDate = [];
      state.getReportDateFail = payload;
    },
  },
});

export const {
  getReportPending,
  getReportSuccess,
  getReportFail,
  getReportScript,
  getReportScriptFail,
  getReportVoice,
  getReportVoiceFail,
  getReportVideo,
  getReportVideoFail,
  getReportThumbnail,
  getReportThumbnailFail,
  getReportDesc,
  getReportDescFail,
  getReportDate,
  getReportDateFail,
} = reportSlice.actions;
export default reportSlice.reducer;
