import React, { useEffect, useState } from "react";
import Board from "./kanban/Board";
// import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getChannelInfo } from "../../../Feature/Admin/projectManagementSlice";
import { searchArchiveProjects, searchProject } from "../../../Feature/Admin/ChannelManagement/kanban/updateSlice";
import {
  // getArchiveProjects,
  // getBacklogProjects,
  // getCompProjects,
  // getPublishedProjects,
  // getReadyProjects,
  // getRevisionProjects,
  // getScriptProjects,
  // getVideoProjects,
  // getVoiceProjects,
  getAllProject, getArchiveProjects,
} from "../../../Feature/Admin/ChannelManagement/kanban/projectSlice";
import { useLocation } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
import Archive from "./kanban/Archive";

function ProjectManagement() {
  // let { id } = useParams();
  const dispatch = useDispatch();
  const { channelInfo } = useSelector((state) => state.channelInfo);
  const [searchText, setSearchText] = useState("");
  const [key, setKey] = useState("activeTab");

  useEffect(() => {
    dispatch(getChannelInfo(data));
    // eslint-disable-next-line
  }, []);

  const location = useLocation();
  const data = location.state?.channel_id;

  const onChangeHandler = (text) => {
    console.log(text);
    setSearchText(text);
    const obj = {
      project_id: data,
      regex: text,
    };
    if (text) {
      if (key === 'activeTab') {
        dispatch(searchProject(obj));
      }
      if (key === 'inactiveTab') {
        dispatch(searchArchiveProjects(obj));
      }
    } else {
      if (key === 'activeTab') {
        dispatch(getAllProject(data));
      }
      if (key === 'inactiveTab') {
        console.log(key)
        dispatch(getArchiveProjects(data))
      }
    }
  };

  return (
    <>
      <div className="project-management">
        <div className="content-title">
          Channel Management -{" "}
          <span style={{ color: "#5f48ea" }}>{channelInfo.channel_name}</span>
        </div>
        <div>{channelInfo.description}</div>
        <div style={{ textAlign: 'end', marginRight: "50px" }}>
          <input
            type="text"
            className="searchBox"
            placeholder="Search"
            value={searchText}
            onChange={(e) => onChangeHandler(e.target.value)}
            style={{ width: "20%" }}
          />
        </div>
        <div className="wrapper">
          <div className="tabs-section">
            <Tabs
              defaultActiveKey="activeTab"
              id="uncontrolled-tab-example"
              className="mb-3"
              transition={true}
              activeKey={key}
              onSelect={(key) => { setKey(key); setSearchText(""); }}
            >
              <Tab eventKey="activeTab" title="In Progress">
                <Board state={key} />
              </Tab>
              <Tab eventKey="inactiveTab" title="Archive">
                <Archive state={key} />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProjectManagement;
