import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../Utils/Api";
import {
  pmGetActiveChannelPath,
  pmGetInactiveChannelPath,
  searchProjectInChannelPath
} from "../../Utils/ApiPathResources";

const initialState = {
  isLoading: false,
  getPmActiveChannels: [],
  getPmActiveChannelsFail: "",
  getPmInactiveChannels: [],
  getPmInactiveChannelsFail: "",
  getSearchChannel:[],
  getSearchChannelError:""
};

export const getPmActiveChannel = createAsyncThunk(
  "/pm/getPmActiveChannel",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getPmActiveChannelsPending());
      const user_id = Number(localStorage.getItem("user_id"));
      const res = await api.get(pmGetActiveChannelPath + user_id);
      if (res.data) {
        thunkAPI.dispatch(getPmActiveChannelsSuccess(res.data));
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getPmActiveChannelsFail(err));
    }
  }
);

export const getPmInactiveChannel = createAsyncThunk(
  "/pm/getPmInactiveChannel",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getPmInactiveChannelsPending());
      const user_id = Number(localStorage.getItem("user_id"));
      const res = await api.get(pmGetInactiveChannelPath + user_id);
      if (res.data) {
        thunkAPI.dispatch(getPmInactiveChannelsSuccess(res.data));
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getPmInactiveChannelsFail(err));
    }
  }
);

export const getSearchProjectInChannel = createAsyncThunk(
  "/pm/getSearchChannelInProject",
  async(_,thunkAPI) =>{
    try {
      thunkAPI.dispatch(getsearchChannelInProjectPending());
      const res = await api.get(searchProjectInChannelPath + _.regx + "/" + _.status);
      if (res.data) {
        thunkAPI.dispatch(getsearchChannelInProjectSuccess(res.data));
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getsearchChannelInProjectFail(err));
    }
  }
)

const PmDashboard = createSlice({
  name: "Pm",
  initialState,
  reducers: {
    getPmActiveChannelsPending: (state) => {
      state.isLoading = true;
    },
    getPmActiveChannelsSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.getPmActiveChannels = payload;
      state.getPmActiveChannelsFail = "";
    },
    getPmActiveChannelsFail: (state, { payload }) => {
      state.isLoading = false;
      state.getPmActiveChannels = [];
      state.getPmActiveChannelsFail = payload;
    },
    getPmInactiveChannelsPending: (state) => {
      state.isLoading = true;
    },
    getPmInactiveChannelsSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.getPmInactiveChannels = payload;
      state.getPmInactiveChannelsFail = "";
    },
    getPmInactiveChannelsFail: (state, { payload }) => {
      state.isLoading = false;
      state.getPmInactiveChannels = [];
      state.getPmInactiveChannelsFail = payload;
    },
    getsearchChannelInProjectPending: (state) => {
      state.isLoading = true;
    },
    getsearchChannelInProjectSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.getSearchChannel = payload;
      state.getSearchChannelError = "";
    },
    getsearchChannelInProjectFail: (state, { payload }) => {
      state.isLoading = false;
      state.getSearchChannelError = [];
      state.getSearchChannelError = payload;
    },
  },
});

export const {
  getPmActiveChannelsPending,
  getPmActiveChannelsSuccess,
  getPmActiveChannelsFail,
  getPmInactiveChannelsPending,
  getPmInactiveChannelsSuccess,
  getPmInactiveChannelsFail,
  getsearchChannelInProjectPending,
  getsearchChannelInProjectSuccess,
  getsearchChannelInProjectFail
} = PmDashboard.actions;
export default PmDashboard.reducer;
