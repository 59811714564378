import React, { useEffect } from "react";
import DashboardCards from "../../Components/Cards/DashboardCards";
import {
  UilYoutube,
  UilSpinnerAlt,
  UilCheckCircle,
  UilUploadAlt,
  UilArchive,
} from "@iconscout/react-unicons";
import { userName } from "../../Common/CommonFunction";
import { useDispatch, useSelector } from "react-redux";
import { getClientCount } from "../../Feature/Client/dashboardSlice";

const Dashboard = () => {
  var token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const { getClientDashboardCount } = useSelector(
    (state) => state.clientDashboard
  );

  useEffect(() => {
    var id = localStorage.getItem("user_id");
    dispatch(getClientCount(id));
    // eslint-disable-next-line
  }, []);

  const data = [
    {
      name: "Not Started Videos",
      value: getClientDashboardCount.getnotstarted
        ? getClientDashboardCount.getnotstarted[0].count
        : 0,
      icon: <UilYoutube size="30" color="#fff" />,
    },
    {
      name: "Inprogress Videos",
      value: getClientDashboardCount.inprogress
        ? getClientDashboardCount.inprogress[0].count
        : 0,
      icon: <UilSpinnerAlt size="30" color="#fff" />,
    },
    {
      name: "Ready To Upload",
      value: getClientDashboardCount.readyupload
        ? getClientDashboardCount.readyupload[0].count
        : 0,
      icon: <UilCheckCircle size="30" color="#fff" />,
    },
    {
      name: "Uploaded Videos",
      value: getClientDashboardCount.uploaded
        ? getClientDashboardCount.uploaded[0].count
        : 0,
      icon: <UilUploadAlt size="30" color="#fff" />,
    },
    {
      name: "Archive Videos",
      value: getClientDashboardCount.archive
        ? getClientDashboardCount.archive[0].count
        : 0,
      icon: <UilArchive size="30" color="#fff" />,
    },
  ];

  return (
    <>
      <div className="dashboard">
        <div className="dashboard-title">Welcome {userName(token)}</div>
        <div className="row justify-content-evenly">
          {data.map((item, index) => (
            <DashboardCards data={item} key={index} />
          ))}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
