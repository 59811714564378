import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../Utils/Api";
import {
  checkUserStatusPath,
  availableScriptCountPath,
  scriptCountPath,
  availableVoiceCountPath,
  voiceCountPath,
  availableVideoCountPath,
  videoCountPath,
  thumbDashboardCountPath,
  descDashboardCountPath,
} from "../../Utils/ApiPathResources";

const initialState = {
  isLoading: false,
  userStatus: "",
  userStatusFail: "",
  getDashboardCount: {},
  getDashboardCountFail: "",
};

export const CheckUserStatus = createAsyncThunk(
  "/resources/CheckUserStatus",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(CheckUserStatusPending());
      const res = await api.get(
        checkUserStatusPath + Number(localStorage.getItem("user_id"))
      );
      if (res) {
        thunkAPI.dispatch(CheckUserStatusSuccess(res.data));
      }
      return res.data;
    } catch (err) {
      thunkAPI.dispatch(CheckUserStatusFail(err));
    }
  }
);

export const getCount = createAsyncThunk(
  "/resources/getCount",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getCountPending());
      var role_id = Number(localStorage.getItem("role_id"));
      var user_id = Number(localStorage.getItem("user_id"));
      var count = {};
      if (role_id === 3) {
        const res = await api.get(availableScriptCountPath + user_id);
        const res1 = await api.get(scriptCountPath + user_id);
        if (res.data && res1.data) {
          count = {
            available: res.data.getscript[0],
            assigned: res1.data.scriptcount[0],
            completed: res1.data.scriptcompletecount[0],
          };
          thunkAPI.dispatch(getCountSuccess(count));
        }
      }
      if (role_id === 4) {
        const res = await api.get(availableVoiceCountPath + user_id);
        const res1 = await api.get(voiceCountPath + user_id);
        if (res.data && res1.data) {
          count = {
            available: res.data.getvoice[0],
            assigned: res1.data.voicecount[0],
            completed: res1.data.voicecompletecount[0],
          };
          thunkAPI.dispatch(getCountSuccess(count));
        }
      }
      if (role_id === 5) {
        const res = await api.get(availableVideoCountPath + user_id);
        const res1 = await api.get(videoCountPath + user_id);
        if (res.data && res1.data) {
          count = {
            available: res.data.getvideo[0],
            assigned: res1.data.videocount[0],
            completed: res1.data.videocompletecount[0],
          };
          thunkAPI.dispatch(getCountSuccess(count));
        }
      }
      if (role_id === 7) {
        const res = await api.get(thumbDashboardCountPath + user_id);
        if (res.data) {
          thunkAPI.dispatch(getCountSuccess(res.data));
        }
      }
      if (role_id === 8) {
        const res = await api.get(descDashboardCountPath + user_id);
        if (res.data) {
          thunkAPI.dispatch(getCountSuccess(res.data));
        }
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getCountFail());
    }
  }
);

const L1RDashboard = createSlice({
  name: "L1R",
  initialState,
  reducers: {
    CheckUserStatusPending: (state) => {
      state.isLoading = true;
    },
    CheckUserStatusSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.userStatus = payload;
      state.userStatusFail = "";
    },
    CheckUserStatusFail: (state, { payload }) => {
      state.isLoading = false;
      state.userStatus = "";
      state.userStatusFail = payload;
    },
    getCountPending: (state) => {
      state.isLoading = true;
    },
    getCountSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.getDashboardCount = payload;
      state.getDashboardCountFail = "";
    },
    getCountFail: (state, { payload }) => {
      state.isLoading = false;
      state.getDashboardCount = {};
      state.getDashboardCountFail = payload;
    },
  },
});

export const {
  CheckUserStatusPending,
  CheckUserStatusSuccess,
  CheckUserStatusFail,
  getCountPending,
  getCountSuccess,
  getCountFail,
} = L1RDashboard.actions;
export default L1RDashboard.reducer;
