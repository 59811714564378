import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import MyReportTable from "./MyReportTable";
import { useDispatch, useSelector } from "react-redux";
import {
  getReportData,
  getReportDataByDate,
} from "../../Feature/LevelOneResource/ReportSlice";
import ReactPaginate from "react-paginate";

const MyReport = () => {
  const dispatch = useDispatch();
  const { getReport, getReportByDate } = useSelector(
    (state) => state.L1RReport
  );

  const tabData1 = [
    { key: "0", title: "In Progress" },
    { key: "1", title: "Completed" },
    { key: "2", title: "Paid" },
  ];
  const tabData2 = [
    { key: "1", title: "Completed" },
    { key: "2", title: "Paid" },
  ];

  const [role, setRole] = useState("");
  const [tab, setTab] = useState("");
  const [key, setKey] = useState("0");
  const [data, setData] = useState([]);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [recordsPerPage, setRecordsPerPage] = useState(10);
  // const indexOfLastRecord = currentPage * recordsPerPage;
  // const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  // const currentRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);
  // const nPages = Math.ceil(data.length / recordsPerPage);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + Number(itemsPerPage);
  const currentItems = data.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(data.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    setItemOffset(newOffset);
  };

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    const role = localStorage.getItem("role_id");
    if (role === "3" || role === "4" || role === "5") {
      setRole("level1");
    } else {
      setRole("level2");
    }
  }, []);

  useEffect(() => {
    const role = localStorage.getItem("role_id");
    if (role === "3" || role === "4" || role === "5") {
      setTab(tabData1);
      setKey("0");
    } else {
      setTab(tabData2);
      setKey("1");
    }
    // eslint-disable-next-line
  }, [role]);

  //UseEffects:
  useEffect(() => {
    if (role === "level1" && key === "0") {
      dispatch(getReportData(key));
      setItemOffset(0);
    } else if (role === "level1" && key === "1") {
      dispatch(getReportData(key));
      setItemOffset(0);
    } else if (role === "level1" && key === "2") {
      dispatch(getReportData(key));
      setItemOffset(0);
    } else if (role === "level2" && key === "1") {
      dispatch(getReportData(key));
      setItemOffset(0);
    } else if (role === "level2" && key === "2") {
      dispatch(getReportData(key));
      setItemOffset(0);
    }
    // eslint-disable-next-line
  }, [key, role]);

  useEffect(() => {
    if (getReport) {
      setData(getReport);
    }
    // eslint-disable-next-line
  }, [getReport]);

  useEffect(() => {
    if (getReportByDate) {
      setData(getReportByDate);
    }
    // eslint-disable-next-line
  }, [getReportByDate]);

  const startHandleChange = (date) => {
    setStartDate(date);
  };
  const endHandleChange = (date) => {
    setEndDate(date);
  };

  const apply = () => {
    if (startDate && endDate) {
      const obj = {
        start_date: startDate,
        end_date: endDate,
        state: Number(key),
      };
      dispatch(getReportDataByDate(obj));
    }
  };
  const clear = () => {
    setStartDate("");
    setEndDate("");
    if (role === "level1" && key === "0") {
      dispatch(getReportData(key));
    } else if (role === "level1" && key === "1") {
      dispatch(getReportData(key));
    } else if (role === "level1" && key === "2") {
      dispatch(getReportData(key));
    }
  };

  return (
    <>
      <div className="my-report">
        <div className="content-title">My Reports</div>
        {role === "level1" && (
          <div className="row d-flex mb-4 mt-4">
            <div className="col-md-3">
              <input
                type="date"
                className="date form-control"
                id="date"
                onFocus={(e) => {
                  e.currentTarget.type = "date";
                  e.currentTarget.focus();
                }}
                onChange={(e) => startHandleChange(e.target.value)}
                value={startDate}
              />
            </div>
            <div className="col-md-3">
              <input
                type="date"
                className="date form-control"
                id="date"
                onFocus={(e) => {
                  e.currentTarget.type = "date";
                  e.currentTarget.focus();
                }}
                onChange={(e) => endHandleChange(e.target.value)}
                value={endDate}
              />
            </div>
            <div className="col-md">
              <button
                type="button"
                className="apply-button me-3"
                onClick={apply}
              >
                Apply
              </button>
              <button type="cancel" className="cancel-button" onClick={clear}>
                Clear
              </button>
            </div>
          </div>
        )}

        <div className="tabs-section mb-3">
          <Tabs
            defaultActiveKey="0"
            id="uncontrolled-tab-example"
            className="mb-3"
            transition={true}
            activeKey={key}
            onSelect={(key) => {
              setKey(key);
              setStartDate("");
              setEndDate("");
            }}
          >
            {tab &&
              tab.map((item, index) => (
                <Tab key={index} eventKey={item.key} title={item.title}>
                  <>
                    <div className="d-flex justify-content-end select-form-control">
                      Show
                      <select
                        className="select-enteries ms-2 me-2 mb-2"
                        value={itemsPerPage}
                        onChange={(e) => setItemsPerPage(e.target.value)}
                      >
                        <option value="10">10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                      Entries
                    </div>
                    <MyReportTable
                      data={currentItems}
                      state={key}
                      role={role}
                    />
                    <ReactPaginate
                      previousLabel={"<<"}
                      nextLabel={">>"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination justify-content-center"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </>
                </Tab>
              ))}
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default MyReport;
