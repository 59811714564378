import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../Utils/Api";
import {
  getProjectUsersPath,
  addRevisionPath,
  deleteRevisionPath,
  addRevisionListPath,
  getRevisionListPath,
  deleteRevisionListPath,
  getRevisionPath,
} from "../../../../Utils/ApiPath";

const initialState = {
  getProjectInfoLoading: false,
  getProjectInfoSuccess: {},
  getProjectInfoFail: "",
  addRevisionLoading: false,
  addRevisionSuccess: false,
  addRevisionFail: "",
  getRevisionLoading: false,
  getRevisionSuccess: {},
  getRevisionFail: "",
  deleteRevisionLoading: false,
  deleteRevisionSuccess: false,
  deleteRevisionFail: "",
  addRevisionListLoading: false,
  addRevisionListSuccess: false,
  addRevisionListFail: "",
  getRevisionListLoading: false,
  getRevisionListSuccess: [],
  getRevisionListFail: "",
  deleteRevisionListLoading: false,
  deleteRevisionListSuccess: false,
  deleteRevisionListFail: "",
};

export const fetchProjectUsers = createAsyncThunk(
  "/user/fetchProjectUsers",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getProjectInfoLoading());
      const res = await api.get(getProjectUsersPath + _);
      if (res) {
        thunkAPI.dispatch(getProjectInfoSuccess(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getProjectInfoFail(err));
    }
  }
);

export const addRevisions = createAsyncThunk(
  "/user/addRevisions",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(addRevisionLoading());
      const res = await api.post(addRevisionPath, _);
      if (res) {
        thunkAPI.dispatch(addRevisionSuccess(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(addRevisionFail(err));
    }
  }
);

export const getRevisions = createAsyncThunk(
  "/user/getRevisions",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getRevisionLoading());
      const res = await api.get(getRevisionPath + _);
      if (res) {
        thunkAPI.dispatch(getRevisionSuccess(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getRevisionFail(err));
    }
  }
);

export const deleteRevisions = createAsyncThunk(
  "/user/deleteRevisions",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(deleteRevisionLoading());
      const res = await api.delete(deleteRevisionPath + _);
      if (res) {
        thunkAPI.dispatch(deleteRevisionSuccess(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(deleteRevisionFail(err));
    }
  }
);

export const addRevisionsList = createAsyncThunk(
  "/user/addRevisionsList",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(addRevisionListLoading());
      const res = await api.post(addRevisionListPath, _);
      if (res) {
        thunkAPI.dispatch(addRevisionListSuccess(res.data));
        setTimeout(() => {
          thunkAPI.dispatch(clearAddList());
        }, 200);
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(addRevisionListFail(err));
    }
  }
);

export const getRevisionsList = createAsyncThunk(
  "/user/getRevisionsList",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getRevisionListLoading());
      const res = await api.get(getRevisionListPath + _);
      if (res) {
        thunkAPI.dispatch(getRevisionListSuccess(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getRevisionListFail(err));
    }
  }
);

export const deleteRevisionsList = createAsyncThunk(
  "/user/deleteRevisionsList",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(deleteRevisionListLoading());
      const res = await api.delete(deleteRevisionListPath + _);
      if (res) {
        thunkAPI.dispatch(deleteRevisionListSuccess(res.data));
        setTimeout(() => {
          thunkAPI.dispatch(clearDeleteList());
        }, 200);
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(deleteRevisionListFail(err));
    }
  }
);

const projectInfoSlice = createSlice({
  name: "projectInfoSlice",
  initialState,
  reducers: {
    getProjectInfoLoading: (state) => {
      state.getProjectInfoLoading = true;
    },
    getProjectInfoSuccess: (state, { payload }) => {
      state.getProjectInfoLoading = false;
      state.getProjectInfoSuccess = payload;
      state.getProjectInfoFail = "";
    },
    getProjectInfoFail: (state, { payload }) => {
      state.getProjectInfoLoading = false;
      state.getProjectInfoSuccess = {};
      state.getProjectInfoFail = payload;
    },
    addRevisionLoading: (state) => {
      state.addRevisionLoading = true;
    },
    addRevisionSuccess: (state) => {
      state.addRevisionLoading = false;
      state.addRevisionSuccess = true;
      state.addRevisionFail = "";
    },
    addRevisionFail: (state, { payload }) => {
      state.addRevisionLoading = false;
      state.addRevisionSuccess = false;
      state.addRevisionFail = payload;
    },
    getRevisionLoading: (state) => {
      state.getRevisionLoading = true;
    },
    getRevisionSuccess: (state, { payload }) => {
      state.getRevisionLoading = false;
      state.getRevisionSuccess = payload;
      state.getRevisionFail = "";
    },
    getRevisionFail: (state, { payload }) => {
      state.getRevisionLoading = false;
      state.getRevisionSuccess = {};
      state.getRevisionFail = payload;
    },
    deleteRevisionLoading: (state) => {
      state.deleteRevisionLoading = true;
    },
    deleteRevisionSuccess: (state) => {
      state.deleteRevisionLoading = false;
      state.deleteRevisionSuccess = true;
      state.deleteRevisionFail = "";
    },
    deleteRevisionFail: (state, { payload }) => {
      state.deleteRevisionLoading = false;
      state.deleteRevisionSuccess = false;
      state.deleteRevisionFail = payload;
    },
    addRevisionListLoading: (state) => {
      state.addRevisionListLoading = true;
    },
    addRevisionListSuccess: (state) => {
      state.addRevisionListLoading = false;
      state.addRevisionListSuccess = true;
      state.addRevisionListFail = "";
    },
    addRevisionListFail: (state, { payload }) => {
      state.addRevisionListLoading = false;
      state.addRevisionListSuccess = false;
      state.addRevisionListFail = payload;
    },
    getRevisionListLoading: (state) => {
      state.getRevisionListLoading = true;
    },
    getRevisionListSuccess: (state, { payload }) => {
      state.getRevisionListLoading = false;
      state.getRevisionListSuccess = payload;
      state.getRevisionListFail = "";
    },
    getRevisionListFail: (state, { payload }) => {
      state.getRevisionListLoading = false;
      state.getRevisionListSuccess = [];
      state.getRevisionListFail = payload;
    },
    deleteRevisionListLoading: (state) => {
      state.deleteRevisionListLoading = true;
    },
    deleteRevisionListSuccess: (state) => {
      state.deleteRevisionListLoading = false;
      state.deleteRevisionListSuccess = true;
      state.deleteRevisionListFail = "";
    },
    deleteRevisionListFail: (state, { payload }) => {
      state.deleteRevisionListLoading = false;
      state.deleteRevisionListSuccess = false;
      state.deleteRevisionListFail = payload;
    },
    clearDeleteList: (state) => {
      state.deleteRevisionListSuccess = false;
    },
    clearAddList: (state) => {
      state.addRevisionListSuccess = false;
    },
  },
});

export const {
  getProjectInfoLoading,
  getProjectInfoSuccess,
  getProjectInfoFail,
  addRevisionLoading,
  addRevisionSuccess,
  addRevisionFail,
  getRevisionLoading,
  getRevisionSuccess,
  getRevisionFail,
  deleteRevisionLoading,
  deleteRevisionSuccess,
  deleteRevisionFail,
  addRevisionListLoading,
  addRevisionListSuccess,
  addRevisionListFail,
  getRevisionListLoading,
  getRevisionListSuccess,
  getRevisionListFail,
  deleteRevisionListLoading,
  deleteRevisionListSuccess,
  deleteRevisionListFail,
  clearDeleteList,
  clearAddList,
} = projectInfoSlice.actions;
export default projectInfoSlice.reducer;
