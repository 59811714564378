export const headerColors = [
    {
      color: "#d3d3d3",
    },
    {
      color: "#ff7fab",
    },
    {
      color: "#2ecd6f",
    },
    {
      color: "#f9d900",
    },
    {
      color: "#02BCD4",
    },
    {
      color: "#ff7800",
    },
    {
      color: "#bf55ec",
    },
    {
      color: "#e50000",
    },
    {
      color: "#0231E8",
    },
  ];