import React from "react";
import { Outlet } from "react-router-dom";

function Header() {
  // const location = useLocation();
  // if(location.pathname.split("/") === 'channel' || 'role' || 'user'){
  //   var title = location.pathname.split("/") +" "+'management';
  // }else if(location.pathname.split("/") === 'dashboard'){
  //   var title = location.pathname.split("/");
  // }
  return (
    <>
      <div className="d-flex flex-column" style={{ width: "100%" }}>
        <div className="header">
          <div className="header-name"></div>
        </div>
        <div className="content">
          <Outlet />
        </div>
      </div>
    </>
  );
}

export default Header;
