import React from "react";
import { dateFormat } from "../../Common/dateFormat";

const MyReportTable = ({ data, state,role }) => {
  return (
    <>
      <div className="myreport-table">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>#</th>
                <th>Project</th>
                {state === "0" ? (
                  <th>Start Date</th>
                ) : state === "1" ? (
                  <th>Submitted Date</th>
                ) : (
                  <th>Paid Date</th>
                )}
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.project_name}</td>
                    {(state === "0" || state === "2") && <td>{dateFormat(item.updated_at)}</td>}
                    {(state === "1" && role === "level1") && <td>{dateFormat(item.completed_date)}</td>}
                    {(state === "1" && role === "level2") && <td>{dateFormat(item.updated_at)}</td>}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default MyReportTable;
