import jwtDecode from "jwt-decode";

//return user name
export const userName = (name) => {
  if (name) {
    var decodeToken = jwtDecode(name);
    if (decodeToken) {
      return (
        decodeToken.user[0].first_name + " " + decodeToken.user[0].last_name
      );
    }
  } else {
    return "";
  }
};

export const getInitials = (value) => {
  if (value) {
    let name = value;
    let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");
    let initials = [...name.matchAll(rgx)] || [];
    initials = (
      (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
    ).toUpperCase();
    return initials;
  } else {
    return "";
  }
};
