import { setPasswordPending, setPasswordSuccess, setPasswordFail,clearSetPassword  } from "./setPasswordSlice";
import api from "../../../Utils/Api";
import { setPasswordPath } from "../../../Utils/ApiPath";

export const setPassword = (userData) => async (dispatch) => {
  const formData = {
    password: userData.password
  };
  try {
    dispatch(setPasswordPending());
    //call api
    const res = await api.put(setPasswordPath+"/"+userData.email, formData);
    if (res) {
        console.log(res.data)
      dispatch(setPasswordSuccess(res.data.token));
      // setTimeout(() => {
      //   dispatch(clearSetPassword());
      // }, 5000);
      return;
    }
  } catch (err) {
    console.log(err);
    dispatch(setPasswordFail(err));
    setTimeout(() => {
      dispatch(clearSetPassword());
    }, 5000);
    return;
  }
};