import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Utils/Api";
import {
  getPmReportScriptDescPath,
  getPmReportVoiceDescPath,
  getPmReportVideoDescPath,
  getPmReportScriptThumbPath,
  getPmReportVoiceThumbPath,
  getPmReportVideoThumbPath,
  getPmReportScriptVoicePath,
  getPmReportScriptVideoPath,
  getPmReportScriptDatePath,
  getPmReportVoiceDatePath,
  getPmReportVideoDatePath,
  getPmReportVoiceVideoPath,
  getPmReportThumbDescPath,
  getPmReportScriptVoiceVideoPath,
  getPmReportScriptVoiceVideoThumPath,
  getPmReportScriptVoiceVideoDescPath,
  getPmReportThumbDatePath,
  getPmReportDescDatePath,
} from "../../../Utils/ApiPathResources";

const initialState = {
  isLoadingState: false,
  getPmReportScriptDesc: [],
  getPmReportScriptDescFail: "",
  getPmReportVoiceDesc: [],
  getPmReportVoiceDescFail: "",
  getPmReportVideoDesc: [],
  getPmReportVideoDescFail: "",
  getPmReportScriptThumb: [],
  getPmReportScriptThumbFail: "",
  getPmReportVoiceThumb: [],
  getPmReportVoiceThumbFail: "",
  getPmReportVideoThumb: [],
  getPmReportVideoThumbFail: "",
  getPmReportScriptVoice: [],
  getPmReportScriptVoiceFail: "",
  getPmReportScriptVideo: [],
  getPmReportScriptVideoFail: "",
  getPmReportScriptDate: [],
  getPmReportScriptDateFail: "",
  getPmReportVoiceDate: [],
  getPmReportVoiceDateFail: "",
  getPmReportVideoDate: [],
  getPmReportVideoDateFail: "",
  getPmReportVoiceVideo: [],
  getPmReportVoiceVideoFail: "",
  getPmReportThumbDesc: [],
  getPmReportThumbDescFail: "",
  getPmReportScriptVoiceVideo: [],
  getPmReportScriptVoiceVideoFail: "",
  getPmReportScriptVoiceVideoThum: [],
  getPmReportScriptVoiceVideoThumFail: "",
  getPmReportScriptVoiceVideoDesc: [],
  getPmReportScriptVoiceVideoDescFail: "",
  getPmReportThumbDate: [],
  getPmReportThumbDateFail: "",
  getPmReportDescDate: [],
  getPmReportDescDateFail: "",
};

export const searchPmReportScriptDesc = createAsyncThunk(
  "/pm/getScriptDesc",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getPmReportPending());
      const user_id = Number(localStorage.getItem("user_id"));
      const res = await api.get(
        getPmReportScriptDescPath +
          "/" +
          _.s_id +
          "/" +
          _.d_id +
          "/" +
          _.state +
          "/" +
          user_id
      );
      if (res) {
        thunkAPI.dispatch(getPmReportScriptDesc(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getPmReportScriptDescFail(err));
    }
  }
);

export const searchPmReportVoiceDesc = createAsyncThunk(
  "/pm/getVoiceDesc",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getPmReportPending());
      const user_id = Number(localStorage.getItem("user_id"));
      const res = await api.get(
        getPmReportVoiceDescPath +
          "/" +
          _.s_id +
          "/" +
          _.d_id +
          "/" +
          _.state +
          "/" +
          user_id
      );
      if (res) {
        thunkAPI.dispatch(getPmReportVoiceDesc(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getPmReportVoiceDescFail(err));
    }
  }
);

export const searchPmReportVideoDesc = createAsyncThunk(
  "/pm/getVideoDesc",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getPmReportPending());
      const user_id = Number(localStorage.getItem("user_id"));
      const res = await api.get(
        getPmReportVideoDescPath +
          "/" +
          _.s_id +
          "/" +
          _.d_id +
          "/" +
          _.state +
          "/" +
          user_id
      );
      if (res) {
        thunkAPI.dispatch(getPmReportVideoDesc(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getPmReportVideoDescFail(err));
    }
  }
);

export const searchPmReportScriptThumb = createAsyncThunk(
  "/pm/getScriptThumb",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getPmReportPending());
      const user_id = Number(localStorage.getItem("user_id"));
      const res = await api.get(
        getPmReportScriptThumbPath +
          "/" +
          _.s_id +
          "/" +
          _.d_id +
          "/" +
          _.state +
          "/" +
          user_id
      );
      if (res) {
        thunkAPI.dispatch(getPmReportScriptThumb(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getPmReportScriptThumbFail(err));
    }
  }
);

export const searchPmReportVoiceThumb = createAsyncThunk(
  "/pm/getVoiceThumb",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getPmReportPending());
      const user_id = Number(localStorage.getItem("user_id"));
      const res = await api.get(
        getPmReportVoiceThumbPath +
          "/" +
          _.s_id +
          "/" +
          _.d_id +
          "/" +
          _.state +
          "/" +
          user_id
      );
      if (res) {
        thunkAPI.dispatch(getPmReportVoiceThumb(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getPmReportVoiceThumbFail(err));
    }
  }
);

export const searchPmReportVideoThumb = createAsyncThunk(
  "/pm/getVideoThumb",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getPmReportPending());
      const user_id = Number(localStorage.getItem("user_id"));
      const res = await api.get(
        getPmReportVideoThumbPath +
          "/" +
          _.s_id +
          "/" +
          _.d_id +
          "/" +
          _.state +
          "/" +
          user_id
      );
      if (res) {
        thunkAPI.dispatch(getPmReportVideoThumb(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getPmReportVideoThumbFail(err));
    }
  }
);

export const searchPmReportScriptVoice = createAsyncThunk(
  "/pm/getScriptVoice",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getPmReportPending());
      const user_id = Number(localStorage.getItem("user_id"));
      const res = await api.get(
        getPmReportScriptVoicePath +
          "/" +
          _.s_id +
          "/" +
          _.d_id +
          "/" +
          _.state +
          "/" +
          user_id
      );
      if (res) {
        thunkAPI.dispatch(getPmReportScriptVoice(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getPmReportScriptVoiceFail(err));
    }
  }
);

export const searchPmReportScriptVideo = createAsyncThunk(
  "/pm/getScriptVideo",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getPmReportPending());
      const user_id = Number(localStorage.getItem("user_id"));
      const res = await api.get(
        getPmReportScriptVideoPath +
          "/" +
          _.s_id +
          "/" +
          _.d_id +
          "/" +
          _.state +
          "/" +
          user_id
      );
      if (res) {
        thunkAPI.dispatch(getPmReportScriptVideo(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getPmReportScriptVideoFail(err));
    }
  }
);

export const searchPmReportScriptDate = createAsyncThunk(
  "/pm/getScriptDate",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getPmReportPending());
      const user_id = Number(localStorage.getItem("user_id"));
      const res = await api.get(
        getPmReportScriptDatePath +
          "/" +
          _.s_id +
          "/" +
          _.start_date +
          "/" +
          _.end_date +
          "/" +
          _.state +
          "/" +
          user_id
      );
      if (res) {
        thunkAPI.dispatch(getPmReportScriptDate(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getPmReportScriptDateFail(err));
    }
  }
);

export const searchPmReportVoiceDate = createAsyncThunk(
  "/pm/getVoiceDate",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getPmReportPending());
      const user_id = Number(localStorage.getItem("user_id"));
      const res = await api.get(
        getPmReportVoiceDatePath +
          "/" +
          _.s_id +
          "/" +
          _.start_date +
          "/" +
          _.end_date +
          "/" +
          _.state +
          "/" +
          user_id
      );
      if (res) {
        thunkAPI.dispatch(getPmReportVoiceDate(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getPmReportVoiceDateFail(err));
    }
  }
);

export const searchPmReportVideoDate = createAsyncThunk(
  "/pm/getVideoDate",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getPmReportPending());
      const user_id = Number(localStorage.getItem("user_id"));
      const res = await api.get(
        getPmReportVideoDatePath +
          "/" +
          _.s_id +
          "/" +
          _.start_date +
          "/" +
          _.end_date +
          "/" +
          _.state +
          "/" +
          user_id
      );
      if (res) {
        thunkAPI.dispatch(getPmReportVideoDate(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getPmReportVideoDateFail(err));
    }
  }
);

export const searchPmReportVoiceVideo = createAsyncThunk(
  "/pm/getVoiceVideo",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getPmReportPending());
      const user_id = Number(localStorage.getItem("user_id"));
      const res = await api.get(
        getPmReportVoiceVideoPath +
          "/" +
          _.v_id +
          "/" +
          _.ve_id +
          "/" +
          _.state +
          "/" +
          user_id
      );
      if (res) {
        thunkAPI.dispatch(getPmReportVoiceVideo(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getPmReportVoiceVideoFail(err));
    }
  }
);

export const searchPmReportThumDesc = createAsyncThunk(
  "/pm/getThumDesc",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getPmReportPending());
      const user_id = Number(localStorage.getItem("user_id"));
      const res = await api.get(
        getPmReportThumbDescPath +
          "/" +
          _.de_id +
          "/" +
          _.th_id +
          "/" +
          _.state +
          "/" +
          user_id
      );
      if (res) {
        thunkAPI.dispatch(getPmReportThumbDesc(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getPmReportThumbDescFail(err));
    }
  }
);

export const searchPmReportScriptVoiceVideo = createAsyncThunk(
  "/pm/getScriptVoiceVideo",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getPmReportPending());
      const user_id = Number(localStorage.getItem("user_id"));
      const res = await api.get(
        getPmReportScriptVoiceVideoPath +
          "/" +
          _.s_id +
          "/" +
          _.v_id +
          "/" +
          _.ve_id +
          "/" +
          _.state +
          "/" +
          user_id
      );
      if (res) {
        thunkAPI.dispatch(getPmReportScriptVoiceVideo(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getPmReportScriptVoiceVideoFail(err));
    }
  }
);

export const searchPmReportScriptVoiceVideoThum = createAsyncThunk(
  "/pm/getScriptVoiceVideoThum",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getPmReportPending());
      const user_id = Number(localStorage.getItem("user_id"));
      const res = await api.get(
        getPmReportScriptVoiceVideoThumPath +
          "/" +
          _.state +
          "/" +
          _.s_id +
          "/" +
          _.v_id +
          "/" +
          _.ve_id +
          "/" +
          _.th_id +
          "/" +
          user_id
      );
      if (res) {
        thunkAPI.dispatch(getPmReportScriptVoiceVideoThum(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getPmReportScriptVoiceVideoThumFail(err));
    }
  }
);

export const searchPmReportScriptVoiceVideoDesc = createAsyncThunk(
  "/pm/getScriptVoiceVideoDesc",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getPmReportPending());
      const user_id = Number(localStorage.getItem("user_id"));
      const res = await api.get(
        getPmReportScriptVoiceVideoDescPath +
          "/" +
          _.state +
          "/" +
          _.s_id +
          "/" +
          _.v_id +
          "/" +
          _.ve_id +
          "/" +
          _.d_id +
          "/" +
          user_id
      );
      if (res) {
        thunkAPI.dispatch(getPmReportScriptVoiceVideoDesc(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getPmReportScriptVoiceVideoDescFail(err));
    }
  }
);

export const searchPmReportThumbDate = createAsyncThunk(
  "/pm/getThumbDate",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getPmReportPending());
      const user_id = Number(localStorage.getItem("user_id"));
      const res = await api.get(
        getPmReportThumbDatePath +
          "/" +
          _.id +
          "/" +
          _.start_date +
          "/" +
          _.end_date +
          "/" +
          _.state +
          "/" +
          user_id
      );
      if (res) {
        thunkAPI.dispatch(getPmReportThumbDate(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getPmReportThumbDateFail(err));
    }
  }
);

export const searchPmReportDescDate = createAsyncThunk(
  "/pm/getDescDate",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getPmReportPending());
      const user_id = Number(localStorage.getItem("user_id"));
      const res = await api.get(
        getPmReportDescDatePath +
          "/" +
          _.id +
          "/" +
          _.start_date +
          "/" +
          _.end_date +
          "/" +
          _.state +
          "/" +
          user_id
      );
      if (res) {
        thunkAPI.dispatch(getPmReportDescDate(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getPmReportDescDateFail(err));
    }
  }
);

const pmReportFilterSlice = createSlice({
  name: "pmReportFilterSlice",
  initialState,
  reducers: {
    getPmReportPending: (state) => {
      state.isLoadingState = true;
    },
    getPmReportScriptDesc: (state, { payload }) => {
      state.isLoadingState = false;
      state.getPmReportScriptDesc = payload;
      state.getPmReportScriptDescFail = "";
    },
    getPmReportScriptDescFail: (state, payload) => {
      state.isLoadingState = false;
      state.getPmReportScriptDesc = [];
      state.getPmReportScriptDescFail = payload;
    },
    getPmReportVoiceDesc: (state, { payload }) => {
      state.isLoadingState = false;
      state.getPmReportVoiceDesc = payload;
      state.getPmReportVoiceDescFail = "";
    },
    getPmReportVoiceDescFail: (state, payload) => {
      state.isLoadingState = false;
      state.getPmReportVoiceDesc = [];
      state.getPmReportVoiceDescFail = payload;
    },
    getPmReportVideoDesc: (state, { payload }) => {
      state.isLoadingState = false;
      state.getPmReportVideoDesc = payload;
      state.getPmReportVideoDescFail = "";
    },
    getPmReportVideoDescFail: (state, payload) => {
      state.isLoadingState = false;
      state.getPmReportVideoDesc = [];
      state.getPmReportVideoDescFail = payload;
    },
    getPmReportScriptThumb: (state, { payload }) => {
      state.isLoadingState = false;
      state.getPmReportScriptThumb = payload;
      state.getPmReportScriptThumbFail = "";
    },
    getPmReportScriptThumbFail: (state, payload) => {
      state.isLoadingState = false;
      state.getPmReportScriptThumb = [];
      state.getPmReportScriptThumbFail = payload;
    },
    getPmReportVoiceThumb: (state, { payload }) => {
      state.isLoadingState = false;
      state.getPmReportVoiceThumb = payload;
      state.getPmReportVoiceThumbFail = "";
    },
    getPmReportVoiceThumbFail: (state, payload) => {
      state.isLoadingState = false;
      state.getPmReportVoiceThumb = [];
      state.getPmReportVoiceThumbFail = payload;
    },
    getPmReportVideoThumb: (state, { payload }) => {
      state.isLoadingState = false;
      state.getPmReportVideoThumb = payload;
      state.getPmReportVideoThumbFail = "";
    },
    getPmReportVideoThumbFail: (state, payload) => {
      state.isLoadingState = false;
      state.getPmReportVideoThumb = [];
      state.getPmReportVideoThumbFail = payload;
    },
    getPmReportScriptVoice: (state, { payload }) => {
      state.isLoadingState = false;
      state.getPmReportScriptVoice = payload;
      state.getPmReportScriptVoiceFail = "";
    },
    getPmReportScriptVoiceFail: (state, payload) => {
      state.isLoadingState = false;
      state.getPmReportScriptVoice = [];
      state.getPmReportScriptVoiceFail = payload;
    },
    getPmReportScriptVideo: (state, { payload }) => {
      state.isLoadingState = false;
      state.getPmReportScriptVideo = payload;
      state.getPmReportScriptVideoFail = "";
    },
    getPmReportScriptVideoFail: (state, payload) => {
      state.isLoadingState = false;
      state.getPmReportScriptVideo = [];
      state.getPmReportScriptVideoFail = payload;
    },
    getPmReportScriptDate: (state, { payload }) => {
      state.isLoadingState = false;
      state.getPmReportScriptDate = payload;
      state.getPmReportScriptDateFail = "";
    },
    getPmReportScriptDateFail: (state, payload) => {
      state.isLoadingState = false;
      state.getPmReportScriptDate = [];
      state.getPmReportScriptDateFail = payload;
    },
    getPmReportVoiceDate: (state, { payload }) => {
      state.isLoadingState = false;
      state.getPmReportVoiceDate = payload;
      state.getPmReportVoiceDateFail = "";
    },
    getPmReportVoiceDateFail: (state, payload) => {
      state.isLoadingState = false;
      state.getPmReportVoiceDate = [];
      state.getPmReportVoiceDateFail = payload;
    },
    getPmReportVideoDate: (state, { payload }) => {
      state.isLoadingState = false;
      state.getPmReportVideoDate = payload;
      state.getPmReportVideoDateFail = "";
    },
    getPmReportVideoDateFail: (state, payload) => {
      state.isLoadingState = false;
      state.getPmReportVideoDate = [];
      state.getPmReportVideoDateFail = payload;
    },
    getPmReportVoiceVideo: (state, { payload }) => {
      state.isLoadingState = false;
      state.getPmReportVoiceVideo = payload;
      state.getPmReportVoiceVideoFail = "";
    },
    getPmReportVoiceVideoFail: (state, payload) => {
      state.isLoadingState = false;
      state.getPmReportVoiceVideo = [];
      state.getPmReportVoiceVideoFail = payload;
    },
    getPmReportThumbDesc: (state, { payload }) => {
      state.isLoadingState = false;
      state.getPmReportThumbDesc = payload;
      state.getPmReportThumbDescFail = "";
    },
    getPmReportThumbDescFail: (state, payload) => {
      state.isLoadingState = false;
      state.getPmReportThumbDesc = [];
      state.getPmReportThumbDescFail = payload;
    },
    getPmReportScriptVoiceVideo: (state, { payload }) => {
      state.isLoadingState = false;
      state.getPmReportScriptVoiceVideo = payload;
      state.getPmReportScriptVoiceVideoFail = "";
    },
    getPmReportScriptVoiceVideoFail: (state, payload) => {
      state.isLoadingState = false;
      state.getPmReportScriptVoiceVideo = [];
      state.getPmReportScriptVoiceVideoFail = payload;
    },
    getPmReportScriptVoiceVideoThum: (state, { payload }) => {
      state.isLoadingState = false;
      state.getPmReportScriptVoiceVideoThum = payload;
      state.getPmReportScriptVoiceVideoThumFail = "";
    },
    getPmReportScriptVoiceVideoThumFail: (state, payload) => {
      state.isLoadingState = false;
      state.getPmReportScriptVoiceVideoThum = [];
      state.getPmReportScriptVoiceVideoThumFail = payload;
    },
    getPmReportScriptVoiceVideoDesc: (state, { payload }) => {
      state.isLoadingState = false;
      state.getPmReportScriptVoiceVideoDesc = payload;
      state.getPmReportScriptVoiceVideoDescFail = "";
    },
    getPmReportScriptVoiceVideoDescFail: (state, payload) => {
      state.isLoadingState = false;
      state.getPmReportScriptVoiceVideoDesc = [];
      state.getPmReportScriptVoiceVideoDescFail = payload;
    },
    getPmReportThumbDate: (state, { payload }) => {
      state.isLoadingState = false;
      state.getPmReportThumbDate = payload;
      state.getPmReportThumbDateFail = "";
    },
    getPmReportThumbDateFail: (state, payload) => {
      state.isLoadingState = false;
      state.getPmReportThumbDate = [];
      state.getPmReportThumbDateFail = payload;
    },
    getPmReportDescDate: (state, { payload }) => {
      state.isLoadingState = false;
      state.getPmReportDescDate = payload;
      state.getPmReportDescDateFail = "";
    },
    getPmReportDescDateFail: (state, payload) => {
      state.isLoadingState = false;
      state.getPmReportDescDate = [];
      state.getPmReportDescDateFail = payload;
    },
  },
});

export const {
  getPmReportPending,
  getPmReportScriptDesc,
  getPmReportScriptDescFail,
  getPmReportVoiceDesc,
  getPmReportVoiceDescFail,
  getPmReportVideoDesc,
  getPmReportVideoDescFail,
  getPmReportScriptThumb,
  getPmReportScriptThumbFail,
  getPmReportVoiceThumb,
  getPmReportVoiceThumbFail,
  getPmReportVideoThumb,
  getPmReportVideoThumbFail,
  getPmReportScriptVoice,
  getPmReportScriptVoiceFail,
  getPmReportScriptVideo,
  getPmReportScriptVideoFail,
  getPmReportScriptDate,
  getPmReportScriptDateFail,
  getPmReportVoiceDate,
  getPmReportVoiceDateFail,
  getPmReportVideoDate,
  getPmReportVideoDateFail,
  getPmReportVoiceVideo,
  getPmReportVoiceVideoFail,
  getPmReportThumbDesc,
  getPmReportThumbDescFail,
  getPmReportScriptVoiceVideo,
  getPmReportScriptVoiceVideoFail,
  getPmReportScriptVoiceVideoThum,
  getPmReportScriptVoiceVideoThumFail,
  getPmReportScriptVoiceVideoDesc,
  getPmReportScriptVoiceVideoDescFail,
  getPmReportThumbDate,
  getPmReportThumbDateFail,
  getPmReportDescDate,
  getPmReportDescDateFail,
} = pmReportFilterSlice.actions;
export default pmReportFilterSlice.reducer;
