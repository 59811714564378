import React, { useEffect, useState } from "react";
import {
  UilExclamationTriangle,
  UilInfoCircle,
  UilEye,
  UilEyeSlash,
} from "@iconscout/react-unicons";
import { Formik, Form, Field } from "formik";
import { setPasswordValidationSchema } from "../../../Common/Validations";
import { useParams, useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { setPassword } from "../../../Feature/Entry/setPassword/setPasswordAction";
import Loaders from "../../../Components/Loaders/Loaders";
import paperPlane from "../../../Assets/Images/paperPlane.png";
import { NavLink } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { clearState } from "../../../Feature/Entry/login/loginSlice";

function SetPasswordForm() {
  // Component State:
  const initialValues = {
    password: "",
    confirm_password: "",
  };

  //Hooks
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const { isLoading, isSuccess } = useSelector((state) => state.setPassword);
  let { token } = useParams();
  const navigate = useNavigate();

  //useEffects
  useEffect(() => {
    const decodeToken = jwtDecode(token);
    setEmail(decodeToken.user.email);
  }, [email, token]);

  //handlers
  const handleSubmit = (values) => {
    let obj = {
      email: email,
      password: values.password,
    };
    dispatch(setPassword(obj));
  };

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        dispatch(clearState());
        navigate("/");
      }, 3000);
    }
    // eslint-disable-next-line
  }, [isSuccess]);

  const [type1, setType1] = useState("password");
  const [type2, setType2] = useState("password");

  const handleToggle = () => {
    if (type1 === "password") {
      setType1("text");
    } else {
      setType1("password");
    }
  };

  const handleToggle2 = () => {
    if (type2 === "password") {
      setType2("text");
    } else {
      setType2("password");
    }
  };

  return (
    <>
      {!isSuccess && (
        <div className="login-form">
          <h1 className="title">Create a new password!</h1>
          <div className="login-form-main">
            <Formik
              initialValues={initialValues}
              validationSchema={setPasswordValidationSchema}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={handleSubmit}
            >
              {({ errors, touched }) => (
                <Form>
                  {/* password */}
                  <div className="login-main-row">
                    <label
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "end",
                      }}
                    >
                      Choose new password
                      <Dropdown>
                        <Dropdown.Toggle>
                          <UilInfoCircle size="16" color="#7b68ee" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          style={{ width: "226px", lineHeight: "16px" }}
                        >
                          <Dropdown.Item>
                            &#8226; Password must Contain 8 Characters
                          </Dropdown.Item>
                          <Dropdown.Item>
                            &#8226; Must contain one Uppercase
                          </Dropdown.Item>
                          <Dropdown.Item>
                            &#8226; Must contain one Lowercase
                          </Dropdown.Item>
                          <Dropdown.Item>
                            &#8226; Must contain one Number
                          </Dropdown.Item>
                          <Dropdown.Item>
                            &#8226; Must contain one Special Case Character
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </label>
                    <Field
                      type={type1}
                      className={
                        errors.password && touched.password
                          ? "form-control is-invalid form-control-pass"
                          : "form-control form-control-pass"
                      }
                      id="password"
                      name="password"
                      placeholder="Enter password"
                    />
                    {type1 === "password" ? (
                      <div
                        className="forgot-password pe-3"
                        onClick={handleToggle}
                      >
                        <UilEyeSlash size="20" color="#7b68ee" />
                      </div>
                    ) : (
                      <div
                        className="forgot-password pe-3"
                        onClick={handleToggle}
                      >
                        <UilEye size="20" color="#7b68ee" />
                      </div>
                    )}
                    {/* error */}
                    {touched.password && errors.password ? (
                      <div className="invalid-feedback d-flex">
                        <UilExclamationTriangle size="15" className="me-1" />
                        {errors.password}
                      </div>
                    ) : null}
                  </div>

                  {/* comfirm-password */}
                  <div className="login-main-row">
                    <label>Confirm</label>
                    <Field
                      type={type2}
                      className={
                        errors.confirm_password && touched.confirm_password
                          ? "form-control is-invalid form-control-pass"
                          : "form-control form-control-pass"
                      }
                      id="confirm_password"
                      name="confirm_password"
                      placeholder="Enter your confirm password"
                    />
                    {type2 === "password" ? (
                      <div
                        className="forgot-password pe-3"
                        onClick={handleToggle2}
                      >
                        <UilEyeSlash size="20" color="#7b68ee" />
                      </div>
                    ) : (
                      <div
                        className="forgot-password pe-3"
                        onClick={handleToggle2}
                      >
                        <UilEye size="20" color="#7b68ee" />
                      </div>
                    )}
                    {/* error */}
                    {touched.confirm_password && errors.confirm_password ? (
                      <div className="invalid-feedback d-flex">
                        <UilExclamationTriangle size="15" className="me-1" />
                        {errors.confirm_password}
                      </div>
                    ) : null}
                  </div>

                  {/* submit */}
                  <div className="login-main-row">
                    <button className="login-button" type="submit">
                      {isLoading ? <Loaders color="#fff" /> : "Create Password"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
      {isSuccess && (
        <div className="login-form">
          <h1 className="title">Successfully updated password</h1>
          <div className="d-flex justify-content-center mb-3">
            <img src={paperPlane} alt="icon" />
          </div>
          <div className="sign-in-link text-center">
            <NavLink className="ms-1" to="/">
              Sign In
            </NavLink>
          </div>
        </div>
      )}
    </>
  );
}

export default SetPasswordForm;
