import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../Utils/Api";
import {
  QCDashboardCountPath,
  VuDashboardCountPath,
} from "../../Utils/ApiPathResources";

const initialState = {
  isLoading: false,
  getL2RDashboardCount: {},
  getL2RDashboardCountFail: "",
};

export const getL2RCount = createAsyncThunk(
  "/L2R/getL2RCount",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getL2RDashboardCountPending());
      const role_id = Number(localStorage.getItem("role_id"));
      const user_id = Number(localStorage.getItem("user_id"));
      if (role_id === 9) {
        const res = await api.get(QCDashboardCountPath + user_id);
        if (res.data) {
          thunkAPI.dispatch(getL2RDashboardCountSuccess(res.data));
        }
      }
      if (role_id === 10) {
        const res = await api.get(VuDashboardCountPath + user_id);
        if (res.data) {
          thunkAPI.dispatch(getL2RDashboardCountSuccess(res.data));
        }
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getL2RDashboardCountFail(err));
    }
  }
);

const L2RDashboard = createSlice({
  name: "L2R",
  initialState,
  reducers: {
    getL2RDashboardCountPending: (state) => {
      state.isLoading = true;
    },
    getL2RDashboardCountSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.getL2RDashboardCount = payload;
      state.getL2RDashboardCountFail = "";
    },
    getL2RDashboardCountFail: (state, { payload }) => {
      state.isLoading = false;
      state.getL2RDashboardCount = {};
      state.getL2RDashboardCountFail = payload;
    },
  },
});

export const {
  getL2RDashboardCountPending,
  getL2RDashboardCountSuccess,
  getL2RDashboardCountFail,
} = L2RDashboard.actions;
export default L2RDashboard.reducer;
