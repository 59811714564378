import {
  getChannelPending,
  getActiveChannelSuccess,
  getActiveChannelFail,
  getInactiveChannelSuccess,
  getInactiveChannelFail,
  searchChannelByClient,
  searchChannelByClientError,
  searchChannel,
  searchChannelError,
} from "./channelSlice";
import api from "../../../Utils/Api";
import {
  activeChannel,
  inactiveChannel,
  searchChannelByClientPath,
  searchChannelPath,
} from "../../../Utils/ApiPath";

export const getActiveChannel = () => async (dispatch) => {
  try {
    dispatch(getChannelPending());
    const res = await api.get(activeChannel);
    if (res) {
      dispatch(getActiveChannelSuccess(res.data));
      return;
    }
  } catch (err) {
    console.log(err);
    dispatch(getActiveChannelFail(err));
    return;
  }
};

export const getInactiveChannel = () => async (dispatch) => {
  try {
    dispatch(getChannelPending());
    const res = await api.get(inactiveChannel);
    if (res) {
      dispatch(getInactiveChannelSuccess(res.data));
      return;
    }
  } catch (err) {
    console.log(err);
    dispatch(getInactiveChannelFail(err));
    return;
  }
};

export const getChannelByClient = (data) => async (dispatch) => {
  try {
    dispatch(getChannelPending());
    const res = await api.get(
      searchChannelByClientPath + data.client_id + "/" + data.status
    );
    if (res) {
      dispatch(searchChannelByClient(res.data));
      return;
    }
  } catch (err) {
    console.log(err);
    dispatch(searchChannelByClientError(err));
    return;
  }
};

export const getChannel = (data) => async (dispatch) => {
  try {
    dispatch(getChannelPending());
    const res = await api.get(
      searchChannelPath + data.regx + "/" + data.status
    );
    if (res) {
      dispatch(searchChannel(res.data));
      return;
    }
  } catch (err) {
    console.log(err);
    dispatch(searchChannelError(err));
    return;
  }
};

// export const get
