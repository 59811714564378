import React from "react";
import logo from "../../Assets/svg/logo.svg";

function LoginHeader() {
  return (
    <>
      <div className="login-header">
        <div className="d-flex justify-content-between">
            <div className="logo d-flex align-items-center">
                <img src={logo} alt="logo" width={30}/>
                <h3 className="brand_name">Taskforc</h3>
            </div>
        </div>
      </div>
    </>
  );
}

export default LoginHeader;
