import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../Utils/Api";
import {
  pmDashboardCountPath,
  pmDueProjectsPath,
} from "../../Utils/ApiPathResources";

const initialState = {
  isLoading: false,
  getPmDashboardCount: {},
  getPmDashboardCountFail: "",
  getPmDueProjects: [],
  getPmDueProjectsFail: "",
};

export const getPmCount = createAsyncThunk(
  "/pm/getPmCount",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getCountPmPending());
      const user_id = Number(localStorage.getItem("user_id"));
      const res = await api.get(pmDashboardCountPath + user_id);
      if (res.data) {
        thunkAPI.dispatch(getCountPmSuccess(res.data));
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getCountPmFail(err));
    }
  }
);

export const getPmDueProject = createAsyncThunk(
  "/pm/getPmDueProject",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getPmDueProjectsPending());
      const user_id = Number(localStorage.getItem("user_id"));
      const res = await api.get(pmDueProjectsPath + user_id);
      if (res.data) {
        thunkAPI.dispatch(getPmDueProjectsSuccess(res.data));
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getPmDueProjectsFail(err));
    }
  }
);

const PmDashboard = createSlice({
  name: "Pm",
  initialState,
  reducers: {
    getCountPmPending: (state) => {
      state.isLoading = true;
    },
    getCountPmSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.getPmDashboardCount = payload;
      state.getPmDashboardCountFail = "";
    },
    getCountPmFail: (state, { payload }) => {
      state.isLoading = false;
      state.getPmDashboardCount = {};
      state.getPmDashboardCountFail = payload;
    },
    getPmDueProjectsPending: (state) => {
      state.isLoading = true;
    },
    getPmDueProjectsSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.getPmDueProjects = payload;
      state.getPmDueProjectsFail = "";
    },
    getPmDueProjectsFail: (state, { payload }) => {
      state.isLoading = false;
      state.getPmDueProjects = [];
      state.getPmDueProjectsFail = payload;
    },
  },
});

export const {
  getCountPmPending,
  getCountPmSuccess,
  getCountPmFail,
  getPmDueProjectsPending,
  getPmDueProjectsSuccess,
  getPmDueProjectsFail,
} = PmDashboard.actions;
export default PmDashboard.reducer;
