import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../Utils/Api";
import {
  getCommentPath,
  addCommentPath,
  editCommentPath,
  deleteCommentPath,
} from "../../../../Utils/ApiPath";

const initialState = {
  getCommentLoading: false,
  getCommentSuccess: {},
  getCommentFail: "",
  addCommentLoading: false,
  addCommentSuccess: false,
  addCommentFail: "",
  editCommentLoading: false,
  editCommentSuccess: false,
  editCommentFail: "",
  deleteCommentLoading: false,
  deleteCommentSuccess: false,
  deleteCommentFail: "",
};

export const getcomments = createAsyncThunk(
  "/user/getcomments",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getCommentLoading());
      const res = await api.get(getCommentPath + _);
      thunkAPI.dispatch(getCommentSuccess(res.data));
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getCommentFail(err));
    }
  }
);

export const addcomments = createAsyncThunk(
  "/user/addcomments",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(addCommentLoading());
      const res = await api.post(addCommentPath, _);
      thunkAPI.dispatch(addCommentSuccess(res.data));
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(addCommentFail(err));
    }
  }
);

export const editcomments = createAsyncThunk(
  "/user/editcomments",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(editCommentLoading());
      const res = await api.put(editCommentPath + _.id, { comment: _.comment });
      thunkAPI.dispatch(editCommentSuccess(res.data));
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(editCommentFail(err));
    }
  }
);

export const deletecomments = createAsyncThunk(
  "/user/deletecomments",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(deleteCommentLoading());
      const res = await api.delete(deleteCommentPath + _);
      thunkAPI.dispatch(deleteCommentSuccess(res.data));
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(deleteCommentFail(err));
    }
  }
);

const commentSlice = createSlice({
  name: "commentSlice",
  initialState,
  reducers: {
    getCommentLoading: (state) => {
      state.getCommentLoading = true;
    },
    getCommentSuccess: (state, { payload }) => {
      state.getCommentLoading = false;
      state.getCommentSuccess = payload;
      state.getCommentFail = "";
    },
    getCommentFail: (state, { payload }) => {
      state.getCommentLoading = false;
      state.getCommentSuccess = {};
      state.getCommentFail = payload;
    },
    addCommentLoading: (state) => {
      state.addCommentLoading = true;
    },
    addCommentSuccess: (state, { payload }) => {
      state.addCommentLoading = false;
      state.addCommentSuccess = payload;
      state.addCommentFail = "";
    },
    addCommentFail: (state, { payload }) => {
      state.addCommentLoading = false;
      state.addCommentSuccess = {};
      state.addCommentFail = payload;
    },
    editCommentLoading: (state) => {
      state.editCommentLoading = true;
    },
    editCommentSuccess: (state, { payload }) => {
      state.editCommentLoading = false;
      state.editCommentSuccess = payload;
      state.editCommentFail = "";
    },
    editCommentFail: (state, { payload }) => {
      state.editCommentLoading = false;
      state.editCommentSuccess = {};
      state.editCommentFail = payload;
    },
    deleteCommentLoading: (state) => {
      state.deleteCommentLoading = true;
    },
    deleteCommentSuccess: (state, { payload }) => {
      state.deleteCommentLoading = false;
      state.deleteCommentSuccess = payload;
      state.deleteCommentFail = "";
    },
    deleteCommentFail: (state, { payload }) => {
      state.deleteCommentLoading = false;
      state.deleteCommentSuccess = {};
      state.deleteCommentFail = payload;
    },
  },
});

export const {
  getCommentLoading,
  getCommentSuccess,
  getCommentFail,
  addCommentLoading,
  addCommentSuccess,
  addCommentFail,
  editCommentLoading,
  editCommentFail,
  editCommentSuccess,
  deleteCommentLoading,
  deleteCommentFail,
  deleteCommentSuccess,
} = commentSlice.actions;
export default commentSlice.reducer;
