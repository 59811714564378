// export const baseAppURL = "http://localhost:3002/api/";
// export const customerPortalPath = "https://billing.stripe.com/p/login/test_7sI6oQdfl5Ms7OEcMM"
// //multi-tier backend url
// export const checkoutURL = "http://localhost:3003/";

//QA

// export const baseAppURL = "https://qaapi.taskforc.com/api/";
// export const customerPortalPath = "https://billing.stripe.com/p/login/test_7sI6oQdfl5Ms7OEcMM"
// export const checkoutURL = "https://multi-tier-backend.onrender.com/";

//Live

export const baseAppURL = "https://api.taskforc.com/api/";
export const customerPortalPath = "https://billing.stripe.com/p/login/9AQ3dZ29g5NZ57G9AA";
export const checkoutURL = "https://superadminapi.taskforc.com/";
