import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Utils/Api";
import { updatePmPaidPath } from "../../../Utils/ApiPathResources";

const initialState = {
  updatePmPaidLoading: false,
  updatePmPaidSuccess: false,
  updatePmPaidFail: "",
};

export const updatePmReportPaid = createAsyncThunk(
  "/pm/updatePmReportPaid",
  async (_, thunkAPI) => {
    try {
      var obj = {
        state: 2,
        paid_by: _.paid_by,
      };
      thunkAPI.dispatch(updatePmPaidLoading());
      const res = await api.put(
        updatePmPaidPath + _.project_id + "/" + _.user_id,
        obj
      );
      if (res) {
        thunkAPI.dispatch(updatePmPaidSuccess(res.data));
        // setTimeout(() => {
        //   thunkAPI.dispatch(clearPmPaidState);
        // }, 3000);
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(updatePmPaidFail(err));
    //   setTimeout(() => {
    //     thunkAPI.dispatch(clearPmPaidState);
    //   }, 3000);
    }
  }
);

const pmReportPaidSlice = createSlice({
  name: "pmReportPaidSlice",
  initialState,
  reducers: {
    updatePmPaidLoading: (state) => {
      state.updatePmPaidLoading = true;
    },
    updatePmPaidSuccess: (state) => {
      state.updatePmPaidLoading = false;
      state.updatePmPaidSuccess = true;
      state.updatePmPaidFail = "";
    },
    updatePmPaidFail: (state, payload) => {
      state.updatePmPaidLoading = false;
      state.updatePmPaidSuccess = false;
      state.updatePmPaidFail = payload;
    },
    // clearPmPaidState: (state) => {
    //   state.updatePmPaidLoading = false;
    //   state.updatePmPaidSuccess = false;
    //   state.updatePmPaidFail = "";
    // },
  },
});

export const {
  updatePmPaidLoading,
  updatePmPaidSuccess,
  updatePmPaidFail,
  clearPmPaidState,
} = pmReportPaidSlice.actions;
export default pmReportPaidSlice.reducer;
