import { loginPending, loginSuccess, loginFail } from "./loginSlice";
import api from "../../../Utils/Api";
import { loginPath } from "../../../Utils/ApiPath";
import jwtDecode from "jwt-decode";
import SetAuthToken from "../../../Utils/SetAuthToken";

export const login = (userData) => async (dispatch) => {
  const formData = {
    email: userData.email,
    password: userData.password,
  };
  try {
    dispatch(loginPending());
    //call api
    const res = await api.post(loginPath, formData);
    if (res) {
      const decodedToken = jwtDecode(res.data.token);
     // const end_date = decodedToken.user[2].end_date;
      const role_id = decodedToken.user[1].role_id;
      //console.log(new Date(end_date), end_date);
      // var now = new Date();
      // now.setHours(0, 0, 0, 0);
      // console.log(end_date < new Date().toISOString(),new Date().toISOString())
      // if (end_date < new Date().toISOString()) {
      //   if(role_id === 1){
      //     dispatch(loginFail("The package has been expired, Please update subscription"))
      //   }else{
      //     dispatch(loginFail("Oops, something went wrong. Please contact Adminstrator!"))
      //   }
      //   return;
      // }
      localStorage.setItem("token", res.data.token);
      SetAuthToken(res.data.token);
      const id = decodedToken.user[0].user_id;
      // const role_id = decodedToken.user[1].role_id;
      const business_id = decodedToken.user[2].business_id;
      localStorage.setItem("user_id", id);
      localStorage.setItem("role_id", role_id);
      localStorage.setItem("business_id", business_id);
      dispatch(loginSuccess(res.data.token));
      return;
    }
  } catch (err) {
    console.log(err);
    dispatch(loginFail(err.response.data.msg));
    return;
  }
};
