import React, { useEffect, useState } from "react";
import {
  UilExclamationTriangle,
  UilInfoCircle,
  UilEye,
  UilEyeSlash,
} from "@iconscout/react-unicons";
import { NavLink, useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { loginValidationSchema } from "../../../Common/Validations";
import { useDispatch, useSelector } from "react-redux";
import Loaders from "../../../Components/Loaders/Loaders";
import { login } from "../../../Feature/Entry/login/loginAction";
import Dropdown from "react-bootstrap/Dropdown";

function LoginForm() {
  //initial value:
  const initialValues = {
    email: "",
    password: "",
  };

  //redux and router hooks
  const dispatch = useDispatch();
  const { isLoading, isAuth, error, token } = useSelector(
    (state) => state.login
  );
  const navigate = useNavigate();

  useEffect(() => {
    // console.log("isA", isAuth, "token",token)
    if (isAuth && (token !== "" || token !== undefined || token !== null)) {
      navigate("/admin/dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth, token]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const role_id = localStorage.getItem("role_id");
    if (token && Number(role_id) === 1) {
      navigate("admin/dashboard");
    }
    if (
      token &&
      (Number(role_id) === 3 ||
        Number(role_id) === 4 ||
        Number(role_id) === 5 ||
        Number(role_id) === 7 ||
        Number(role_id) === 8)
    ) {
      navigate("resource/dashboard");
    }
    if (token && Number(role_id) === 2) {
      navigate("client/dashboard");
    }
    if (token && Number(role_id) === 6) {
      navigate("pm/dashboard");
    }
    if (token && (Number(role_id) === 9 || Number(role_id) === 10)) {
      navigate("resource2/dashboard");
    }
    // eslint-disable-next-line
  }, [token]);

  //submit
  const handleSubmit = (values) => {
    dispatch(login(values));
  };

  const [type, setType] = useState("password");

  const handleToggle = () => {
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };

  return (
    <>
      <div className="login-form">
        <h1 className="title">Welcome back!</h1>
        {error && (
          <div
            style={{
              color: "#e04f44",
              fontSize: "12px",
              textAlign: "center",
              marginBottom: "10px",
            }}
          >
            {error}
          </div>
        )}
        <Formik
          initialValues={initialValues}
          validationSchema={loginValidationSchema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => (
            <Form>
              <div className="login-form-main">
                {/* email */}
                <div className="login-main-row">
                  <label>Email</label>
                  <Field
                    type="text"
                    id="email"
                    name="email"
                    className={
                      errors.email && touched.email
                        ? "form-control is-invalid form-control-email"
                        : "form-control form-control-email"
                    }
                    placeholder="Enter your email"
                  />
                  {/* error */}
                  {touched.email && errors.email ? (
                    <div className="invalid-feedback d-flex">
                      <UilExclamationTriangle size="15" className="me-1" />
                      {errors.email}
                    </div>
                  ) : null}
                </div>

                {/* password */}
                <div className="login-main-row">
                  <label
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "end",
                    }}
                  >
                    Password
                    <Dropdown>
                      <Dropdown.Toggle>
                        <UilInfoCircle size="16" color="#7b68ee" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        style={{ width: "226px", lineHeight: "16px" }}
                      >
                        <Dropdown.Item>
                          &#8226; Password must Contain 8 Characters
                        </Dropdown.Item>
                        <Dropdown.Item>
                          &#8226; Must contain one Uppercase
                        </Dropdown.Item>
                        <Dropdown.Item>
                          &#8226; Must contain one Lowercase
                        </Dropdown.Item>
                        <Dropdown.Item>
                          &#8226; Must contain one Number
                        </Dropdown.Item>
                        <Dropdown.Item>
                          &#8226; Must contain one Special Case Character
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </label>

                  <Field
                    type={type}
                    className={
                      errors.password && touched.password
                        ? "form-control is-invalid form-control-pass"
                        : "form-control form-control-pass"
                    }
                    id="password"
                    name="password"
                    placeholder="Enter password"
                  />
                  {type === "password" ? (
                    <div
                      className="forgot-password pe-3"
                      onClick={handleToggle}
                    >
                      <UilEyeSlash size="20" color="#7b68ee" />
                    </div>
                  ) : (
                    <div
                      className="forgot-password pe-3"
                      onClick={handleToggle}
                    >
                      <UilEye size="20" color="#7b68ee" />
                    </div>
                  )}
                  {/* error */}
                  {touched.password && errors.password ? (
                    <div className="invalid-feedback d-flex">
                      <UilExclamationTriangle size="15" className="me-1" />
                      {errors.password}
                    </div>
                  ) : null}
                </div>

                {/* submit */}
                <div className="login-main-row">
                  <button className="login-button" type="submit">
                    {isLoading ? <Loaders color="#fff" /> : "Log In"}
                  </button>
                </div>
                <div style={{display:"flex",justifyContent:"center", fontSize:"12px"}}>
                  <NavLink
                    to="/forgot-password"
                    className="forgot-password-link"
                  >
                    Forgot Password?
                  </NavLink>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default LoginForm;
