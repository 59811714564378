import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import DashboardCards from "../../Components/Cards/DashboardCards";
import {
  UilYoutube,
  UilSpinnerAlt,
  UilCheckCircle,
  UilUploadAlt,
  UilArchive,
  UilFileCheckAlt,
} from "@iconscout/react-unicons";
import { useDispatch, useSelector } from "react-redux";
import {
  CheckUserStatus,
  getCount,
} from "../../Feature/LevelOneResource/DashboardSlice";
import { clearState } from "../../Feature/Entry/login/loginSlice";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { userStatusFail, getDashboardCount } = useSelector(
    (state) => state.L1RDashboard
  );
  const navigate = useNavigate();

  //check user status
  useEffect(() => {
    dispatch(CheckUserStatus());
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (userStatusFail) {
      localStorage.clear();
      dispatch(clearState());
      navigate("/");
    }
    // eslint-disable-next-line
  }, [userStatusFail]);

  //get count
  useEffect(() => {
    dispatch(getCount());
    // eslint-disable-next-line
  }, []);
  const data = [
    {
      name: "Available Projects",
      value: getDashboardCount.available
        ? getDashboardCount.available.count
        : 0,
      icon: <UilYoutube size="30" color="#fff" />,
    },
    {
      name: "Assigned Projects",
      value: getDashboardCount.assigned ? getDashboardCount.assigned.count : 0,
      icon: <UilFileCheckAlt size="30" color="#fff" />,
    },
    {
      name: "Completed Projects",
      value: getDashboardCount.completed
        ? getDashboardCount.completed.count
        : 0,
      icon: <UilCheckCircle size="30" color="#fff" />,
    },
  ];
  const data1 = [
    {
      name: "Not Started Projects",
      value: getDashboardCount.getnotstarted
        ? getDashboardCount.getnotstarted[0].count
        : 0,
      icon: <UilYoutube size="30" color="#fff" />,
    },
    {
      name: "Inprogress Projects",
      value: getDashboardCount.inprogress
        ? getDashboardCount.inprogress[0].count
        : 0,
      icon: <UilSpinnerAlt size="30" color="#fff" />,
    },
    {
      name: "Ready To Upload",
      value: getDashboardCount.readyupload
        ? getDashboardCount.readyupload[0].count
        : 0,
      icon: <UilCheckCircle size="30" color="#fff" />,
    },
    {
      name: "Uploaded Projects",
      value: getDashboardCount.uploaded
        ? getDashboardCount.uploaded[0].count
        : 0,
      icon: <UilUploadAlt size="30" color="#fff" />,
    },
    {
      name: "Archive Projects",
      value: getDashboardCount.archive ? getDashboardCount.archive[0].count : 0,
      icon: <UilArchive size="30" color="#fff" />,
    },
  ];
  const [name, setName] = useState("");
  useEffect(() => {
    var token = localStorage.getItem("token");
    var decodeToken = jwtDecode(token);
    if (decodeToken) {
      setName(
        decodeToken.user[0].first_name + " " + decodeToken.user[0].last_name
      );
    }
  }, []);
  const [role, setRole] = useState("");
  useEffect(() => {
    const role = localStorage.getItem("role_id");
    if (role === "3" || role === "4" || role === "5") {
      setRole("level1");
    } else {
      setRole("level2");
    }
  }, []);
  return (
    <>
      <div className="dashboard">
        <div className="dashboard-title">Welcome {name}</div>
        <div className="row justify-content-evenly">
          {role === "level1" &&
            data.map((item, index) => (
              <DashboardCards data={item} key={index} />
            ))}
          {role === "level2" &&
            data1.map((item, index) => (
              <DashboardCards data={item} key={index} />
            ))}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
