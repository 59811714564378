import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../Utils/Api";
import {
  getScriptReportPath,
  getVoiceReportPath,
  getVideoReportPath,
  getThumbReportPath,
  getDescReportPath,
  getScriptReportByDatePath,
  getVoiceReportByDatePath,
  getVideoReportByDatePath,
} from "../../Utils/ApiPathResources";

const initialState = {
  isLoading: false,
  getReport: [],
  getReportFail: "",
  getReportByDate: [],
  getReportByDateFail: "",
};

export const getReportData = createAsyncThunk(
  "/resources/getReportData",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getReportPending());
      const role_id = Number(localStorage.getItem("role_id"));
      const user_id = Number(localStorage.getItem("user_id"));
      if (role_id === 3) {
        const res = await api.get(getScriptReportPath + user_id + "/" + _);
        if (res.data) {
          thunkAPI.dispatch(getReportSuccess(res.data));
        }
      }
      if (role_id === 4) {
        const res = await api.get(getVoiceReportPath + user_id + "/" + _);
        if (res.data) {
          thunkAPI.dispatch(getReportSuccess(res.data));
        }
      }
      if (role_id === 5) {
        const res = await api.get(getVideoReportPath + user_id + "/" + _);
        if (res.data) {
          thunkAPI.dispatch(getReportSuccess(res.data));
        }
      }
      if (role_id === 7) {
        const res = await api.get(getThumbReportPath + user_id + "/" + _);
        if (res.data) {
          thunkAPI.dispatch(getReportSuccess(res.data));
        }
      }
      if (role_id === 8) {
        const res = await api.get(getDescReportPath + user_id + "/" + _);
        if (res.data) {
          thunkAPI.dispatch(getReportSuccess(res.data));
        }
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getReportFail(err));
    }
  }
);

export const getReportDataByDate = createAsyncThunk(
  "/resources/getReportDataByDate",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getReportByDatePending());
      const role_id = Number(localStorage.getItem("role_id"));
      const user_id = Number(localStorage.getItem("user_id"));
      if (role_id === 3) {
        const res = await api.get(
          getScriptReportByDatePath +
            user_id +
            "/" +
            _.start_date +
            "/" +
            _.end_date +
            "/" +
            _.state
        );
        if (res.data) {
          thunkAPI.dispatch(getReportByDateSuccess(res.data));
        }
      }
      if (role_id === 4) {
        const res = await api.get(
          getVoiceReportByDatePath +
            user_id +
            "/" +
            _.start_date +
            "/" +
            _.end_date +
            "/" +
            _.state
        );
        if (res.data) {
          thunkAPI.dispatch(getReportByDateSuccess(res.data));
        }
      }
      if (role_id === 5) {
        const res = await api.get(
          getVideoReportByDatePath +
            user_id +
            "/" +
            _.start_date +
            "/" +
            _.end_date +
            "/" +
            _.state
        );
        if (res.data) {
          thunkAPI.dispatch(getReportByDateSuccess(res.data));
        }
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getReportByDateFail(err));
    }
  }
);

const L1RReport = createSlice({
  name: "L1R",
  initialState,
  reducers: {
    getReportPending: (state) => {
      state.isLoading = true;
    },
    getReportSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.getReport = payload;
      state.getReportFail = "";
    },
    getReportFail: (state, { payload }) => {
      state.isLoading = false;
      state.getReport = [];
      state.getReportFail = payload;
    },
    getReportByDatePending: (state) => {
      state.isLoading = true;
    },
    getReportByDateSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.getReportByDate = payload;
      state.getReportByDateFail = "";
    },
    getReportByDateFail: (state, { payload }) => {
      state.isLoading = false;
      state.getReportByDate = [];
      state.getReportByDateFail = payload;
    },
  },
});

export const {
  getReportSuccess,
  getReportPending,
  getReportFail,
  getReportByDatePending,
  getReportByDateSuccess,
  getReportByDateFail,
} = L1RReport.actions;
export default L1RReport.reducer;
