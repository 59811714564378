import React, { useEffect, useState } from "react";
import DashboardCards from "../../Components/Cards/DashboardCards";
import {
  projectInformation,
  userInformation,
} from "../../Common/DashboardData";
import { DueProjectHeader } from "../../Common/CommonTable";
import { useDispatch, useSelector } from "react-redux";
import {
  getCount,
  getProjectDue,
} from "../../Feature/Admin/Dashboard/dashboardAction";
import { dateFormat } from "../../Common/dateFormat";
import wave from "../../Assets/Images/wave.gif";
import { userName } from "../../Common/CommonFunction";

function Dashboard() {
  var token = localStorage.getItem("token");
  //Hooks
  const dispatch = useDispatch();
  const { count, dueProjects, isLoading } = useSelector(
    (state) => state.dashboard
  );
  const [userCount, setUserCount] = useState([]);
  const [projectCount, setProjectCount] = useState([]);
  // const [title, setTitle] = useState("");

  // useEffect(() =>{
  //   var role = localStorage.getItem("role_id");
  //   if(Number(role) === 1){
  //     setTitle("Admin")
  //   }else if(Number(role) === 12){
  //     setTitle("Sub admin")
  //   } 
  // },[])

  useEffect(() => {
    dispatch(getCount());
  }, [dispatch]);

  useEffect(() => {
    if (JSON.stringify(count) !== "{}") {
      // console.log(count, userInformation, projectInformation);
      const userCount = [];
      const projectCount = [];
      for (let i = 0; i < userInformation.length; i++) {
        if (userInformation[i].id === "client") {
          let obj = {
            icon: userInformation[i].icon,
            name: userInformation[i].name,
            value: count.client[0].count,
          };
          userCount.push(obj);
        }
        if (userInformation[i].id === "script") {
          let obj = {
            icon: userInformation[i].icon,
            name: userInformation[i].name,
            value: count.writer[0].count,
          };
          userCount.push(obj);
        }
        if (userInformation[i].id === "voice") {
          let obj = {
            icon: userInformation[i].icon,
            name: userInformation[i].name,
            value: count.voice[0].count,
          };
          userCount.push(obj);
        }
        if (userInformation[i].id === "video") {
          let obj = {
            icon: userInformation[i].icon,
            name: userInformation[i].name,
            value: count.video[0].count,
          };
          userCount.push(obj);
        }
      }
      setUserCount(userCount);
      for (let i = 0; i < projectInformation.length; i++) {
        if (projectInformation[i].id === "completed") {
          let obj = {
            icon: projectInformation[i].icon,
            name: projectInformation[i].name,
            value: count.completed[0].count,
          };
          projectCount.push(obj);
        }
        if (projectInformation[i].id === "pending") {
          let obj = {
            icon: projectInformation[i].icon,
            name: projectInformation[i].name,
            value: count.pending[0].count,
          };
          projectCount.push(obj);
        }
        if (projectInformation[i].id === "archive") {
          let obj = {
            icon: projectInformation[i].icon,
            name: projectInformation[i].name,
            value: count.archive[0].count,
          };
          projectCount.push(obj);
        }
      }
      setProjectCount(projectCount);
    }
  }, [count]);

  useEffect(() => {
    dispatch(getProjectDue());
  }, [dispatch]);

  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center">
          <img src={wave} alt="wave" />
        </div>
      ) : (
        <div className="dashboard">
          <div className="dashboard-title">Welcome {userName(token)}</div>
          <div className="row justify-content-evenly">
            <div className="dashboard-text mb-3 mt-3">User Information</div>
            {userCount.map((item, index) => (
              <DashboardCards data={item} key={index} value />
            ))}
            <div className="dashboard-text mb-3 mt-3">Project Information</div>
            {projectCount.map((item, index) => (
              <DashboardCards data={item} key={index} />
            ))}
            <div className="dashboard-text mb-3 mt-3">Due Projects</div>

            <div className="common-table">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      {DueProjectHeader &&
                        DueProjectHeader.map((item, index) => {
                          return <th key={index}>{item.header}</th>;
                        })}
                    </tr>
                  </thead>
                  <tbody>
                    {dueProjects.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.project_name}</td>
                        <td>{item.channel}</td>
                        {item.stage_id === 1 ? <td>Backlog</td> : ""}
                        {item.stage_id === 2 ? <td>Script</td> : ""}
                        {item.stage_id === 3 ? <td>Voice</td> : ""}
                        {item.stage_id === 4 ? <td>Video</td> : ""}
                        {item.stage_id === 5 ? <td>Completed</td> : ""}
                        {item.stage_id === 9 ? <td>Revision</td> : ""}
                        {item.stage_id === 6 ? <td>Ready to upload</td> : ""}
                        <td>{dateFormat(item.due_date)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Dashboard;
