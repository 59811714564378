import React, { useEffect } from "react";
import { userName } from "../../Common/CommonFunction";
import DashboardCards from "../../Components/Cards/DashboardCards";
import {
  UilYoutube,
  UilSpinnerAlt,
  UilCheckCircle,
  UilUploadAlt,
  UilArchive,
} from "@iconscout/react-unicons";
import { DueProjectHeader } from "../../Common/CommonTable";
import { useDispatch, useSelector } from "react-redux";
import {
  getPmCount,
  getPmDueProject,
} from "../../Feature/ProjectManager/dashboardSlice";
import { dateFormat } from "../../Common/dateFormat";

const Dashboard = () => {
  var token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const { getPmDashboardCount, getPmDueProjects } = useSelector(
    (state) => state.pmDashboard
  );

  //useEffects
  useEffect(() => {
    dispatch(getPmCount());
    dispatch(getPmDueProject());
    // eslint-disable-next-line
  }, []);

  const data = [
    {
      name: "Not Started Projects",
      value: getPmDashboardCount.getnotstarted
        ? getPmDashboardCount.getnotstarted[0].count
        : 0,
      icon: <UilYoutube size="30" color="#fff" />,
    },
    {
      name: "Inprogress Projects",
      value: getPmDashboardCount.inprogress
        ? getPmDashboardCount.inprogress[0].count
        : 0,
      icon: <UilSpinnerAlt size="30" color="#fff" />,
    },
    {
      name: "Ready To Upload",
      value: getPmDashboardCount.readyupload
        ? getPmDashboardCount.readyupload[0].count
        : 0,
      icon: <UilCheckCircle size="30" color="#fff" />,
    },
    {
      name: "Uploaded Projects",
      value: getPmDashboardCount.uploaded
        ? getPmDashboardCount.uploaded[0].count
        : 0,
      icon: <UilUploadAlt size="30" color="#fff" />,
    },
    {
      name: "Archive Projects",
      value: getPmDashboardCount.archive
        ? getPmDashboardCount.archive[0].count
        : 0,
      icon: <UilArchive size="30" color="#fff" />,
    },
  ];
  return (
    <>
      <div className="dashboard">
        <div className="dashboard-title">Welcome {userName(token)}</div>
        <div className="row justify-content-evenly">
          <div className="dashboard-text mb-3 mt-3">Project Information</div>
          {data.map((item, index) => (
            <DashboardCards data={item} key={index} />
          ))}
          <div className="dashboard-text mb-3 mt-3">Due Projects</div>
          <div className="common-table">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    {DueProjectHeader &&
                      DueProjectHeader.map((item, index) => {
                        return <th key={index}>{item.header}</th>;
                      })}
                  </tr>
                </thead>
                <tbody>
                  {getPmDueProject &&
                    getPmDueProjects.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.project_name}</td>
                        <td>{item.channel}</td>
                        {item.stage_id === 1 ? <td>Backlog</td> : ""}
                        {item.stage_id === 2 ? <td>Script</td> : ""}
                        {item.stage_id === 3 ? <td>Voice</td> : ""}
                        {item.stage_id === 4 ? <td>Video</td> : ""}
                        {item.stage_id === 5 ? <td>Completed</td> : ""}
                        {item.stage_id === 9 ? <td>Revision</td> : ""}
                        {item.stage_id === 6 ? <td>Ready to upload</td> : ""}
                        <td>{dateFormat(item.due_date)}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
