import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Utils/Api";
import {
  getUserOfChannelPath,
  getUserOfProjectPath,
} from "../../../Utils/ApiPath";

const initialState = {
  userOfChannelLoading: false,
  userOfChannelSuccess: {},
  userOfChannelFail: false,
  userOfProjectLoading: false,
  userOfProjectSuccess: {},
  userOfProjectFail: false,
};

export const fetchUserOfProject = createAsyncThunk(
  "/user/fetchUserpfProject",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(userOfProjectLoading());
      const res = await api.get(getUserOfProjectPath + _);
      thunkAPI.dispatch(userOfProjectSuccess(res.data));
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(userOfProjectFail(err));
    }
  }
);

export const fetchUserOfChannel = createAsyncThunk(
  "/user/fetchUserOfChannel",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(userOfChannelLoading());
      const res = await api.get(getUserOfChannelPath + _);
      thunkAPI.dispatch(userOfChannelSuccess(res.data));
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(userOfChannelFail(err));
    }
  }
);

const getUserEditSlice = createSlice({
  name: "getUserEditSlice",
  initialState,
  reducers: {
    userOfChannelLoading: (state) => {
      state.userOfChannelLoading = true;
    },
    userOfChannelSuccess: (state, { payload }) => {
      state.userOfChannelLoading = false;
      state.userOfChannelSuccess = payload;
      state.userOfChannelFail = "";
    },
    userOfChannelFail: (state, { payload }) => {
      state.userOfChannelLoading = false;
      state.userOfChannelSuccess = {};
      state.userOfChannelFail = payload;
    },
    userOfProjectLoading: (state) => {
      state.userOfProjectLoading = true;
    },
    userOfProjectSuccess: (state, { payload }) => {
      state.userOfProjectLoading = false;
      state.userOfProjectSuccess = payload;
      state.userOfProjectFail = "";
    },
    userOfProjectFail: (state, { payload }) => {
      state.userOfProjectLoading = false;
      state.userOfProjectSuccess = {};
      state.userOfProjectFail = payload;
    },
  },
});

export const {
  userOfChannelLoading,
  userOfChannelSuccess,
  userOfChannelFail,
  userOfProjectLoading,
  userOfProjectSuccess,
  userOfProjectFail,
} = getUserEditSlice.actions;
export default getUserEditSlice.reducer;
