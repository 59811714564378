import React from "react";
import { Modal } from "react-bootstrap";
import { UilCloudUpload } from "@iconscout/react-unicons";
import { useDispatch } from "react-redux";
import { uploadProject } from "../../Feature/LevelOneResource/AssignProjectSlice";
import { updateRevisionStateData } from "../../Feature/LevelOneResource/RevisionSlice";

const UploadModal = ({ show, toggle, projectData, label, id }) => {
  const dispatch = useDispatch();
  const submit = () => {
    if (label === "revision") {
      const obj = {
        project_id: id,
      };
      dispatch(updateRevisionStateData(obj));
      toggle();
      return;
    } else {
      dispatch(uploadProject(projectData.project_id));
      toggle();
      return;
    }
  };
  return (
    <>
      <Modal
        show={show}
        onHide={toggle}
        className="re-invite"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload files</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <div className="d-flex justify-content-center align-items-center">
            <UilCloudUpload size="200" color="#7b68ee" />
          </div>

          <div className="d-flex justify-content-center align-items-center">
            <span className="text-center">Mark File as uploaded?</span>
          </div>

          <div className="footer d-flex justify-content-between">
            <button className="cancel-btn" onClick={toggle}>
              No
            </button>
            <button className="delete-btn" onClick={submit}>
              Yes, Mark as uploaded
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UploadModal;
