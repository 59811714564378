import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Utils/Api";
import { deleteUserPath, reinviteUserPath } from "../../../Utils/ApiPath";

const initialState = {
  isLoading: false,
  isDeleteSuccess: false,
  isDeleteFail: "",
  isReinviteSuccess: false,
  isReinviteFail: "",
};

export const deleteUserId = createAsyncThunk(
  "/user/deleteUser",
  async (_, thunkAPI) => {
    let object = {
      status_flag: 0,
    };
    try {
      thunkAPI.dispatch(deleteUserPending());
      const res = await api.put(deleteUserPath + "/" + _, object);
      if (res) {
        thunkAPI.dispatch(deleteUserSucess(res.data));
        setTimeout(() =>{
          thunkAPI.dispatch(clearDeleteUserState())
        },4000)
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(deleteUserFail(err));
    }
  }
);

export const reinviteUserId = createAsyncThunk(
  "/user/reinvite",
  async (_, thunkAPI) => {
    try {
      const data = {
        email: _.email,
        first_name:_.first_name,
        last_name:_.last_name
      }
      thunkAPI.dispatch(reinviteUserPending());
      const res = await api.post(reinviteUserPath,data);
      if (res) {
        thunkAPI.dispatch(reinviteUserSuccess(res.data));
        setTimeout(() =>{
          thunkAPI.dispatch(clearReinviteUser())
        },4000)
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(reinviteUserFail(err));
    }
  }
);

const userActionSlice = createSlice({
  name: "userAction",
  initialState,
  reducers: {
    deleteUserPending: (state) => {
      state.isLoading = true;
    },
    deleteUserSucess: (state) => {
      state.isLoading = false;
      state.isDeleteSuccess = true;
      state.isDeleteFail = "";
    },
    deleteUserFail: (state, { payload }) => {
      state.isLoading = false;
      state.isDeleteSuccess = false;
      state.isDeleteFail = payload;
    },
    reinviteUserPending: (state) => {
      state.isLoading = true;
    },
    reinviteUserSuccess: (state) => {
      state.isLoading = false;
      state.isReinviteSuccess = true;
      state.isReinviteFail = "";
    },
    reinviteUserFail: (state, { payload }) => {
      state.isLoading = false;
      state.isReinviteSuccess = false;
      state.isReinviteFail = payload;
    },
    clearDeleteUserState:(state)=>{
      state.isLoading = false;
      state.isDeleteSuccess = false;
      state.isDeleteFail = "";
    },
    clearReinviteUser:(state)=>{
      state.isLoading = false;
      state.isReinviteSuccess = false;
      state.isReinviteFail = "";
    }
  },
});

export const {
  deleteUserPending,
  deleteUserSucess,
  deleteUserFail,
  reinviteUserPending,
  reinviteUserSuccess,
  reinviteUserFail,
  clearDeleteUserState,
  clearReinviteUser
} = userActionSlice.actions;
export default userActionSlice.reducer;
