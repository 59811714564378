import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { checkoutURL } from "../../Config/RequestPath";

const initialState = {
  isLoading: false,
  isSuccess: "",
  isError: "",
};

export const register = createAsyncThunk(
  "/user/register",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getRegisterPending());
      axios.post(checkoutURL+"stripeAPI/create-checkout-session",_)
      .then((res) => {
        console.log(res)
        thunkAPI.dispatch(getRegisterSuccess(res.data));
      })
      .catch((err)=>{
        console.log(err, err.response.data)
        thunkAPI.dispatch(getRegisterFail(err.response.data));
      })
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getRegisterFail(err));
    }
  }
);

const registerSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    getRegisterPending: (state) => {
      state.isLoading = true;
    },
    getRegisterSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = payload;
      state.isError = "";
    },
    getRegisterFail: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess= "";
      state.isError = payload;
    },
  },
});

export const {
  getRegisterPending,
  getRegisterSuccess,
  getRegisterFail
} = registerSlice.actions;
export default registerSlice.reducer;
