import React from "react";
import { Modal } from "react-bootstrap";
import deleteImage from "../Assets/Images/deleteImage.png";

function DeleteModal({ show, toggle, head, data, trigger}) {
  return (
    <>
      <Modal
        size="md"
        aria-labelledby="example-modal-sizes-title-lg"
        show={show}
        centered
        className="delete-modal"
        onHide={toggle}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center">
            <img src={deleteImage} alt="deleteImage" />
          </div>
          <h4
            style={{
              color: "#fd71af",
              fontSize: "25px",
              lineHeight: "1.2",
              textAlign: "center",
              margin: "15px 0",
            }}
          >
            Delete{" "+head}
          </h4>
          <div
            style={{
              color: "#7c828d",
              fontSize: "14px",
              lineHeight: "20px",
              padding: "10px 25px",
              textAlign: "center",
            }}
          >
            Are you sure you want to delete {data} {head} everywhere?
          </div>
          <div className="footer d-flex justify-content-between">
            <button className="cancel-btn" onClick={toggle}>Cancel</button>
            <button className="delete-btn" onClick={trigger}>Delete</button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default DeleteModal;
