import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useDispatch } from "react-redux";
import { bulkInactivateChannel, bulkInactivateUsers } from "../../Feature/Admin/ChannelManagement/ChannelDeleteSlice";

const DowngradeModal = ({ show, handleClose, data, name }) => {
  const [checkboxValues, setCheckboxValues] = useState([]);
  const dispatch = useDispatch();
  const handleOnChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setCheckboxValues([...checkboxValues, value]);
    } else {
      setCheckboxValues(checkboxValues.filter((item) => item !== value));
    }
  };

  const submit = () => {
    console.log(checkboxValues);
    if (checkboxValues.length > 0) {
      if (name === "users") {
        dispatch(bulkInactivateUsers(checkboxValues));
      }else{
        dispatch(bulkInactivateChannel(checkboxValues));
      }
      handleClose();
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Downgraded Package!</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <p>
            Since you have downgraded package you need to delete / inactivate
            <span className="ms-1">{name}</span> inorder to use the application without any stoppage.
          </p>
          {/* <p>Select & Delete / Inactive 5 {name} from the list.</p> */}
          {data &&
            data.map((item, index) => (
              <>
                <div key={index}>
                  <Form.Check // prettier-ignore
                    type="checkbox"
                    id="default-checkbox"
                    label={
                      name === "users"
                        ? item.first_name + " " + item.last_name
                        : item.channel
                    }
                    value={name === "users" ? item.user_id : item.channel_id}
                    checked={item?.isChecked}
                    onChange={handleOnChange}
                  />
                </div>
              </>
            ))}
          <div className="text-center mt-5">
            <button className="apply-button" type="submit" onClick={submit}>
              Submit
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DowngradeModal;
