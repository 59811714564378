import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../Utils/Api";
import {
  updateProjectPhasePath,
  searchProjectPath,
  updateProjectStatusPath,
  searchArchiveProjectPath
} from "../../../../Utils/ApiPath";
import { v4 as uuidv4 } from "uuid";

const initialState = {
  updatePhaseLoading: false,
  updatePhaseSuccess: false,
  updatePhaseError: "",
  searchProjectLoading: false,
  searchProjectSuccess: [],
  searchProjectError: "",
  updateProjectStatusLoading: false,
  updateProjectStatusSuccess: false,
  updateProjectStatusError: "",
  searchArchiveProjectLoading: false,
  searchArchiveProjectSuccess: [],
  searchArchiveProjectError: "",
};

export const updateProjectPhase = createAsyncThunk(
  "/projects/updateProjectPhase",
  async (_, thunkAPI) => {
    try {
      const data = {
        flag: _.flag,
        stage_id: _.stage_id,
      };
      thunkAPI.dispatch(updatePhaseLoading());
      const res = await api.put(updateProjectPhasePath + _.project_id, data);
      if (res) {
        thunkAPI.dispatch(updatePhaseSuccess(res.data));
        setTimeout(() => {
          thunkAPI.dispatch(clearPhaseSuccess());
        }, 1000);
        return res.data;
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(updatePhaseError(err));
      // setTimeout(() => {
      //   clearError();
      // }, 1000);
      return err;
    }
  }
);

export const searchProject = createAsyncThunk(
  "/projects/searchProject",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(searchProjectLoading());
      const res = await api.get(
        searchProjectPath + _.project_id + "/" + _.regex
      );
      if (res) {
        var newData = res.data.map((item) =>
          Object.assign({}, item, { id: uuidv4() })
        );
        var finalData = {};
        var projectData = [];
        var projectData1 = [];
        var projectData2 = [];
        var projectData3 = [];
        var projectData4 = [];
        var projectData5 = [];
        var projectData6 = [];
        //var projectData7 = [];
        var projectData8 = [];
        for (var i = 0; i < newData.length; i++) {
          if (newData[i].stage_id === 1) {
            projectData.push(newData[i]);
          }
          if (newData[i].stage_id === 2) {
            projectData1.push(newData[i]);
          }
          if (newData[i].stage_id === 3) {
            projectData2.push(newData[i]);
          }
          if (newData[i].stage_id === 4) {
            projectData3.push(newData[i]);
          }
          if (newData[i].stage_id === 5) {
            projectData4.push(newData[i]);
          }
          if (newData[i].stage_id === 6) {
            projectData5.push(newData[i]);
          }
          if (newData[i].stage_id === 7) {
            projectData6.push(newData[i]);
          }
          // if (newData[i].stage_id === 8) {
          //   projectData7.push(newData[i]);
          // }
          if (newData[i].stage_id === 9) {
            projectData8.push(newData[i]);
          }
          finalData = {
            // eslint-disable-next-line
            ["Backlog"]: {
              items: projectData,
            },
            // eslint-disable-next-line
            ["Script"]: {
              items: projectData1,
            },
            // eslint-disable-next-line
            ["Voice"]: {
              items: projectData2,
            },
            // eslint-disable-next-line
            ["Video"]: {
              items: projectData3,
            },
            // eslint-disable-next-line
            ["Comp"]: {
              items: projectData4,
            },
            // // eslint-disable-next-line
            // ["Comp"]: {
            //   items: projectData4,
            // },
            // eslint-disable-next-line
            ["Ready"]: {
              items: projectData5,
            },
            // eslint-disable-next-line
            ["Published"]: {
              items: projectData6,
            },
            // eslint-disable-next-line
            // ["Archived"]: {
            //   items: projectData7,
            // },
            // eslint-disable-next-line
            ["Revision"]: {
              items: projectData8,
            },
          };
        }
        thunkAPI.dispatch(searchProjectSuccess(finalData));
        return res.data;
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(searchProjectError(err));
      return err;
    }
  }
);

export const updateProjectStatus = createAsyncThunk(
  "/projects/updateProjectStatus",
  async (_, thunkAPI) => {
    try {
      const data = {
        project_status: _.project_status,
        stage_id: _.stage_id,
      };
      thunkAPI.dispatch(updateProjectStatusLoading());
      const res = await api.put(updateProjectStatusPath + _.project_id, data);
      if (res) {
        thunkAPI.dispatch(updateProjectStatusSuccess(res.data));
        setTimeout(() => {
          thunkAPI.dispatch(clearProjectStatus());
        }, 1000);
        return res.data;
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(updateProjectStatusError(err));
      return err;
    }
  }
);

export const searchArchiveProjects = createAsyncThunk(
  "/projects/searchArchiveProjects",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(searchArchiveProjectLoading());
      const res = await api.get(searchArchiveProjectPath + _.project_id + "/" + _.regex);
      if (res.data) {
        thunkAPI.dispatch(searchArchiveProjectSuccess(res.data));
        return res.data;
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(updatePhaseError(err));
      return err;
    }
  }
);

const projectPhaseSlice = createSlice({
  name: "projectPhase",
  initialState,
  reducers: {
    updatePhaseLoading: (state) => {
      state.updatePhaseLoading = true;
    },
    updatePhaseSuccess: (state) => {
      state.updatePhaseLoading = false;
      state.updatePhaseSuccess = true;
      state.updatePhaseError = "";
    },
    updatePhaseError: (state, { payload }) => {
      state.updatePhaseLoading = false;
      state.updatePhaseSuccess = false;
      state.updatePhaseError = payload;
    },
    clearError: (state) => {
      state.updatePhaseError = "";
    },
    clearPhaseSuccess: (state) => {
      state.updatePhaseSuccess = false;
    },
    searchProjectLoading: (state) => {
      state.searchProjectLoading = true;
    },
    searchProjectSuccess: (state, { payload }) => {
      state.searchProjectLoading = false;
      state.searchProjectSuccess = payload;
      state.searchProjectError = "";
    },
    searchProjectError: (state, { payload }) => {
      state.searchProjectLoading = false;
      state.searchProjectSuccess = [];
      state.searchProjectError = payload;
    },
    updateProjectStatusLoading: (state) => {
      state.updateProjectStatusLoading = true;
    },
    updateProjectStatusSuccess: (state, { payload }) => {
      state.updateProjectStatusLoading = false;
      state.updateProjectStatusSuccess = payload;
      state.updateProjectStatusError = "";
    },
    updateProjectStatusError: (state, { payload }) => {
      state.updateProjectStatusLoading = false;
      state.updateProjectStatusSuccess = false;
      state.updateProjectStatusError = payload;
    },
    clearProjectStatus: (state) => {
      state.updateProjectStatusLoading = false;
      state.updateProjectStatusSuccess = false;
      state.updateProjectStatusError = "";
    },
    searchArchiveProjectLoading: (state) => {
      state.searchArchiveProjectLoading = true;
    },
    searchArchiveProjectSuccess: (state, { payload }) => {
      state.searchArchiveProjectLoading = false;
      state.searchArchiveProjectSuccess = payload;
      state.searchArchiveProjectError = "";
    },
    searchArchiveProjectError: (state, { payload }) => {
      state.searchArchiveProjectLoading = false;
      state.searchArchiveProjectSuccess = [];
      state.searchArchiveProjectError = payload;
    },
  },
});

export const {
  updatePhaseLoading,
  updatePhaseError,
  updatePhaseSuccess,
  clearError,
  clearPhaseSuccess,
  searchProjectLoading,
  searchProjectSuccess,
  searchProjectError,
  updateProjectStatusLoading,
  updateProjectStatusSuccess,
  updateProjectStatusError,
  clearProjectStatus,
  searchArchiveProjectLoading,
  searchArchiveProjectSuccess,
  searchArchiveProjectError
} = projectPhaseSlice.actions;
export default projectPhaseSlice.reducer;
