import React, { useEffect, useState } from "react";
import {
  UilMessage,
  UilPen,
  UilTrash,
  UilPaperclip,
} from "@iconscout/react-unicons";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteChat,
  getChat,
  readCount,
  sendChat,
  updateChat,
  updateReadStatus,
} from "../../../Feature/Admin/Chat/ChatSlice";
import { userName, getInitials } from "../../../Common/CommonFunction";
import { dateFormat } from "../../../Common/dateFormat";

function Chat() {
  const dispatch = useDispatch();
  const [chats, setChats] = useState("");
  const [initials, setInitial] = useState("");
  const [user, setUser] = useState(0);
  const { getChats, isSuccess, isEditSuccess, isDeleteSuccess } = useSelector(
    (state) => state.chat
  );
  const [hidden, setHidden] = useState({});
  const [editChat, setEditChat] = useState("");
  const [file, setFile] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setInitial(userName(token));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(getChat());
    setUser(Number(localStorage.getItem("user_id")));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    console.log(isSuccess)
    if ((isSuccess || isEditSuccess || isDeleteSuccess)) {
      dispatch(getChat());
    }
    // eslint-disable-next-line
  }, [isSuccess, isEditSuccess, isDeleteSuccess]);

  useEffect(() => {
    window.setTimeout(function () {
      var elem = document.getElementById("read-chat");
      elem.scrollTop = elem.scrollHeight;
    }, 1000);
  }, []);

  useEffect(() => {
    const user_id = Number(localStorage.getItem("user_id"));
    dispatch(updateReadStatus(user_id));
    dispatch(readCount(user_id));
    // eslint-disable-next-line
  }, []);

  const ref = React.useRef();

  const sendComment = () => {
    if (chats || file) {
      const obj = {
        comments: chats,
        file: file,
      };
      dispatch(sendChat(obj));
      setChats("");
      setFile("");
      ref.current.value = ""
    }
  };

  const toggleHidden = (index, item) => {
    setHidden({ ...hidden, [index]: !hidden[index] });
    setChats("");
    setEditChat(item);
  };
  const submitEditComment = (id, index) => {
    const obj = {
      id: id,
      comments: editChat,
    };
    dispatch(updateChat(obj));
    setHidden({ ...hidden, [index]: !hidden[index] });
  };
  const deleteComment = (id) => {
    dispatch(deleteChat(id));
  };
  const handleFile = (e) => {
    if (e.target.files[0]) {
      // console.log(e.target.files);
      setFile(e.target.files[0]);
    }
  };
  const autoGrow = (element) => {
    element.target.style.height = "5px";
    element.target.style.height = element.target.scrollHeight + "px";
  };
  return (
    <>
      <div className="chat">
        <div className="content-title">Chat with us</div>
        <div className="d-flex justify-content-center">
          <div
            className="col-md-10 chat-container"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "column",
            }}
          >
            <div className="read-chat" id="read-chat">
              {getChats &&
                getChats.map((item, i) => (
                  <>
                    <div
                      key={item}
                      className="d-flex align-items-center"
                      style={
                        user === item.sender_id
                          ? { flexDirection: "row-reverse" }
                          : {}
                      }
                    >
                      <div className="client-icon">
                        {user === item.sender_id ? getInitials(initials) : "S"}
                      </div>
                      <div className="in-chat p-2 m-3">
                        <div className="d-flex justify-content-between">
                          <div className="sm-text">
                            {user === item.sender_id ? initials : "Support"}
                          </div>
                          <div className="me-2 sm-text d-flex">
                            <div className="me-2">
                              {dateFormat(item.createdAt)}
                            </div>
                            {user === item.sender_id && (
                              <div>
                                <UilPen
                                  size="14"
                                  color="#7c828d"
                                  className="me-2"
                                  onClick={(e) => {
                                    toggleHidden(i, item.comments);
                                  }}
                                  style={{ cursor: "pointer" }}
                                />
                                <UilTrash
                                  size="14"
                                  color="#e04f44"
                                  className="me-2"
                                  onClick={() => deleteComment(item.id)}
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        {!hidden[i] && (
                          <div className="comment-text">
                            {item.comments} &nbsp;
                            {item.files ? (
                              <a
                                href={
                                  "https://s3.amazonaws.com/taskforc2.0/" +
                                  item.files
                                }
                                // target="_blank"
                                download
                                rel="noreferrer"
                              >
                                {item.files}
                              </a>
                            ) : (
                              ""
                            )}
                          </div>
                        )}
                        {!!hidden[i] && (
                          <>
                            <input
                              type="text"
                              value={editChat}
                              onChange={(e) => setEditChat(e.target.value)}
                              className="form-control"
                            />
                            <div className="text-end mt-3">
                              <button
                                type="button"
                                className="cancel-button me-2"
                                onClick={() => {
                                  setHidden({
                                    ...hidden,
                                    [i]: !hidden[i],
                                  });
                                }}
                              >
                                Cancel
                              </button>
                              <button
                                type="button"
                                className="apply-button"
                                onClick={() => submitEditComment(item.id, i)}
                              >
                                Save
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </>
                ))}
            </div>

            {/* //send chat */}
            <div className="d-flex chat-input">
              <div
                style={{ width: "90%" }}
                className="send-comment d-flex align-items-center"
              >
                <textarea
                  className="comment-input"
                  type="text"
                  placeholder="Chat here..."
                  onChange={(e) => setChats(e.target.value)}
                  value={chats}
                  onInput={(e) => autoGrow(e)}
                  style={{maxHeight:"50px"}}
                ></textarea>
                <div className="ms-3 d-flex">
                  <label htmlFor="file">
                    <UilPaperclip color="#777" />
                  </label>
                  <input type="file" id="file" onChange={handleFile} ref={ref}/>
                </div>
              </div>
              <div
                style={{
                  width: "10%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className="send"
              >
                <button
                  type="button"
                  className="btn"
                  style={{ display: "flex", alignItems: "center" }}
                  onClick={sendComment}
                >
                  <UilMessage color="#5f48ea" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Chat;
