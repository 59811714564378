import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../Utils/Api";
import {
  clientAddProjectChannelPath,
  clientAddProjectPath,
  clientAddProjectUserPath,
  clientGetProjectPath,
  clientSearchProjectPath,
} from "../../Utils/ApiPathResources";

const initialState = {
  isLoading: false,
  getClientProject: [],
  getClientProjectFail: "",
  addClientProject: false,
  addClientProjectFail: "",
  searchClientProject: [],
  searchClientProjectFail: "",
};

export const getClientProjects = createAsyncThunk(
  "/client/getClientProject",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getClientProjectPending());
      const res = await api.get(clientGetProjectPath + _);
      if (res.data) {
        var newData = res.data;
        var finalData = {};
        var projectData = [];
        var projectData1 = [];
        var projectData2 = [];
        var projectData3 = [];
        var projectData4 = [];
        var projectData5 = [];
        var projectData6 = [];
        var projectData7 = [];
        var projectData8 = [];
        for (var i = 0; i < newData.length; i++) {
          if (newData[i].stage_id === 1) {
            projectData.push(newData[i]);
          }
          if (newData[i].stage_id === 2) {
            projectData1.push(newData[i]);
          }
          if (newData[i].stage_id === 3) {
            projectData2.push(newData[i]);
          }
          if (newData[i].stage_id === 4) {
            projectData3.push(newData[i]);
          }
          if (newData[i].stage_id === 5) {
            projectData4.push(newData[i]);
          }
          if (newData[i].stage_id === 6) {
            projectData5.push(newData[i]);
          }
          if (newData[i].stage_id === 7) {
            projectData6.push(newData[i]);
          }
          if (newData[i].stage_id === 8) {
            projectData7.push(newData[i]);
          }
          if (newData[i].stage_id === 9) {
            projectData8.push(newData[i]);
          }
          finalData = {
            // eslint-disable-next-line
            ["Backlog"]: {
              items: projectData,
            },
            // eslint-disable-next-line
            ["Script"]: {
              items: projectData1,
            },
            // eslint-disable-next-line
            ["Voice"]: {
              items: projectData2,
            },
            // eslint-disable-next-line
            ["Video"]: {
              items: projectData3,
            },
            // eslint-disable-next-line
            ["Comp"]: {
              items: projectData4,
            },
            // eslint-disable-next-line
            ["Comp"]: {
              items: projectData4,
            },
            // eslint-disable-next-line
            ["Ready"]: {
              items: projectData5,
            },
            // eslint-disable-next-line
            ["Published"]: {
              items: projectData6,
            },
            // eslint-disable-next-line
            ["Archived"]: {
              items: projectData7,
            },
            // eslint-disable-next-line
            ["Revision"]: {
              items: projectData8,
            },
          };
        }
        thunkAPI.dispatch(getClientProjectSuccess(finalData));
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getClientProjectFail(err));
    }
  }
);

export const addClientProjectData = createAsyncThunk(
  "/client/addClientProjectData",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(addClientProjectPending());
      const obj = {
        project_name: _.project_name,
        stage_id: "1",
        project_state: "1",
      };
      const user_id = Number(localStorage.getItem("user_id"));
      const res = await api.post(clientAddProjectPath, obj);
      if (res.data) {
        const project_id = res.data.project_id;
        const obj1 = {
          project_id: project_id,
          client_id: user_id,
          channel_id: _.channel_id,
        };
        const obj2 = {
          project_id: project_id,
          channel_id: _.channel_id,
        };
        const res1 = await api.post(clientAddProjectUserPath, obj1);
        const res2 = await api.post(clientAddProjectChannelPath, obj2);
        if (res1.data && res2.data) {
          thunkAPI.dispatch(addClientProjectSuccess(res.data));
        }
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(addClientProjectFail(err));
    }
  }
);

export const searchClientProjects = createAsyncThunk(
  "/client/searchClientProject",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(searchClientProjectPending());
      const res = await api.get(
        clientSearchProjectPath + _.channel_id + "/" + _.regex
      );
      if (res.data) {
        var newData = res.data;
        var finalData = {};
        var projectData = [];
        var projectData1 = [];
        var projectData2 = [];
        var projectData3 = [];
        var projectData4 = [];
        var projectData5 = [];
        var projectData6 = [];
        var projectData7 = [];
        var projectData8 = [];
        for (var i = 0; i < newData.length; i++) {
          if (newData[i].stage_id === 1) {
            projectData.push(newData[i]);
          }
          if (newData[i].stage_id === 2) {
            projectData1.push(newData[i]);
          }
          if (newData[i].stage_id === 3) {
            projectData2.push(newData[i]);
          }
          if (newData[i].stage_id === 4) {
            projectData3.push(newData[i]);
          }
          if (newData[i].stage_id === 5) {
            projectData4.push(newData[i]);
          }
          if (newData[i].stage_id === 6) {
            projectData5.push(newData[i]);
          }
          if (newData[i].stage_id === 7) {
            projectData6.push(newData[i]);
          }
          if (newData[i].stage_id === 8) {
            projectData7.push(newData[i]);
          }
          if (newData[i].stage_id === 9) {
            projectData8.push(newData[i]);
          }
          finalData = {
            // eslint-disable-next-line
            ["Backlog"]: {
              items: projectData,
            },
            // eslint-disable-next-line
            ["Script"]: {
              items: projectData1,
            },
            // eslint-disable-next-line
            ["Voice"]: {
              items: projectData2,
            },
            // eslint-disable-next-line
            ["Video"]: {
              items: projectData3,
            },
            // eslint-disable-next-line
            ["Comp"]: {
              items: projectData4,
            },
            // eslint-disable-next-line
            ["Comp"]: {
              items: projectData4,
            },
            // eslint-disable-next-line
            ["Ready"]: {
              items: projectData5,
            },
            // eslint-disable-next-line
            ["Published"]: {
              items: projectData6,
            },
            // eslint-disable-next-line
            ["Archived"]: {
              items: projectData7,
            },
            // eslint-disable-next-line
            ["Revision"]: {
              items: projectData8,
            },
          };
        }
        thunkAPI.dispatch(searchClientProjectSuccess(finalData));
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(searchClientProjectFail(err));
    }
  }
);

const clientProject = createSlice({
  name: "Client",
  initialState,
  reducers: {
    getClientProjectPending: (state) => {
      state.isLoading = true;
    },
    getClientProjectSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.getClientProject = payload;
      state.getClientProjectFail = "";
    },
    getClientProjectFail: (state, { payload }) => {
      state.isLoading = false;
      state.getClientProject = [];
      state.getClientProjectFail = payload;
    },
    addClientProjectPending: (state) => {
      state.isLoading = true;
    },
    addClientProjectSuccess: (state) => {
      state.isLoading = false;
      state.addClientProject = true;
      state.addClientProjectFail = "";
    },
    addClientProjectFail: (state, { payload }) => {
      state.isLoading = false;
      state.addClientProject = false;
      state.addClientProjectFail = payload;
    },
    searchClientProjectPending: (state) => {
      state.isLoading = true;
    },
    searchClientProjectSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.searchClientProject = payload;
      state.searchClientProjectFail = "";
    },
    searchClientProjectFail: (state, { payload }) => {
      state.isLoading = false;
      state.searchClientProject = false;
      state.searchClientProjectFail = payload;
    },
  },
});

export const {
  getClientProjectPending,
  getClientProjectSuccess,
  getClientProjectFail,
  addClientProjectPending,
  addClientProjectSuccess,
  addClientProjectFail,
  searchClientProjectPending,
  searchClientProjectSuccess,
  searchClientProjectFail,
} = clientProject.actions;
export default clientProject.reducer;
