import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Utils/Api";
import {
  getReportScriptDescPath,
  getReportVoiceDescPath,
  getReportVideoDescPath,
  getReportScriptThumbPath,
  getReportVoiceThumbPath,
  getReportVideoThumbPath,
  getReportScriptVoicePath,
  getReportScriptVideoPath,
  getReportScriptDatePath,
  getReportVoiceDatePath,
  getReportVideoDatePath,
  getReportVoiceVideoPath,
  getReportThumbDescPath,
  getReportScriptVoiceVideoPath,
  getReportScriptVoiceVideoThumPath,
  getReportScriptVoiceVideoDescPath,
  getReportThumbDatePath,
  getReportDescDatePath,
} from "../../../Utils/ApiPath";

const initialState = {
  isLoadingState: false,
  getReportScriptDesc: [],
  getReportScriptDescFail: "",
  getReportVoiceDesc: [],
  getReportVoiceDescFail: "",
  getReportVideoDesc: [],
  getReportVideoDescFail: "",
  getReportScriptThumb: [],
  getReportScriptThumbFail: "",
  getReportVoiceThumb: [],
  getReportVoiceThumbFail: "",
  getReportVideoThumb: [],
  getReportVideoThumbFail: "",
  getReportScriptVoice: [],
  getReportScriptVoiceFail: "",
  getReportScriptVideo: [],
  getReportScriptVideoFail: "",
  getReportScriptDate: [],
  getReportScriptDateFail: "",
  getReportVoiceDate: [],
  getReportVoiceDateFail: "",
  getReportVideoDate: [],
  getReportVideoDateFail: "",
  getReportVoiceVideo: [],
  getReportVoiceVideoFail: "",
  getReportThumbDesc: [],
  getReportThumbDescFail: "",
  getReportScriptVoiceVideo: [],
  getReportScriptVoiceVideoFail: "",
  getReportScriptVoiceVideoThum: [],
  getReportScriptVoiceVideoThumFail: "",
  getReportScriptVoiceVideoDesc: [],
  getReportScriptVoiceVideoDescFail: "",
  getReportThumbDate: [],
  getReportThumbDateFail: "",
  getReportDescDate: [],
  getReportDescDateFail: "",
};

export const searchReportScriptDesc = createAsyncThunk(
  "/user/getScriptDesc",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getReportPending());
      const res = await api.get(
        getReportScriptDescPath + "/" + _.s_id + "/" + _.d_id + "/" + _.state
      );
      if (res) {
        thunkAPI.dispatch(getReportScriptDesc(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getReportScriptDescFail(err));
    }
  }
);

export const searchReportVoiceDesc = createAsyncThunk(
  "/user/getVoiceDesc",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getReportPending());
      const res = await api.get(
        getReportVoiceDescPath + "/" + _.s_id + "/" + _.d_id + "/" + _.state
      );
      if (res) {
        thunkAPI.dispatch(getReportVoiceDesc(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getReportVoiceDescFail(err));
    }
  }
);

export const searchReportVideoDesc = createAsyncThunk(
  "/user/getVideoDesc",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getReportPending());
      const res = await api.get(
        getReportVideoDescPath + "/" + _.s_id + "/" + _.d_id + "/" + _.state
      );
      if (res) {
        thunkAPI.dispatch(getReportVideoDesc(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getReportVideoDescFail(err));
    }
  }
);

export const searchReportScriptThumb = createAsyncThunk(
  "/user/getScriptThumb",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getReportPending());
      const res = await api.get(
        getReportScriptThumbPath + "/" + _.s_id + "/" + _.d_id + "/" + _.state
      );
      if (res) {
        thunkAPI.dispatch(getReportScriptThumb(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getReportScriptThumbFail(err));
    }
  }
);

export const searchReportVoiceThumb = createAsyncThunk(
  "/user/getVoiceThumb",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getReportPending());
      const res = await api.get(
        getReportVoiceThumbPath + "/" + _.s_id + "/" + _.d_id + "/" + _.state
      );
      if (res) {
        thunkAPI.dispatch(getReportVoiceThumb(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getReportVoiceThumbFail(err));
    }
  }
);

export const searchReportVideoThumb = createAsyncThunk(
  "/user/getVideoThumb",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getReportPending());
      const res = await api.get(
        getReportVideoThumbPath + "/" + _.s_id + "/" + _.d_id + "/" + _.state
      );
      if (res) {
        thunkAPI.dispatch(getReportVideoThumb(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getReportVideoThumbFail(err));
    }
  }
);

export const searchReportScriptVoice = createAsyncThunk(
  "/user/getScriptVoice",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getReportPending());
      const res = await api.get(
        getReportScriptVoicePath + "/" + _.s_id + "/" + _.d_id + "/" + _.state
      );
      if (res) {
        thunkAPI.dispatch(getReportScriptVoice(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getReportScriptVoiceFail(err));
    }
  }
);

export const searchReportScriptVideo = createAsyncThunk(
  "/user/getScriptVideo",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getReportPending());
      const res = await api.get(
        getReportScriptVideoPath + "/" + _.s_id + "/" + _.d_id + "/" + _.state
      );
      if (res) {
        thunkAPI.dispatch(getReportScriptVideo(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getReportScriptVideoFail(err));
    }
  }
);

export const searchReportScriptDate = createAsyncThunk(
  "/user/getScriptDate",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getReportPending());
      const res = await api.get(
        getReportScriptDatePath +
          "/" +
          _.s_id +
          "/" +
          _.start_date +
          "/" +
          _.end_date +
          "/" +
          _.state
      );
      if (res) {
        thunkAPI.dispatch(getReportScriptDate(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getReportScriptDateFail(err));
    }
  }
);

export const searchReportVoiceDate = createAsyncThunk(
  "/user/getVoiceDate",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getReportPending());
      const res = await api.get(
        getReportVoiceDatePath +
          "/" +
          _.s_id +
          "/" +
          _.start_date +
          "/" +
          _.end_date +
          "/" +
          _.state
      );
      if (res) {
        thunkAPI.dispatch(getReportVoiceDate(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getReportVoiceDateFail(err));
    }
  }
);

export const searchReportVideoDate = createAsyncThunk(
  "/user/getVideoDate",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getReportPending());
      const res = await api.get(
        getReportVideoDatePath +
          "/" +
          _.s_id +
          "/" +
          _.start_date +
          "/" +
          _.end_date +
          "/" +
          _.state
      );
      if (res) {
        thunkAPI.dispatch(getReportVideoDate(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getReportVideoDateFail(err));
    }
  }
);

export const searchReportVoiceVideo = createAsyncThunk(
  "/user/getVoiceVideo",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getReportPending());
      const res = await api.get(
        getReportVoiceVideoPath + "/" + _.v_id + "/" + _.ve_id + "/" + _.state
      );
      if (res) {
        thunkAPI.dispatch(getReportVoiceVideo(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getReportVoiceVideoFail(err));
    }
  }
);

export const searchReportThumDesc = createAsyncThunk(
  "/user/getThumDesc",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getReportPending());
      const res = await api.get(
        getReportThumbDescPath + "/" + _.de_id + "/" + _.th_id + "/" + _.state
      );
      if (res) {
        thunkAPI.dispatch(getReportThumbDesc(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getReportThumbDescFail(err));
    }
  }
);

export const searchReportScriptVoiceVideo = createAsyncThunk(
  "/user/getScriptVoiceVideo",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getReportPending());
      const res = await api.get(
        getReportScriptVoiceVideoPath +
          "/" +
          _.s_id +
          "/" +
          _.v_id +
          "/" +
          _.ve_id +
          "/" +
          _.state
      );
      if (res) {
        thunkAPI.dispatch(getReportScriptVoiceVideo(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getReportScriptVoiceVideoFail(err));
    }
  }
);

export const searchReportScriptVoiceVideoThum = createAsyncThunk(
  "/user/getScriptVoiceVideoThum",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getReportPending());
      const res = await api.get(
        getReportScriptVoiceVideoThumPath +
          "/" +
          _.state +
          "/" +
          _.s_id +
          "/" +
          _.v_id +
          "/" +
          _.ve_id +
          "/" +
          _.th_id
      );
      if (res) {
        thunkAPI.dispatch(getReportScriptVoiceVideoThum(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getReportScriptVoiceVideoThumFail(err));
    }
  }
);

export const searchReportScriptVoiceVideoDesc = createAsyncThunk(
  "/user/getScriptVoiceVideoDesc",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getReportPending());
      const res = await api.get(
        getReportScriptVoiceVideoDescPath +
          "/" +
          _.state +
          "/" +
          _.s_id +
          "/" +
          _.v_id +
          "/" +
          _.ve_id +
          "/" +
          _.d_id
      );
      if (res) {
        thunkAPI.dispatch(getReportScriptVoiceVideoDesc(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getReportScriptVoiceVideoDescFail(err));
    }
  }
);

export const searchReportThumbDate = createAsyncThunk(
  "/user/getThumbDate",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getReportPending());
      const res = await api.get(
        getReportThumbDatePath +
          "/" +
          _.id +
          "/" +
          _.start_date +
          "/" +
          _.end_date +
          "/" +
          _.state
      );
      if (res) {
        thunkAPI.dispatch(getReportThumbDate(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getReportThumbDateFail(err));
    }
  }
);

export const searchReportDescDate = createAsyncThunk(
    "/user/getDescDate",
    async (_, thunkAPI) => {
      try {
        thunkAPI.dispatch(getReportPending());
        const res = await api.get(
          getReportDescDatePath +
            "/" +
            _.id +
            "/" +
            _.start_date +
            "/" +
            _.end_date +
            "/" +
            _.state 
        );
        if (res) {
          thunkAPI.dispatch(getReportDescDate(res.data));
        }
        return res.data;
      } catch (err) {
        console.log(err);
        thunkAPI.dispatch(getReportDescDateFail(err));
      }
    }
  );

const reportFilterSlice = createSlice({
  name: "reportFilterSlice",
  initialState,
  reducers: {
    getReportPending: (state) => {
      state.isLoadingState = true;
    },
    getReportScriptDesc: (state, { payload }) => {
      state.isLoadingState = false;
      state.getReportScriptDesc = payload;
      state.getReportScriptDescFail = "";
    },
    getReportScriptDescFail: (state, payload) => {
      state.isLoadingState = false;
      state.getReportScriptDesc = [];
      state.getReportScriptDescFail = payload;
    },
    getReportVoiceDesc: (state, { payload }) => {
      state.isLoadingState = false;
      state.getReportVoiceDesc = payload;
      state.getReportVoiceDescFail = "";
    },
    getReportVoiceDescFail: (state, payload) => {
      state.isLoadingState = false;
      state.getReportVoiceDesc = [];
      state.getReportVoiceDescFail = payload;
    },
    getReportVideoDesc: (state, { payload }) => {
      state.isLoadingState = false;
      state.getReportVideoDesc = payload;
      state.getReportVideoDescFail = "";
    },
    getReportVideoDescFail: (state, payload) => {
      state.isLoadingState = false;
      state.getReportVideoDesc = [];
      state.getReportVideoDescFail = payload;
    },
    getReportScriptThumb: (state, { payload }) => {
      state.isLoadingState = false;
      state.getReportScriptThumb = payload;
      state.getReportScriptThumbFail = "";
    },
    getReportScriptThumbFail: (state, payload) => {
      state.isLoadingState = false;
      state.getReportScriptThumb = [];
      state.getReportScriptThumbFail = payload;
    },
    getReportVoiceThumb: (state, { payload }) => {
      state.isLoadingState = false;
      state.getReportVoiceThumb = payload;
      state.getReportVoiceThumbFail = "";
    },
    getReportVoiceThumbFail: (state, payload) => {
      state.isLoadingState = false;
      state.getReportVoiceThumb = [];
      state.getReportVoiceThumbFail = payload;
    },
    getReportVideoThumb: (state, { payload }) => {
      state.isLoadingState = false;
      state.getReportVideoThumb = payload;
      state.getReportVideoThumbFail = "";
    },
    getReportVideoThumbFail: (state, payload) => {
      state.isLoadingState = false;
      state.getReportVideoThumb = [];
      state.getReportVideoThumbFail = payload;
    },
    getReportScriptVoice: (state, { payload }) => {
      state.isLoadingState = false;
      state.getReportScriptVoice = payload;
      state.getReportScriptVoiceFail = "";
    },
    getReportScriptVoiceFail: (state, payload) => {
      state.isLoadingState = false;
      state.getReportScriptVoice = [];
      state.getReportScriptVoiceFail = payload;
    },
    getReportScriptVideo: (state, { payload }) => {
      state.isLoadingState = false;
      state.getReportScriptVideo = payload;
      state.getReportScriptVideoFail = "";
    },
    getReportScriptVideoFail: (state, payload) => {
      state.isLoadingState = false;
      state.getReportScriptVideo = [];
      state.getReportScriptVideoFail = payload;
    },
    getReportScriptDate: (state, { payload }) => {
      state.isLoadingState = false;
      state.getReportScriptDate = payload;
      state.getReportScriptDateFail = "";
    },
    getReportScriptDateFail: (state, payload) => {
      state.isLoadingState = false;
      state.getReportScriptDate = [];
      state.getReportScriptDateFail = payload;
    },
    getReportVoiceDate: (state, { payload }) => {
      state.isLoadingState = false;
      state.getReportVoiceDate = payload;
      state.getReportVoiceDateFail = "";
    },
    getReportVoiceDateFail: (state, payload) => {
      state.isLoadingState = false;
      state.getReportVoiceDate = [];
      state.getReportVoiceDateFail = payload;
    },
    getReportVideoDate: (state, { payload }) => {
      state.isLoadingState = false;
      state.getReportVideoDate = payload;
      state.getReportVideoDateFail = "";
    },
    getReportVideoDateFail: (state, payload) => {
      state.isLoadingState = false;
      state.getReportVideoDate = [];
      state.getReportVideoDateFail = payload;
    },
    getReportVoiceVideo: (state, { payload }) => {
      state.isLoadingState = false;
      state.getReportVoiceVideo = payload;
      state.getReportVoiceVideoFail = "";
    },
    getReportVoiceVideoFail: (state, payload) => {
      state.isLoadingState = false;
      state.getReportVoiceVideo = [];
      state.getReportVoiceVideoFail = payload;
    },
    getReportThumbDesc: (state, { payload }) => {
      state.isLoadingState = false;
      state.getReportThumbDesc = payload;
      state.getReportThumbDescFail = "";
    },
    getReportThumbDescFail: (state, payload) => {
      state.isLoadingState = false;
      state.getReportThumbDesc = [];
      state.getReportThumbDescFail = payload;
    },
    getReportScriptVoiceVideo: (state, { payload }) => {
      state.isLoadingState = false;
      state.getReportScriptVoiceVideo = payload;
      state.getReportScriptVoiceVideoFail = "";
    },
    getReportScriptVoiceVideoFail: (state, payload) => {
      state.isLoadingState = false;
      state.getReportScriptVoiceVideo = [];
      state.getReportScriptVoiceVideoFail = payload;
    },
    getReportScriptVoiceVideoThum: (state, { payload }) => {
      state.isLoadingState = false;
      state.getReportScriptVoiceVideoThum = payload;
      state.getReportScriptVoiceVideoThumFail = "";
    },
    getReportScriptVoiceVideoThumFail: (state, payload) => {
      state.isLoadingState = false;
      state.getReportScriptVoiceVideoThum = [];
      state.getReportScriptVoiceVideoThumFail = payload;
    },
    getReportScriptVoiceVideoDesc: (state, { payload }) => {
      state.isLoadingState = false;
      state.getReportScriptVoiceVideoDesc = payload;
      state.getReportScriptVoiceVideoDescFail = "";
    },
    getReportScriptVoiceVideoDescFail: (state, payload) => {
      state.isLoadingState = false;
      state.getReportScriptVoiceVideoDesc = [];
      state.getReportScriptVoiceVideoDescFail = payload;
    },
    getReportThumbDate: (state, { payload }) => {
      state.isLoadingState = false;
      state.getReportThumbDate = payload;
      state.getReportThumbDateFail = "";
    },
    getReportThumbDateFail: (state, payload) => {
      state.isLoadingState = false;
      state.getReportThumbDate = [];
      state.getReportThumbDateFail = payload;
    },
    getReportDescDate: (state, { payload }) => {
      state.isLoadingState = false;
      state.getReportDescDate = payload;
      state.getReportDescDateFail = "";
    },
    getReportDescDateFail: (state, payload) => {
      state.isLoadingState = false;
      state.getReportDescDate = [];
      state.getReportDescDateFail = payload;
    },
  },
});

export const {
  getReportPending,
  getReportScriptDesc,
  getReportScriptDescFail,
  getReportVoiceDesc,
  getReportVoiceDescFail,
  getReportVideoDesc,
  getReportVideoDescFail,
  getReportScriptThumb,
  getReportScriptThumbFail,
  getReportVoiceThumb,
  getReportVoiceThumbFail,
  getReportVideoThumb,
  getReportVideoThumbFail,
  getReportScriptVoice,
  getReportScriptVoiceFail,
  getReportScriptVideo,
  getReportScriptVideoFail,
  getReportScriptDate,
  getReportScriptDateFail,
  getReportVoiceDate,
  getReportVoiceDateFail,
  getReportVideoDate,
  getReportVideoDateFail,
  getReportVoiceVideo,
  getReportVoiceVideoFail,
  getReportThumbDesc,
  getReportThumbDescFail,
  getReportScriptVoiceVideo,
  getReportScriptVoiceVideoFail,
  getReportScriptVoiceVideoThum,
  getReportScriptVoiceVideoThumFail,
  getReportScriptVoiceVideoDesc,
  getReportScriptVoiceVideoDescFail,
  getReportThumbDate,
  getReportThumbDateFail,
  getReportDescDate,
  getReportDescDateFail,
} = reportFilterSlice.actions;
export default reportFilterSlice.reducer;
