import React, { useState, memo } from "react";
import ProjectCard from "./ProjectCard";
import { Card } from "react-bootstrap";
import { Droppable } from "react-beautiful-dnd";
import { UilPlus } from "@iconscout/react-unicons";
import { UilTimes } from "@iconscout/react-unicons";
import { useLocation } from "react-router-dom";

function Column({ columnData, id, colors, addProject, state}) {
 // console.log(columnData)
  // console.log(columnData)
  const [showCard, setShowCard] = useState(false);
  const [addProjectName, setAddProjectName] = useState("");
  // const [count, newCount] = useState(columnData.count ? columnData.data : {});
  const location = useLocation();
  const onClick = () => {
    setShowCard(true);
  };
  const onHide = () => {
    setShowCard(false);
  };
  const onChangeHandler = (project_name) => {
    setAddProjectName(project_name);
  };
  const handleSave = () => {
    addProject(addProjectName);
    onHide();
    setAddProjectName("");
  };

  return (
    <>
      <div className="column">
        <div className="column-header" style={{ position: "sticky" }}>
          <Card
            className="card-header mb-4"
            style={{ borderTop: `2px solid ${colors.color}` }}
          >
            <Card.Text>
              {columnData.name}
              <span className="widget ms-3">
                {/* {columnData.count ? columnData.count[0].count : 0} */}
                {columnData.items.length}
              </span>
            </Card.Text>
          </Card>
        </div>
        <div className="column-content">
          <Droppable droppableId={id} key={id}>
            {(provided) => (
              <div
                ref={provided.innerRef}
                className="column-container"
                style={{ minHeight: "400px" }}
                {...provided.droppableProps}
              >
                {columnData.items.map((item, index) => {
                  return (
                    <ProjectCard
                      project={item}
                      key={item.id}
                      index={index}
                      color={colors.color}
                      stage={columnData.name}
                      state={state}
                    />
                  );
                })}

                {provided.placeholder}
                {columnData.name === "Backlog" ? (
                  <>
                    <div className="add-project">
                      {showCard ? (
                        ""
                      ) : (
                        <div className="add-btn">
                          <button
                            type="button"
                            className="project-button mt-2"
                            onClick={onClick}
                          >
                            <UilPlus size="13" /> New Project
                          </button>
                        </div>
                      )}
                      {showCard && location.state.state ==="activeTab" ? (
                        <>
                          <Card className="mt-2">
                            <UilTimes
                              size="13"
                              onClick={onHide}
                              className="mt-2 ms-2"
                              color="#bcc0c7"
                              style={{ cursor: "pointer" }}
                            />
                            <input
                              className="input-box"
                              type="text"
                              placeholder="Enter project title"
                              style={{ marginBottom: "20px" }}
                              value={addProjectName}
                              onChange={(e) => onChangeHandler(e.target.value)}
                            />

                            <div className="d-flex justify-content-end mb-2 me-2">
                              <button
                                className="save-btn btn"
                                type="button"
                                onClick={() => handleSave()}
                              >
                                Save
                              </button>
                            </div>
                          </Card>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </>
                ) : null}
              </div>
            )}
          </Droppable>
        </div>
      </div>
    </>
  );
}

export default memo(Column);
