import {
  forgotPasswordPending,
  forgotPasswordSuccess,
  forgotPasswordFail,
  clearForgotPasword,
} from "./forgotPasswordSlice";
import api from "../../../Utils/Api";
import { forgotPasswordPath } from "../../../Utils/ApiPath";

export const setforgotPassword = (userData) => async (dispatch) => {
  const formData = {
    email: userData.email,
  };
  try {
    dispatch(forgotPasswordPending());
    //call api
    const res = await api.post(forgotPasswordPath, formData);
    if (res) {
      console.log(res.data);
      dispatch(forgotPasswordSuccess(res.data.token));
      setTimeout(() => {
        dispatch(clearForgotPasword());
      }, 5000);
      return;
    }
  } catch (err) {
    console.log(err);
    dispatch(forgotPasswordFail(err));
    setTimeout(() => {
      dispatch(clearForgotPasword());
    }, 1000);
    return;
  }
};
