//user
export const loginPath = "/user/login";
export const forgotPasswordPath = "/user/forgotPassword";
export const setPasswordPath = "/password/updatePassword";
export const getUserPath = "/dropdown/getUser";
export const addUserPath = "/users/addUser";
export const activeUserPath = "/users/userDetailsActive";
export const inactiveUserPath = "/users/userDetailsInactive";
export const deleteUserPath = "/users/deleteUser";
export const editUserPath = "/users/updateUsers";
export const reinviteUserPath = "/users/reinvite";
export const bulkInactivateUsersPath = "/users/bulkInactivateUsers";

//role
export const getRolePath = "/role/getRole";
export const addRolePath = "/users/userRole";
export const updateRolePath = "/users/updateUserRole";
export const searchUserRolePath = "/users/searchUserRole";
export const searchUserNamePath = "/users/searchUserName";
export const searchUserEmailPath = "/users/searchUserEmail";

//dashboard
export const getCountPath = "/count/getCount";
export const dueProjectPath = "/dashboard/getDueProject";

//channel
export const activeChannel = "/channel/getChannelActive";
export const inactiveChannel = "/channel/getChannelInactive";
export const searchActiveChannel = "/channel/searchChannelClientName";
export const addChannelPath = "/channel/addChannel";
export const addChannelClientPath = "/channel/addChannelUser";
export const addChannelScriptPath = "/channel/addChannelScript";
export const addChannelVoicePath = "/channel/addChannelVoice";
export const addChannelVideoPath = "/channel/addChannelVideo";
export const addChannelPmPath = "/channel/addChannelManager";
export const addChannelThumbPath = "/channel/addChannelThumbnail";
export const addChannelDescPath = "/channel/addChannelDescriptionWriter";
export const addChannelQcPath = "/channel/addChannelQualityChecker";
export const addChannelVideoUploaderPath = "/channel/addChannelVideoUploader";
export const getChannelUserPath = "/channel/updateChannelUsers";
export const updateChannelPath = "/channel/updateChannel";
export const deleteScriptPath = "/channel/deleteScript";
export const deleteVoicePath = "/channel/deleteVoice";
export const deleteVideoPath = "/channel/deleteVideo";
export const deleteUpdateClientPath = "/channel/deleteUpdateUser";
export const updateScriptPath = "/channel/addChannelScript";
export const updateVoicePath = "/channel/addChannelVoice";
export const updateVideoPath = "/channel/addChannelVideo";
export const updatePmPath = "/channel/addChannelManager";
export const updateThumbPath = "/channel/addChannelThumbnail";
export const updateDescPath = "/channel/addChannelDescriptionWriter";
export const updateQcPath = "/channel/addChannelQualityChecker";
export const updateVuPath = "/channel/addChannelVideoUploader";
export const deleteManagerPath = "/channel/deleteManager";
export const deleteThumbPath = "/channel/deleteTHumbnail";
export const deleteDescPath = "/channel/deleteDescriptionWriter";
export const deleteQcPath = "/channel/deleteQualityChecker";
export const deleteVuPath = "/channel/deleteVideoUploader";
export const deleteChannelPath = "/channel/deleteChannel/";
export const userRestrictPath = "/dropdown/getUserRestrict/";
export const channelUserRestrictPath = "/channel/getChannelRestrict/";
export const deleteScriptRestrictPath = "/channel/deleteChannelScriptRestrict/";
export const deleteVoiceRestrictPath = "/channel/deleteChannelVoiceRestrict/";
export const deleteVideoRestrictPath = "/channel/deleteChannelVideoRestrict/";
export const addScriptRestrictPath = "/channel/addChannelScriptRestrict";
export const addVoiceRestrictPath = "/channel/addChannelVoiceRestrict";
export const addVideoRestrictPath = "/channel/addChannelVideoRestrict";
export const updatePublishDatePath = "/channel/updateChannelPublishedDate/";
export const searchChannelByClientPath = "/channel/searchChannelClientName/";
export const searchChannelPath = "/channel/searchChannel/";
export const updateChannelStatusPath = "/channel/updateChannelStatus/";
export const updateChannelModePath = "/channel/updateChannelMode/";
export const bulkInactivateChannelPath = "/channel//bulkInactivateChannels/";

//team report
export const getReportPath = "/admin/getUsers";
export const getReportScriptPath = "/admin/getUsersScript";
export const getReportVoicePath = "/admin/getUsersVoice";
export const getReportVideoPath = "/admin/getUsersVideo";
export const getReportThumbnailPath = "/admin/getUsersThumbnail";
export const getReportDescPath = "/admin/getUsersDescription";
export const getReportDatePath = "/admin/getUsersStateDate";
export const getReportScriptDescPath = "/admin/getUsersScriptDescription";
export const getReportVoiceDescPath = "/admin/getUsersVoiceDescription";
export const getReportVideoDescPath = "/admin/getUsersVideoDescription";
export const getReportScriptThumbPath = "/admin/getUsersScriptThumbnail";
export const getReportVoiceThumbPath = "/admin/getUsersVoiceThumbnail";
export const getReportVideoThumbPath = "/admin/getUsersVideoThumbnail";
export const getReportScriptVoicePath = "/admin/getUsersScriptVoice";
export const getReportScriptVideoPath = "/admin/getUsersScriptVideo";
export const getReportScriptDatePath = "/admin/getUsersScriptDate";
export const getReportVoiceDatePath = "/admin/getUsersVoiceDate";
export const getReportVideoDatePath = "/admin/getUsersVideoDate";
export const getReportVoiceVideoPath = "/admin/getUsersVoiceVideo";
export const getReportThumbDescPath = "/admin/getUsersThumbnailDescription";
export const getReportScriptVoiceVideoPath = "/admin/getUsersScriptVoiceVideo";
export const getReportScriptVoiceVideoThumPath =
  "/admin/getUsersScriptVoiceVideoThumbnail";
export const getReportScriptVoiceVideoDescPath =
  "/admin/getUsersScriptVoiceVideoDescription";
export const getReportThumbDatePath = "/admin/getUsersThumbnailDate";
export const getReportDescDatePath = "/admin/getUsersDescriptionDate";
export const updatePaidPath = "/admin/updatePaid/";

//project
export const getProjectPath = "/channel/getProject/";
export const getProjectCountPath = "/count/getCountProjectStageChannel/";
export const updateProjectPhasePath = "/project/updatePhase/";
export const searchProjectPath = "/channel/getProjectsByChannelSearch/";
export const addProjectPath = "/sub/addProject";
export const addProjectChannelPath = "/sub/addProjectChannel";
export const addProjectClientPath = "/client/addProjectUser/";
export const getProjectClientPath = "/project/getClient/";
export const getUserOfChannelPath = "/dropdown/getUserEditDropdown/";
export const getUserOfProjectPath = "/sub/getChannelUser/";
export const getProjectUsersPath = "/project/getProjectUsers/";
export const getVideoUserPath = "/dropdown/getVideo/";
export const addRevisionPath = "/revision/addRevision";
export const getRevisionPath = "/revision/getRevision/";
export const deleteRevisionPath = "/revision/deleteRevision/";
export const addRevisionListPath = "/revision/addRevisionList/";
export const getRevisionListPath = "/revision/getRevisionList/";
export const deleteRevisionListPath = "/revision/deleteRevisionList/";
export const updateProjectPath = "/project/updateProject/";
export const updateProjectStatePath = "/project/updateProjectState/";
export const deleteProjectScriptPath = "/sub/deleteScript/";
export const updateProjectScriptPath = "/sub/updateProjectScript/";
export const deleteProjectVoicePath = "/sub/deleteVoice/";
export const updateProjectVoicePath = "/sub/updateProjectVoice/";
export const deleteProjectVideoPath = "/sub/deleteVideo/";
export const updateProjectVideoPath = "/sub/updateProjectVideo/";
export const deleteProjectPath = "/project/deleteProject/";
export const updateThumbStatePath = "/admin/markThumbnailCompleted/";
export const updateDescStatePath = "/admin/markDescriptionCompleted/";
export const getChannelNamePath = "/sub/getProjectData/";
export const getChannelDescPath = "/channel/getChannelDescription/";
export const updateProjectStatusPath = "/sub/updateProjectStatus/";
export const searchArchiveProjectPath = "/channel/getArchiveByProjectSearch/";

//comment
export const getCommentPath = "/comment/getComment/";
export const addCommentPath = "/comment/addComment/";
export const editCommentPath = "/comment/editComment/";
export const deleteCommentPath = "/comment/deleteComment/";

//subscription
export const getSubscriptionPath = '/subscription/getSubscription/';
export const getPaymentsPath = '/subscription/getPayments/';

//chat
export const postChatPath = "chat/postChat";
export const getChatsPath = "/chat/getChat";
export const editChatPath = "/chat/editChat/";
export const deleteChatPath = "/chat/deleteChat/";
export const getReadCouthPath = "/chat/getReadCount/";
export const updateReadStatusPath = "/chat/updateReadStatus/";