import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../Utils/Api";
import {
  getRevisionPath,
  getRevisionListPath,
  updateRevisionDataPath,
  updateRevisionStatePath,
} from "../../Utils/ApiPathResources";

const initialState = {
  isLoading: false,
  getRevision: [],
  getRevisionFail: "",
  getRevisionList: [],
  getRevisionListFail: "",
  updateRevisionData: false,
  updateRevisionDataFail: "",
  updateRevisionStateData: false,
  updateRevisionStateDataFail: "",
};

export const getRevisionData = createAsyncThunk(
  "/resources/getRevisionData",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getRevisionPending());
      const role_id = Number(localStorage.getItem("role_id"));
      const user_id = Number(localStorage.getItem("user_id"));
      if (role_id === 5) {
        const res = await api.get(getRevisionPath + user_id);
        if (res.data) {
          thunkAPI.dispatch(getRevisionSuccess(res.data));
        }
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getRevisionFail(err));
    }
  }
);

export const getRevisionListData = createAsyncThunk(
  "/resources/getRevisionListData",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getRevisionListPending());
      const role_id = Number(localStorage.getItem("role_id"));
      if (role_id === 5) {
        const res = await api.get(getRevisionListPath + _);
        if (res.data) {
          thunkAPI.dispatch(getRevisionListSuccess(res.data));
        }
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getRevisionListFail(err));
    }
  }
);

export const updateRevisionDataById = createAsyncThunk(
  "/resources/updateRevisionDataById",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(updateRevisionDataPending());
      const role_id = Number(localStorage.getItem("role_id"));
      if (role_id === 5) {
        const res = await api.put(updateRevisionDataPath + _.id, _);
        if (res.data) {
          thunkAPI.dispatch(updateRevisionDataSuccess(res.data));
        }
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(updateRevisionDataFail(err));
    }
  }
);

export const updateRevisionStateData = createAsyncThunk(
  "/resources/updateRevisionStateData",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(updateRevisionStateDataPending());
      const role_id = Number(localStorage.getItem("role_id"));
      const user_id = Number(localStorage.getItem("user_id"));
      if (role_id === 5) {
        const res = await api.put(
          updateRevisionStatePath + _.project_id + "/" + user_id,
          _
        );
        if (res.data) {
          thunkAPI.dispatch(updateRevisionStateDataSuccess(res.data));
          setTimeout(() => {
            thunkAPI.dispatch(clearUpdateState());
          }, 3000);
        }
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(updateRevisionStateDataFail(err.response.data.error));
      setTimeout(() => {
        thunkAPI.dispatch(clearUpdateState());
      }, 3000);
    }
  }
);

const L1RRevision = createSlice({
  name: "L1R",
  initialState,
  reducers: {
    getRevisionPending: (state) => {
      state.isLoading = true;
    },
    getRevisionSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.getRevision = payload;
      state.getRevisionFail = "";
    },
    getRevisionFail: (state, { payload }) => {
      state.isLoading = false;
      state.getRevision = [];
      state.getRevisionFail = payload;
    },
    getRevisionListPending: (state) => {
      state.isLoading = true;
    },
    getRevisionListSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.getRevisionList = payload;
      state.getRevisionListFail = "";
    },
    getRevisionListFail: (state, { payload }) => {
      state.isLoading = false;
      state.getRevisionList = [];
      state.getRevisionListFail = payload;
    },
    updateRevisionDataPending: (state) => {
      state.isLoading = true;
    },
    updateRevisionDataSuccess: (state) => {
      state.isLoading = false;
      state.updateRevisionData = true;
      state.updateRevisionDataFail = "";
    },
    updateRevisionDataFail: (state, { payload }) => {
      state.isLoading = false;
      state.updateRevisionData = false;
      state.updateRevisionDataFail = payload;
    },
    updateRevisionStateDataPending: (state) => {
      state.isLoading = true;
    },
    updateRevisionStateDataSuccess: (state) => {
      state.isLoading = false;
      state.updateRevisionStateData = true;
      state.updateRevisionStateDataFail = "";
    },
    updateRevisionStateDataFail: (state, { payload }) => {
      state.isLoading = false;
      state.updateRevisionStateData = false;
      state.updateRevisionStateDataFail = payload;
    },
    clearUpdateState: (state) => {
      state.isLoading = false;
      state.updateRevisionStateData = false;
      state.updateRevisionStateDataFail = "";
    },
  },
});

export const {
  getRevisionPending,
  getRevisionSuccess,
  getRevisionFail,
  getRevisionListPending,
  getRevisionListSuccess,
  getRevisionListFail,
  updateRevisionDataPending,
  updateRevisionDataSuccess,
  updateRevisionDataFail,
  updateRevisionStateDataPending,
  updateRevisionStateDataSuccess,
  updateRevisionStateDataFail,
  clearUpdateState,
} = L1RRevision.actions;
export default L1RRevision.reducer;
