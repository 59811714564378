import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../Utils/Api";
import {
  avaialableVideoProjectPath,
  avaialableVoiceProjectPath,
  availableScriptProjectPath,
  avaialableThumbProjectPath,
  avaialableDescProjectPath,
  takeScriptProjectPath,
  takeVideoProjectPath,
  takeVoiceProjectPath,
  takeThumbProjectPath,
  takeDescProjectPath,
} from "../../Utils/ApiPathResources";

const initialState = {
  isLoading: false,
  availableProjects: [],
  availableProjectFail: "",
  takeProjectSuccess: false,
  takeProjectFail: "",
};

export const getAvailableProjects = createAsyncThunk(
  "/resources/getAvailableProject",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(availableProjectPending());
      var role_id = Number(localStorage.getItem("role_id"));
      var user_id = Number(localStorage.getItem("user_id"));
      if (role_id === 3) {
        const res = await api.get(availableScriptProjectPath + user_id);
        if (res.data) {
          thunkAPI.dispatch(availableProjectSuccess(res.data));
        }
      }
      if (role_id === 4) {
        const res = await api.get(avaialableVoiceProjectPath + user_id);
        if (res.data) {
          thunkAPI.dispatch(availableProjectSuccess(res.data));
        }
      }
      if (role_id === 5) {
        const res = await api.get(avaialableVideoProjectPath + user_id);
        if (res.data) {
          thunkAPI.dispatch(availableProjectSuccess(res.data));
        }
      }
      if (role_id === 7) {
        const res = await api.get(avaialableThumbProjectPath + user_id);
        if (res.data) {
          thunkAPI.dispatch(availableProjectSuccess(res.data));
        }
      }
      if (role_id === 8) {
        const res = await api.get(avaialableDescProjectPath + user_id);
        if (res.data) {
          thunkAPI.dispatch(availableProjectSuccess(res.data));
        }
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(availableProjectFail(err));
    }
  }
);

export const takeProject = createAsyncThunk(
  "/resources/takeProject",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(takeProjectPending());
      var role_id = Number(localStorage.getItem("role_id"));
      var user_id = Number(localStorage.getItem("user_id"));
      if (role_id === 3) {
        _.assigned_status = 1;
        _.channel_script_id = user_id;
        const res = await api.post(takeScriptProjectPath, _);
        console.log(res)
        if (res.data) {
          thunkAPI.dispatch(takeProjectSuccess(res.data));
          setTimeout(() => {
            thunkAPI.dispatch(clearTakeTaskState());
          }, 3000);
        }
      }
      if (role_id === 4) {
        _.assigned_status = 1;
        _.channel_voice_id = user_id;
        const res = await api.post(takeVoiceProjectPath, _);
        if (res.data) {
          thunkAPI.dispatch(takeProjectSuccess(res.data));
          setTimeout(() => {
            thunkAPI.dispatch(clearTakeTaskState());
          }, 3000);
        }
      }
      if (role_id === 5) {
        _.assigned_status = 1;
        _.channel_video_id = user_id;
        const res = await api.post(takeVideoProjectPath, _);
        if (res.data) {
          thunkAPI.dispatch(takeProjectSuccess(res.data));
          setTimeout(() => {
            thunkAPI.dispatch(clearTakeTaskState());
          }, 3000);
        }
      }
      if (role_id === 7) {
        const obj = {
          project_id: _,
          state: 0,
          user_id: user_id,
        };
        const res = await api.post(takeThumbProjectPath, obj);
        if (res.data) {
          thunkAPI.dispatch(takeProjectSuccess(res.data));
          setTimeout(() => {
            thunkAPI.dispatch(clearTakeTaskState());
          }, 3000);
        }
      }
      if (role_id === 8) {
        const obj = {
          project_id: _,
          state: 0,
          user_id: user_id,
        };
        const res = await api.post(takeDescProjectPath, obj);
        if (res.data) {
          thunkAPI.dispatch(takeProjectSuccess(res.data));
          setTimeout(() => {
            thunkAPI.dispatch(clearTakeTaskState());
          }, 3000);
        }
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(takeProjectFail(err.response.data.error));
      setTimeout(() => {
        thunkAPI.dispatch(clearTakeTaskState());
      }, 3000);
    }
  }
);

const L1RAvailableProject = createSlice({
  name: "L1R",
  initialState,
  reducers: {
    availableProjectPending: (state) => {
      state.isLoading = true;
    },
    availableProjectSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.availableProjects = payload;
      state.availableProjectFail = "";
    },
    availableProjectFail: (state, { payload }) => {
      state.isLoading = false;
      state.availableProjects = [];
      state.availableProjectFail = payload;
    },
    takeProjectPending: (state) => {
      state.isLoading = true;
    },
    takeProjectSuccess: (state) => {
      state.isLoading = false;
      state.takeProjectSuccess = true;
      state.takeProjectFail = "";
    },
    takeProjectFail: (state, { payload }) => {
      state.isLoading = false;
      state.takeProjectSuccess = false;
      state.takeProjectFail = payload;
    },
    clearTakeTaskState: (state) => {
      state.isLoading = false;
      state.takeProjectSuccess = false;
      state.takeProjectFail = "";
    },
  },
});

export const {
  CheckUserStatusPending,
  CheckUserStatusSuccess,
  CheckUserStatusFail,
  getCountPending,
  getCountSuccess,
  getCountFail,
  availableProjectPending,
  availableProjectSuccess,
  availableProjectFail,
  takeProjectPending,
  takeProjectSuccess,
  takeProjectFail,
  clearTakeTaskState,
} = L1RAvailableProject.actions;
export default L1RAvailableProject.reducer;
