import React from "react";

function LoginLayout() {
  return (
    <>
      <div className="login-layout">
        <div className="login-main-bg"></div>
      </div>
    </>
  );
}

export default LoginLayout;
