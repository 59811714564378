import React, { useState, useEffect } from "react";
import Comments from "./Comments";
import UploadModal from "./uploadModal";
import {
  UilCommentDots,
  UilCloudUpload,
  UilEye,
  UilEllipsisH,
} from "@iconscout/react-unicons";
import ViewRevisions from "./ViewRevisions";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { getRevisionData } from "../../Feature/LevelOneResource/RevisionSlice";
import { dateFormat } from "../../Common/dateFormat";
import { AlertToast } from "../../Common/Toast";
import ReactPaginate from "react-paginate";

const MyRevisions = () => {
  const dispatch = useDispatch();
  const { getRevision, updateRevisionStateData, updateRevisionStateDataFail } =
    useSelector((state) => state.L1RRevision);
  const [data, setData] = useState([]);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [recordsPerPage, setRecordsPerPage] = useState(10);
  // const indexOfLastRecord = currentPage * recordsPerPage;
  // const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  // const currentRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);
  // const nPages = Math.ceil(data.length / recordsPerPage);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + Number(itemsPerPage);
  const currentItems = data.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(data.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    setItemOffset(newOffset);
  };

  const [projectId, setProjectId] = useState(0);
  const [alert, setAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");

  //useEffects
  useEffect(() => {
    dispatch(getRevisionData());
    setItemOffset(0);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setData(getRevision);
  }, [getRevision]);

  useEffect(() => {
    if (updateRevisionStateData) {
      setAlert(true);
      setAlertMsg("Project marked as uploaded successfully");
      dispatch(getRevisionData());
    }
    // eslint-disable-next-line
  }, [updateRevisionStateData]);

  useEffect(() => {
    if (updateRevisionStateDataFail) {
      setAlert(true);
      setAlertMsg(updateRevisionStateDataFail);
    }
  }, [updateRevisionStateDataFail]);

  const [show, setShow] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [showRevisions, setShowRevisions] = useState(false);
  const toggle = () => setShow(!show);
  const handleShow = (i) => {
    setShow(true);
    setProjectId(i);
  };
  const toggleComment = () => setShowComments(!showComments);
  const handleComment = (i) => {
    setShowComments(true);
    setProjectId(i);
  };
  const toggleRevision = () => setShowRevisions(!showRevisions);
  const handleRevision = (i) => {
    setShowRevisions(true);
    setProjectId(i);
  };
  const closeToast = () => {
    setAlert(false);
  };
  return (
    <>
      <AlertToast
        show={alert}
        alert={updateRevisionStateData ? "success" : "fail"}
        msg={alertMsg}
        close={closeToast}
      />
      <div className="my-revisions">
        <div className="content-title">My Revisions</div>
        <div className="d-flex justify-content-end select-form-control">
          Show
          <select
            className="select-enteries ms-2 me-2 mb-2"
            value={itemsPerPage}
            onChange={(e) => setItemsPerPage(e.target.value)}
          >
            <option value="10">10</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          Entries
        </div>
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>#</th>
                <th>Project</th>
                <th>Assigned Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.project_name}</td>
                  <td>{dateFormat(item.created_at)}</td>
                  <td>
                    <Dropdown drop="start">
                      <Dropdown.Toggle id="dropdown-basic">
                        <UilEllipsisH color="#7c828d" size="18" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          style={{ display: "flex", alignItems: "center" }}
                          onClick={() => handleRevision(item.project_id)}
                        >
                          <UilEye size="18" color="#7c828d" className="me-2" />{" "}
                          Feedback
                        </Dropdown.Item>
                        <Dropdown.Item
                          style={{ display: "flex", alignItems: "center" }}
                          onClick={() => handleShow(item.project_id)}
                        >
                          <UilCloudUpload
                            size="20"
                            color="#7c828d"
                            className="me-2"
                          />
                          Upload
                        </Dropdown.Item>
                        <Dropdown.Item
                          style={{ display: "flex", alignItems: "center" }}
                          onClick={() => handleComment(item.project_id)}
                        >
                          <UilCommentDots
                            size="18"
                            color="#7c828d"
                            className="me-2"
                          />
                          Comment
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <ReactPaginate
          previousLabel={"<<"}
          nextLabel={">>"}
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination justify-content-center"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
        />
        <UploadModal
          show={show}
          toggle={toggle}
          label={"revision"}
          id={projectId}
        />
        <Comments show={showComments} toggle={toggleComment} id={projectId} />
        <ViewRevisions
          show={showRevisions}
          toggle={toggleRevision}
          id={projectId}
        />
      </div>
    </>
  );
};

export default MyRevisions;
