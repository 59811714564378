import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const useAuth = () => {
  const token = localStorage.getItem("token");
//   console.log("user",token);
  if (token) {
    return true;
  } else {
    return false;
  }
};

const  ProtectedRoute=({allowedRoles}) =>{
    const auth=useAuth()
    const role_id = localStorage.getItem("role_id");

    // console.log("auth",auth,allowedRoles, role_id, (auth && allowedRoles.includes(Number(role_id))))
  
    // return auth?<Outlet/>: <Navigate to="/"/>
    return (auth && allowedRoles.includes(Number(role_id))) ? <Outlet/>: <Navigate to="/"/>
  }
  
  export default ProtectedRoute;
