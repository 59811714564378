import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../Utils/Api";
import { clientDashboardCountPath } from "../../Utils/ApiPathResources";

const initialState = {
  isLoading: false,
  getClientDashboardCount: {},
  getClientDashboardCountFail: "",
};

export const getClientCount = createAsyncThunk(
  "/client/getClientCount",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getCountClientPending());
      const res = await api.get(clientDashboardCountPath + _);
      if (res.data) {
        thunkAPI.dispatch(getCountClientSuccess(res.data));
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getCountClientFail(err));
    }
  }
);

const clientDashboard = createSlice({
  name: "Client",
  initialState,
  reducers: {
    getCountClientPending: (state) => {
      state.isLoading = true;
    },
    getCountClientSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.getClientDashboardCount = payload;
      state.getClientDashboardCountFail = "";
    },
    getCountClientFail: (state, { payload }) => {
      state.isLoading = false;
      state.getClientDashboardCount = {};
      state.getClientDashboardCountFail = payload;
    },
  },
});

export const {
  getCountClientPending,
  getCountClientSuccess,
  getCountClientFail,
} = clientDashboard.actions;
export default clientDashboard.reducer;
