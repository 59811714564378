import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Tab, Tabs } from "react-bootstrap";
import TeamReportTable from "./TeamReportTable";
import { useDispatch, useSelector } from "react-redux";
import {
  getUser,
  searchReportDate,
  searchReportDesc,
  searchReportScript,
  searchReportThumbnail,
  searchReportVideo,
  searchReportVoice,
} from "../../../Feature/Admin/TeamReport/reportSlice";
import { fetchUser } from "../../../Feature/Admin/Dropdown/getUserSlice";
import Loaders from "../../../Components/Loaders/Loaders";
import {
  searchReportDescDate,
  searchReportScriptDate,
  searchReportScriptDesc,
  searchReportScriptThumb,
  searchReportScriptVideo,
  searchReportScriptVoice,
  searchReportScriptVoiceVideo,
  searchReportScriptVoiceVideoDesc,
  searchReportScriptVoiceVideoThum,
  searchReportThumbDate,
  searchReportThumDesc,
  searchReportVideoDate,
  searchReportVideoDesc,
  searchReportVideoThumb,
  searchReportVoiceDate,
  searchReportVoiceDesc,
  searchReportVoiceThumb,
  searchReportVoiceVideo,
} from "../../../Feature/Admin/TeamReport/reportFilterSlice";
import ReactPaginate from "react-paginate";

function TeamReport() {
  const [key, setKey] = useState("0");
  const [data, setData] = useState([]);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [recordsPerPage, setRecordsPerPage] = useState(10);
  // const indexOfLastRecord = currentPage * recordsPerPage;
  // const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  // const currentRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);
  // const nPages = Math.ceil(data.length / recordsPerPage);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + Number(itemsPerPage);
  const currentItems = data.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(data.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    setItemOffset(newOffset);
  };

  const dispatch = useDispatch();

  const {
    getReportSuccess,
    getReportPending,
    getReportScript,
    getReportVoice,
    getReportVideo,
    getReportThumbnail,
    getReportDesc,
    getReportDate,
  } = useSelector((state) => state.report);
  const {
    isLoadingState,
    getReportScriptDesc,
    getReportVoiceDesc,
    getReportVideoDesc,
    getReportScriptThumb,
    getReportVoiceThumb,
    getReportVideoThumb,
    getReportScriptVoice,
    getReportScriptVideo,
    getReportScriptDate,
    getReportVoiceDate,
    getReportVideoDate,
    getReportVoiceVideo,
    getReportThumbDesc,
    getReportScriptVoiceVideo,
    getReportScriptVoiceVideoThum,
    getReportScriptVoiceVideoDesc,
    getReportThumbDate,
    getReportDescDate,
  } = useSelector((state) => state.reportFilter);
  const { getUserSuccess } = useSelector((state) => state.dropdown);
  const { updatePaidSuccess } = useSelector((state) => state.reportPaid);

  const [selectedScript, setSelectedScript] = useState(null);
  const [selectedVoice, setSelectedVoice] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [selectedThumbnail, setSelectedThumbnail] = useState(null);
  const [selectedDesc, setSelectedDesc] = useState(null);
  const [selectedStart, setSelectedStart] = useState("");
  const [selectedEnd, setSelectedEnd] = useState("");

  //Get Data(Start):
  useEffect(() => {
    if (key === "0") {
      dispatch(getUser(Number(key)));
      setItemOffset(0);
    } else if (key === "1") {
      dispatch(getUser(Number(key)));
      setItemOffset(0);
    } else if (key === "2") {
      dispatch(getUser(Number(key)));
      setItemOffset(0);
    }
    // eslint-disable-next-line
  }, [key]);

  useEffect(() => {
    if (getReportSuccess) {
      setData(getReportSuccess);
    }
  }, [getReportSuccess]);

  useEffect(() => {
    dispatch(fetchUser());
    // eslint-disable-next-line
  }, []);
  //Get Data(End):

  //Filters(Start):
  const scriptHandleChange = (selected) => {
    setSelectedScript(selected.user_id);
  };
  const voiceHandleChange = (selected) => {
    setSelectedVoice(selected.user_id);
  };
  const videoHandleChange = (selected) => {
    setSelectedVideo(selected.user_id);
  };
  const thumbnailHandleChange = (selected) => {
    setSelectedThumbnail(selected.user_id);
  };
  const descHandleChange = (selected) => {
    setSelectedDesc(selected.user_id);
  };
  const startHandleChange = (selected) => {
    setSelectedStart(selected);
  };
  const endHandleChange = (selected) => {
    setSelectedEnd(selected);
  };

  const getData = () => {
    if (
      selectedScript &&
      !selectedVoice &&
      !selectedVideo &&
      !selectedThumbnail &&
      !selectedDesc &&
      !selectedStart &&
      !selectedEnd
    ) {
      const data = {
        id: selectedScript,
        state: key,
      };
      dispatch(searchReportScript(data));
    }
    if (
      !selectedScript &&
      selectedVoice &&
      !selectedVideo &&
      !selectedThumbnail &&
      !selectedDesc &&
      !selectedStart &&
      !selectedEnd
    ) {
      const data = {
        id: selectedVoice,
        state: key,
      };
      dispatch(searchReportVoice(data));
    }
    if (
      !selectedScript &&
      !selectedVoice &&
      selectedVideo &&
      !selectedThumbnail &&
      !selectedDesc &&
      !selectedStart &&
      !selectedEnd
    ) {
      const data = {
        id: selectedVideo,
        state: key,
      };
      dispatch(searchReportVideo(data));
    }
    if (
      !selectedScript &&
      !selectedVoice &&
      !selectedVideo &&
      selectedThumbnail &&
      !selectedDesc &&
      !selectedStart &&
      !selectedEnd
    ) {
      const data = {
        id: selectedThumbnail,
        state: key,
      };
      dispatch(searchReportThumbnail(data));
    }
    if (
      !selectedScript &&
      !selectedVoice &&
      !selectedVideo &&
      !selectedThumbnail &&
      selectedDesc &&
      !selectedStart &&
      !selectedEnd
    ) {
      const data = {
        id: selectedDesc,
        state: key,
      };
      dispatch(searchReportDesc(data));
    }
    if (
      !selectedScript &&
      !selectedVoice &&
      !selectedVideo &&
      !selectedThumbnail &&
      !selectedDesc &&
      selectedStart &&
      selectedEnd
    ) {
      const data = {
        start_date: selectedStart,
        end_date: selectedEnd,
        state: key,
      };
      dispatch(searchReportDate(data));
    }
    if (
      selectedScript &&
      !selectedVoice &&
      !selectedVideo &&
      !selectedThumbnail &&
      selectedDesc &&
      !selectedStart &&
      !selectedEnd
    ) {
      const data = {
        s_id: selectedScript,
        d_id: selectedDesc,
        state: key,
      };
      dispatch(searchReportScriptDesc(data));
    }
    if (
      !selectedScript &&
      selectedVoice &&
      !selectedVideo &&
      !selectedThumbnail &&
      selectedDesc &&
      !selectedStart &&
      !selectedEnd
    ) {
      const data = {
        s_id: selectedVoice,
        d_id: selectedDesc,
        state: key,
      };
      dispatch(searchReportVoiceDesc(data));
    }
    if (
      !selectedScript &&
      !selectedVoice &&
      selectedVideo &&
      !selectedThumbnail &&
      selectedDesc &&
      !selectedStart &&
      !selectedEnd
    ) {
      const data = {
        s_id: selectedVideo,
        d_id: selectedDesc,
        state: key,
      };
      dispatch(searchReportVideoDesc(data));
    }
    if (
      selectedScript &&
      !selectedVoice &&
      !selectedVideo &&
      selectedThumbnail &&
      !selectedDesc &&
      !selectedStart &&
      !selectedEnd
    ) {
      const data = {
        s_id: selectedScript,
        d_id: selectedThumbnail,
        state: key,
      };
      dispatch(searchReportScriptThumb(data));
    }
    if (
      !selectedScript &&
      selectedVoice &&
      !selectedVideo &&
      selectedThumbnail &&
      !selectedDesc &&
      !selectedStart &&
      !selectedEnd
    ) {
      const data = {
        s_id: selectedVoice,
        d_id: selectedThumbnail,
        state: key,
      };
      dispatch(searchReportVoiceThumb(data));
    }
    if (
      !selectedScript &&
      !selectedVoice &&
      selectedVideo &&
      selectedThumbnail &&
      !selectedDesc &&
      !selectedStart &&
      !selectedEnd
    ) {
      const data = {
        s_id: selectedVideo,
        d_id: selectedThumbnail,
        state: key,
      };
      dispatch(searchReportVideoThumb(data));
    }
    if (
      selectedScript &&
      selectedVoice &&
      !selectedVideo &&
      !selectedThumbnail &&
      !selectedDesc &&
      !selectedStart &&
      !selectedEnd
    ) {
      const data = {
        s_id: selectedScript,
        d_id: selectedVoice,
        state: key,
      };
      dispatch(searchReportScriptVoice(data));
    }
    if (
      selectedScript &&
      !selectedVoice &&
      selectedVideo &&
      !selectedThumbnail &&
      !selectedDesc &&
      !selectedStart &&
      !selectedEnd
    ) {
      const data = {
        s_id: selectedScript,
        d_id: selectedVideo,
        state: key,
      };
      dispatch(searchReportScriptVideo(data));
    }
    if (
      selectedScript &&
      !selectedVoice &&
      !selectedVideo &&
      !selectedThumbnail &&
      !selectedDesc &&
      selectedStart &&
      selectedEnd
    ) {
      const data = {
        s_id: selectedScript,
        start_date: selectedStart,
        end_date: selectedEnd,
        state: key,
      };
      dispatch(searchReportScriptDate(data));
    }
    if (
      !selectedScript &&
      selectedVoice &&
      !selectedVideo &&
      !selectedThumbnail &&
      !selectedDesc &&
      selectedStart &&
      selectedEnd
    ) {
      const data = {
        s_id: selectedVoice,
        start_date: selectedStart,
        end_date: selectedEnd,
        state: key,
      };
      dispatch(searchReportVoiceDate(data));
    }
    if (
      !selectedScript &&
      !selectedVoice &&
      selectedVideo &&
      !selectedThumbnail &&
      !selectedDesc &&
      selectedStart &&
      selectedEnd
    ) {
      const data = {
        s_id: selectedVideo,
        start_date: selectedStart,
        end_date: selectedEnd,
        state: key,
      };
      dispatch(searchReportVideoDate(data));
    }
    if (
      !selectedScript &&
      selectedVoice &&
      selectedVideo &&
      !selectedThumbnail &&
      !selectedDesc &&
      !selectedStart &&
      !selectedEnd
    ) {
      const data = {
        v_id: selectedVoice,
        ve_id: selectedVideo,
        state: key,
      };
      dispatch(searchReportVoiceVideo(data));
    }
    if (
      !selectedScript &&
      !selectedVoice &&
      !selectedVideo &&
      selectedThumbnail &&
      selectedDesc &&
      !selectedStart &&
      !selectedEnd
    ) {
      const data = {
        th_id: selectedThumbnail,
        de_id: selectedDesc,
        state: key,
      };
      dispatch(searchReportThumDesc(data));
    }
    if (
      selectedScript &&
      selectedVoice &&
      selectedVideo &&
      !selectedThumbnail &&
      !selectedDesc &&
      !selectedStart &&
      !selectedEnd
    ) {
      const data = {
        s_id: selectedScript,
        v_id: selectedVoice,
        ve_id: selectedVideo,
        state: key,
      };
      dispatch(searchReportScriptVoiceVideo(data));
    }
    if (
      selectedScript &&
      selectedVoice &&
      selectedVideo &&
      selectedThumbnail &&
      !selectedDesc &&
      !selectedStart &&
      !selectedEnd
    ) {
      const data = {
        s_id: selectedScript,
        v_id: selectedVoice,
        ve_id: selectedVideo,
        th_id: selectedThumbnail,
        state: key,
      };
      dispatch(searchReportScriptVoiceVideoThum(data));
    }
    if (
      selectedScript &&
      selectedVoice &&
      selectedVideo &&
      !selectedThumbnail &&
      selectedDesc &&
      !selectedStart &&
      !selectedEnd
    ) {
      const data = {
        s_id: selectedScript,
        v_id: selectedVoice,
        ve_id: selectedVideo,
        d_id: selectedDesc,
        state: key,
      };
      dispatch(searchReportScriptVoiceVideoDesc(data));
    }
    if (
      !selectedScript &&
      !selectedVoice &&
      !selectedVideo &&
      selectedThumbnail &&
      !selectedDesc &&
      selectedStart &&
      selectedEnd
    ) {
      const data = {
        id: selectedThumbnail,
        start_date: selectedStart,
        end_date: selectedEnd,
        state: key,
      };
      dispatch(searchReportThumbDate(data));
    }
    if (
      !selectedScript &&
      !selectedVoice &&
      !selectedVideo &&
      !selectedThumbnail &&
      selectedDesc &&
      selectedStart &&
      selectedEnd
    ) {
      const data = {
        id: selectedDesc,
        start_date: selectedStart,
        end_date: selectedEnd,
        state: key,
      };
      dispatch(searchReportDescDate(data));
    }
  };

  const clearData = () => {
    if (
      selectedScript ||
      selectedVoice ||
      selectedVideo ||
      selectedThumbnail ||
      selectedDesc ||
      selectedStart ||
      selectedEnd
    ) {
      setSelectedScript(null);
      setSelectedVoice(null);
      setSelectedVideo(null);
      setSelectedThumbnail(null);
      setSelectedDesc(null);
      setSelectedStart("");
      setSelectedEnd("");
      if (key === "0") {
        dispatch(getUser(Number(key)));
      } else if (key === "1") {
        dispatch(getUser(Number(key)));
      } else if (key === "2") {
        dispatch(getUser(Number(key)));
      }
    }
  };

  useEffect(() => {
    if (updatePaidSuccess) {
      if (key === "0") {
        dispatch(getUser(Number(key)));
      } else if (key === "1") {
        dispatch(getUser(Number(key)));
      } else if (key === "2") {
        dispatch(getUser(Number(key)));
      }
    }
    // eslint-disable-next-line
  }, [updatePaidSuccess, key]);

  useEffect(() => {
    if (getReportScript) {
      setData(getReportScript);
    }
  }, [getReportScript]);
  useEffect(() => {
    if (getReportVoice) {
      setData(getReportVoice);
    }
  }, [getReportVoice]);
  useEffect(() => {
    if (getReportVideo) {
      setData(getReportVideo);
    }
  }, [getReportVideo]);
  useEffect(() => {
    if (getReportThumbnail) {
      setData(getReportThumbnail);
    }
  }, [getReportThumbnail]);
  useEffect(() => {
    if (getReportDesc) {
      setData(getReportDesc);
    }
  }, [getReportDesc]);
  useEffect(() => {
    if (getReportDate) {
      setData(getReportDate);
    }
  }, [getReportDate]);
  useEffect(() => {
    if (getReportScriptDesc) {
      setData(getReportScriptDesc);
    }
  }, [getReportScriptDesc]);
  useEffect(() => {
    if (getReportVoiceDesc) {
      setData(getReportVoiceDesc);
    }
  }, [getReportVoiceDesc]);
  useEffect(() => {
    if (getReportVideoDesc) {
      setData(getReportVideoDesc);
    }
  }, [getReportVideoDesc]);
  useEffect(() => {
    if (getReportScriptThumb) {
      setData(getReportScriptThumb);
    }
  }, [getReportScriptThumb]);
  useEffect(() => {
    if (getReportVoiceThumb) {
      setData(getReportVoiceThumb);
    }
  }, [getReportVoiceThumb]);
  useEffect(() => {
    if (getReportVideoThumb) {
      setData(getReportVideoThumb);
    }
  }, [getReportVideoThumb]);
  useEffect(() => {
    if (getReportScriptVoice) {
      setData(getReportScriptVoice);
    }
  }, [getReportScriptVoice]);
  useEffect(() => {
    if (getReportScriptVideo) {
      setData(getReportScriptVideo);
    }
  }, [getReportScriptVideo]);
  useEffect(() => {
    if (getReportScriptDate) {
      setData(getReportScriptDate);
    }
  }, [getReportScriptDate]);
  useEffect(() => {
    if (getReportVoiceDate) {
      setData(getReportVoiceDate);
    }
  }, [getReportVoiceDate]);
  useEffect(() => {
    if (getReportVideoDate) {
      setData(getReportVideoDate);
    }
  }, [getReportVideoDate]);
  useEffect(() => {
    if (getReportVoiceVideo) {
      setData(getReportVoiceVideo);
    }
  }, [getReportVoiceVideo]);
  useEffect(() => {
    if (getReportThumbDesc) {
      setData(getReportThumbDesc);
    }
  }, [getReportThumbDesc]);
  useEffect(() => {
    if (getReportScriptVoiceVideo) {
      setData(getReportScriptVoiceVideo);
    }
  }, [getReportScriptVoiceVideo]);
  useEffect(() => {
    if (getReportScriptVoiceVideoThum) {
      setData(getReportScriptVoiceVideoThum);
    }
  }, [getReportScriptVoiceVideoThum]);
  useEffect(() => {
    if (getReportScriptVoiceVideoDesc) {
      setData(getReportScriptVoiceVideoDesc);
    }
  }, [getReportScriptVoiceVideoDesc]);
  useEffect(() => {
    if (getReportThumbDate) {
      setData(getReportThumbDate);
    }
  }, [getReportThumbDate]);
  useEffect(() => {
    if (getReportDescDate) {
      setData(getReportDescDate);
    }
  }, [getReportDescDate]);
  //Fileter(End):

  const tabData = [
    { key: "0", title: "In Progress" },
    { key: "1", title: "Completed" },
    { key: "2", title: "Paid" },
  ];

  return (
    <div className="team-report">
      <div className="content-title">Team Report</div>
      <div className="row d-flex">
        <div className="col-md mb-3">
          <Select
            options={getUserSuccess?.writer}
            className="react-select-container"
            classNamePrefix="react-select"
            placeholder="Select Script Writer"
            getOptionLabel={(option) =>
              option.first_name + " " + option.last_name
            }
            getOptionValue={(option) =>
              option.first_name + " " + option.last_name
            }
            onChange={scriptHandleChange}
            value={
              getUserSuccess?.writer
                ? getUserSuccess?.writer.filter((op) => {
                    return op.user_id === selectedScript;
                  })
                : []
            }
          />
        </div>
        <div className="col-md mb-3">
          <Select
            options={getUserSuccess?.voice}
            className="react-select-container"
            classNamePrefix="react-select"
            placeholder="Select Voice Artist"
            getOptionLabel={(option) =>
              option.first_name + " " + option.last_name
            }
            getOptionValue={(option) =>
              option.first_name + " " + option.last_name
            }
            onChange={voiceHandleChange}
            value={
              getUserSuccess?.voice
                ? getUserSuccess?.voice.filter((op) => {
                    return op.user_id === selectedVoice;
                  })
                : []
            }
          />
        </div>
        <div className="col-md mb-3">
          <Select
            options={getUserSuccess?.video}
            className="react-select-container"
            classNamePrefix="react-select"
            placeholder="Select Video Editor"
            getOptionLabel={(option) =>
              option.first_name + " " + option.last_name
            }
            getOptionValue={(option) =>
              option.first_name + " " + option.last_name
            }
            onChange={videoHandleChange}
            value={
              getUserSuccess?.video
                ? getUserSuccess?.video.filter((op) => {
                    return op.user_id === selectedVideo;
                  })
                : []
            }
          />
        </div>
        <div className="col-md mb-3">
          <Select
            options={getUserSuccess?.thumbnail}
            className="react-select-container"
            classNamePrefix="react-select"
            placeholder="Select Thumbnail Artist"
            getOptionLabel={(option) =>
              option.first_name + " " + option.last_name
            }
            getOptionValue={(option) =>
              option.first_name + " " + option.last_name
            }
            onChange={thumbnailHandleChange}
            value={
              getUserSuccess?.thumbnail
                ? getUserSuccess?.thumbnail.filter((op) => {
                    return op.user_id === selectedThumbnail;
                  })
                : []
            }
          />
        </div>
      </div>
      <div className="row d-flex">
        <div className="col-md mb-3">
          <Select
            options={getUserSuccess?.descriptionWriter}
            className="react-select-container"
            classNamePrefix="react-select"
            placeholder="Select Description Writer"
            getOptionLabel={(option) =>
              option.first_name + " " + option.last_name
            }
            getOptionValue={(option) =>
              option.first_name + " " + option.last_name
            }
            onChange={descHandleChange}
            value={
              getUserSuccess?.descriptionWriter
                ? getUserSuccess?.descriptionWriter.filter((op) => {
                    return op.user_id === selectedDesc;
                  })
                : []
            }
          />
        </div>
        <div className="col-md mb-3">
          <input
            type="date"
            className="date form-control"
            id="date"
            onChange={(e) => startHandleChange(e.target.value)}
            onFocus={(e) => {
              e.currentTarget.type = "date";
              e.currentTarget.focus();
            }}
            value={selectedStart}
          />
        </div>
        <div className="col-md mb-3">
          <input
            type="date"
            className="date form-control"
            id="date"
            onChange={(e) => endHandleChange(e.target.value)}
            onFocus={(e) => {
              e.currentTarget.type = "date";
              e.currentTarget.focus();
            }}
            value={selectedEnd}
          />
        </div>
        <div className="col-md">
          <button type="button" className="apply-button me-3" onClick={getData}>
            Apply
          </button>
          <button type="cancel" className="cancel-button" onClick={clearData}>
            Clear
          </button>
        </div>
      </div>

      <div className="tabs-section mb-3">
        <Tabs
          defaultActiveKey="0"
          id="uncontrolled-tab-example"
          className="mb-3"
          transition={true}
          activeKey={key}
          onSelect={(key) => {
            setKey(key);
            setSelectedScript(null);
            setSelectedVoice(null);
            setSelectedVideo(null);
            setSelectedThumbnail(null);
            setSelectedDesc(null);
            setSelectedStart("");
            setSelectedEnd("");
          }}
        >
          {tabData &&
            tabData.map((item, index) => (
              <Tab key={index} eventKey={item.key} title={item.title}>
                {getReportPending || isLoadingState ? (
                  <div className="d-flex justify-content-center">
                    <Loaders color={"#5f48ea"} />
                  </div>
                ) : (
                  <>
                    <div className="d-flex justify-content-end select-form-control">
                      Show
                      <select
                        className="select-enteries ms-2 me-2 mb-2"
                        value={itemsPerPage}
                        onChange={(e) => setItemsPerPage(e.target.value)}
                      >
                        <option value="10">10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                      Entries
                    </div>
                    <TeamReportTable data={currentItems} state={key} />
                    <ReactPaginate
                      previousLabel={"<<"}
                      nextLabel={">>"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination justify-content-center"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </>
                )}
              </Tab>
            ))}
        </Tabs>
      </div>
    </div>
  );
}

export default TeamReport;
