import React, { useState, useEffect } from "react";
import {
  UilCommentDots,
  UilCloudUpload,
  UilEllipsisH,
  UilLink,
} from "@iconscout/react-unicons";
import UploadModal from "./uploadModal";
import Comments from "./Comments";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { getAssignedProjects } from "../../Feature/LevelOneResource/AssignProjectSlice";
import { dateFormat } from "../../Common/dateFormat";
import { AlertToast } from "../../Common/Toast";
import ReactPaginate from "react-paginate";

const MyProjects = () => {
  const dispatch = useDispatch();
  const { assignedProjects, uploadProject, uploadProjectFail } = useSelector(
    (state) => state.L1RAssignedProject
  );
  const roleId = localStorage.getItem("role_id");
  const [role, setRole] = useState("");
  const [data, setData] = useState([]);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [recordsPerPage, setRecordsPerPage] = useState(10);
  // const indexOfLastRecord = currentPage * recordsPerPage;
  // const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  // const currentRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);
  // const nPages = Math.ceil(data.length / recordsPerPage);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + Number(itemsPerPage);
  const currentItems = data.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(data.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    setItemOffset(newOffset);
  };

  const [show, setShow] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [projectInfo, setProjectInfo] = useState({});
  const [alert, setAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [projectId, setProjectId] = useState(0);
  const [showMore, setShowMore] = useState({});

  //useEffects:
  useEffect(() => {
    dispatch(getAssignedProjects());
    setItemOffset(0);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const role = localStorage.getItem("role_id");
    if (role === "3" || role === "4" || role === "5") {
      setRole("level1");
    } else {
      setRole("level2");
    }
  }, []);

  useEffect(() => {
    if (assignedProjects) {
      setData(assignedProjects);
    }
    // eslint-disable-next-line
  }, [assignedProjects]);

  useEffect(() => {
    if (uploadProject) {
      setAlert(true);
      setAlertMsg("Project marked as uploaded successfully");
      dispatch(getAssignedProjects());
    }
    if (uploadProjectFail) {
      setAlert(true);
      setAlertMsg(uploadProjectFail);
      dispatch(getAssignedProjects());
    }

    // eslint-disable-next-line
  }, [uploadProject, uploadProjectFail]);

  const toggle = () => setShow(!show);
  const handleShow = (item) => {
    setShow(true);
    setProjectInfo(item);
  };
  const toggleComment = () => setShowComments(!showComments);
  const handleComment = (item) => {
    setShowComments(true);
    setProjectId(item);
  };
  const closeToast = () => {
    setAlert(false);
  };
  const toggleHidden = (index, item) => {
    setShowMore({ ...showMore, [index]: !showMore[index] });
  };

  return (
    <>
      <AlertToast
        show={alert}
        alert={(uploadProject && 'success') || (uploadProjectFail && 'fail')}
        msg={alertMsg}
        close={closeToast}
      />
      <div className="my-projects">
        <div className="content-title">Assigned Projects</div>
        <div className="d-flex justify-content-end select-form-control">
          Show
          <select
            className="select-enteries ms-2 me-2 mb-2"
            value={itemsPerPage}
            onChange={(e) => setItemsPerPage(e.target.value)}
          >
            <option value="10">10</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          Entries
        </div>
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>#</th>
                <th>Project</th>
                {role === "level1" && <th>Start Time</th>}
                {role === "level2" && <th>Channel</th>}
                {role === "level2" && <th>Created on</th>}
                {Number(roleId) === 3 && <th>Description</th>}
                {role === "level1" && <th>Project Status</th>}
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.project_name}</td>
                  {role === "level1" && <td>{dateFormat(item.updated_at)}</td>}
                  {role === "level2" && <td>{item.channel}</td>}
                  {role === "level2" && <td>{dateFormat(item.created_at)}</td>}
                  {Number(roleId) === 3 && (
                    <td style={{ width: "40%" }}>
                      {!showMore[index]
                        ? item.description !== null &&
                        `${item.description.substring(0, 100) + "..."}` : item.description}
                      <div
                        style={{
                          fontSize: "10px",
                          color: "#5f48ea",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={(e) => toggleHidden(index, item.description)}
                      >
                        {!showMore[index] && item.description !== null
                          ? "Show more"
                          : item.description !== null
                            ? "Show less"
                            : ""}
                      </div>
                    </td>
                  )}
                  {role === "level1" && <td>{item.project_status === 1 ? 'Project submitted, wait for the Admin Approval' : item.project_status === 2 ? "Project is disapproved by Admin, Please review your project again" : ""}</td>}
                  <td>
                    <Dropdown drop="start">
                      <Dropdown.Toggle id="dropdown-basic">
                        <UilEllipsisH color="#7c828d" size="18" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          style={{ display: "flex", alignItems: "center" }}
                          href={item.project_link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <UilLink size="18" color="#7c828d" className="me-2" />{" "}
                          Drive link
                        </Dropdown.Item>
                        <Dropdown.Item
                          style={{ display: "flex", alignItems: "center" }}
                          onClick={() => handleShow(item)}
                        >
                          <UilCloudUpload
                            size="20"
                            color="#7c828d"
                            className="me-2"
                          />
                          Upload
                        </Dropdown.Item>
                        <Dropdown.Item
                          style={{ display: "flex", alignItems: "center" }}
                          onClick={() => handleComment(item.project_id)}
                        >
                          <UilCommentDots
                            size="18"
                            color="#7c828d"
                            className="me-2"
                          />
                          Comment
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <ReactPaginate
          previousLabel={"<<"}
          nextLabel={">>"}
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination justify-content-center"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
        />
        <UploadModal show={show} toggle={toggle} projectData={projectInfo} />
        <Comments show={showComments} toggle={toggleComment} id={projectId} />
      </div>
    </>
  );
};

export default MyProjects;
