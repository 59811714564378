import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchRoles } from "../../Feature/Admin/RoleManagement/roleSlice";
import Pagination from "../../Common/Pagination";
import { UilInfoCircle } from "@iconscout/react-unicons";
import Dropdown from "react-bootstrap/Dropdown";

function RoleManagement() {
  const dispatch = useDispatch();
  const { roles } = useSelector((state) => state.role);

  //Pagination hooks
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(data.length / recordsPerPage);

  useEffect(() => {
    dispatch(fetchRoles());
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   setData(roles);
  // }, [roles]);

  useEffect(() => {
    var newdata = [];
    for (var i = 0; i < roles.length; i++) {
      if (roles[i].role_id === 2) {
        newdata.push(
          Object.assign({}, roles[i], {
            content:
              "A client is an individual or an organization that seeks assistance in creating professional video content for their Youtube Channels.",
          })
        );
      }
      if (roles[i].role_id === 3) {
        newdata.push(
          Object.assign({}, roles[i], {
            content:
              "A professional who specializes in crafting scripts and written documents outlining the desired information or story within the video.",
          })
        );
      }
      if (roles[i].role_id === 4) {
        newdata.push(
          Object.assign({}, roles[i], {
            content:
              "A professional who specializes in the narration of the scripts or dialogues of a Youtube video with their vocal talents enhancing the audiovisual experience for the viewers.",
          })
        );
      }
      if (roles[i].role_id === 5) {
        newdata.push(
          Object.assign({}, roles[i], {
            content:
              "A professional who specializes in effective editing and enhancing the visual appeal, storytelling, enhancements into the quality of the video content. Transforming the raw footage into a visually appealing Youtube Video.",
          })
        );
      }
      if (roles[i].role_id === 6) {
        newdata.push(
          Object.assign({}, roles[i], {
            content:
              "An individual who oversees and manages the successful execution of creating Youtube Videos with the necessary resources, timelines, communication to deliver high-quality videos meeting the client's objectives.",
          })
        );
      }
      if (roles[i].role_id === 7) {
        newdata.push(
          Object.assign({}, roles[i], {
            content:
              "A professional who specializes in designing visually appealing and attention-grabbing thumbnails for Youtube videos enticing viewers to click on a video.",
          })
        );
      }
      if (roles[i].role_id === 8) {
        newdata.push(
          Object.assign({}, roles[i], {
            content:
              "A professional who specializes in crafting engaging and informative descriptions for Youtube videos to attract viewers with relevant content.",
          })
        );
      }
      if (roles[i].role_id === 9) {
        newdata.push(
          Object.assign({}, roles[i], {
            content:
              "An individual who ensures the overall quality in accordance to the standards of Youtube videos before they are published or delivered to the client ensuring that the final product meets the desired level of quality.",
          })
        );
      }
      if (roles[i].role_id === 10) {
        newdata.push(
          Object.assign({}, roles[i], {
            content:
              "An individual or a role responsible for uploading and publishing videos on the channel ensuring them being available to the intended audience.",
          })
        );
      }
      if (roles[i].role_id === 12) {
        newdata.push(
          Object.assign({}, roles[i], {
            content:
              "An individual or an entity that operates and oversees the complete video creation services managing the goals, objectives and requirements for the video creation process from each aspect of the business to run successfully.",
          })
        );
      }
    }
    setData(newdata);
  }, [roles]);

  return (
    <>
      <div className="role">
        <div className="content-title">Role Management</div>
        <div className="d-flex justify-content-end select-form-control">
          Show
          <select
            className="select-enteries ms-2 me-2 mb-2"
            value={recordsPerPage}
            onChange={(e) => setRecordsPerPage(e.target.value)}
          >
            <option value="10">10</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          Entries
        </div>
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>#</th>
                <th>Role</th>
              </tr>
            </thead>
            <tbody>
              {currentRecords.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td style={{ textTransform: "capitalize" }}>
                    <div style={{ display: "flex", alignItems: "end" }}>
                      {item.role}
                      <Dropdown>
                        <Dropdown.Toggle>
                          <UilInfoCircle size="12" color="#7b68ee" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          style={{ width: "226px", lineHeight: "16px" }}
                        >
                          <Dropdown.Item style={{lineHeight:1.7, textTransform:"initial"}}>{item.content}</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Pagination
          nPages={nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </>
  );
}

export default RoleManagement;
