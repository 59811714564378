import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field } from "formik";
import {
  UilExclamationTriangle,
  UilInfoCircle,
} from "@iconscout/react-unicons";
import {
  userEditValidationSchema,
  userValidationSchema,
} from "../../../Common/Validations";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  addUser,
  editUser,
} from "../../../Feature/Admin/userManagement/userSlice";
import Dropdown from "react-bootstrap/Dropdown";

function UserForm(props) {
  const { show, toggle, form, data } = props;
  const { roles } = useSelector((state) => state.role);
  // const { isLoading } = useSelector((state) => state.userData);
  const dispatch = useDispatch();
  const [role, setRole] = useState("");
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
    first_name: "",
    last_name: "",
    role: "",
    status: "",
    project_limit: 1,
    take_project: "",
  });
  const [selectedStatus, setSelectedStatus] = useState({
    value: "Select Status",
    label: "",
  });
  const [selectedRole, setSelectedRole] = useState({ role: "Select Role" });
  const [showMsg, setShowMsg] = useState(false);

  const options = [
    { value: "active", label: "active" },
    { value: "inactive", label: "inactive" },
  ];

  const onChangeHandler = (value) => {
    setRole(value);
  };

  const handleSubmit = (values) => {
    if (
      (values.role === 3 ||
        values.role === 4 ||
        values.role === 5 ||
        values.role === 7 ||
        values.role === 8) &&
      values.project_limit === "" &&
      form === "isAdd"
    ) {
      setShowMsg(true);
      return;
    }
    if (
      (values.role === "script writer" ||
        values.role === "voice artist" ||
        values.role === "video editor" ||
        values.role === "thumbnail artist" ||
        values.role === "description writer") &&
      values.project_limit === "" &&
      form === "isEdit"
    ) {
      setShowMsg(true);
      return;
    }
    if (form === "isAdd") {
      setShowMsg(false);
      dispatch(addUser(values));
      toggle();
    } else {
      setShowMsg(false);
      dispatch(editUser(values));
      toggle();
    }
  };

  useEffect(() => {
    if (form === "isEdit") {
      setFormValues({
        email: data.email,
        first_name: data.first_name,
        last_name: data.last_name,
        role: data.role,
        status: data.status,
        project_limit: data.project_limit,
        take_project: data.take_project === 1 ? true : false,
        user_id: data.user_id,
        role_id: data.role_id,
      });
      setSelectedStatus({ value: data.status, label: data.status });
      setSelectedRole({ role: data.role });
      setRole(data.role_id);
    }
  }, [data, form, show]);

  useEffect(() => {
    if (show === false && form === "isAdd") {
      setSelectedStatus({
        value: "Select Status",
        label: "",
      });
      setSelectedRole({ role: "Select Role" });
      setRole("");
    }
  }, [show, form]);

  return (
    <>
      <div className="user-form">
        <Modal
          size="lg"
          aria-labelledby="example-modal-sizes-title-lg"
          show={show}
          onHide={toggle}
          centered
          backdrop="static"
          keyboard={false}
          className="user-form"
        >
          <Modal.Header closeButton>
            <Modal.Title
              id="example-modal-sizes-title-lg"
              className="content-title"
            >
              {form === "isAdd" ? "Add User" : "Edit User"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              enableReinitialize={true}
              initialValues={formValues}
              validationSchema={
                form === "isAdd"
                  ? userValidationSchema
                  : userEditValidationSchema
              }
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={(values) => {
                handleSubmit(values);
              }}
            >
              {({ errors, touched, setFieldValue, handleChange }) => (
                <Form>
                  <div className="row modal-form-main g-0">
                    <div className="col-md p-4">
                      {/* email */}
                      <div className="modal-main-row row align-items-baseline mb-3">
                        <div className="col-md-4">
                          <label>
                            Email <span className="asterisk">*</span>
                          </label>
                        </div>

                        <div className="col-md">
                          <Field
                            type="text"
                            id="email"
                            name="email"
                            className={
                              errors.email && touched.email
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            placeholder="Enter Your Email"
                            disabled={form === "isEdit" ? true : false}
                          />
                          {/* error */}
                          {touched.email && errors.email ? (
                            <div className="invalid-feedback d-flex">
                              <UilExclamationTriangle
                                size="15"
                                className="me-1"
                              />
                              {errors.email}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* password */}
                      {form === "isAdd" ? (
                        <div className="modal-main-row row align-items-baseline mb-3">
                          <div className="col-md-4">
                            <label>
                              Password <span className="asterisk">*</span>
                            </label>
                          </div>
                          <div className="col-md">
                            <Field
                              type="password"
                              className={
                                errors.password && touched.password
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              id="password"
                              name="password"
                              placeholder="Enter Password"
                            />
                            {/* error */}
                            {touched.password && errors.password ? (
                              <div className="invalid-feedback d-flex">
                                <UilExclamationTriangle
                                  size="15"
                                  className="me-1"
                                />
                                {errors.password}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ) : null}

                      {/* first name */}
                      <div className="modal-main-row row align-items-baseline mb-3">
                        <div className="col-md-4">
                          <label>
                            First Name <span className="asterisk">*</span>
                          </label>
                        </div>
                        <div className="col-md">
                          <Field
                            type="text"
                            className={
                              errors.first_name && touched.first_name
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            id="first_name"
                            name="first_name"
                            placeholder="Enter First Name"
                          />
                          {/* error */}
                          {touched.first_name && errors.first_name ? (
                            <div className="invalid-feedback d-flex">
                              <UilExclamationTriangle
                                size="15"
                                className="me-1"
                              />
                              {errors.first_name}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* last name */}
                      <div className="modal-main-row row align-items-baseline mb-3">
                        <div className="col-md-4">
                          <label>
                            Last Name <span className="asterisk">*</span>
                          </label>
                        </div>
                        <div className="col-md">
                          <Field
                            type="text"
                            className={
                              errors.last_name && touched.last_name
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            id="last_name"
                            name="last_name"
                            placeholder="Enter Last Name"
                          />
                          {/* error */}
                          {touched.last_name && errors.last_name ? (
                            <div className="invalid-feedback d-flex">
                              <UilExclamationTriangle
                                size="15"
                                className="me-1"
                              />
                              {errors.last_name}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="col-md vertical-line p-4">
                      {/* role */}
                      <div className="modal-main-row row align-items-baseline mb-3">
                        <div className="col-md-4">
                          <label>
                            Role <span className="asterisk">*</span>
                          </label>
                        </div>
                        <div className="col-md">
                          <Select
                            options={roles}
                            className={
                              errors.role && touched.role
                                ? "react-select-container is-invalid"
                                : "react-select-container"
                            }
                            classNamePrefix="react-select"
                            placeholder="Select Role"
                            onChange={(value) => {
                              onChangeHandler(value.role_id);
                              setSelectedRole({ role: value.role });
                              if (form === "isAdd") {
                                setFieldValue("role", value.role_id);
                              } else {
                                setFieldValue("role", value.role);
                                setFieldValue("role_id", value.role_id);
                              }
                            }}
                            getOptionLabel={(option) => option.role}
                            getOptionValue={(option) => option.role}
                            name="role"
                            value={selectedRole}
                          />
                          {/* error */}
                          {touched.role && errors.role ? (
                            <div className="invalid-feedback d-flex">
                              <UilExclamationTriangle
                                size="15"
                                className="me-1"
                              />
                              {errors.role}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* Status */}
                      <div className="modal-main-row row align-items-baseline mb-3">
                        <div className="col-md-4">
                          <label>
                            Status <span className="asterisk">*</span>
                          </label>
                        </div>
                        <div className="col-md">
                          <Select
                            name="status"
                            options={options}
                            className={
                              errors.status && touched.status
                                ? "react-select-container is-invalid"
                                : "react-select-container"
                            }
                            classNamePrefix="react-select"
                            placeholder="Select Status"
                            onChange={(value) => {
                              if (form === "isEdit") {
                                setSelectedStatus({
                                  value: value.value,
                                  label: value.value,
                                });
                                setFieldValue("status", value.value);
                              } else {
                                setFieldValue("status", value.value);
                                setSelectedStatus({
                                  value: value.value,
                                  label: value.value,
                                });
                              }
                            }}
                            getOptionLabel={(option) => option.value}
                            getOptionValue={(option) => option.value}
                            value={selectedStatus}
                          />
                          {/* error */}
                          {touched.status && errors.status ? (
                            <div className="invalid-feedback d-flex">
                              <UilExclamationTriangle
                                size="15"
                                className="me-1"
                              />
                              {errors.status}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/*project limit*/}
                      {role === 3 ||
                      role === 4 ||
                      role === 5 ||
                      role === 7 ||
                      role === 8 ? (
                        <div className="modal-main-row row align-items-baseline mb-3">
                          <div className="col-md-4">
                            <label>Project Limit</label>
                          </div>
                          <div className="col-md">
                            <Field
                              type="number"
                              className="form-control"
                              id="project_limit"
                              name="project_limit"
                              placeholder="Enter Project Limit"
                              disabled={
                                role === 3 ||
                                role === 4 ||
                                role === 5 ||
                                role === 7 ||
                                role === 8
                                  ? false
                                  : true
                              }
                            />
                            {showMsg ? (
                              <div className="invalid-feedback d-flex">
                                <UilExclamationTriangle
                                  size="15"
                                  className="me-1"
                                />
                                Project limit is required
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ) : null}

                      {/* take project */}
                      {role === 3 || role === 4 || role === 5 ? (
                        <div className="modal-main-row row align-items-baseline mb-3">
                          <div className="col-md-5">
                            <label>
                              Allow user to pick projects
                              <Dropdown
                                style={{
                                  marginTop: "-26px",
                                  marginLeft: "160px",
                                }}
                              >
                                <Dropdown.Toggle>
                                  <UilInfoCircle size="12" color="#7b68ee" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                  style={{ width: "226px", lineHeight: "16px" }}
                                >
                                  <Dropdown.Item style={{ lineHeight: "20px" }}>
                                    Allow this contractor to take projects at
                                    will, If left unchecked, This contractor
                                    will have to manually be assigned projects
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </label>
                          </div>
                          <div className="col-md">
                            <Field
                              className="form-check-input"
                              type="checkbox"
                              name="take_project"
                              id="take_project"
                            />
                          </div>
                        </div>
                      ) : null}
                    </div>

                    {/* submit */}
                    <div className="modal-main-row text-center p-3 bottom-footer">
                      <button className="apply-button" type="submit">
                        {form === "isAdd" ? "Send Invite" : "Update User"}
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default UserForm;
