import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Utils/Api";
import {
  addChannelClientPath,
  addChannelDescPath,
  addChannelPath,
  addChannelPmPath,
  addChannelQcPath,
  addChannelScriptPath,
  addChannelThumbPath,
  addChannelVideoPath,
  addChannelVideoUploaderPath,
  addChannelVoicePath,
  getChannelUserPath,
} from "../../../Utils/ApiPath";

const initialState = {
  channelPending: false,
  addChannel: false,
  addChannelError: "",
  addChannelClient: false,
  addChannelClientError: "",
  addChannelScript: false,
  addChannelScriptError: "",
  addChannelVoice: false,
  addChannelVoiceError: "",
  addChannelVideo: false,
  addChannelVideoError: "",
  addChannelPm: false,
  addChannelPmError: "",
  addChannelThumb: false,
  addChannelThumbError: "",
  addChannelDesc: false,
  addChannelDescError: "",
  addChannelQC: false,
  addChannelQCError: "",
  addChannelVideoUploader: false,
  addChannelVideoUploaderError: "",
  channelUserPending: false,
  channelUser: {},
  channelUserError: "",
  channelUpgrade: false,
};

export const postChannel = createAsyncThunk(
  "/channelForm/postChannel",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(channelPending());
      const channelObj = {
        channel: _.channel_name,
        client: _.client,
        status: _.status,
        description: _.channel_description,
      };
      const res = await api.post(addChannelPath, channelObj);
      if (res.data) {
        var channel_id = res.data.channel_id;
        for (let i = 0; i < _.client.length; i++) {
          const clientObj = {
            channel: _.channel_name,
            channel_id: channel_id,
            channel_user_id: _.client[i],
          };
          await api.post(addChannelClientPath, clientObj);
        }
        for (let i = 0; i < _.script_writer.length; i++) {
          const obj = {
            channel_script_id: channel_id,
            channel_script_user_id: _.script_writer[i],
          };
          await api.post(addChannelScriptPath, obj);
        }
        for (let i = 0; i < _.voice_artist.length; i++) {
          const obj = {
            channel_voice_id: channel_id,
            channel_voice_user_id: _.voice_artist[i],
          };
          await api.post(addChannelVoicePath, obj);
        }
        for (let i = 0; i < _.video_editor.length; i++) {
          const obj = {
            channel_video_id: channel_id,
            channel_video_user_id: _.video_editor[i],
          };
          await api.post(addChannelVideoPath, obj);
        }
        for (let i = 0; i < _.project_manager.length; i++) {
          const obj = {
            channel_id: channel_id,
            channel_manager_user_id: _.project_manager[i],
          };
          await api.post(addChannelPmPath, obj);
        }
        for (let i = 0; i < _.thumbnail_artist.length; i++) {
          const obj = {
            channel_id: channel_id,
            channel_thumbnail_user_id: _.thumbnail_artist[i],
          };
          await api.post(addChannelThumbPath, obj);
        }
        for (let i = 0; i < _.description_writer.length; i++) {
          const obj = {
            channel_id: channel_id,
            channel_description_writer_user_id: _.description_writer[i],
          };
          await api.post(addChannelDescPath, obj);
        }
        for (let i = 0; i < _.quality_checker.length; i++) {
          const obj = {
            channel_id: channel_id,
            channel_quality_checker_user_id: _.quality_checker[i],
          };
          await api.post(addChannelQcPath, obj);
        }
        for (let i = 0; i < _.video_uploader.length; i++) {
          console.log(_.video_uploader.length);
          const obj = {
            channel_id: channel_id,
            channel_video_uploader_user_id: _.video_uploader[i],
          };
          await api.post(addChannelVideoUploaderPath, obj);
        }
        thunkAPI.dispatch(addChannel());
        setTimeout(() => {
          thunkAPI.dispatch(clearAddChannel());
        }, 3100);
      }
      // return res.data;
    } catch (err) {
      console.log(err);
      if (err.response.data.error === "Reached Limit") {
        thunkAPI.dispatch(channelUpgrade());
        setTimeout(() => {
          thunkAPI.dispatch(clearChannelUpgrade());
        }, 3100);
      } else {
        thunkAPI.dispatch(addChannelError(err.response.data.error));
        setTimeout(() => {
          thunkAPI.dispatch(clearAddChannel());
        }, 3100);
      }
    }
  }
);

export const getChannelUser = createAsyncThunk(
  "/channelForm/getChannelUser",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(channelUserPending());
      const res = await api.get(getChannelUserPath + "/" + _);
      if (res) {
        thunkAPI.dispatch(channelUserSuccess(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(channelUserError(err));
    }
  }
);

const channelFormSlice = createSlice({
  name: "channelForm",
  initialState,
  reducers: {
    channelPending: (state) => {
      state.channelPending = true;
    },
    addChannel: (state) => {
      state.channelPending = false;
      state.addChannel = true;
      state.addChannelError = "";
    },
    addChannelError: (state, { payload }) => {
      state.channelPending = false;
      state.addChannel = false;
      state.addChannelError = payload;
    },
    addChannelClient: (state) => {
      state.channelPending = false;
      state.addChannelClient = true;
      state.addChannelClientError = "";
    },
    addChannelClientError: (state, { payload }) => {
      state.channelPending = false;
      state.addChannelClient = false;
      state.addChannelClientError = payload;
    },
    addChannelScript: (state) => {
      state.channelPending = false;
      state.addChannelScript = true;
      state.addChannelScriptError = "";
    },
    addChannelScriptError: (state, { payload }) => {
      state.channelPending = false;
      state.addChannelScript = false;
      state.addChannelScriptError = payload;
    },
    addChannelVoice: (state) => {
      state.channelPending = false;
      state.addChannelVoice = true;
      state.addChannelVoiceError = "";
    },
    addChannelVoiceError: (state, { payload }) => {
      state.channelPending = false;
      state.addChannelVoice = false;
      state.addChannelVoiceError = payload;
    },
    addChannelVideo: (state) => {
      state.channelPending = false;
      state.addChannelVideo = true;
      state.addChannelVideoError = "";
    },
    addChannelVideoError: (state, { payload }) => {
      state.channelPending = false;
      state.addChannelVideo = false;
      state.addChannelVideoError = payload;
    },
    addChannelPm: (state) => {
      state.channelPending = false;
      state.addChannelPm = true;
      state.addChannelPmError = "";
    },
    addChannelPmError: (state, { payload }) => {
      state.channelPending = false;
      state.addChannelPm = false;
      state.addChannelPmError = payload;
    },
    addChannelThumb: (state) => {
      state.channelPending = false;
      state.addChannelThumb = true;
      state.addChannelThumbError = "";
    },
    addChannelThumbError: (state, { payload }) => {
      state.channelPending = false;
      state.addChannelThumb = false;
      state.addChannelThumbError = payload;
    },
    addChannelDesc: (state) => {
      state.channelPending = false;
      state.addChannelDesc = true;
      state.addChannelDescError = "";
    },
    addChannelDescError: (state, { payload }) => {
      state.channelPending = false;
      state.addChannelDesc = false;
      state.addChannelDescError = payload;
    },
    addChannelQC: (state) => {
      state.channelPending = false;
      state.addChannelQC = true;
      state.addChannelQCError = "";
    },
    addChannelQCError: (state, { payload }) => {
      state.channelPending = false;
      state.addChannelQC = false;
      state.addChannelQCError = payload;
    },
    addChannelVideoUploader: (state) => {
      state.channelPending = false;
      state.addChannelVideoUploader = true;
      state.addChannelVideoUploaderError = "";
    },
    addChannelVideoUploaderError: (state, { payload }) => {
      state.channelPending = false;
      state.addChannelVideoUploader = false;
      state.addChannelVideoUploaderError = payload;
    },
    channelUserPending: (state) => {
      state.channelUserPending = true;
    },
    channelUserSuccess: (state, { payload }) => {
      state.channelUserError = false;
      state.channelUser = payload;
      state.channelUserError = "";
    },
    channelUserError: (state, { payload }) => {
      state.channelUserError = false;
      state.channelUser = {};
      state.channelUserError = payload;
    },
    clearAddChannel: (state) => {
      state.addChannel = false;
      state.addChannelError = "";
    },
    channelUpgrade: (state) => {
      state.addChannel = false;
      state.addChannelError = "";
      state.channelUpgrade = true;
    },
    clearChannelUpgrade: (state) => {
      state.addChannel = false;
      state.addChannelError = "";
      state.channelUpgrade = false;
    },
  },
});

const { reducer, actions } = channelFormSlice;

export const {
  channelPending,
  addChannel,
  addChannelError,
  addChannelClient,
  addChannelClientError,
  addChannelScript,
  addChannelScriptError,
  addChannelVoice,
  addChannelVoiceError,
  addChannelVideo,
  addChannelVideoError,
  addChannelPm,
  addChannelPmError,
  addChannelThumb,
  addChannelThumbError,
  addChannelDesc,
  addChannelDescError,
  addChannelQC,
  addChannelQCError,
  addChannelVideoUploader,
  addChannelVideoUploaderError,
  channelUserPending,
  channelUserSuccess,
  channelUserError,
  clearAddChannel,
  channelUpgrade,
  clearChannelUpgrade,
} = actions;

export default reducer;
