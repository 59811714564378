import { Toast, ToastContainer } from "react-bootstrap";

export const AlertToast = ({ show, alert, msg, close }) => {
  // useEffect(() => {
  //   if (show) {
  //     // setTimeout(() => {
  //     //   close();
  //     // }, 3000);
  //   }
  //   // eslint-disable-next-line
  // }, [show]);

  return (
    <>
      <ToastContainer
        position="bottom-start"
        className={
          alert === "success"
            ? "is-success"
            : alert === "fail"
            ? "is-error"
            : ""
        }
      >
        <Toast show={show} delay={2000} autohide onClose={close}>
          <Toast.Body>
            <div>
              {" "}
              <strong className="me-auto head">
                {alert === "success"
                  ? "Success"
                  : alert === "fail"
                  ? "Error"
                  : ""}
              </strong>
            </div>
            <div className="mt-2"> {msg}</div>
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
};
