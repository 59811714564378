import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getL2RChannel } from "../../Feature/LevelTwoResource/ChannelSlice";
import ReactPaginate from "react-paginate";

const Channel = () => {
  const dispatch = useDispatch();
  const { channels } = useSelector((state) => state.L2RChannel);
  //Pagination hooks
  const [data, setData] = useState([]);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [recordsPerPage, setRecordsPerPage] = useState(10);
  // const indexOfLastRecord = currentPage * recordsPerPage;
  // const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  // const currentRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);
  // const nPages = Math.ceil(data.length / recordsPerPage);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + Number(itemsPerPage);
  const currentItems = data.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(data.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    setItemOffset(newOffset);
  };

  //useeffect
  useEffect(() => {
    dispatch(getL2RChannel());
    setItemOffset(0);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (channels) {
      setData(channels);
    }
    // eslint-disable-next-line
  }, [channels]);
  return (
    <>
      <div className="channel">
        <div className="content-title">Channel Management</div>
        <div className="d-flex justify-content-end select-form-control">
          Show
          <select
            className="select-enteries ms-2 me-2 mb-2"
            value={itemsPerPage}
            onChange={(e) => setItemsPerPage(e.target.value)}
          >
            <option value="10">10</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          Entries
        </div>
        <div className="table-responsive d-flex">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Channel</th>
                <th scope="col">Client</th>
                <th scope="col">Status</th>
                <th scope="col">Backlog</th>
                <th scope="col">Script</th>
                <th scope="col">Voice</th>
                <th scope="col">Video</th>
                <th scope="col">Completed</th>
                <th scope="col">Ready To Upload</th>
                <th scope="col">Published</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1} </td>
                  <td>
                    <Link to={`project`} state={{state:item.status === 'active' ? 'activeTab' : 'inactiveTab',channel_id:item?.channel_id}}>{item?.channel}</Link>
                  </td>
                  <td>{item?.clientName ? item.clientName[0] : ""}</td>
                  <td>{item?.status}</td>
                  <td>{item?.backlog ? item.backlog[0].backlog : 0} </td>
                  <td>{item?.script ? item.script[0].script : 0} </td>
                  <td>{item?.voice ? item.voice[0].voice : 0} </td>
                  <td>{item?.video ? item.video[0].video : 0} </td>
                  <td>{item?.completed ? item.completed[0].comp : 0} </td>
                  <td>{item?.ready ? item.ready[0].ready : 0} </td>
                  <td>{item?.uploaded ? item.uploaded[0].upload : 0} </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <ReactPaginate
          previousLabel={"<<"}
          nextLabel={">>"}
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination justify-content-center"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
        />
      </div>
    </>
  );
};

export default Channel;
