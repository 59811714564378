import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "./Feature/Entry/login/loginSlice";
import dashboardReducer from "./Feature/Admin/Dashboard/dashboardSlice";
import forgotPasswordReducer from "./Feature/Entry/forgotPassword/forgotPasswordSlice";
import setPasswordReducer from "./Feature/Entry/setPassword/setPasswordSlice";
import registerReducer from "./Feature/Entry/RegisterSlice";
import channelReducer from "./Feature/Admin/ChannelManagement/channelSlice";
import userReducer from "./Feature/Admin/userManagement/userSlice";
import roleReducer from "./Feature/Admin/RoleManagement/roleSlice";
import userActionReducer from "./Feature/Admin/userManagement/userActionSlice";
import userFilterReducer from "./Feature/Admin/userManagement/userFilterSlice";
import reportReducer from "./Feature/Admin/TeamReport/reportSlice";
import getUserReducer from "./Feature/Admin/Dropdown/getUserSlice";
import reportFilterReducer from "./Feature/Admin/TeamReport/reportFilterSlice";
import channelFormReducer from "./Feature/Admin/ChannelManagement/ChannelFormSlice";
import channelEditReducer from "./Feature/Admin/ChannelManagement/ChannelEditSlice";
import channelDeleteReducer from "./Feature/Admin/ChannelManagement/ChannelDeleteSlice";
import projectReducer from "./Feature/Admin/ChannelManagement/kanban/projectSlice";
import updateProjectReducer from "./Feature/Admin/ChannelManagement/kanban/updateSlice";
import getUserEditReducer from "./Feature/Admin/Dropdown/getUserEditSlice";
import projectInfoReducer from "./Feature/Admin/ChannelManagement/kanban/projectInfoSlice";
import updateProjectDataReducer from "./Feature/Admin/ChannelManagement/kanban/updateProjectSlice";
import commentReducer from "./Feature/Admin/ChannelManagement/kanban/commentSlice";
import reportPaidReducer from "./Feature/Admin/TeamReport/reportPaidSlice";
import updateStateReducer from "./Feature/Admin/ChannelManagement/kanban/updateState";
import channelInfoReducer from "./Feature/Admin/projectManagementSlice";
import SubscriptionReducer from "./Feature/Admin/Subscription/SubscriptionSlice";
import ChatReducer from "./Feature/Admin/Chat/ChatSlice";

//resource
import L1RDashboardReducer from "./Feature/LevelOneResource/DashboardSlice";
import L1RAvailableProjectReducer from "./Feature/LevelOneResource/AvailableProjectSlice";
import L1RAssignedProjectReducer from "./Feature/LevelOneResource/AssignProjectSlice";
import L1RCommentReducer from "./Feature/LevelOneResource/CommentSlice";
import L1RReportReducer from "./Feature/LevelOneResource/ReportSlice";
import L1RRevisionReducer from "./Feature/LevelOneResource/RevisionSlice";

//client
import ClientDashboardReducer from "./Feature/Client/dashboardSlice";
import ClientChannelReducer from "./Feature/Client/channelSlice";
import ClientProjectReducer from "./Feature/Client/projectSlice";

//Project manager
import PmDashboardReducer from "./Feature/ProjectManager/dashboardSlice";
import PmChannelReducer from "./Feature/ProjectManager/channelSlice";
import PmReportReducer from "./Feature/ProjectManager/TeamReport/reportSlice";
import PmReportFilterReducer from "./Feature/ProjectManager/TeamReport/reportFilterSlice";
import PmReportPaidReducer from "./Feature/ProjectManager/TeamReport/reportPaidSlice";

//level Two Resources
import L2RDashboardReducer from "./Feature/LevelTwoResource/dashboardSlice";
import L2RChannelReducer from "./Feature/LevelTwoResource/ChannelSlice";

export default configureStore({
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  reducer: {
    login: loginReducer,
    dashboard: dashboardReducer,
    forgotPassword: forgotPasswordReducer,
    setPassword: setPasswordReducer,
    channel: channelReducer,
    role: roleReducer,
    userData: userReducer,
    userAction: userActionReducer,
    userFilter: userFilterReducer,
    report: reportReducer,
    dropdown: getUserReducer,
    reportFilter: reportFilterReducer,
    channelForm: channelFormReducer,
    channelEdit: channelEditReducer,
    channelDelete: channelDeleteReducer,
    projects: projectReducer,
    updateProject: updateProjectReducer,
    getUserEdit: getUserEditReducer,
    projectInfo: projectInfoReducer,
    updateProjectData: updateProjectDataReducer,
    comments: commentReducer,
    reportPaid: reportPaidReducer,
    updateState: updateStateReducer,
    L1RDashboard: L1RDashboardReducer,
    L1RAvailableProject: L1RAvailableProjectReducer,
    L1RAssignedProject: L1RAssignedProjectReducer,
    L1RComment: L1RCommentReducer,
    L1RReport: L1RReportReducer,
    L1RRevision: L1RRevisionReducer,
    clientDashboard: ClientDashboardReducer,
    clientChannel: ClientChannelReducer,
    clientProject: ClientProjectReducer,
    pmDashboard: PmDashboardReducer,
    pmChannel: PmChannelReducer,
    pmReport: PmReportReducer,
    pmReportFilter: PmReportFilterReducer,
    pmReportPaid: PmReportPaidReducer,
    L2RDashboard: L2RDashboardReducer,
    L2RChannel: L2RChannelReducer,
    channelInfo: channelInfoReducer,
    subscription: SubscriptionReducer,
    register: registerReducer,
    chat: ChatReducer,
  },
});
