import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../Pages/Entry/Login/Login";
import LoginForm from "../Pages/Entry/Login/LoginForm";
import ForgotPasswordForm from "../Pages/Entry/ForgotPassword/ForgotPasswordForm";
import SetPasswordForm from "../Pages/Entry/SetPassword/SetPasswordForm";
import MainLayout from "../Layouts/Sidebar/MainLayout";
import Dashboard from "../Pages/Admin/Dashboard";
import ChannelManagement from "../Pages/Admin/ChannelManagement/ChannelManagement";
import RoleManagement from "../Pages/Admin/RoleManagement";
import UserManagement from "../Pages/Admin/UserManagement/UserManagement";
import TeamReport from "../Pages/Admin/TeamReport/TeamReport";
import ProjectManagement from "../Pages/Admin/ChannelManagement/ProjectManagement";
import ProtectedRoute from "../Utils/ProtectedRoute";
import ResourceDashboard from "../Pages/LevelOneResources/Dashboard";
import AvailableProjects from "../Pages/LevelOneResources/AvailableProjects";
import MyProjects from "../Pages/LevelOneResources/MyProjects";
import MyReport from "../Pages/LevelOneResources/MyReport";
import MyRevisions from "../Pages/LevelOneResources/MyRevisions";
import ClientDashBoard from "../Pages/Client/Dashboard";
import ClientChannel from "../Pages/Client/Channel";
import ClientProject from "../Pages/Client/Kanban/Project";
import PmDashBoard from "../Pages/ProjectManager/Dashboard";
import PmChannel from "../Pages/ProjectManager/Channel";
import PmReport from "../Pages/ProjectManager/TeamReport";
import Resouce2Dashboard from "../Pages/LevelTwoResources/Dashboard";
import Resource2Channel from "../Pages/LevelTwoResources/Channel";
import Subscription from "../Pages/Admin/Subscription/Subscriptions";
import Upgrade from "../Pages/Admin/Subscription/Upgrade";
import Register from "../Pages/Entry/Register/Register";
import Chat from "../Pages/Admin/Chat/Chat";
import RequestNewFeature from "../Pages/Admin/RequestNewFeature";

const BaseRouter = () => {
  return (
    <>
      <Routes>
        <Route element={<Login />}>
          <Route path="/" element={<LoginForm />} />
          <Route path="/forgot-password" element={<ForgotPasswordForm />} />
          <Route path="/setPassword/:token" element={<SetPasswordForm />} />
          <Route path="/register/:id" element={<Register />} />
        </Route>
        {/* route for admin and sub admin */}
        <Route
          path="/admin"
          element={<ProtectedRoute allowedRoles={[1, 12]} />}
        >
          <Route element={<MainLayout />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="channel" element={<ChannelManagement />} />
            <Route path="channel/project" element={<ProjectManagement />} />
            <Route path="role" element={<RoleManagement />} />
            <Route path="user" element={<UserManagement />} />
            <Route path="team-report" element={<TeamReport />} />
            <Route path="subscription" element={<Subscription />} />
            <Route path="chat" element={<Chat />} />
            <Route path="request" element={<RequestNewFeature />}/>
          </Route>
        </Route>
            {/* route for admin only */}
            <Route path="/admin" element={<ProtectedRoute allowedRoles={[1]} />}>
          <Route element={<MainLayout />}>
            <Route path="subscription" element={<Subscription />} />
            <Route path="subscription/upgrade" element={<Upgrade />} />
          </Route>
        </Route>
        {/* route for client */}
        <Route path="/client" element={<ProtectedRoute allowedRoles={[2]} />}>
          <Route element={<MainLayout />}>
            <Route path="dashboard" element={<ClientDashBoard />} />
            <Route path="channel" element={<ClientChannel />} />
            <Route path="channel/project" element={<ClientProject />} />
          </Route>
        </Route>
        {/* route for script,writer, voice artist, video editor, thumbnail artist, description writer */}
        <Route
          path="/resource"
          element={<ProtectedRoute allowedRoles={[3, 4, 5, 7, 8]} />}
        >
          <Route element={<MainLayout />}>
            <Route path="dashboard" element={<ResourceDashboard />} />
            <Route path="available-projects" element={<AvailableProjects />} />
            <Route path="my-projects" element={<MyProjects />} />
            <Route path="my-report" element={<MyReport />} />
            <Route path="my-revisions" element={<MyRevisions />} />
          </Route>
        </Route>
        {/* route for pm */}
        <Route path="/pm" element={<ProtectedRoute allowedRoles={[6]} />}>
          <Route element={<MainLayout />}>
            <Route path="dashboard" element={<PmDashBoard />} />
            <Route path="channel" element={<PmChannel />} />
            <Route path="channel/:id" element={<ProjectManagement />} />
            <Route path="team-report" element={<PmReport />} />
          </Route>
        </Route>
        {/* route for qc and video uploader */}
        <Route
          path="/resource2"
          element={<ProtectedRoute allowedRoles={[9, 10]} />}
        >
          <Route element={<MainLayout />}>
            <Route path="dashboard" element={<Resouce2Dashboard/>} />
            <Route path="channel" element={<Resource2Channel />} />
            <Route path="channel/:id" element={<ProjectManagement />} />
          </Route>
        </Route>
      </Routes>
    </>
  );
};

export default BaseRouter;
