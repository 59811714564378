import React, { useEffect, useState } from "react";
import { UilPlusSquare, UilTimes, UilTrash } from "@iconscout/react-unicons";
import { FieldArray, Formik, Form, Field } from "formik";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  addRevisions,
  addRevisionsList,
  deleteRevisions,
  deleteRevisionsList,
  getRevisions,
  getRevisionsList,
} from "../../../../Feature/Admin/ChannelManagement/kanban/projectInfoSlice";

const Revision = ({ getVideoSuccess, project_id }) => {
  const [editor, setEditor] = useState("");
  const [selectEditor, setSelectEditor] = useState(false);
  const [showArray, setShowArray] = useState(false);
  const [list, setList] = useState([]);
  const {
    getRevisionListSuccess,
    deleteRevisionListSuccess,
    addRevisionListSuccess,
    getRevisionSuccess,
    deleteRevisionSuccess,
  } = useSelector((state) => state.projectInfo);
  const [revisionInitialValues, setRevisionInitialValues] = useState({
    revision: [
      {
        timestamp: "",
        explanation: "",
        link: "",
      },
    ],
  });
  const dispatch = useDispatch();

  const addRevision = (value) => {
    if (!editor) {
      setSelectEditor(true);
      return;
    } else {
      setSelectEditor(false);
      for (var i = 0; i < value.revision.length; i++) {
        var obj = {
          added_id: localStorage.getItem("user_id"),
          project_id: project_id,
          user_id: editor.user_id,
          timestamp: value.revision[i].timestamp,
          explanation: value.revision[i].explanation,
          link: value.revision[i].link,
        };
        dispatch(addRevisionsList(obj));
      }
      setShowArray(false);
      setRevisionInitialValues((prevState) => ({
        ...prevState,
        revision: [
          {
            timestamp: "",
            explanation: "",
            link: "",
          },
        ],
      }));
    }
  };

  useEffect(() => {
    if (project_id) {
      dispatch(getRevisions(project_id));
      dispatch(getRevisionsList(project_id));
    }
    // eslint-disable-next-line
  }, [project_id]);

  useEffect(() => {
    if (getRevisionSuccess) {
      setEditor("");
      if (getRevisionSuccess.video) {
        for (var i = 0; i < getRevisionSuccess.video.length; i++) {
          setEditor({
            user_id: getRevisionSuccess.video[i].user_id,
            first_name: getRevisionSuccess.video[i].first_name,
            last_name: getRevisionSuccess.video[i].last_name,
          });
        }
      }
    }
  }, [getRevisionSuccess]);

  useEffect(() => {
    if (getRevisionListSuccess) {
      setList(getRevisionListSuccess);
    }
  }, [getRevisionListSuccess]);

  const deleteData = (item) => {
    dispatch(deleteRevisionsList(item.id_revision_data));
  };

  useEffect(() => {
    if (deleteRevisionListSuccess) {
      dispatch(getRevisionsList(project_id));
    }
    // eslint-disable-next-line
  }, [deleteRevisionListSuccess]);

  useEffect(() => {
    if (addRevisionListSuccess) {
      dispatch(getRevisionsList(project_id));
    }
    // eslint-disable-next-line
  }, [addRevisionListSuccess]);

  useEffect(() => {
    if (deleteRevisionListSuccess) {
      setList(getRevisionListSuccess);
    }
    // eslint-disable-next-line
  }, [deleteRevisionListSuccess]);

  useEffect(() => {
    if (addRevisionListSuccess) {
      setList(getRevisionListSuccess);
    }
    // eslint-disable-next-line
  }, [addRevisionListSuccess]);

  useEffect(() => {
    if (deleteRevisionSuccess) {
      dispatch(getRevisions(project_id));
    }
    // eslint-disable-next-line
  }, [deleteRevisionSuccess]);

  return (
    <>
      <div className="col-md-4 mb-4">
        <label className="mb-3">Revision:</label>
        <Select
          options={getVideoSuccess.video}
          onChange={(value) => {
            if (value !== null) {
              setEditor({
                user_id: value.user_id,
                first_name: value.first_name,
                last_name: value.last_name,
              });
              const obj = {
                project_id: project_id,
                user_id: value.user_id,
              };
              dispatch(addRevisions(obj));
            } else {
              setEditor("");
              dispatch(deleteRevisions(project_id));
            }
          }}
          className="react-select-container"
          classNamePrefix="react-select"
          placeholder="Select Video Editor"
          getOptionLabel={(option) =>
            option.first_name + " " + option.last_name
          }
          getOptionValue={(option) =>
            option.first_name + " " + option.last_name
          }
          isClearable={true}
          value={editor}
        />
      </div>
      <div className="col-md-12 mb-4">
        <Formik
          initialValues={revisionInitialValues}
          onSubmit={(value) => {
            addRevision(value);
          }}
        >
          {({ values }) => (
            <Form>
              <>
                <FieldArray name="revision">
                  {({ insert, remove, push }) => (
                    <>
                      <label
                        style={{ cursor: "pointer" }}
                        className="mb-3"
                        onClick={() => {
                          setShowArray(true);
                          if (showArray) {
                            push({
                              timestamp: "",
                              explanation: "",
                              link: "",
                            });
                          }
                        }}
                      >
                        <UilPlusSquare color="#7b42d1" />
                        Add revisions:
                      </label>
                      <>
                        {showArray &&
                          values.revision.length > 0 &&
                          values.revision.map((revision, index) => (
                            <div className="row mb-3" key={index}>
                              <div className="col-md-3">
                                <Field
                                  name={`revision.${index}.timestamp`}
                                  type="text"
                                  placeholder="Enter timestamp"
                                  className="form-control"
                                />
                              </div>
                              <div className="col-md-3">
                                <Field
                                  name={`revision.${index}.explanation`}
                                  type="text"
                                  placeholder="Enter explanation"
                                  className="form-control"
                                />
                              </div>
                              <div className="col-md-3">
                                <Field
                                  name={`revision.${index}.link`}
                                  type="text"
                                  placeholder="Enter link"
                                  className="form-control"
                                />
                              </div>
                              <div className="col-md-3">
                                <button
                                  className="btn apply-button d-flex justify-content-center align-items-center"
                                  type="button"
                                  onClick={() => {
                                    setSelectEditor(false);
                                    remove(index);
                                  }}
                                >
                                  <UilTimes />
                                </button>
                              </div>
                            </div>
                          ))}
                      </>
                    </>
                  )}
                </FieldArray>
                {selectEditor && (
                  <div style={{ fontSize: "12px", color: "#e04f44" }}>
                    Please select Revision user to assign the revisions.{" "}
                  </div>
                )}
                {showArray && values.revision.length > 0 && (
                  <button type="submit" className="apply-button">
                    Apply
                  </button>
                )}
              </>
            </Form>
          )}
        </Formik>
      </div>

      <div>
        {list.length > 0 && (
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Timestamp</th>
                  <th>Explanation</th>
                  <th>Reference link</th>
                  <th>Added by</th>
                  <th>Role</th>
                  <th>Fixed</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {getRevisionListSuccess.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.timestamp}</td>
                    <td>{item.explanation}</td>
                    <td>{item.link}</td>
                    <td>{item.first_name + " " + item.last_name}</td>
                    <td>{item.role}</td>
                    <td><input type="checkbox" checked={item.state === '1'} /></td>
                    <td>
                      <UilTrash
                        color="#e04f44"
                        size="16"
                        style={{ cursor: "pointer" }}
                        onClick={() => deleteData(item)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
};

export default Revision;
