import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../Utils/Api";
import {
  QCGetChannelPath,
  VuGetChannelPath
} from "../../Utils/ApiPathResources";

const initialState = {
  isLoading: false,
  channels: [],
  channelsFail: "",
};

export const getL2RChannel = createAsyncThunk(
  "/client/getL2RChannel",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getL2RChannelPending());
      const role_id = Number(localStorage.getItem("role_id"));
      const user_id = Number(localStorage.getItem("user_id"));
      if (role_id === 9) {
        const res = await api.get(QCGetChannelPath + user_id);
        if (res.data) {
          thunkAPI.dispatch(getL2RChannelSuccess(res.data));
        }
      }
      if (role_id === 10) {
        const res = await api.get(VuGetChannelPath+ user_id);
        if (res.data) {
          thunkAPI.dispatch(getL2RChannelSuccess(res.data));
        }
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getL2RChannelFail(err));
    }
  }
);

const L2RChannel = createSlice({
  name: "L2R",
  initialState,
  reducers: {
    getL2RChannelPending: (state) => {
      state.isLoading = true;
    },
    getL2RChannelSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.channels = payload;
      state.channelsFail = "";
    },
    getL2RChannelFail: (state, { payload }) => {
      state.isLoading = false;
      state.channels = [];
      state.channelsFail = payload;
    },
  },
});

export const {
  getL2RChannelPending,
  getL2RChannelSuccess,
  getL2RChannelFail,
} = L2RChannel.actions;
export default L2RChannel.reducer;
