import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../Utils/Api";
import { getChannelNamePath, getChannelDescPath } from "../../Utils/ApiPath";

const initialState = {
  isLoading: false,
  channelInfo: {},
  channelInfoFail: "",
};

export const getChannelInfo = createAsyncThunk(
  "/client/getChannelInfo",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getChannelInfoPending());
      const res = await api.get(getChannelNamePath + _);
      const res1 = await api.get(getChannelDescPath + _);
      if (res.data && res1.data) {
        const obj = {
          channel_name: res.data[0].channel,
          description: res1.data[0].description,
        };
        thunkAPI.dispatch(getChannelInfoSuccess(obj));
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getChannelInfoFail(err));
    }
  }
);

const projectManagement = createSlice({
  name: "projectManagement",
  initialState,
  reducers: {
    getChannelInfoPending: (state) => {
      state.isLoading = true;
    },
    getChannelInfoSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.channelInfo = payload;
      state.channelInfoFail = "";
    },
    getChannelInfoFail: (state, { payload }) => {
      state.isLoading = false;
      state.channelInfo = {};
      state.channelInfoFail = payload;
    },
  },
});

export const {
  getChannelInfoPending,
  getChannelInfoSuccess,
  getChannelInfoFail,
} = projectManagement.actions;
export default projectManagement.reducer;
