import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  getChannelUserRestrict,
  getUserRestrict,
  restrictScript,
  restrictVideo,
  restrictVoice,
} from "../../../Feature/Admin/ChannelManagement/ChannelDeleteSlice";

function RestrictUser({ show, toggle, id }) {
  const dispatch = useDispatch();
  const { userRestrictSuccess, channelUserRestrictSuccess } = useSelector(
    (state) => state.channelDelete
  );
  const [script, setScript] = useState([]);
  const [voice, setVoice] = useState([]);
  const [video, setVideo] = useState([]);

  useEffect(() => {
    if (show && id) {
      dispatch(getUserRestrict(id));
      dispatch(getChannelUserRestrict(id));
      // setTimeout(() => {
      //   dispatch(getChannelUserRestrict(id));
      // }, 100);
    }
    // eslint-disable-next-line
  }, [show, id]);

  useEffect(() => {
    if (channelUserRestrictSuccess) {
      setScript(
        channelUserRestrictSuccess?.writer
          ? channelUserRestrictSuccess?.writer.map((item) => item)
          : []
      );
      setVoice(
        channelUserRestrictSuccess?.voice
          ? channelUserRestrictSuccess?.voice.map((item) => item)
          : []
      );
      setVideo(
        channelUserRestrictSuccess?.video
          ? channelUserRestrictSuccess?.video.map((item) => item)
          : []
      );
    }
  }, [channelUserRestrictSuccess]);

  return (
    <>
      <Modal
        show={show}
        onHide={toggle}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Restrict User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row modal-form-main p-4">
            <div className="col-md-12">
              <label>Select Script Writer</label>
              <Select
                options={userRestrictSuccess?.script_writer}
                className="react-select-container"
                classNamePrefix="react-select"
                placeholder="Select Script Writer"
                getOptionLabel={(option) =>
                  option.first_name + " " + option.last_name
                }
                getOptionValue={(option) =>
                  option.first_name + " " + option.last_name
                }
                isMulti={true}
                value={script}
                onChange={(value) => {
                  setScript(value.map((item) => item));
                  var obj = {};
                  obj.script_writer = value.map((item) => item);
                  obj.channel_id = id;
                  dispatch(restrictScript(obj));
                }}
              />
            </div>
            <div className="col-md-12 mt-3">
              <label>Select Voice Artist</label>
              <Select
                options={userRestrictSuccess?.voice_artist}
                className="react-select-container"
                classNamePrefix="react-select"
                placeholder="Select Voice Artist"
                getOptionLabel={(option) =>
                  option.first_name + " " + option.last_name
                }
                getOptionValue={(option) =>
                  option.first_name + " " + option.last_name
                }
                isMulti={true}
                value={voice}
                onChange={(value) => {
                  setVoice(value.map((item) => item));
                  var obj = {};
                  obj.voice_artist = value.map((item) => item);
                  obj.channel_id = id;
                  dispatch(restrictVoice(obj));
                }}
              />
            </div>
            <div className="col-md-12 mt-3">
              <label>Select Video Editor</label>
              <Select
                options={userRestrictSuccess?.video_editor}
                className="react-select-container"
                classNamePrefix="react-select"
                placeholder="Select Video Editor"
                getOptionLabel={(option) =>
                  option.first_name + " " + option.last_name
                }
                getOptionValue={(option) =>
                  option.first_name + " " + option.last_name
                }
                isMulti={true}
                value={video}
                onChange={(value) => {
                  setVideo(value.map((item) => item));
                  var obj = {};
                  obj.video_editor = value.map((item) => item);
                  obj.channel_id = id;
                  dispatch(restrictVideo(obj));
                }}
              />
            </div>
            <div className="col-md text-center mt-3">
              <button className="apply-button" onClick={toggle}>
                Update
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default RestrictUser;
