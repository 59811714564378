import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dateTimeFormat } from "../../../../Common/dateFormat";
import {
  addcomments,
  deletecomments,
  editcomments,
  getcomments,
} from "../../../../Feature/Admin/ChannelManagement/kanban/commentSlice";
import { UilMessage, UilPen, UilTrash } from "@iconscout/react-unicons";

export const SendComment = ({ id }) => {
  const [comment, setComment] = useState("");
  const dispatch = useDispatch();
  const handleComment = (comment) => {
    setComment(comment);
  };
  const sendComment = () => {
    const obj = {
      project_id: id,
      comment: comment,
      user_id: localStorage.getItem("user_id"),
    };
    dispatch(addcomments(obj));
    setComment("");
  };
  const autoGrow = (element) => {
    element.target.style.height = "5px";
    element.target.style.height = element.target.scrollHeight + "px";
  };
  return (
    <>
      <div className="d-flex">
        <div style={{ width: "90%" }} className="send-comment">
          <textarea
            className="comment-input"
            type="text"
            placeholder="Comment"
            value={comment}
            onChange={(e) => handleComment(e.target.value)}
            onInput={(e) => autoGrow(e)}
            style={{ height: "29px", overflow: "hidden" }}
          ></textarea>
        </div>
        <div
          style={{
            width: "10%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="send"
        >
          <button
            type="button"
            className="btn"
            style={{ display: "flex", alignItems: "center" }}
            onClick={sendComment}
          >
            <UilMessage color="#5f48ea" />
          </button>
        </div>
      </div>
    </>
  );
};

export const ReadComment = ({ id }) => {
  const dispatch = useDispatch();
  const {
    getCommentSuccess,
    addCommentSuccess,
    editCommentSuccess,
    deleteCommentSuccess,
  } = useSelector((state) => state.comments);
  const [hidden, setHidden] = useState({});
  const [text, setText] = useState("");
  const [user, setUser] = useState(0);
  useEffect(() => {
    if (id) {
      dispatch(getcomments(id));
      setUser(Number(localStorage.getItem("user_id")));
    }
    // eslint-disable-next-line
  }, [id]);
  useEffect(() => {
    if (addCommentSuccess || editCommentSuccess || deleteCommentSuccess) {
      dispatch(getcomments(id));
    }
    // eslint-disable-next-line
  }, [addCommentSuccess, editCommentSuccess, deleteCommentSuccess]);
  const getInitials = (value) => {
    let name = value;
    let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");
    let initials = [...name.matchAll(rgx)] || [];
    initials = (
      (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
    ).toUpperCase();
    return initials;
  };
  const toggleHidden = (index, item) => {
    setHidden({ ...hidden, [index]: !hidden[index] });
    setText(item);
  };
  const handleChange = (text) => {
    setText(text);
  };
  const submitComment = (id, index) => {
    const obj = {
      id: id,
      comment: text,
    };
    dispatch(editcomments(obj));
    setHidden({ ...hidden, [index]: !hidden[index] });
  };
  const deleteComment = (id) => {
    dispatch(deletecomments(id));
  };
  return (
    <>
      <div className="read-comment">
        {getCommentSuccess.getComment &&
          getCommentSuccess.getComment.map((item, index) => (
            <div
              className="d-flex p-3"
              key={index}
              style={
                user === item.user_id ? { flexDirection: "row-reverse" } : {}
              }
            >
              <div className="client-icon">
                {getInitials(item.first_name + " " + item.last_name)}
              </div>
              <div className="comment-box col-md p-2">
                <div className="comment-header d-flex justify-content-between mb-2">
                  <div className="sm-text">
                    {item.first_name + " " + item.last_name}
                  </div>
                  <div className="sm-text date d-flex align-items-center">
                    <div className="me-2">
                      {dateTimeFormat(item.created_at)}
                    </div>
                    {user === item.user_id && (
                      <div>
                        <UilPen
                          size="14"
                          color="#7c828d"
                          className="me-2"
                          onClick={(e) => toggleHidden(index, item.comment)}
                          style={{ cursor: "pointer" }}
                        />
                        <UilTrash
                          size="14"
                          color="#e04f44"
                          className="me-2"
                          onClick={() => deleteComment(item.comment_id)}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    )}
                  </div>
                </div>
                {!hidden[index] && (
                  <div className="comment-text">{item.comment}</div>
                )}
                {!!hidden[index] && (
                  <>
                    <input
                      type="text"
                      value={text}
                      onChange={(e) => handleChange(e.target.value)}
                      className="form-control"
                    />
                    <div className="text-end mt-3">
                      <button
                        type="button"
                        className="cancel-button me-2"
                        onClick={() =>
                          setHidden({ ...hidden, [index]: !hidden[index] })
                        }
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="apply-button"
                        onClick={() => submitComment(item.comment_id, index)}
                      >
                        Save
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          ))}
      </div>
    </>
  );
};
