import React, { useState, useEffect } from "react";
import { UilPlus, UilCommentDots } from "@iconscout/react-unicons";
import { useDispatch, useSelector } from "react-redux";
import {
  getAvailableProjects,
  takeProject,
} from "../../Feature/LevelOneResource/AvailableProjectSlice";
import { AlertToast } from "../../Common/Toast";
import { dateFormat } from "../../Common/dateFormat";
import Comments from "./Comments";
import ReactPaginate from "react-paginate";

const AvailableProjects = () => {
  const dispatch = useDispatch();
  const { availableProjects, takeProjectSuccess, takeProjectFail } =
    useSelector((state) => state.L1RAvailableProject);
  const [role, setRole] = useState("");
  const [data, setData] = useState([]);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [recordsPerPage, setRecordsPerPage] = useState(10);
  // const indexOfLastRecord = currentPage * recordsPerPage;
  // const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  // const currentRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);
  // const nPages = Math.ceil(data.length / recordsPerPage);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + Number(itemsPerPage);
  const currentItems = data.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(data.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    setItemOffset(newOffset);
  };

  const [alert, setAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [showComments, setShowComments] = useState(false);
  const [projectId, setProjectId] = useState(0);

  //UseEffects:
  useEffect(() => {
    const role = localStorage.getItem("role_id");
    if (role === "3" || role === "4" || role === "5") {
      setRole("level1");
    } else {
      setRole("level2");
    }
  }, []);
  useEffect(() => {
    dispatch(getAvailableProjects());
    setItemOffset(0);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (availableProjects) {
      setData(availableProjects);
    }
    // eslint-disable-next-line
  }, [availableProjects]);

  useEffect(() => {
    if (takeProjectSuccess) {
      setAlert(true);
      setAlertMsg("Task taken successfully");
      dispatch(getAvailableProjects());
    }
    if (takeProjectFail) {
      setAlert(true);
      setAlertMsg(takeProjectFail);
      dispatch(getAvailableProjects());
    }

    // eslint-disable-next-line
  }, [takeProjectSuccess, takeProjectFail]);

  // Handlers
  const submit = (item) => {
    if (role === "level1") {
      const obj = {
        project_id: item.project_id,
        channel_id: item.channel_id,
      };
      dispatch(takeProject(obj));
    } else if (role === "level2") {
      dispatch(takeProject(item.project_id));
    }
  };
  const closeToast = () => {
    setAlert(false);
  };
  const toggleComment = () => setShowComments(!showComments);
  const handleComment = (item) => {
    setShowComments(true);
    setProjectId(item);
  };
  return (
    <>
      <AlertToast
        show={alert}
        alert={(takeProjectSuccess && "success") || (takeProjectFail && "fail")}
        msg={alertMsg}
        close={closeToast}
      />
      <div className="available-projects">
        <div className="content-title">Available Projects</div>
        <div className="d-flex justify-content-end select-form-control">
          Show
          <select
            className="select-enteries ms-2 me-2 mb-2"
            value={itemsPerPage}
            onChange={(e) => setItemsPerPage(e.target.value)}
          >
            <option value="10">10</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          Entries
        </div>
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>#</th>
                <th>Project</th>
                {role === "level2" && <th>Channel</th>}
                {role === "level2" && <th>Created On</th>}
                <th>Action</th>
                {role === "level2" && <th>Comments</th>}
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.project_name}</td>
                  {role === "level2" && <td>{item.channel}</td>}
                  {role === "level2" && <td>{dateFormat(item.created_at)}</td>}
                  <td>
                    <button
                      type="button"
                      className="apply-button d-flex justify-content-center align-items-center"
                      onClick={() => submit(item)}
                    >
                      <UilPlus size="16" />
                      Take task
                    </button>
                  </td>
                  {role === "level2" && (
                    <td>
                      <button
                        type="button"
                        className="icon-btn"
                        onClick={() => handleComment(item.project_id)}
                      >
                        <UilCommentDots color="#7c828d" />
                      </button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <ReactPaginate
          previousLabel={"<<"}
          nextLabel={">>"}
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination justify-content-center"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
        />
        <Comments show={showComments} toggle={toggleComment} id={projectId} />
      </div>
    </>
  );
};

export default AvailableProjects;
