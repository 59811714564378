import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../Utils/Api";
import {
  getCommentPath,
  addCommentPath,
  deleteCommentPath,
  editCommentPath,
} from "../../Utils/ApiPath";

const initialState = {
  isLoading: false,
  getComments: [],
  getCommentsFail: "",
  addCommentLoading: false,
  addCommentSuccess: false,
  addCommentFail: "",
  editCommentLoading: false,
  editCommentSuccess: false,
  editCommentFail: "",
  deleteCommentLoading: false,
  deleteCommentSuccess: false,
  deleteCommentFail: "",
};

export const getComment = createAsyncThunk(
  "/resources/getComments",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getCommentsPending());
      const res = await api.get(getCommentPath + _);
      if (res.data) {
        thunkAPI.dispatch(getCommentsSuccess(res.data.getComment));
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getCommentsFail(err));
    }
  }
);

export const addcomments = createAsyncThunk(
  "/resources/addcomments",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(addCommentLoading());
      const res = await api.post(addCommentPath, _);
      thunkAPI.dispatch(addCommentSuccess(res.data));
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(addCommentFail(err));
    }
  }
);

export const editcomments = createAsyncThunk(
  "/resources/editcomments",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(editCommentLoading());
      const res = await api.put(editCommentPath + _.id, { comment: _.comment });
      thunkAPI.dispatch(editCommentSuccess(res.data));
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(editCommentFail(err));
    }
  }
);

export const deletecomments = createAsyncThunk(
  "/resources/deletecomments",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(deleteCommentLoading());
      const res = await api.delete(deleteCommentPath + _);
      thunkAPI.dispatch(deleteCommentSuccess(res.data));
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(deleteCommentFail(err));
    }
  }
);

const L1RComments = createSlice({
  name: "L1R",
  initialState,
  reducers: {
    getCommentsPending: (state) => {
      state.isLoading = true;
    },
    getCommentsSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.getComments = payload;
      state.getCommentsFail = "";
    },
    getCommentsFail: (state, { payload }) => {
      state.isLoading = false;
      state.getComments = [];
      state.getCommentsFail = payload;
    },
    addCommentLoading: (state) => {
      state.addCommentLoading = true;
    },
    addCommentSuccess: (state, { payload }) => {
      state.addCommentLoading = false;
      state.addCommentSuccess = payload;
      state.addCommentFail = "";
    },
    addCommentFail: (state, { payload }) => {
      state.addCommentLoading = false;
      state.addCommentSuccess = {};
      state.addCommentFail = payload;
    },
    editCommentLoading: (state) => {
      state.editCommentLoading = true;
    },
    editCommentSuccess: (state, { payload }) => {
      state.editCommentLoading = false;
      state.editCommentSuccess = payload;
      state.editCommentFail = "";
    },
    editCommentFail: (state, { payload }) => {
      state.editCommentLoading = false;
      state.editCommentSuccess = {};
      state.editCommentFail = payload;
    },
    deleteCommentLoading: (state) => {
      state.deleteCommentLoading = true;
    },
    deleteCommentSuccess: (state, { payload }) => {
      state.deleteCommentLoading = false;
      state.deleteCommentSuccess = payload;
      state.deleteCommentFail = "";
    },
    deleteCommentFail: (state, { payload }) => {
      state.deleteCommentLoading = false;
      state.deleteCommentSuccess = {};
      state.deleteCommentFail = payload;
    },
  },
});

export const {
  getCommentsSuccess,
  getCommentsPending,
  getCommentsFail,
  addCommentLoading,
  addCommentSuccess,
  addCommentFail,
  editCommentLoading,
  editCommentFail,
  editCommentSuccess,
  deleteCommentLoading,
  deleteCommentFail,
  deleteCommentSuccess,
} = L1RComments.actions;
export default L1RComments.reducer;
