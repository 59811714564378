//user status check
export const checkUserStatusPath = "/user/checkInactive/";

//level one resources(sw,vo,ve)
export const availableScriptCountPath = "/count/getAvailableScript/";
export const scriptCountPath = "/count/getScript/";
export const availableVoiceCountPath = "/count/getAvailableVoice/";
export const voiceCountPath = "/count/getVoice/";
export const availableVideoCountPath = "/count/getAvailableVideo/";
export const videoCountPath = "/count/getVideo/";
export const availableScriptProjectPath = "/script/getScriptProject/";
export const avaialableVoiceProjectPath = "/voice/getVoiceProject/";
export const avaialableVideoProjectPath = "/video/getVideoProject/";
export const takeScriptProjectPath = "/script/assign";
export const takeVoiceProjectPath = "/voice/assign";
export const takeVideoProjectPath = "/video/assign";
export const assignedScriptProjectPath = "/script/getAssignScript/";
export const assignedVoiceProjectPath = "/voice/getAssignVoice/";
export const assignedVideoProjectPath = "/video/getAssignVideo/";
export const uploadScriptProjectPath = "/script/assign/";
export const uploadVoiceProjectPath = "/voice/assign/";
export const uploadVideoProjectPath = "/video/assign/";
export const uploadScriptCompletedPath = "/script/assignCompleted/";
export const uploadVoiceCompletedPath = "/voice/assignCompleted/";
export const uploadVideoCompletedPath = "/video/assignCompleted/";

//resource-report
export const getScriptReportPath = "/script/getReport/";
export const getVoiceReportPath = "/voice/getReport/";
export const getVideoReportPath = "/video/getReport/";
export const getScriptReportByDatePath = "/script/getReportDate/";
export const getVoiceReportByDatePath = "/voice/getReportDate/";
export const getVideoReportByDatePath = "/video/getReportDate/";

//revision
export const getRevisionPath = "/revisionData/getProject/";
export const getRevisionListPath = "/revisionData/getProjectData/";
export const updateRevisionDataPath = "/revisionData/updateRevisionState/";
export const updateRevisionStatePath = "/revisionData/updateProjectState/";

//thumbnail artist and description writer
export const thumbDashboardCountPath = "/count/thumbnailCount/";
export const descDashboardCountPath = "/count/descriptionWriterCount/";
export const avaialableThumbProjectPath = "/thumbnail/getAvailableProject/";
export const avaialableDescProjectPath =
  "/descriptionWriter/getAvailableProject/";
export const takeThumbProjectPath = "/thumbnail/takeTask";
export const takeDescProjectPath = "/descriptionWriter/takeTask";
export const assignedThumbProjectPath = "/thumbnail/getMyProject/";
export const assignedDescProjectPath = "/descriptionWriter/getMyProject/";
export const uploadThumbCompletedPath = "/thumbnail/markProjectCompleted/";
export const uploadDescCompletedPath =
  "/descriptionWriter/markProjectCompleted/";
export const getThumbReportPath = "/thumbnail/getMyProjectState/";
export const getDescReportPath = "/descriptionWriter/getMyProjectState/";

//client
export const clientDashboardCountPath = "/count/clientCount/";
export const clientGetChannelPath = "/client/getChannel/";
export const addClientChannelPath = "/client/addChannel/";
export const addClientChannelUserPath = "/channel/addChannelUser/";
export const updateClientChannelPath = "/client/updateChannel/";
export const updateClientChannelStatusPath = "/client/updateChannelStatus/";
export const clientGetProjectPath = "/client/getChannelProject/";
export const clientAddProjectPath = "/client/addProject/";
export const clientAddProjectUserPath = "/client/addProjectUser/";
export const clientAddProjectChannelPath = "/client/addProjectChannel/";
export const clientSearchProjectPath = "client/getChannelProjectSearch/";

//project manager
export const pmDashboardCountPath = "/count/managerCount/";
export const pmDueProjectsPath = "/dashboard/getDueProjectManager/";
export const pmGetActiveChannelPath = "/manager/getChannelsActive/";
export const pmGetInactiveChannelPath = "/manager/getChannelInactive/";
//pm report
export const getPmReportPath = "/pm/getUsers";
export const getPmReportScriptPath = "/pm/getUsersScript";
export const getPmReportVoicePath = "/pm/getUsersVoice";
export const getPmReportVideoPath = "/pm/getUsersVideo";
export const getPmReportThumbnailPath = "/pm/getUsersThumbnail";
export const getPmReportDescPath = "/pm/getUsersDescription";
export const getPmReportDatePath = "/pm/getUsersStateDate";
export const getPmReportScriptDescPath = "/pm/getUsersScriptDescription";
export const getPmReportVoiceDescPath = "/pm/getUsersVoiceDescription";
export const getPmReportVideoDescPath = "/pm/getUsersVideoDescription";
export const getPmReportScriptThumbPath = "/pm/getUsersScriptThumbnail";
export const getPmReportVoiceThumbPath = "/pm/getUsersVoiceThumbnail";
export const getPmReportVideoThumbPath = "/pm/getUsersVideoThumbnail";
export const getPmReportScriptVoicePath = "/pm/getUsersScriptVoice";
export const getPmReportScriptVideoPath = "/pm/getUsersScriptVideo";
export const getPmReportScriptDatePath = "/pm/getUsersScriptDate";
export const getPmReportVoiceDatePath = "/pm/getUsersVoiceDate";
export const getPmReportVideoDatePath = "/pm/getUsersVideoDate";
export const getPmReportVoiceVideoPath = "/pm/getUsersVoiceVideo";
export const getPmReportThumbDescPath = "/pm/getUsersThumbnailDescription";
export const getPmReportScriptVoiceVideoPath = "/pm/getUsersScriptVoiceVideo";
export const getPmReportScriptVoiceVideoThumPath =
  "/pm/getUsersScriptVoiceVideoThumbnail";
export const getPmReportScriptVoiceVideoDescPath =
  "/pm/getUsersScriptVoiceVideoDescription";
export const getPmReportThumbDatePath = "/pm/getUsersThumbnailDate";
export const getPmReportDescDatePath = "/pm/getUsersDescriptionDate";
export const updatePmPaidPath = "/pm/updatePaid/";
export const searchProjectInChannelPath = "/pm/searchProjectInChannel/";

//level two resources
export const QCDashboardCountPath = "/count/qualityCheckerCount/";
export const VuDashboardCountPath = "/count/videoUploaderCount/";
export const QCGetChannelPath = "/qualityChecker/getChannel/";
export const VuGetChannelPath = "/videoUploader/getChannel/";
