import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Utils/Api";
import {
  getPmReportPath,
  getPmReportScriptPath,
  getPmReportVoicePath,
  getPmReportVideoPath,
  getPmReportThumbnailPath,
  getPmReportDescPath,
  getPmReportDatePath,
} from "../../../Utils/ApiPathResources";

const initialState = {
  getPmReportPending: false,
  getPmReportSuccess: [],
  getPmReportFail: "",
  getPmReportScript: [],
  getPmReportScriptFail: "",
  getPmReportVoice: [],
  getPmReportVoiceFail: "",
  getPmReportVideo: [],
  getPmReportVideoFail: "",
  getPmReportThumbnail: [],
  getPmReportThumbnailFail: "",
  getPmReportDesc: [],
  getPmReportDescFail: "",
  getPmReportDate: [],
  getPmReportDateFail: "",
};

export const getUser = createAsyncThunk("/pm/getUser", async (_, thunkAPI) => {
  try {
    thunkAPI.dispatch(getPmReportPending());
    const user_id = Number(localStorage.getItem("user_id"));
    const res = await api.get(getPmReportPath + "/" + _ + "/" + user_id);
    if (res) {
      thunkAPI.dispatch(getPmReportSuccess(res.data));
    }
    return res.data;
  } catch (err) {
    console.log(err);
    thunkAPI.dispatch(getPmReportFail(err));
  }
});

export const searchPmReportScript = createAsyncThunk(
  "/pm/getUserScript",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getPmReportPending());
      const user_id = Number(localStorage.getItem("user_id"));
      const res = await api.get(
        getPmReportScriptPath +
          "/" +
          _.id +
          "/" +
          Number(_.state) +
          "/" +
          user_id
      );
      if (res) {
        thunkAPI.dispatch(getPmReportScript(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getPmReportScriptFail(err));
    }
  }
);

export const searchPmReportVoice = createAsyncThunk(
  "/pm/getUserVoice",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getPmReportPending());
      const user_id = Number(localStorage.getItem("user_id"));
      const res = await api.get(
        getPmReportVoicePath +
          "/" +
          _.id +
          "/" +
          Number(_.state) +
          "/" +
          user_id
      );
      if (res) {
        thunkAPI.dispatch(getPmReportVoice(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getPmReportVoiceFail(err));
    }
  }
);

export const searchPmReportVideo = createAsyncThunk(
  "/pm/getUserVideo",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getPmReportPending());
      const user_id = Number(localStorage.getItem("user_id"));
      const res = await api.get(
        getPmReportVideoPath +
          "/" +
          _.id +
          "/" +
          Number(_.state) +
          "/" +
          user_id
      );
      if (res) {
        thunkAPI.dispatch(getPmReportVideo(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getPmReportVideoFail(err));
    }
  }
);

export const searchPmReportThumbnail = createAsyncThunk(
  "/pm/getUserThumbnail",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getPmReportPending());
      const user_id = Number(localStorage.getItem("user_id"));
      const res = await api.get(
        getPmReportThumbnailPath +
          "/" +
          _.id +
          "/" +
          Number(_.state) +
          "/" +
          user_id
      );
      if (res) {
        thunkAPI.dispatch(getPmReportThumbnail(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getPmReportThumbnailFail(err));
    }
  }
);

export const searchPmReportDesc = createAsyncThunk(
  "/pm/getUserDesc",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getPmReportPending());
      const user_id = Number(localStorage.getItem("user_id"));
      const res = await api.get(
        getPmReportDescPath + "/" + _.id + "/" + Number(_.state) + "/" + user_id
      );
      if (res) {
        thunkAPI.dispatch(getPmReportDesc(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getPmReportDescFail(err));
    }
  }
);

export const searchPmReportDate = createAsyncThunk(
  "/pm/getUserDate",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getPmReportPending());
      const user_id = Number(localStorage.getItem("user_id"));
      const res = await api.get(
        getPmReportDatePath +
          "/" +
          Number(_.state) +
          "/" +
          _.start_date +
          "/" +
          _.end_date +
          "/" +
          user_id
      );
      if (res) {
        thunkAPI.dispatch(getPmReportDate(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getPmReportDateFail(err));
    }
  }
);

const pmReportSlice = createSlice({
  name: "pmReportSlice",
  initialState,
  reducers: {
    getPmReportPending: (state) => {
      state.getPmReportPending = true;
    },
    getPmReportSuccess: (state, { payload }) => {
      state.getPmReportPending = false;
      state.getPmReportSuccess = payload;
      state.getPmReportFail = "";
    },
    getPmReportFail: (state, payload) => {
      state.getPmReportPending = false;
      state.getPmReportSuccess = [];
      state.getPmReportFail = payload;
    },
    getPmReportScript: (state, { payload }) => {
      state.getPmReportPending = false;
      state.getPmReportScript = payload;
      state.getPmReportScriptFail = "";
    },
    getPmReportScriptFail: (state, payload) => {
      state.getPmReportPending = false;
      state.getPmReportScript = [];
      state.getPmReportScriptFail = payload;
    },
    getPmReportVoice: (state, { payload }) => {
      state.getPmReportPending = false;
      state.getPmReportVoice = payload;
      state.getPmReportVoiceFail = "";
    },
    getPmReportVoiceFail: (state, payload) => {
      state.getPmReportPending = false;
      state.getPmReportVoice = [];
      state.getPmReportVoiceFail = payload;
    },
    getPmReportVideo: (state, { payload }) => {
      state.getPmReportPending = false;
      state.getPmReportVideo = payload;
      state.getPmReportVideoFail = "";
    },
    getPmReportVideoFail: (state, payload) => {
      state.getPmReportPending = false;
      state.getPmReportVideo = [];
      state.getPmReportVideoFail = payload;
    },
    getPmReportThumbnail: (state, { payload }) => {
      state.getPmReportPending = false;
      state.getPmReportThumbnail = payload;
      state.getPmReportThumbnailFail = "";
    },
    getPmReportThumbnailFail: (state, payload) => {
      state.getPmReportPending = false;
      state.getPmReportThumbnail = [];
      state.getPmReportThumbnailFail = payload;
    },
    getPmReportDesc: (state, { payload }) => {
      state.getPmReportPending = false;
      state.getPmReportDesc = payload;
      state.getPmReportDescFail = "";
    },
    getPmReportDescFail: (state, payload) => {
      state.getPmReportPending = false;
      state.getPmReportDesc = [];
      state.getPmReportDescFail = payload;
    },
    getPmReportDate: (state, { payload }) => {
      state.getPmReportPending = false;
      state.getPmReportDate = payload;
      state.getPmReportDateFail = "";
    },
    getPmReportDateFail: (state, payload) => {
      state.getPmReportPending = false;
      state.getPmReportDate = [];
      state.getPmReportDateFail = payload;
    },
  },
});

export const {
  getPmReportPending,
  getPmReportSuccess,
  getPmReportFail,
  getPmReportScript,
  getPmReportScriptFail,
  getPmReportVoice,
  getPmReportVoiceFail,
  getPmReportVideo,
  getPmReportVideoFail,
  getPmReportThumbnail,
  getPmReportThumbnailFail,
  getPmReportDesc,
  getPmReportDescFail,
  getPmReportDate,
  getPmReportDateFail,
} = pmReportSlice.actions;
export default pmReportSlice.reducer;
