import React from "react";
import { Formik, Form, Field } from "formik";
import { UpgradeValidationSchema } from "../../../Common/Validations";
import { UilExclamationTriangle } from "@iconscout/react-unicons";
import Select from "react-select";

const Upgrade = () => {
  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    plan: "",
  };
  const submit = () => { };
  return (
    <>
      <div className="upgrade">
        <div className="content-title">Upgrade Subscription</div>
        <div className="wrapper" style={{display:'block'}}>
          <div className="content-title text-center fw-bolder">
            Let's Get You Upgraded!
          </div>
          <div className="content-title text-center">
            Just need some info before moving forward
          </div>
          <div className="d-flex justify-content-center">
            <Formik
              initialValues={initialValues}
              validationSchema={UpgradeValidationSchema}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={submit}
            >
              {({ errors, touched }) => (
                <Form style={{width:'500px'}}>
                  <div className=" mt-3">
                    <label>First Name <span className="asterisk">*</span></label>
                    <Field
                      type="text"
                      id="first_name"
                      name="first_name"
                      className={
                        errors.first_name && touched.first_name
                          ? "form-control is-invalid"
                          : "form-control "
                      }
                      placeholder="Enter your First Name"
                    />
                    {/* error */}
                    {touched.first_name && errors.first_name ? (
                      <div className="invalid-feedback d-flex">
                        <UilExclamationTriangle size="15" className="me-1" />
                        {errors.first_name}
                      </div>
                    ) : null}
                  </div>

                  <div className=" mt-3">
                    <label>Last Name <span className="asterisk">*</span></label>
                    <Field
                      type="text"
                      id="last_name"
                      name="last_name"
                      className={
                        errors.last_name && touched.last_name
                          ? "form-control is-invalid"
                          : "form-control "
                      }
                      placeholder="Enter your Last Name"
                    />
                    {/* error */}
                    {touched.last_name && errors.last_name ? (
                      <div className="invalid-feedback d-flex">
                        <UilExclamationTriangle size="15" className="me-1" />
                        {errors.last_name}
                      </div>
                    ) : null}
                  </div>

                  <div className=" mt-3">
                    <label>Email <span className="asterisk">*</span></label>
                    <Field
                      type="text"
                      id="email"
                      name="email"
                      className={
                        errors.email && touched.email
                          ? "form-control is-invalid"
                          : "form-control "
                      }
                      placeholder="Enter your Email"
                    />
                    {/* error */}
                    {touched.email && errors.email ? (
                      <div className="invalid-feedback d-flex">
                        <UilExclamationTriangle size="15" className="me-1" />
                        {errors.email}
                      </div>
                    ) : null}
                  </div>

                  <div className=" mt-3">
                    <label>Select your current Plan <span className="asterisk">*</span></label>
                    <Select
                      options={[]}
                      className={
                        errors.plan && touched.plan
                          ? "react-select-container is-invalid"
                          : "react-select-container"
                      }
                      classNamePrefix="react-select"
                      placeholder="Select Current Plan"
                      getOptionLabel={(option) => option.plan}
                      getOptionValue={(option) => option.plan}
                      name="plan"
                    />
                    {/* error */}
                    {touched.plan && errors.plan ? (
                      <div className="invalid-feedback d-flex">
                        <UilExclamationTriangle size="15" className="me-1" />
                        {errors.plan}
                      </div>
                    ) : null}
                  </div>

                  <div className="mt-5">
                    <button className="delete-btn" type="submit">
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default Upgrade;