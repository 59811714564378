import React, { useState, useEffect } from "react";
import { UilPlus, UilPen } from "@iconscout/react-unicons";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getClientChannel } from "../../Feature/Client/channelSlice";
import ChannelForm from "./ChannelForm";
import { AlertToast } from "../../Common/Toast";
import ReactPaginate from "react-paginate";

const Channel = () => {
  const dispatch = useDispatch();
  const {
    channels,
    addClientChannel,
    editClientChannel,
    addClientChannelFail,
    editClientChannelFail,
  } = useSelector((state) => state.clientChannel);
  //Pagination hooks
  const [data, setData] = useState([]);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [recordsPerPage, setRecordsPerPage] = useState(10);
  // const indexOfLastRecord = currentPage * recordsPerPage;
  // const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  // const currentRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);
  // const nPages = Math.ceil(data.length / recordsPerPage);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + Number(itemsPerPage);
  const currentItems = data.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(data.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    setItemOffset(newOffset);
  };

  const [show, setShow] = useState(false);
  const [form, setForm] = useState("");
  const [channelInfo, setChannelInfo] = useState("");
  const [alert, setAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");

  //useeffects
  useEffect(() => {
    dispatch(getClientChannel());
    setItemOffset(0);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (channels) {
      setData(channels);
    }
  }, [channels]);

  useEffect(() => {
    if (addClientChannel) {
      setAlert(true);
      setAlertMsg("Added channel successfully!");
      dispatch(getClientChannel());
    } else if (editClientChannel) {
      setAlert(true);
      setAlertMsg("Updated channel successfully!");
      dispatch(getClientChannel());
    } else if (addClientChannelFail) {
      setAlert(true);
      setAlertMsg(addClientChannelFail);
    } else if (editClientChannelFail) {
      setAlert(true);
      setAlertMsg(editClientChannelFail);
    }
    // eslint-disable-next-line
  }, [
    addClientChannel,
    editClientChannel,
    addClientChannelFail,
    editClientChannelFail,
  ]);

  //handlers
  const toggle = () => setShow(!show);
  const handleShow = (item, channel) => {
    setShow(true);
    setForm(item);
    setChannelInfo(channel);
  };
  const closeToast = () => {
    setAlert(false);
  };
  return (
    <>
      <AlertToast
        show={alert}
        alert={addClientChannel || editClientChannel ? "success" : "fail"}
        msg={alertMsg}
        close={closeToast}
      />
      <div className="channel">
        <div className="content-title">Channel Management</div>
        <div className="row">
          <div className="col-md mb-2">
            <button
              type="button"
              className="add-button"
              onClick={() => handleShow("isAdd")}
            >
              <UilPlus size="16" /> &nbsp; Add Channel
            </button>
          </div>
        </div>
        <div className="d-flex justify-content-end select-form-control">
          Show
          <select
            className="select-enteries ms-2 me-2 mb-2"
            value={itemsPerPage}
            onChange={(e) => setItemsPerPage(e.target.value)}
          >
            <option value="10">10</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          Entries
        </div>
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>#</th>
                <th>Channel</th>
                <th>Description</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <Link to={`project`} state={{channel_id:item?.channel_id,channel_name:item?.channel}} relative="path">
                      {item?.channel}
                    </Link>
                  </td>
                  <td>{item.description}</td>
                  <td>{item.status}</td>
                  <td>
                    <button
                      type="button"
                      className="icon-btn d-flex justify-content-center align-items-center"
                      onClick={() => handleShow("isEdit", item)}
                    >
                      <UilPen color="#7c828d" size="20" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <ReactPaginate
          previousLabel={"<<"}
          nextLabel={">>"}
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination justify-content-center"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
        />
        <ChannelForm
          show={show}
          toggle={toggle}
          formType={form}
          data={channelInfo}
        />
      </div>
    </>
  );
};

export default Channel;
