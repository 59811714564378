import Modal from "react-bootstrap/Modal";
import paperPlane from "../../../Assets/Images/paperPlane.png";
function ReinviteModal({ show, toggle, data, trigger }) {
  return (
    <>
      <Modal show={show} className="re-invite" onHide={toggle} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="p-4">
          <div className="d-flex justify-content-center">
            <img src={paperPlane} alt="plane" />
          </div>
          <div className="d-flex justify-content-center">
            <p style={{ color: "rgb(124, 130, 141)" }}>
              Are you sure to reinvite {data.first_name + " " + data.last_name}{" "}
              user
            </p>
          </div>
          <div className="footer d-flex justify-content-between">
            <button type="button" className="cancel-btn" onClick={toggle}>
              Cancel
            </button>
            <button type="button" className="delete-btn" onClick={trigger}>
              Apply
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ReinviteModal;
