import { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import {
  UilEllipsisH,
  UilPen,
  UilTrash,
  UilCalender,
  UilUserTimes,
  UilInfoCircle,
} from "@iconscout/react-unicons";
import { dateFormat } from "../../../Common/dateFormat";
import ChannelForm from "./ChannelForm";
import DeleteModal from "../../../Common/DeleteModal";
import { useDispatch } from "react-redux";
import {
  deleteChannelId,
  updateChannelMode,
} from "../../../Feature/Admin/ChannelManagement/ChannelDeleteSlice";
import RestrictUser from "./RestrictUser";
import PublishDate from "./PublishDate";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";

function ChannelTable({ tableData, data, state }) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState("");
  const [deleteId, setDeleteId] = useState(0);
  const [showRestrictUser, setShowRestrictUser] = useState(false);
  const [id, setId] = useState(0);
  const [showPublishDate, setShowPublishDate] = useState(false);
  const [newData, setNewData] = useState([]);

  useEffect(() => {
    if (tableData) {
      let tempUser = tableData.map((item) =>
        item.channel_mode === 2 ? { ...item, isChecked: true } : item
      );
      setNewData(tempUser);
    }
  }, [tableData]);
  const openModal = (item) => {
    setShow(true);
    setFormData(item);
  };
  const toggleModal = () => {
    setShow(!show);
  };
  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };
  const sendDeleteData = (values) => {
    setShowDeleteModal(true);
    setDeleteData(values.channel);
    setDeleteId(values.channel_id);
  };
  const deleteChannel = () => {
    dispatch(deleteChannelId(deleteId));
    toggleDeleteModal();
  };
  const openRestrictUser = (item) => {
    setShowRestrictUser(true);
    setId(item.channel_id);
  };
  const toggleRestrictModal = () => {
    setShowRestrictUser(!showRestrictUser);
  };
  const openPublishDate = (item) => {
    setShowPublishDate(true);
    setId(item.channel_id);
  };
  const togglePublishDate = () => {
    setShowPublishDate(!showPublishDate);
  };
  const editChannelMode = (event) => {
    const { id, checked } = event.target;
    let tempUser = newData.map((item) =>
      item.channel_id === Number(id) ? { ...item, isChecked: checked } : item
    );
    const obj = {
      channel_id: id,
      mode: checked === true ? 2 : 1,
    };
    dispatch(updateChannelMode(obj));
    setNewData(tempUser);
  };

  return (
    <>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Channel</th>
              <th scope="col">Client</th>
              <th scope="col">Backlog</th>
              <th scope="col">Script</th>
              <th scope="col">Voice</th>
              <th scope="col">Video</th>
              <th scope="col">Completed</th>
              <th scope="col">Revision</th>
              <th scope="col">Ready To Upload</th>
              <th scope="col">Published</th>
              <th scope="col">Last Video Published</th>
              <th scope="col">
                <div style={{ display: "flex", alignItems: "end" }}>
                  Channel Settings
                  <Dropdown>
                    <Dropdown.Toggle style={{ backgroundColor: "#f0f1f3e6" }}>
                      <UilInfoCircle size="12" color="#7b68ee" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ lineHeight: "16px", width: "200px",}}>
                      <Dropdown.Item style={{ lineHeight: 1.6}}>
                        Quality Check At Each Step with Manual Mode or Quality Check at the end with
                        Automatic. At Default, Channels are set to Automatic
                        Mode.
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {newData.map((item, index) => (
              <tr key={index}>
                <td>{index + 1} </td>
                <td>
                  <Link
                    to={`project`}
                    state={{ state: state, channel_id: item?.channel_id }}
                    relative="path"
                  >
                    {item?.channel}
                  </Link>
                </td>
                <td>{(item?.clientName).join(", ")}</td>
                <td>{item?.backlog[0]?.backlog} </td>
                <td>{item?.script[0]?.script} </td>
                <td>{item?.voice[0]?.voice} </td>
                <td>{item?.video[0]?.video} </td>
                <td>{item?.completed[0]?.comp} </td>
                <td>{item?.revision[0]?.revision}</td>
                <td>{item?.ready[0]?.ready} </td>
                <td>{item?.uploaded[0]?.upload} </td>
                <td>
                  {dateFormat(item?.latest_upload)}
                  <UilCalender
                    size="18"
                    className="ms-2"
                    color="#7b68ee"
                    onClick={(e) => openPublishDate(item)}
                    style={{ cursor: "pointer" }}
                  />
                </td>
                <td style={{ width: "5%" }}>
                  <Form>
                    <Form.Check
                      type="switch"
                      id={item.channel_id}
                      checked={item?.isChecked || false}
                      onChange={(e) => editChannelMode(e, item)}
                    />
                  </Form>
                </td>
                <td>
                  <Dropdown drop="start">
                    <Dropdown.Toggle id="dropdown-basic">
                      <UilEllipsisH color="#7c828d" size="18" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={(e) => openModal(item)}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <UilPen size="16" color="#7c828d" className="me-2" />
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item
                        style={{
                          color: "#e04f44",
                          display: "flex",
                          alignItems: "center",
                        }}
                        onClick={() => openRestrictUser(item)}
                      >
                        <UilUserTimes
                          size="16"
                          color="#e04f44"
                          className="me-2"
                        />
                        Restrict User
                      </Dropdown.Item>
                      <Dropdown.Item
                        style={{
                          color: "#e04f44",
                          display: "flex",
                          alignItems: "center",
                        }}
                        onClick={() => sendDeleteData(item)}
                      >
                        <UilTrash size="16" color="#e04f44" className="me-2" />
                        Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <ChannelForm
          show={show}
          toggle={toggleModal}
          form={"isEdit"}
          formInfo={formData}
          data={data}
        />
        <DeleteModal
          show={showDeleteModal}
          toggle={toggleDeleteModal}
          head={"Channel"}
          data={deleteData}
          trigger={deleteChannel}
        />
        <RestrictUser
          show={showRestrictUser}
          toggle={toggleRestrictModal}
          id={id}
        />
        <PublishDate
          show={showPublishDate}
          toggle={togglePublishDate}
          id={id}
        />
      </div>
    </>
  );
}

export default ChannelTable;
