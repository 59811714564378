import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../Utils/Api";
import {
  updateThumbStatePath,
  updateDescStatePath,
} from "../../../../Utils/ApiPath";

const initialState = {
  updateStateLoading: false,
  updateThumbStateSuccess: false,
  updateThumbStateError: "",
  updateDescStateSuccess: false,
  updateDescStateError: "",
};

export const updateThumbState = createAsyncThunk(
  "/projects/updateThumbState",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(updateStateLoading());
      const res = await api.post(updateThumbStatePath, _);
      if (res) {
        thunkAPI.dispatch(updateThumbStateSuccess(res.data));
        return res.data;
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(updateThumbStateError(err.response.data));
      setTimeout(() => {
        thunkAPI.dispatch(clearThumbState());
      }, 3000);
      return err;
    }
  }
);

export const updateDescState = createAsyncThunk(
  "/projects/updateDescState",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(updateStateLoading());
      const res = await api.post(updateDescStatePath, _);
      if (res) {
        thunkAPI.dispatch(updateDescStateSuccess(res.data));
        return res.data;
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(updateDescStateError(err.response.data));
      setTimeout(() => {
        thunkAPI.dispatch(clearDescState());
      }, 3000);
      return err;
    }
  }
);

const updateStateSlice = createSlice({
  name: "updateStateSlice",
  initialState,
  reducers: {
    updateStateLoading: (state) => {
      state.updateStateLoading = true;
    },
    updateThumbStateSuccess: (state) => {
      state.updateStateLoading = false;
      state.updateThumbStateSuccess = true;
      state.updateThumbStateError = "";
    },
    updateThumbStateError: (state, { payload }) => {
      state.updateStateLoading = false;
      state.updateThumbStateSuccess = false;
      state.updateThumbStateError = payload;
    },
    updateDescStateSuccess: (state) => {
      state.updateStateLoading = false;
      state.updateDescStateSuccess = true;
      state.updateDescStateError = "";
    },
    updateDescStateError: (state, { payload }) => {
      state.updateStateLoading = false;
      state.updateDescStateSuccess = false;
      state.updateDescStateError = payload;
    },
    clearThumbState: (state) => {
      state.updateStateLoading = false;
      state.updateThumbStateSuccess = false;
      state.updateThumbStateError = "";
    },
    clearDescState: (state) => {
      state.updateStateLoading = false;
      state.updateDescStateSuccess = false;
      state.updateDescStateError = "";
    },
  },
});

export const {
  updateStateLoading,
  updateThumbStateSuccess,
  updateThumbStateError,
  updateDescStateSuccess,
  updateDescStateError,
  clearThumbState,
  clearDescState,
} = updateStateSlice.actions;
export default updateStateSlice.reducer;
