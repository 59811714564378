import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../Utils/Api";
import {
  clientGetChannelPath,
  addClientChannelPath,
  addClientChannelUserPath,
  updateClientChannelPath,
  updateClientChannelStatusPath,
} from "../../Utils/ApiPathResources";

const initialState = {
  isLoading: false,
  channels: [],
  channelsFail: "",
  addClientChannel: false,
  addClientChannelFail: "",
  editClientChannel: false,
  editClientChannelFail: "",
  editClientChannelStatus: false,
  editClientChannelStatusFail: "",
};

export const getClientChannel = createAsyncThunk(
  "/client/getClientChannel",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getClientChannelPending());
      var user_id = localStorage.getItem("user_id");
      const res = await api.get(clientGetChannelPath + user_id);
      if (res.data) {
        thunkAPI.dispatch(getClientChannelSuccess(res.data));
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getClientChannelFail(err));
    }
  }
);

export const addClientChannel = createAsyncThunk(
  "/client/addClientChannel",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(addClientChannelPending());
      var user_id = localStorage.getItem("user_id");
      _.client = user_id;
      const res = await api.post(addClientChannelPath, _);
      if (res.data) {
        const obj = {
          channel_id: res.data.channel_id,
          channel_user_id: user_id,
        };
        const res1 = await api.post(addClientChannelUserPath, obj);
        if (res1.data) {
          thunkAPI.dispatch(addClientChannelSuccess(res.data));
          setTimeout(() => {
            thunkAPI.dispatch(clearAddClientChannel());
          }, 3000);
        }
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(addClientChannelFail(err.response.data.error));
      setTimeout(() => {
        thunkAPI.dispatch(clearAddClientChannel());
      }, 3000);
    }
  }
);

export const editClientChannel = createAsyncThunk(
  "/client/editClientChannel",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(editClientChannelPending());
      var user_id = localStorage.getItem("user_id");
      const obj = {
        channel: _.channel,
        description: _.description,
        client: user_id,
      };
      const res = await api.put(updateClientChannelPath + _.channel_id, obj);
      if (res.data) {
        thunkAPI.dispatch(editClientChannelSuccess(res.data));
        setTimeout(() => {
          thunkAPI.dispatch(clearEditClientChannel());
        }, 3000);
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(editClientChannelFail(err.response.data.error));
      setTimeout(() => {
        thunkAPI.dispatch(clearEditClientChannel());
      }, 3000);
    }
  }
);

export const editClientChannelStatus = createAsyncThunk(
  "/client/editClientChannelStatus",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(editClientChannelStatusPending());
      const res = await api.put(updateClientChannelStatusPath + _.channel_id, {
        status: _.status,
      });
      if (res.data) {
        thunkAPI.dispatch(editClientChannelStatusSuccess(res.data));
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(editClientChannelStatusFail(err));
    }
  }
);

const clientDashboard = createSlice({
  name: "Client",
  initialState,
  reducers: {
    getClientChannelPending: (state) => {
      state.isLoading = true;
    },
    getClientChannelSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.channels = payload;
      state.channelsFail = "";
    },
    getClientChannelFail: (state, { payload }) => {
      state.isLoading = false;
      state.channels = [];
      state.channelsFail = payload;
    },
    addClientChannelPending: (state) => {
      state.isLoading = true;
    },
    addClientChannelSuccess: (state) => {
      state.isLoading = false;
      state.addClientChannel = true;
      state.addClientChannelFail = "";
    },
    addClientChannelFail: (state, { payload }) => {
      state.isLoading = false;
      state.addClientChannel = false;
      state.addClientChannelFail = payload;
    },
    editClientChannelPending: (state) => {
      state.isLoading = true;
    },
    editClientChannelSuccess: (state) => {
      state.isLoading = false;
      state.editClientChannel = true;
      state.editClientChannelFail = "";
    },
    editClientChannelFail: (state, { payload }) => {
      state.isLoading = false;
      state.editClientChannel = false;
      state.editClientChannelFail = payload;
    },
    editClientChannelStatusPending: (state) => {
      state.isLoading = true;
    },
    editClientChannelStatusSuccess: (state) => {
      state.isLoading = false;
      state.editClientChannelStatus = true;
      state.editClientChannelStatusFail = "";
    },
    editClientChannelStatusFail: (state, { payload }) => {
      state.isLoading = false;
      state.editClientChannelStatus = false;
      state.editClientChannelStatusFail = payload;
    },
    clearAddClientChannel: (state) => {
      state.isLoading = false;
      state.addClientChannel = false;
      state.addClientChannelFail = "";
    },
    clearEditClientChannel: (state) => {
      state.isLoading = false;
      state.editClientChannel = false;
      state.editClientChannelFail = "";
    },
  },
});

export const {
  getClientChannelPending,
  getClientChannelSuccess,
  getClientChannelFail,
  addClientChannelPending,
  addClientChannelSuccess,
  addClientChannelFail,
  editClientChannelPending,
  editClientChannelSuccess,
  editClientChannelFail,
  editClientChannelStatusPending,
  editClientChannelStatusSuccess,
  editClientChannelStatusFail,
  clearAddClientChannel,
  clearEditClientChannel,
} = clientDashboard.actions;
export default clientDashboard.reducer;
