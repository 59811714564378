import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Utils/Api";
import { updatePaidPath } from "../../../Utils/ApiPath";

const initialState = {
  updatePaidLoading: false,
  updatePaidSuccess: false,
  updatePaidFail: "",
};

export const updateReportPaid = createAsyncThunk(
  "/user/updateReportPaid",
  async (_, thunkAPI) => {
    try {
      var obj = {
        state: 2,
        paid_by: _.paid_by,
      };
      thunkAPI.dispatch(updatePaidLoading());
      const res = await api.put(
        updatePaidPath + _.project_id + "/" + _.user_id,
        obj
      );
      if (res) {
        thunkAPI.dispatch(updatePaidSuccess(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(updatePaidFail(err));
    }
  }
);

const reportPaidSlice = createSlice({
  name: "reportPaidSlice",
  initialState,
  reducers: {
    updatePaidLoading: (state) => {
      state.updatePaidLoading = true;
    },
    updatePaidSuccess: (state) => {
      state.updatePaidLoading = false;
      state.updatePaidSuccess = true;
      state.updatePaidFail = "";
    },
    updatePaidFail: (state, payload) => {
      state.updatePaidLoading = false;
      state.updatePaidSuccess = false;
      state.updatePaidFail = payload;
    },
  },
});

export const { updatePaidLoading, updatePaidSuccess, updatePaidFail } =
  reportPaidSlice.actions;
export default reportPaidSlice.reducer;
