import React from "react";
import {
  UilUsersAlt,
  UilClipboardAlt,
  UilMicrophone,
  UilPresentationPlay,
  UilVideo,
  UilHourglass,
  UilArchiveAlt,
} from "@iconscout/react-unicons";


export const userInformation = [
  {
    icon: <UilUsersAlt size="30" color="#fff" />,
    name: "Clients",
    id: 'client'
  },
  {
    icon: <UilClipboardAlt size="30" color="#fff" />,
    name: "Script Writers",
    id:'script'
  },
  {
    icon: <UilMicrophone size="30" color="#fff" />,
    name: "Voice Artists",
    id:'voice'
  },
  {
    icon: <UilPresentationPlay size="30" color="#fff" />,
    name: "Video Editors",
    id:'video'
  },
];

export const projectInformation = [
  {
    icon: <UilVideo size="30" color="#fff" />,
    name: "Uploaded Projects",
    id:'completed'
  },
  {
    icon: <UilHourglass size="30" color="#fff" />,
    name: "Pending Projects",
    id:'pending'
  },
  {
    icon: <UilArchiveAlt size="30" color="#fff" />,
    name: "Archive Projects",
    id:'archive'
  },
];
