import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeChannels: [],
  inactiveChannels: [],
  isLoading: false,
  activeError: "",
  inActiveError: "",
  searchChannelByClient: [],
  searchChannelByClientError: "",
  searchChannel: [],
  searchChannelError: "",
};

const channelSlice = createSlice({
  name: "channel",
  initialState,
  reducers: {
    getChannelPending: (state) => {
      state.isLoading = true;
      state.activeError = "";
    },
    getActiveChannelSuccess: (state, { payload }) => {
      state.activeChannels = payload;
      state.activeError = "";
      state.isLoading = false;
    },
    getActiveChannelFail: (state, { payload }) => {
      state.activeChannels = [];
      state.activeError = payload;
      state.isLoading = false;
    },
    getInactiveChannelSuccess: (state, { payload }) => {
      state.inactiveChannels = payload;
      state.inActiveError = "";
      state.isLoading = false;
    },
    getInactiveChannelFail: (state, { payload }) => {
      state.inactiveChannels = [];
      state.inActiveError = payload;
      state.isLoading = false;
    },
    searchChannelByClient: (state, { payload }) => {
      state.isLoading = false;
      state.searchChannelByClient = payload;
      state.searchChannelByClientError = "";
    },
    searchChannelByClientError: (state, { payload }) => {
      state.isLoading = false;
      state.searchChannelByClient = [];
      state.searchChannelByClientError = payload;
    },
    searchChannel: (state, { payload }) => {
      state.isLoading = false;
      state.searchChannel = payload;
      state.searchChannelError = "";
    },
    searchChannelError: (state, { payload }) => {
      state.isLoading = false;
      state.searchChannel = [];
      state.searchChannelError = payload;
    },
  },
});

const { reducer, actions } = channelSlice;

export const {
  getChannelPending,
  getActiveChannelSuccess,
  getActiveChannelFail,
  getInactiveChannelSuccess,
  getInactiveChannelFail,
  searchChannelByClient,
  searchChannelByClientError,
  searchChannel,
  searchChannelError,
} = actions;

export default reducer;
