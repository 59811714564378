export const dateFormat = (date) => {
  if (date === null) {
    return "-";
  }
  const options = { year: "numeric", month: "short", day: "numeric" };
  const inputDate = new Date(date).toLocaleDateString("en", options);
  return inputDate;
};

export const dateTimeFormat = (date) => {
  if (date === null) {
    return "-";
  }
  const options = { year: "numeric", month: "short", day: "numeric" };
  const inputDate = new Date(date).toLocaleString("en", options);
  return inputDate;
};
