import React, { useState } from "react";
import Board from "./Board";
import {
  getClientProjects,
  searchClientProjects,
} from "../../../Feature/Client/projectSlice";
import { useDispatch } from "react-redux";
// import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

const Project = () => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  // let { id } = useParams();
  const location = useLocation();
  const data = location.state?.channel_id;
  const channel_name = location.state?.channel_name;

  const onChangeHandler = (text) => {
    setSearchText(text);
    const obj = {
      channel_id: data,
      regex: text,
    };
    if (text) {
      dispatch(searchClientProjects(obj));
    } else {
      dispatch(getClientProjects(data));
    }
  };

  return (
    <>
      <div className="project-management">
        <div className="content-title">
          Channel Management -{" "}
          <span style={{ color: "#5f48ea" }}>{channel_name}</span>
        </div>
        <div
          style={{
            textAlign: "end",
            marginBottom: "40px",
            marginRight: "50px",
          }}
        >
          <input
            type="text"
            className="searchBox"
            placeholder="Search"
            value={searchText}
            onChange={(e) => onChangeHandler(e.target.value)}
            style={{ width: "20%" }}
          />
        </div>
        <div className="wrapper">
          <Board />
        </div>
      </div>
    </>
  );
};

export default Project;
