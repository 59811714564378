import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import warning from "../../../../Assets/Images/warning.png";
import { useDispatch } from "react-redux";
import { clearError } from "../../../../Feature/Admin/ChannelManagement/kanban/updateSlice";

function WarningModal({ show, toggle, msg }) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (show) {
      setTimeout(() => {
        toggle();
        dispatch(clearError());
      }, 3000);
    }
    // eslint-disable-next-line
  }, [show]);
  return (
    <>
      <Modal show={show} onHide={toggle} centered>
        <Modal.Body className="p-4">
          <div className="d-flex justify-content-center align-items-center">
            <img src={warning} alt="warning" width={200} height={200} />
          </div>
          <div className="text-center">
            <p className="mt-5">{msg}</p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default WarningModal;
