import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { UilMessage, UilPen, UilTrash } from "@iconscout/react-unicons";
import { useDispatch, useSelector } from "react-redux";
import {
  addcomments,
  deletecomments,
  editcomments,
  getComment,
} from "../../Feature/LevelOneResource/CommentSlice";
import { getInitials } from "../../Common/CommonFunction";
import { dateTimeFormat } from "../../Common/dateFormat";

const Comments = ({ show, toggle, id }) => {
  const dispatch = useDispatch();
  const {
    getComments,
    addCommentSuccess,
    editCommentSuccess,
    deleteCommentSuccess,
  } = useSelector((state) => state.L1RComment);
  const [comment, setComment] = useState("");
  const [editComment, setEditComment] = useState("");
  const [user, setUser] = useState(0);
  const [hidden, setHidden] = useState({});

  //useEffects
  useEffect(() => {
    if (show) {
      dispatch(getComment(id));
      setUser(Number(localStorage.getItem("user_id")));
    }
    // eslint-disable-next-line
  }, [show]);

  useEffect(() => {
    if (addCommentSuccess) {
      dispatch(getComment(id));
    }
    // eslint-disable-next-line
  }, [addCommentSuccess]);

  useEffect(() => {
    if (editCommentSuccess) {
      dispatch(getComment(id));
    }
    // eslint-disable-next-line
  }, [editCommentSuccess]);

  useEffect(() => {
    if (deleteCommentSuccess) {
      dispatch(getComment(id));
    }
    // eslint-disable-next-line
  }, [deleteCommentSuccess]);

  const sendComment = () => {
    const obj = {
      user_id: localStorage.getItem("user_id"),
      project_id: id,
      comment: comment,
    };
    dispatch(addcomments(obj));
    setComment("");
  };
  const toggleHidden = (index, item) => {
    setHidden({ ...hidden, [index]: !hidden[index] });
    setEditComment(item);
  };
  const deleteComment = (id) => {
    dispatch(deletecomments(id));
  };
  const submitComment = (id, index) => {
    const obj = {
      id: id,
      comment: editComment,
    };
    dispatch(editcomments(obj));
    setHidden({ ...hidden, [index]: !hidden[index] });
  };
  const handleChange = (text) => {
    setEditComment(text);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={toggle}
        size="md"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Comments</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4" style={{ background: "#fbfbfb" }}>
          <div className="read-comment">
            {getComments &&
              getComments.map((item, index) => (
                <div
                  className="d-flex p-3"
                  key={index}
                  style={user === item.user_id ? { flexDirection:'row-reverse' } : {}}
                >
                  <div className="client-icon">
                    {getInitials(item.first_name + " " + item.last_name)}
                  </div>
                  <div className="comment-box col-md p-2">
                    <div className="comment-header d-flex justify-content-between mb-2">
                      <div className="sm-text">
                        {" "}
                        {item.first_name + " " + item.last_name}
                      </div>
                      <div className="sm-text date d-flex align-items-center">
                        <div className="me-2">
                          {dateTimeFormat(item.created_at)}
                        </div>
                        {user === item.user_id && (
                          <div>
                            <UilPen
                              size="14"
                              color="#7c828d"
                              className="me-2"
                              onClick={(e) => toggleHidden(index, item.comment)}
                              style={{ cursor: "pointer" }}
                            />
                            <UilTrash
                              size="14"
                              color="#e04f44"
                              className="me-2"
                              onClick={() => deleteComment(item.comment_id)}
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    {!hidden[index] && (
                      <div className="comment-text">{item.comment}</div>
                    )}
                    {!!hidden[index] && (
                      <>
                        <input
                          type="text"
                          value={editComment}
                          onChange={(e) => handleChange(e.target.value)}
                          className="form-control"
                        />
                        <div className="text-end mt-3">
                          <button
                            type="button"
                            className="cancel-button me-2"
                            onClick={() =>
                              setHidden({ ...hidden, [index]: !hidden[index] })
                            }
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="apply-button"
                            onClick={() =>
                              submitComment(item.comment_id, index)
                            }
                          >
                            Save
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ))}
          </div>
          <div className="d-flex">
            <div style={{ width: "90%" }} className="send-comment">
              <input
                className="comment-input"
                type="text"
                placeholder="Comment"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </div>
            <div
              style={{
                width: "10%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="send"
            >
              <button
                type="button"
                className="btn"
                style={{ display: "flex", alignItems: "center" }}
                onClick={sendComment}
              >
                <UilMessage color="#5f48ea" />
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Comments;
