import React, { memo, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { UilExclamationTriangle } from "@iconscout/react-unicons";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  getChannelUser,
  postChannel,
} from "../../../Feature/Admin/ChannelManagement/ChannelFormSlice";
import { Formik, Form, Field } from "formik";
import { ChannelValidationSchema } from "../../../Common/Validations";
import {
  updateChannel,
  updateClient,
  updateDesc,
  updatePm,
  updateQc,
  updateScript,
  updateStatus,
  updateThumb,
  updateVideo,
  updateVoice,
  updateVu,
} from "../../../Feature/Admin/ChannelManagement/ChannelEditSlice";

function ChannelForm({ show, toggle, data, form, formInfo }) {
  const dispatch = useDispatch();
  //Hooks
  const [initialValues, setInitialValues] = useState({
    channel_name: "",
    channel_description: "",
    client: [],
    script_writer: [],
    voice_artist: [],
    video_editor: [],
    project_manager: [],
    thumbnail_artist: [],
    description_writer: [],
    quality_checker: [],
    video_uploader: [],
    status: "active",
  });

  const { channelUser } = useSelector((state) => state.channelForm);
  const { statusError } = useSelector((state) => state.channelEdit);

  const [client, setClient] = useState([]);
  const [script, setScript] = useState([]);
  const [voice, setVoice] = useState([]);
  const [video, setVideo] = useState([]);
  const [pm, setPm] = useState([]);
  const [thumb, setThumb] = useState([]);
  const [desc, setDesc] = useState([]);
  const [qc, setQc] = useState([]);
  const [vu, setVu] = useState([]);
  const [status, setStatus] = useState([]);

  useEffect(() => {
    if (data && show && form === "isAdd") {
      setInitialValues((prevState) => ({
        ...prevState,
        script_writer: data?.writer
          ? data?.writer.map((item) => item.user_id)
          : [],
        voice_artist: data?.voice
          ? data?.voice.map((item) => item.user_id)
          : [],
        video_editor: data?.video
          ? data?.video.map((item) => item.user_id)
          : [],
        project_manager: data?.projectManger
          ? data?.projectManger.map((item) => item.user_id)
          : [],
        thumbnail_artist: data?.thumbnail
          ? data?.thumbnail.map((item) => item.user_id)
          : [],
        description_writer: data?.descriptionWriter
          ? data?.descriptionWriter.map((item) => item.user_id)
          : [],
        quality_checker: data?.qualityChecker
          ? data?.qualityChecker.map((item) => item.user_id)
          : [],
        video_uploader: data?.videoUploader
          ? data?.videoUploader.map((item) => item.user_id)
          : [],
      }));
      setClient([]);
      setScript(data?.writer ? data?.writer.map((item) => item) : []);
      setVoice(data?.voice ? data?.voice.map((item) => item) : []);
      setVideo(data?.video ? data?.video.map((item) => item) : []);
      setPm(data?.projectManger ? data?.projectManger.map((item) => item) : []);
      setThumb(data?.thumbnail ? data?.thumbnail.map((item) => item) : []);
      setDesc(
        data?.descriptionWriter
          ? data?.descriptionWriter.map((item) => item)
          : []
      );
      setQc(
        data?.qualityChecker ? data?.qualityChecker.map((item) => item) : []
      );
      setVu(data?.videoUploader ? data?.videoUploader.map((item) => item) : []);
      setStatus({
        value: "active",
        label: "Active",
      });
    }
    // eslint-disable-next-line
  }, [show, form]);

  useEffect(() => {
    if (form === "isEdit" && show) {
      dispatch(getChannelUser(formInfo.channel_id));
    }
    // eslint-disable-next-line
  }, [form, show]);

  useEffect(() => {
    if (form === "isEdit" && show) {
      if (channelUser) {
        setClient(
          channelUser?.client ? channelUser?.client.map((item) => item) : []
        );
        setScript(
          channelUser?.writer ? channelUser?.writer.map((item) => item) : []
        );
        setVoice(
          channelUser?.voice ? channelUser?.voice.map((item) => item) : []
        );
        setVideo(
          channelUser?.video ? channelUser?.video.map((item) => item) : []
        );
        setPm(
          channelUser?.manager ? channelUser?.manager.map((item) => item) : []
        );
        setThumb(
          channelUser?.thumbnail
            ? channelUser?.thumbnail.map((item) => item)
            : []
        );
        setDesc(
          channelUser?.descriptionWriter
            ? channelUser?.descriptionWriter.map((item) => item)
            : []
        );
        setQc(
          channelUser?.qualityChecker
            ? channelUser?.qualityChecker.map((item) => item)
            : []
        );
        setVu(
          channelUser?.videoUploader
            ? channelUser?.videoUploader.map((item) => item)
            : []
        );
        setStatus({ value: formInfo.status, label: formInfo.status });
        setInitialValues({
          channel_name: formInfo.channel,
          channel_description: formInfo.description,
          status: formInfo?.status,
          client: channelUser?.client
            ? channelUser?.client.map((item) => item.user_id)
            : [],
          script_writer: channelUser?.writer
            ? channelUser?.writer.map((item) => item.user_id)
            : [],
          voice_artist: channelUser?.voice
            ? channelUser?.voice.map((item) => item.user_id)
            : [],
          video_editor: channelUser?.video
            ? channelUser?.video.map((item) => item.user_id)
            : [],
          project_manager: channelUser?.manager
            ? channelUser?.manager.map((item) => item.user_id)
            : [],
          thumbnail_artist: channelUser?.thumbnail
            ? channelUser?.thumbnail.map((item) => item.user_id)
            : [],
          description_writer: channelUser?.descriptionWriter
            ? channelUser?.descriptionWriter.map((item) => item.user_id)
            : [],
          quality_checker: channelUser?.qualityChecker
            ? channelUser?.qualityChecker.map((item) => item.user_id)
            : [],
          video_uploader: channelUser?.videoUploader
            ? channelUser?.videoUploader.map((item) => item.user_id)
            : [],
        });
      }
    }
    // eslint-disable-next-line
  }, [channelUser]);

  const options = [
    { value: "active", label: "Active" },
    { value: "inactive", label: "Inactive" },
  ];

  return (
    <>
      <Modal
        show={show}
        onHide={toggle}
        size="lg"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {form === "isAdd" ? "Add Channel" : "Edit Channel"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={ChannelValidationSchema}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={(values) => {
              if(statusError){
                return;
              }
              else if (form === "isAdd") {
                //console.log(values);
                dispatch(postChannel(values));
                toggle();
              } else if (form === "isEdit") {
                values.channel_id = formInfo.channel_id;
                //console.log(values);
                dispatch(updateChannel(values));
                toggle();
              }
            }}
          >
            {({ errors, touched, setFieldValue, handleChange }) => (
              <Form>
                <div className="row g-0 modal-form-main">
                  <div className="col-md p-4 main-content">
                    <div className="col-md mb-3">
                      <label htmlFor="channel_name">
                        Channel Name <span className="asterisk">*</span>
                      </label>
                      <Field
                        id="channel_name"
                        name="channel_name"
                        type="text"
                        placeholder="Enter Channel Name"
                        className={
                          errors.channel_name && touched.channel_name
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                      />
                      {touched.channel_name && errors.channel_name ? (
                        <div className="invalid-feedback d-flex">
                          <UilExclamationTriangle size="15" className="me-1" />
                          {errors.channel_name}
                        </div>
                      ) : null}
                    </div>

                    <div className="col-md mb-3">
                      <label htmlFor="channel_description">
                        Channel Description{" "}
                      </label>
                      <Field
                        as="textarea"
                        id="channel_description"
                        name="channel_description"
                        // value={formik.values.channel_description}
                        onChange={handleChange}
                        type="text"
                        placeholder="Enter Channel Description"
                        className="form-control"
                      />
                    </div>

                    <div className="col-md mb-3">
                      <label>
                        Client Name
                      </label>
                      <Select
                        name="client"
                        className={
                          errors.client && touched.client
                            ? "is-invalid react-select-container"
                            : "react-select-container"
                        }
                        classNamePrefix="react-select"
                        placeholder="Select Client"
                        onChange={(value) => {
                          setFieldValue(
                            "client",
                            value ? value.map((item) => item.user_id) : []
                          );
                          setClient(value.map((item) => item));
                          if (form === "isEdit") {
                            const obj = {
                              channel_id: formInfo.channel_id,
                              channel_user_id: value.map(
                                (item) => item.user_id
                              ),
                            };
                            // if (obj.channel_user_id.length !== 0) {
                              dispatch(updateClient(obj));
                            // }
                          }
                        }}
                        options={data?.clientData}
                        getOptionLabel={(option) =>
                          option.first_name + " " + option.last_name
                        }
                        getOptionValue={(option) =>
                          option.first_name + " " + option.last_name
                        }
                        isMulti="true"
                        value={client.map((item) => item)}
                      />

                      {touched.client && errors.client ? (
                        <div className="invalid-feedback d-flex">
                          <UilExclamationTriangle size="15" className="me-1" />
                          {errors.client}
                        </div>
                      ) : null}
                    </div>

                    <div className="col-md mb-3">
                      <label>Script Writer Name(s)</label>
                      <Select
                        placeholder="Select Script Writer"
                        options={data?.writer}
                        onChange={(value) => {
                          setFieldValue(
                            "script_writer",
                            value ? value.map((item) => item.user_id) : []
                          );
                          setScript(value.map((item) => item));
                          if (form === "isEdit") {
                            var obj = {};
                            obj.script_writer = value.map((item) => item);
                            obj.channel_id = formInfo.channel_id;
                            dispatch(updateScript(obj));
                          }
                        }}
                        getOptionLabel={(option) =>
                          option.first_name + " " + option.last_name
                        }
                        getOptionValue={(option) =>
                          option.first_name + " " + option.last_name
                        }
                        classNamePrefix="react-select"
                        isMulti="true"
                        value={script.map((item) => item)}
                      />
                    </div>

                    <div className="col-md mb-3">
                      <label>Voice Artist Name(s)</label>
                      <Select
                        placeholder="Select Voice Artist"
                        onChange={(value) => {
                          setFieldValue(
                            "voice_artist",
                            value ? value.map((item) => item.user_id) : []
                          );
                          setVoice(value.map((item) => item));
                          if (form === "isEdit") {
                            var obj = {};
                            obj.voice_artist = value.map((item) => item);
                            obj.channel_id = formInfo.channel_id;
                            dispatch(updateVoice(obj));
                          }
                        }}
                        classNamePrefix="react-select"
                        options={data?.voice}
                        isMulti="true"
                        getOptionLabel={(option) =>
                          option.first_name + " " + option.last_name
                        }
                        getOptionValue={(option) =>
                          option.first_name + " " + option.last_name
                        }
                        value={voice.map((item) => item)}
                      />
                    </div>

                    <div className="col-md mb-3">
                      <label>Video Editor Name(s)</label>
                      <Select
                        placeholder="Select Video Editor"
                        onChange={(value) => {
                          setFieldValue(
                            "video_editor",
                            value ? value.map((item) => item.user_id) : []
                          );
                          setVideo(value.map((item) => item));
                          if (form === "isEdit") {
                            var obj = {};
                            obj.video_editor = value.map((item) => item);
                            obj.channel_id = formInfo.channel_id;
                            dispatch(updateVideo(obj));
                          }
                        }}
                        classNamePrefix="react-select"
                        options={data?.video}
                        isMulti="true"
                        getOptionLabel={(option) =>
                          option.first_name + " " + option.last_name
                        }
                        getOptionValue={(option) =>
                          option.first_name + " " + option.last_name
                        }
                        value={video.map((item) => item)}
                      />
                    </div>
                  </div>

                  <div className="col-md vertical-line p-4">
                    <div className="col-md mb-3">
                      <label>
                        Project Manager(s)
                      </label>
                      <Select
                        className={
                          errors.project_manager && touched.project_manager
                            ? "is-invalid react-select-container"
                            : "react-select-container"
                        }
                        classNamePrefix="react-select"
                        placeholder="Select Project Manager"
                        onChange={(value) => {
                          setFieldValue(
                            "project_manager",
                            value ? value.map((item) => item.user_id) : []
                          );
                          setPm(value.map((item) => item));
                          if (form === "isEdit") {
                            var obj = {};
                            obj.manager = value.map((item) => item);
                            obj.channel_id = formInfo.channel_id;
                            if (obj.manager.length !== 0) {
                              dispatch(updatePm(obj));
                            }
                          }
                        }}
                        options={data?.projectManger}
                        isMulti="true"
                        getOptionLabel={(option) =>
                          option.first_name + " " + option.last_name
                        }
                        getOptionValue={(option) =>
                          option.first_name + " " + option.last_name
                        }
                        value={pm.map((item) => item)}
                      />
                      {touched.project_manager && errors.project_manager ? (
                        <div className="invalid-feedback d-flex">
                          <UilExclamationTriangle size="15" className="me-1" />
                          {errors.project_manager}
                        </div>
                      ) : null}
                    </div>

                    <div className="col-md mb-3">
                      <label>Thumbnail Artist(s)</label>
                      <Select
                        placeholder="Select Thumbnail Artist"
                        onChange={(value) => {
                          setFieldValue(
                            "thumbnail_artist",
                            value ? value.map((item) => item.user_id) : []
                          );
                          setThumb(value.map((item) => item));
                          if (form === "isEdit") {
                            var obj = {};
                            obj.thumbnail = value.map((item) => item);
                            obj.channel_id = formInfo.channel_id;
                            dispatch(updateThumb(obj));
                          }
                        }}
                        classNamePrefix="react-select"
                        options={data?.thumbnail}
                        isMulti="true"
                        getOptionLabel={(option) =>
                          option.first_name + " " + option.last_name
                        }
                        getOptionValue={(option) =>
                          option.first_name + " " + option.last_name
                        }
                        value={thumb.map((item) => item)}
                      />
                    </div>

                    <div className="col-md mb-3">
                      <label>Description Writer(s)</label>
                      <Select
                        placeholder="Select Description Writer"
                        onChange={(value) => {
                          setFieldValue(
                            "description_writer",
                            value ? value.map((item) => item.user_id) : []
                          );
                          setDesc(value.map((item) => item));
                          if (form === "isEdit") {
                            var obj = {};
                            obj.description_writer = value.map((item) => item);
                            obj.channel_id = formInfo.channel_id;
                            dispatch(updateDesc(obj));
                          }
                        }}
                        classNamePrefix="react-select"
                        options={data?.descriptionWriter}
                        isMulti="true"
                        getOptionLabel={(option) =>
                          option.first_name + " " + option.last_name
                        }
                        getOptionValue={(option) =>
                          option.first_name + " " + option.last_name
                        }
                        value={desc.map((item) => item)}
                      />
                    </div>

                    <div className="col-md mb-3">
                      <label>Quality Checker(s)</label>
                      <Select
                        placeholder="Select Quality Checker"
                        onChange={(value) => {
                          setFieldValue(
                            "quality_checker",
                            value ? value.map((item) => item.user_id) : []
                          );
                          setQc(value.map((item) => item));
                          if (form === "isEdit") {
                            var obj = {};
                            obj.quality_checker = value.map((item) => item);
                            obj.channel_id = formInfo.channel_id;
                            dispatch(updateQc(obj));
                          }
                        }}
                        classNamePrefix="react-select"
                        options={data?.qualityChecker}
                        isMulti="true"
                        getOptionLabel={(option) =>
                          option.first_name + " " + option.last_name
                        }
                        getOptionValue={(option) =>
                          option.first_name + " " + option.last_name
                        }
                        value={qc.map((item) => item)}
                      />
                    </div>

                    <div className="col-md mb-3">
                      <label>Video Uploader(s)</label>
                      <Select
                        placeholder="Select Video Uploader"
                        onChange={(value) => {
                          setFieldValue(
                            "video_editor",
                            value ? value.map((item) => item.user_id) : []
                          );
                          setVu(value.map((item) => item));
                          if (form === "isEdit") {
                            var obj = {};
                            obj.video_uploader = value.map((item) => item);
                            obj.channel_id = formInfo.channel_id;
                            dispatch(updateVu(obj));
                          }
                        }}
                        classNamePrefix="react-select"
                        options={data?.videoUploader}
                        isMulti="true"
                        getOptionLabel={(option) =>
                          option.first_name + " " + option.last_name
                        }
                        getOptionValue={(option) =>
                          option.first_name + " " + option.last_name
                        }
                        value={vu.map((item) => item)}
                      />
                    </div>

                    <div className="col-md mb-3">
                      <label>
                        Status <span className="asterisk">*</span>
                      </label>
                      <Select
                        className={
                          errors.status && touched.status
                            ? "is-invalid react-select-container"
                            : "react-select-container"
                        }
                        placeholder="Select Status"
                        onChange={(value) => {
                          setFieldValue("status", value.value);
                          setStatus({
                            value: value.value,
                            label: value.value,
                          });
                          if (form === "isEdit") {
                            var obj = {
                              channel_id: formInfo.channel_id,
                              status: value.value,
                            };
                            dispatch(updateStatus(obj));
                          }
                        }}
                        classNamePrefix="react-select"
                        options={options}
                        value={status}
                      />
                      {touched.status && errors.status ? (
                        <div className="invalid-feedback d-flex">
                          <UilExclamationTriangle size="15" className="me-1" />
                          {errors.status}
                        </div>
                      ) : null}
                      <div style={{ color: "#e04f44", fontSize: "12px" }}>{statusError}</div>
                    </div>
                  </div>

                  <div className="bottom-footer p-3 text-center">
                    <button className="apply-button" type="submit">
                      {form === "isAdd" ? "Add Channel" : "Update Channel"}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default memo(ChannelForm);
