import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getPmActiveChannel,
  getPmInactiveChannel,
  getSearchProjectInChannel,
} from "../../Feature/ProjectManager/channelSlice";
import ReactPaginate from "react-paginate";
import Dropdown from "react-bootstrap/Dropdown";
import { UilInfoCircle } from "@iconscout/react-unicons";
import Form from "react-bootstrap/Form";
import { updateChannelMode } from "../../Feature/Admin/ChannelManagement/ChannelDeleteSlice";

const Channel = () => {
  const tabData = [
    { key: "activeTab", title: "Active Channels" },
    { key: "inactiveTab", title: "Inactive Channels" },
  ];
  const dispatch = useDispatch();
  const { getPmActiveChannels, getPmInactiveChannels, getSearchChannel } =
    useSelector((state) => state.pmChannel);
  const { updateChannelModeSuccess } = useSelector(
    (state) => state.channelDelete
  );
  const [key, setKey] = useState("activeTab");
  const [data, setData] = useState([]);
  const [projectName, setProjectName] = useState("");
  // const [newData, setNewData] = useState([]);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [recordsPerPage, setRecordsPerPage] = useState(10);
  // const indexOfLastRecord = currentPage * recordsPerPage;
  // const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  // const currentRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);
  // const nPages = Math.ceil(data.length / recordsPerPage);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + Number(itemsPerPage);
  const currentItems = data.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(data.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    setItemOffset(newOffset);
  };

  //useeffects
  useEffect(() => {
    if (key === "activeTab") {
      dispatch(getPmActiveChannel());
      setItemOffset(0);
    } else if (key === "inactiveTab") {
      dispatch(getPmInactiveChannel());
      setItemOffset(0);
    }
    // eslint-disable-next-line
  }, [key]);

  useEffect(() => {
    if (getPmActiveChannels) {
      let tempUser = getPmActiveChannels.map((item) =>
        item.channel_mode === 2 ? { ...item, isChecked: true } : item
      );
      setData(tempUser);
    }
    // eslint-disable-next-line
  }, [getPmActiveChannels]);

  useEffect(() => {
    if (getPmInactiveChannels) {
      setData(getPmInactiveChannels);
    }
    // eslint-disable-next-line
  }, [getPmInactiveChannels]);

  useEffect(() => {
    if (getSearchChannel) {
      setData(getSearchChannel);
    }
    // eslint-disable-next-line
  }, [getSearchChannel]);

  const handleProjectChange = (selected) => {
    setProjectName(selected);
    if (selected) {
      const obj = {
        regx: selected,
        status: key === "activeTab" ? "active" : "inactive",
      };
      dispatch(getSearchProjectInChannel(obj));
    } else {
      if (key === "activeTab") {
        dispatch(getPmActiveChannel());
        setItemOffset(0);
      } else if (key === "inactiveTab") {
        dispatch(getPmInactiveChannel());
        setItemOffset(0);
      }
    }
  };

  const editChannelMode = (event) => {
    const { id, checked } = event.target;
    let tempUser = data.map((item) =>
      item.channel_id === Number(id) ? { ...item, isChecked: checked } : item
    );
    const obj = {
      channel_id: id,
      mode: checked === true ? 2 : 1,
    };
    dispatch(updateChannelMode(obj));
    setData(tempUser);
  };

  return (
    <>
      <div className="channel">
        <div className="content-title">Channel Management</div>
        <div className="row justify-content-end">
          <div className="col-md-3">
            <input
              type="text"
              className="searchBox"
              placeholder="Search Project Name"
              value={projectName}
              onChange={(e) => handleProjectChange(e.target.value)}
            />
          </div>
        </div>

        <div className="tabs-section mt-2">
          <Tabs
            defaultActiveKey="activeTab"
            id="uncontrolled-tab-example"
            className="mb-3"
            transition={true}
            activeKey={key}
            onSelect={(key) => setKey(key)}
          >
            {tabData &&
              tabData.map((item, index) => (
                <Tab key={index} eventKey={item.key} title={item.title}>
                  <div className="d-flex justify-content-end select-form-control">
                    Show
                    <select
                      className="select-enteries ms-2 me-2 mb-2"
                      value={itemsPerPage}
                      onChange={(e) => setItemsPerPage(e.target.value)}
                    >
                      <option value="10">10</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                    Entries
                  </div>
                  <div className="table-responsive d-flex">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Channel</th>
                          <th scope="col">Client</th>
                          <th scope="col">Backlog</th>
                          <th scope="col">Script</th>
                          <th scope="col">Voice</th>
                          <th scope="col">Video</th>
                          <th scope="col">Completed</th>
                          <th scope="col">Revision</th>
                          <th scope="col">Ready To Upload</th>
                          <th scope="col">Published</th>
                          <th scope="col">
                            <div style={{ display: "flex", alignItems: "end" }}>
                              Channel Settings
                              <Dropdown>
                                <Dropdown.Toggle
                                  style={{ backgroundColor: "#f0f1f3e6" }}
                                >
                                  <UilInfoCircle size="12" color="#7b68ee" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                  style={{ lineHeight: "16px", width: "200px" }}
                                >
                                  <Dropdown.Item style={{ lineHeight: 1.6 }}>
                                    Quality Check At Each Step with Manual Mode
                                    or Quality Check at the end with Automatic.
                                    At Default, Channels are set to Automatic
                                    Mode.
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1} </td>
                            <td>
                              <Link
                                to={`project`}
                                state={{
                                  state: key,
                                  channel_id: item?.channel_id,
                                }}
                                relative="path"
                              >
                                {item?.channel}
                              </Link>
                            </td>
                            <td>
                              {item?.clientName ? item.clientName[0] : ""}
                            </td>
                            <td>
                              {item?.backlog ? item.backlog[0].backlog : 0}{" "}
                            </td>
                            <td>{item?.script ? item.script[0].script : 0} </td>
                            <td>{item?.voice ? item.voice[0].voice : 0} </td>
                            <td>{item?.video ? item.video[0].video : 0} </td>
                            <td>
                              {item?.completed ? item.completed[0].comp : 0}{" "}
                            </td>
                            <td>
                              {item?.revision ? item.revision[0].revision : 0}{" "}
                            </td>
                            <td>{item?.ready ? item.ready[0].ready : 0} </td>
                            <td>
                              {item?.uploaded ? item.uploaded[0].upload : 0}{" "}
                            </td>
                            <td style={{ width: "5%" }}>
                              <Form>
                                <Form.Check
                                  type="switch"
                                  id={item.channel_id}
                                  checked={item?.isChecked === true || false}
                                  onChange={(e) => editChannelMode(e, item)}
                                />
                              </Form>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <ReactPaginate
                    previousLabel={"<<"}
                    nextLabel={">>"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-center"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </Tab>
              ))}
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default Channel;
