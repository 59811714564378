import React from "react";
import { Card } from "react-bootstrap";
import { UilShieldCheck } from "@iconscout/react-unicons";

const ProjectCard = ({ project, index, color, stage }) => {
  return (
    <>
      <div className="project-card" key={project.project_id}>
        <Card style={{ minHeight: "90px" }}>
          <div className="row">
            <div className="col-md-10">
              {project?.project_name}
              <div style={{ fontSize: "11px" }} className="mb-2 mt-2"></div>
              {stage === "Ready to upload" ? (
                <>
                  <div>
                    Link :{" "}
                    <a
                      href={project.project_link}
                      target="_blank"
                      rel="noreferrer"
                    >View project</a>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
            <div className="col-md-2">
              {project?.project_state === 1 ? (
                <UilShieldCheck color="#6bc950" size="20" />
              ) : project.project_state === 2 ? (
                <UilShieldCheck color="#f7ce51" size="20" />
              ) : (
                <UilShieldCheck color="#b9bec7" size="20" />
              )}
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

export default ProjectCard;
