import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Utils/Api";
import {
  deleteScriptPath,
  deleteUpdateClientPath,
  deleteVideoPath,
  deleteVoicePath,
  updateChannelPath,
  updateScriptPath,
  updateVoicePath,
  updateVideoPath,
  updatePmPath,
  updateThumbPath,
  updateDescPath,
  updateQcPath,
  updateVuPath,
  deleteManagerPath,
  deleteThumbPath,
  deleteDescPath,
  deleteQcPath,
  deleteVuPath,
  updateChannelStatusPath,
} from "../../../Utils/ApiPath";

const initialState = {
  editChannelPending: false,
  editChannelSuccess: false,
  editChannelError: "",
  clientPending: false,
  clientSuccess: false,
  clientError: "",
  scriptPending: false,
  scriptSuccess: false,
  scriptError: "",
  voicePending: false,
  voiceSuccess: false,
  voiceError: "",
  videoPending: false,
  videoSuccess: false,
  videoError: "",
  pmPending: false,
  pmSuccess: false,
  pmError: "",
  thumbPending: false,
  thumbSuccess: false,
  thumbError: "",
  descPending: false,
  descSuccess: false,
  descError: "",
  qcPending: false,
  qcSuccess: false,
  qcError: "",
  vuPending: false,
  vuSuccess: false,
  vuError: "",
  statusPending: false,
  statusSuccess: false,
  statusError: "",
};

export const updateChannel = createAsyncThunk(
  "/channelEdit/updateChannel",
  async (_, thunkAPI) => {
    try {
      const obj = {
        channel: _.channel_name,
        description: _.channel_description,
        status: _.status,
        client: _.client[0],
      };
      thunkAPI.dispatch(editChannelPending());
      const res = await api.put(updateChannelPath + "/" + _.channel_id, obj);
      if (res) {
        if (_.script_writer.length === 0) {
          await api.delete(deleteScriptPath + "/" + _.channel_id);
        }
        if (_.voice_artist.length === 0) {
          await api.delete(deleteVoicePath + "/" + _.channel_id);
        }
        if (_.video_editor.length === 0) {
          await api.delete(deleteVideoPath + "/" + _.channel_id);
        }
        thunkAPI.dispatch(editChannelSuccess(res.data));
        setTimeout(() => {
          thunkAPI.dispatch(clearState());
        }, 4000);
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(editChannelError(err));
    }
  }
);

export const updateClient = createAsyncThunk(
  "/channelEdit/updateClient",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(editClientPending());
      const res = await api.post(deleteUpdateClientPath, _);
      if (res) {
        thunkAPI.dispatch(editClientSuccess(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(editClientError(err));
    }
  }
);

export const updateScript = createAsyncThunk(
  "/channelEdit/updateScript",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(editScriptPending());
      const res = await api.delete(deleteScriptPath + "/" + _.channel_id);
      if (res) {
        if (_.script_writer.length !== 0) {
          for (let i = 0; i < _.script_writer.length; i++) {
            const obj = {
              channel_script_id: _.channel_id,
              channel_script_user_id: _.script_writer[i].user_id,
            };
            await api.post(updateScriptPath, obj);
          }
        }
        thunkAPI.dispatch(editScriptSuccess(res.data));
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(editScriptError(err));
    }
  }
);

export const updateVoice = createAsyncThunk(
  "/channelEdit/updateVoice",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(editVoicePending());
      const res = await api.delete(deleteVoicePath + "/" + _.channel_id);
      if (res) {
        if (_.voice_artist.length !== 0) {
          for (let i = 0; i < _.voice_artist.length; i++) {
            const obj = {
              channel_voice_id: _.channel_id,
              channel_voice_user_id: _.voice_artist[i].user_id,
            };
            await api.post(updateVoicePath, obj);
          }
        }
        thunkAPI.dispatch(editVoiceSuccess(res.data));
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(editVoiceError(err));
    }
  }
);

export const updateVideo = createAsyncThunk(
  "/channelEdit/updateVideo",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(editVideoPending());
      const res = await api.delete(deleteVideoPath + "/" + _.channel_id);
      if (res) {
        if (_.video_editor.length !== 0) {
          for (let i = 0; i < _.video_editor.length; i++) {
            const obj = {
              channel_video_id: _.channel_id,
              channel_video_user_id: _.video_editor[i].user_id,
            };
            await api.post(updateVideoPath, obj);
          }
        }
        thunkAPI.dispatch(editVideoSuccess(res.data));
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(editVideoError(err));
    }
  }
);

export const updatePm = createAsyncThunk(
  "/channelEdit/updatePm",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(editPmPending());
      const res = await api.delete(deleteManagerPath + "/" + _.channel_id);
      if (res) {
        if (_.manager.length !== 0) {
          for (let i = 0; i < _.manager.length; i++) {
            const obj = {
              channel_id: _.channel_id,
              channel_manager_user_id: _.manager[i].user_id,
            };
            await api.post(updatePmPath, obj);
          }
        }
        thunkAPI.dispatch(editPmSuccess(res.data));
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(editPmError(err));
    }
  }
);

export const updateThumb = createAsyncThunk(
  "/channelEdit/updateThumb",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(editThumbPending());
      const res = await api.delete(deleteThumbPath + "/" + _.channel_id);
      if (res) {
        if (_.thumbnail.length !== 0) {
          for (let i = 0; i < _.thumbnail.length; i++) {
            const obj = {
              channel_id: _.channel_id,
              channel_thumbnail_user_id: _.thumbnail[i].user_id,
            };
            await api.post(updateThumbPath, obj);
          }
        }
        thunkAPI.dispatch(editThumbSuccess(res.data));
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(editThumbError(err));
    }
  }
);

export const updateDesc = createAsyncThunk(
  "/channelEdit/updateDesc",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(editDescPending());
      const res = await api.delete(deleteDescPath + "/" + _.channel_id);
      if (res) {
        if (_.description_writer.length !== 0) {
          for (let i = 0; i < _.description_writer.length; i++) {
            const obj = {
              channel_id: _.channel_id,
              channel_description_writer_user_id:
                _.description_writer[i].user_id,
            };
            await api.post(updateDescPath, obj);
          }
        }
        thunkAPI.dispatch(editDescSuccess(res.data));
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(editDescError(err));
    }
  }
);

export const updateQc = createAsyncThunk(
  "/channelEdit/updateQc",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(editQcPending());
      const res = await api.delete(deleteQcPath + "/" + _.channel_id);
      if (res) {
        if (_.quality_checker.length !== 0) {
          for (let i = 0; i < _.quality_checker.length; i++) {
            const obj = {
              channel_id: _.channel_id,
              channel_quality_checker_user_id: _.quality_checker[i].user_id,
            };
            await api.post(updateQcPath, obj);
          }
        }
        thunkAPI.dispatch(editQcSuccess(res.data));
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(editQcError(err));
    }
  }
);

export const updateVu = createAsyncThunk(
  "/channelEdit/updateVu",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(editVuPending());
      const res = await api.delete(deleteVuPath + "/" + _.channel_id);
      if (res) {
        if (_.video_uploader.length !== 0) {
          for (let i = 0; i < _.video_uploader.length; i++) {
            const obj = {
              channel_id: _.channel_id,
              channel_video_uploader_user_id: _.video_uploader[i].user_id,
            };
            await api.post(updateVuPath, obj);
          }
        }
        thunkAPI.dispatch(editVuSuccess(res.data));
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(editVuError(err));
    }
  }
);

export const updateStatus = createAsyncThunk(
  "/channelEdit/updateStatus",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(editStatusPending());
      const obj = {
        status: _.status,
      };
      const res = await api.put(updateChannelStatusPath + _.channel_id, obj);
      if (res) {
        thunkAPI.dispatch(editStatusSuccess(res.data));
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(editStatusError(err.response.data.message));
    }
  }
);

const channelEditSlice = createSlice({
  name: "channelEdit",
  initialState,
  reducers: {
    editChannelPending: (state) => {
      state.editChannelPending = true;
    },
    editChannelSuccess: (state) => {
      state.editChannelPending = false;
      state.editChannelSuccess = true;
      state.editChannelError = "";
    },
    editChannelError: (state, { payload }) => {
      state.editChannelPending = false;
      state.editChannelSuccess = false;
      state.editChannelError = payload;
    },
    editClientPending: (state) => {
      state.clientPending = true;
    },
    editClientSuccess: (state) => {
      state.clientPending = false;
      state.clientSuccess = true;
      state.clientError = "";
    },
    editClientError: (state, { payload }) => {
      state.clientPending = false;
      state.clientSuccess = false;
      state.clientError = payload;
    },
    clearState: (state) => {
      state.editChannelPending = false;
      state.editChannelSuccess = false;
      state.editChannelError = "";
    },
    editScriptPending: (state) => {
      state.scriptPending = true;
    },
    editScriptSuccess: (state) => {
      state.scriptPending = false;
      state.scriptSuccess = true;
      state.scriptError = "";
    },
    editScriptError: (state, { payload }) => {
      state.scriptPending = false;
      state.scriptSuccess = false;
      state.scriptError = payload;
    },
    editVoicePending: (state) => {
      state.voicePending = true;
    },
    editVoiceSuccess: (state) => {
      state.voicePending = false;
      state.voiceSuccess = true;
      state.voiceError = "";
    },
    editVoiceError: (state, { payload }) => {
      state.voicePending = false;
      state.voiceSuccess = false;
      state.voiceError = payload;
    },
    editVideoPending: (state) => {
      state.videoPending = true;
    },
    editVideoSuccess: (state) => {
      state.videoPending = false;
      state.videoSuccess = true;
      state.videoError = "";
    },
    editVideoError: (state, { payload }) => {
      state.videoPending = false;
      state.videoSuccess = false;
      state.videoError = payload;
    },
    editPmPending: (state) => {
      state.pmPending = true;
    },
    editPmSuccess: (state) => {
      state.pmPending = false;
      state.pmSuccess = true;
      state.pmError = "";
    },
    editPmError: (state, { payload }) => {
      state.pmPending = false;
      state.pmSuccess = false;
      state.pmError = payload;
    },
    editThumbPending: (state) => {
      state.thumbPending = true;
    },
    editThumbSuccess: (state) => {
      state.thumbPending = false;
      state.thumbSuccess = true;
      state.thumbError = "";
    },
    editThumbError: (state, { payload }) => {
      state.thumbPending = false;
      state.thumbSuccess = false;
      state.thumbError = payload;
    },
    editDescPending: (state) => {
      state.descPending = true;
    },
    editDescSuccess: (state) => {
      state.descPending = false;
      state.descSuccess = true;
      state.descError = "";
    },
    editDescError: (state, { payload }) => {
      state.descPending = false;
      state.descSuccess = false;
      state.descError = payload;
    },
    editQcPending: (state) => {
      state.qcPending = true;
    },
    editQcSuccess: (state) => {
      state.qcPending = false;
      state.qcSuccess = true;
      state.qcError = "";
    },
    editQcError: (state, { payload }) => {
      state.qcPending = false;
      state.qcSuccess = false;
      state.qcError = payload;
    },
    editVuPending: (state) => {
      state.vuPending = true;
    },
    editVuSuccess: (state) => {
      state.vuPending = false;
      state.vuSuccess = true;
      state.vuError = "";
    },
    editVuError: (state, { payload }) => {
      state.vuPending = false;
      state.vuSuccess = false;
      state.vuError = payload;
    },
    editStatusPending: (state) => {
      state.statusPending = true;
    },
    editStatusSuccess: (state) => {
      state.statusPending = false;
      state.statusSuccess = true;
      state.statusError = "";
    },
    editStatusError: (state, { payload }) => {
      state.statusPending = false;
      state.statusSuccess = false;
      state.statusError = payload;
    },
  },
});

const { reducer, actions } = channelEditSlice;

export const {
  editChannelPending,
  editChannelSuccess,
  editChannelError,
  editClientPending,
  editClientSuccess,
  editClientError,
  clearState,
  editScriptPending,
  editScriptSuccess,
  editScriptError,
  editVoicePending,
  editVoiceSuccess,
  editVoiceError,
  editVideoPending,
  editVideoSuccess,
  editVideoError,
  editPmPending,
  editPmSuccess,
  editPmError,
  editThumbPending,
  editThumbSuccess,
  editThumbError,
  editDescPending,
  editDescSuccess,
  editDescError,
  editQcPending,
  editQcSuccess,
  editQcError,
  editVuPending,
  editVuSuccess,
  editVuError,
  editStatusPending,
  editStatusSuccess,
  editStatusError,
} = actions;

export default reducer;
