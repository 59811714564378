import {
  UilEstate,
  UilChannel,
  UilUsersAlt,
  UilUserSquare,
  UilClipboardAlt,
  UilFileLandscapeAlt,
  UilFileCheckAlt,
} from "@iconscout/react-unicons";

export const AdminMenuItem = [
  {
    path: "/admin/dashboard",
    name: "Dashboard",
    icon: <UilEstate color="#2a2e34" />,
  },
  {
    path: "/admin/channel",
    name: "Channel",
    icon: <UilChannel color="#2a2e34" />,
  },
  {
    path: "/admin/role",
    name: "Role",
    icon: <UilUsersAlt color="#2a2e34" />,
  },
  {
    path: "/admin/user",
    name: "User",
    icon: <UilUserSquare color="#2a2e34" />,
  },
  {
    path: "/admin/team-report",
    name: "Team Report",
    icon: <UilClipboardAlt color="#2a2e34" />,
  },
];

export const LevelOneResources = [
  {
    path: "/resource/dashboard",
    name: "Dashboard",
    icon: <UilEstate color="#2a2e34" />,
  },
  {
    path: "/resource/available-projects",
    name: "Available Projects",
    icon: <UilFileLandscapeAlt color="#2a2e34" />,
  },
  {
    path: "/resource/my-projects",
    name: "My Projects",
    icon: <UilFileCheckAlt color="#2a2e34" />,
  },
  {
    path: "/resource/my-report",
    name: "My Report",
    icon: <UilClipboardAlt color="#2a2e34" />,
  },
];

export const Client = [
  {
    path: "/client/dashboard",
    name: "Dashboard",
    icon: <UilEstate color="#2a2e34" />,
  },
  {
    path: "/client/channel",
    name: "Channel",
    icon: <UilChannel color="#2a2e34" />,
  },
];

export const Pm = [
  {
    path: "/pm/dashboard",
    name: "Dashboard",
    icon: <UilEstate color="#2a2e34" />,
  },
  {
    path: "/pm/channel",
    name: "Channel",
    icon: <UilChannel color="#2a2e34" />,
  },
  {
    path: "/pm/team-report",
    name: "Team Report",
    icon: <UilClipboardAlt color="#2a2e34" />,
  },
];

export const Resource2 = [
  {
    path: "/resource2/dashboard",
    name: "Dashboard",
    icon: <UilEstate color="#2a2e34" />,
  },
  {
    path: "/resource2/channel",
    name: "Channel",
    icon: <UilChannel color="#2a2e34" />,
  }
];
