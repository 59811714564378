import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Utils/Api";
import {
  searchUserRolePath,
  searchUserNamePath,
  searchUserEmailPath,
} from "../../../Utils/ApiPath";

const initialState = {
  isLoadingData: false,
  userRoleSuccess: [],
  userRoleFail: "",
  userNameSuccess: [],
  userNameFail: "",
  userEmailSuccess: [],
  userEmailFail: "",
};

export const searchUserRole = createAsyncThunk(
  "/user/userRole",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(userRolePending());
      const res = await api.get(
        searchUserRolePath + "/" + _.regx + "/" + _.status
      );
      if (res) {
        thunkAPI.dispatch(userRoleSuccess(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(userRoleFail(err));
    }
  }
);

export const searchUserName = createAsyncThunk(
  "/user/userName",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(userNamePending());
      const res = await api.get(
        searchUserNamePath + "/" + _.regx + "/" + _.status
      );
      if (res) {
        thunkAPI.dispatch(userNameSuccess(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(userNameFail(err));
    }
  }
);

export const searchUserEmail = createAsyncThunk(
  "/user/userEmail",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(userEmailPending());
      const res = await api.get(
        searchUserEmailPath + "/" + _.regx + "/" + _.status
      );
      if (res) {
        thunkAPI.dispatch(userEmailSuccess(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(userEmailFail(err));
    }
  }
);

const userFilterSlice = createSlice({
  name: "userFilter",
  initialState,
  reducers: {
    userRolePending: (state) => {
      state.isLoadingData = true;
    },
    userRoleSuccess: (state, { payload }) => {
      state.isLoadingData = false;
      state.userRoleSuccess = payload;
      state.userRoleFail = "";
    },
    userRoleFail: (state, { payload }) => {
      state.isLoadingData = false;
      state.userRoleSuccess = [];
      state.userRoleFail = payload;
    },
    userNamePending: (state) => {
      state.isLoadingData = true;
    },
    userNameSuccess: (state, { payload }) => {
      state.isLoadingData = false;
      state.userNameSuccess = payload;
      state.userNameFail = "";
    },
    userNameFail: (state, { payload }) => {
      state.isLoadingData = false;
      state.userNameSuccess = [];
      state.userNameFail = payload;
    },
    userEmailPending: (state) => {
      state.isLoadingData = true;
    },
    userEmailSuccess: (state, { payload }) => {
      state.isLoadingData = false;
      state.userEmailSuccess = payload;
      state.userEmailFail = "";
    },
    userEmailFail: (state, { payload }) => {
      state.isLoadingData = false;
      state.userEmailSuccess = [];
      state.userEmailFail = payload;
    },
  },
});

export const {
  userRolePending,
  userRoleSuccess,
  userRoleFail,
  userNamePending,
  userNameSuccess,
  userNameFail,
  userEmailPending,
  userEmailSuccess,
  userEmailFail,
} = userFilterSlice.actions;
export default userFilterSlice.reducer;
