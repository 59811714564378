import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Utils/Api";
import { getRolePath } from "../../../Utils/ApiPath";

const initialState = {
  isLoading: false,
  roles: [],
  error: "",
};

export const fetchRoles = createAsyncThunk(
  "/roles/fetchRoles",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getRolesPending());
      const res = await api.get(getRolePath);
      if (res) {
        res.data.splice(res.data.findIndex(a => a.role_id === 1),1);
        thunkAPI.dispatch(getRolesSuccess(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getRolesFail(err));
    }
  }
);

const roleSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    getRolesPending: (state) => {
      state.isLoading = true;
    },
    getRolesSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.roles = payload;
      state.error = "";
    },
    getRolesFail: (state, { payload }) => {
      state.isLoading = false;
      state.roles = [];
      state.error = payload;
    },
  },
});

export const {
  getRolesPending,
  getRolesSuccess,
  getRolesFail
} = roleSlice.actions;
export default roleSlice.reducer;
