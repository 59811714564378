import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { UilUserPlus } from "@iconscout/react-unicons";

const DropdownList = ({ data }) => {
  return (
    <>
      <Dropdown drop="down">
        <Dropdown.Toggle id="dropdown-basic">
          <div className="visible-icon">
            <UilUserPlus color="#b9bec7" />
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {data &&
            data?.map((item, index) => (
              <Dropdown.Item key={index}>
                {item.first_name + " " + item.last_name}
              </Dropdown.Item>
            ))}
          {data && data.length === 0 ? "No Users" : ""}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default DropdownList;
