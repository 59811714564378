import "./App.scss";
import BaseRouter from "./Router/routes";
import { HashRouter } from "react-router-dom";
import SetAuthToken from "../src/Utils/SetAuthToken";

function App() {
  //setting auth token in headers
  if (localStorage.token) {
    SetAuthToken(localStorage.token);
  }
  return (
    <div className="App">
      <HashRouter>
        <BaseRouter />
      </HashRouter>
    </div>
  );
}

export default App;
