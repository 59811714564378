import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Components/Loaders/Loaders";
import { register } from "../../../Feature/Entry/RegisterSlice";

const Register = () => {
  let { id } = useParams();
  const dispatch = useDispatch();
  const { isError, isSuccess, isLoading } = useSelector(
    (state) => state.register
  );
  const [error, setError] = useState("");
  const initialValues = {
    email: "",
  };
  const handleSubmit = (values) => {
    setError("");
    const obj = {
      id,
      email: values.email,
    };
    dispatch(register(obj));
  };
  useEffect(() => {
    console.log(isError)
    if (isError) {
      setError(isError);
    }
  }, [isError]);

  useEffect(() => {
    console.log(isSuccess)
    if (isSuccess) {
      window.location = isSuccess;
    }
  }, [isSuccess]);
  const validationSchema = (values) => {
    // console.log(values);
    const errors = {};
    if (!values.email) {
      errors.email = "Email is required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      //eslint-disable-line
      errors.email = "Please enter valid email";
    }
    return errors;
  };
  return (
    <>
      <div className="login-form">
        <h1 className="title">Sign Up Here!</h1>
        {error && (
          <div
            style={{
              color: "#e04f44",
              fontSize: "12px",
              textAlign: "center",
              marginBottom: "10px",
            }}
          >
            {error}
          </div>
        )}
        <Formik
          initialValues={initialValues}
          validate={(values) => validationSchema(values)}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => (
            <Form>
              <div className="login-form-main">
                <div className="login-main-row">
                  <label style={{ fontSize: "11px" }}>Email</label>
                  <Field
                    type="text"
                    id="email"
                    name="email"
                    className={
                      errors.email && touched.email
                        ? "form-control is-invalid form-control-email"
                        : "form-control form-control-email"
                    }
                    placeholder="Enter your email"
                  />
                  {touched.email && errors.email ? (
                    <div className="invalid-feedback d-flex">
                      {errors.email}
                    </div>
                  ) : null}
                </div>

                <div className="login-main-row mt-5 text-center">
                  <button className="login-button" type="submit">
                    {isLoading ? <Loader color="#fff" /> : "Sign Up"}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default Register;
