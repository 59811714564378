import React from "react";
import { UilExclamationTriangle } from "@iconscout/react-unicons";
import { NavLink } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { forgotPasswordValidationSchema } from "../../../Common/Validations";
import Loaders from "../../../Components/Loaders/Loaders";
import { useSelector, useDispatch } from "react-redux";
import { setforgotPassword } from "../../../Feature/Entry/forgotPassword/forgotPasswordAction";
import paperPlane from "../../../Assets/Images/paperPlane.png";

function ForgotPasswordForm() {
  // Component State:
  const initialValues = {
    email: "",
  };
  //hooks
  const { isLoading, isSuccess } = useSelector((state) => state.forgotPassword);
  const dispatch = useDispatch();

  //onChange handlers
  const handleSubmit = (values) => {
    dispatch(setforgotPassword(values));
  };

  return (
    <>
      {!isSuccess && (
        <div className="login-form">
          <h1 className="title">Forgot your Password?</h1>
          <div className="login-form-main">
            <Formik
              initialValues={initialValues}
              validationSchema={forgotPasswordValidationSchema}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={handleSubmit}
            >
              {({ errors, touched }) => (
                <Form>
                  {/* email */}
                  <div className="login-main-row">
                    <label>Email</label>
                    <Field
                      type="text"
                      id="email"
                      name="email"
                      className={
                        errors.email && touched.email
                          ? "form-control is-invalid form-control-email"
                          : "form-control form-control-email"
                      }
                      placeholder="Enter your email"
                    />
                    {/* error */}
                    {touched.email && errors.email ? (
                      <div className="invalid-feedback d-flex">
                        <UilExclamationTriangle size="15" className="me-1" />
                        {errors.email}
                      </div>
                    ) : null}
                  </div>

                  {/* submit */}
                  <div className="login-main-row">
                    <button className="login-button" type="submit">
                      {isLoading ? <Loaders color="#fff"/> : "Send me the link"}
                    </button>
                  </div>

                  {/* sign-in */}
                  <div className="sign-in-link">
                    <NavLink to="/">or sign in</NavLink>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}

      {isSuccess && (
        <div className="login-form">
          <h1 className="title">Recovery link sent!</h1>
          <div className="d-flex justify-content-center mb-3">
            <img src={paperPlane} alt="icon" />
          </div>
          <h3 className="text-center mb-2">Now, check your email.</h3>
          <div className="sign-in-link text-center">
            or
            <NavLink className='ms-1' to="/">Sign In</NavLink>
          </div>
        </div>
      )}
    </>
  );
}

export default ForgotPasswordForm;
