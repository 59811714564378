import React from "react";
import LoginHeader from "../../../Layouts/Login/LoginHeader";
import LoginLayout from "../../../Layouts/Login/LoginLayout";
import LoginMain from "./LoginMain";

function Login() {
  return (
    <>
      <LoginHeader />
      <LoginLayout />
      <LoginMain />
    </>
  );
}

export default Login;
