import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import Header from "./Header";
// import {useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";

function MainLayout() {
  // const { isAuth, token } = useSelector((state) => state.login);
  // const navigate = useNavigate();
  // useEffect(() => {
  //   if (!isAuth && (token === "" || token === undefined || token === null)) {
  //     navigate("/");
  //   }
  //   // eslint-disable-next-line 
  // },[!isAuth, token, navigate]);

  return (
    <>
      <div className="main-layout d-flex">
        <Sidebar />
        <Header />
      </div>
    </>
  );
}

export default MainLayout;
