import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Utils/Api";
import { getUserPath, getVideoUserPath } from "../../../Utils/ApiPath";

const initialState = {
  getUserPending: false,
  getUserSuccess: {},
  getClient: [],
  getUserFail: "",
  getVideoLoading: false,
  getVideoSuccess: {},
  getVideoFail: "",
};

export const fetchUser = createAsyncThunk(
  "/user/fetchUser",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getUserPending());
      const res = await api.get(getUserPath);
      if (res) {
        thunkAPI.dispatch(getUserSuccess(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getUserFail(err));
    }
  }
);

export const fetchVideoUser = createAsyncThunk(
  "/user/fetchVideoUser",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getVideoLoading());
      const res = await api.get(getVideoUserPath);
      if (res) {
        thunkAPI.dispatch(getVideoSuccess(res.data));
      }
      return res.data;
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(getVideoFail(err));
    }
  }
);

const getUserSlice = createSlice({
  name: "getUserSlice",
  initialState,
  reducers: {
    getUserPending: (state) => {
      state.getUserPending = true;
    },
    getUserSuccess: (state, action) => {
      state.getUserPending = false;
      state.getUserSuccess = action.payload;
      state.getUserFail = "";
    },
    getUserFail: (state, payload) => {
      state.getUserPending = false;
      state.getUserSuccess = {};
      state.getUserFail = payload;
    },
    getVideoLoading: (state) => {
      state.getVideoLoading = true;
    },
    getVideoSuccess: (state, action) => {
      state.getVideoLoading = false;
      state.getVideoSuccess = action.payload;
      state.getVideoFail = "";
    },
    getVideoFail: (state, payload) => {
      state.getVideoLoading = false;
      state.getVideoSuccess = {};
      state.getVideoFail = payload;
    },
  },
});

export const {
  getUserPending,
  getUserSuccess,
  getUserFail,
  getVideoLoading,
  getVideoSuccess,
  getVideoFail,
} = getUserSlice.actions;
export default getUserSlice.reducer;
