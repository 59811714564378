import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  isSuccess: false,
  error: "",
};

const loginSlice = createSlice({
  name: "forgotPassword",
  initialState,
  reducers: {
    forgotPasswordPending: (state) => {
      state.isLoading = true;
    },
    forgotPasswordSuccess: (state) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.error = "";
    },
    forgotPasswordFail: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
      state.isSuccess = false;
    },
    clearForgotPasword:(state)=>{
      state.isLoading = false;
      state.error = "";
      state.isSuccess = false;
    }
  },
});

const { reducer, actions } = loginSlice;

export const { forgotPasswordPending, forgotPasswordSuccess, forgotPasswordFail, clearForgotPasword } = actions;

export default reducer;