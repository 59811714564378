import React from 'react'
import { Outlet } from 'react-router-dom'

function LoginMain() {
  return (
    <>
    <div className='login-main d-flex justify-content-center'>
      <div className='login-main-container'>
          <Outlet />
      </div>
    </div>
    </>
  )
}

export default LoginMain