import * as Yup from "yup";

//login form validations
export const loginValidationSchema = Yup.object().shape({
  email: Yup.string().required("Email is required!").email("Email is invalid"),
  password: Yup.string()
    .required("Password is required!")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, // eslint-disable-line
      " Password must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character" // eslint-disable-line
    ),
});

//forgot password
export const forgotPasswordValidationSchema = Yup.object().shape({
  email: Yup.string().required("Email is required!").email("Email is invalid"),
});

//set password
export const setPasswordValidationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required!")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, // eslint-disable-line
      " Password must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character" // eslint-disable-line
    ),
  confirm_password: Yup.string()
    .required("Confirm password is required")
    .oneOf(
      [Yup.ref("password"), null],
      "Password and confirm password must match"
    ),
});

//user form validations
export const userValidationSchema = Yup.object().shape({
  email: Yup.string().required("Email is required!").email("Email is invalid"),
  password: Yup.string()
    .required("Password is required!")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, // eslint-disable-line
      " Password must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character" // eslint-disable-line
    ),
  first_name: Yup.string()
    .required("First name is required")
    .matches(/^(?!\s$)/, "First name cannot be empty"),
  last_name: Yup.string()
    .required("Last name is required")
    .matches(/^(?!\s$)/, "Last name cannot be empty"),
  role: Yup.string().required("Role is required"),
  status: Yup.string().required("Status is required"),
});

//user form validations
export const userEditValidationSchema = Yup.object().shape({
  first_name: Yup.string()
    .required("First name is required")
    .matches(/^(?!\s$)/, "First name cannot be empty"),
  last_name: Yup.string()
    .required("Last name is required")
    .matches(/^(?!\s$)/, "Last name cannot be empty"),
  role: Yup.string().required("Role is required"),
  status: Yup.string().required("Status is required"),
});

//channel form validations
export const ChannelValidationSchema = Yup.object().shape({
  channel_name: Yup.string()
    .required("Channel name is required")
    .matches(/^(?!\s$)/, "First name cannot be empty"),
  // client: Yup.array().min(1).required("Client name is required"),
  // project_manager: Yup.array()
  //   .min(1)
  //   .required("Project manager name is required"),
  //status: Yup.string().required("Status is required"),
});

//client=> channel form
export const ClientChannelValidationSchema = Yup.object().shape({
  channel: Yup.string().required("Channel name is required"),
  status: Yup.string().required("Status is required"),
});


export const UpgradeValidationSchema = Yup.object().shape({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  email: Yup.string().required("Email is required!").email("Email is invalid"),
  plan: Yup.string().required("Plan is required"),
});