import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  isSuccess: false,
  error: "",
};

const loginSlice = createSlice({
  name: "setPassword",
  initialState,
  reducers: {
    setPasswordPending: (state) => {
      state.isLoading = true;
    },
    setPasswordSuccess: (state) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.error = "";
    },
    setPasswordFail: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
      state.isSuccess = false;
    },
    clearSetPassword:(state)=>{
      state.isLoading = false;
      state.error = "";
      state.isSuccess = false;
    }
  },
});

const { reducer, actions } = loginSlice;

export const { setPasswordPending, setPasswordSuccess, setPasswordFail, clearSetPassword } = actions;

export default reducer;