import React, { useEffect, useState } from "react";
import {
  UilEllipsisH,
  UilPen,
  UilTrash,
  UilMessage,
} from "@iconscout/react-unicons";
import Dropdown from "react-bootstrap/Dropdown";
import UserForm from "./UserForm";
import DeleteModal from "../../../Common/DeleteModal";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteUserId,
  reinviteUserId,
} from "../../../Feature/Admin/userManagement/userActionSlice";
import { AlertToast } from "../../../Common/Toast";
import ReinviteModal from "./ReinviteModal";

function UserTable({ data }) {
  const [show, setShow] = useState(false);
  const [formData, setformData] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState("");
  const [deleteId, setDeleteId] = useState(0);
  const { isReinviteSuccess } = useSelector((state) => state.userAction);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [showreinviteModal, setShowReinviteModal] = useState(false);
  const [reinviteData, setReinviteData] = useState("");

  const dispatch = useDispatch();
  const openModal = () => {
    setShow(true);
  };
  const toggleModal = () => {
    setShow(!show);
  };
  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };
  const sendFormData = (values) => {
    openModal();
    setformData(values);
  };
  const sendDeleteData = (values) => {
    setShowDeleteModal(true);
    setDeleteData(values.first_name + " " + values.last_name);
    setDeleteId(values.user_id);
  };
  const deleteUser = () => {
    dispatch(deleteUserId(deleteId));
    toggleDeleteModal();
  };
  const closeToast = () => {
    setShowAlert(false);
  };
  const toggleReiniviteModal = () => {
    setShowReinviteModal(!showreinviteModal);
  };
  const sendReinviteData = (values) => {
    setShowReinviteModal(true);
    setReinviteData(values);
  };
  const reinviteUser = () => {
    dispatch(reinviteUserId(reinviteData));
    toggleReiniviteModal();
  };
  useEffect(() => {
    if (isReinviteSuccess) {
      setShowAlert(true);
      setAlertMsg("Email sent successfully");
    }
    // eslint-disable-next-line
  }, [isReinviteSuccess]);

  return (
    <>
      <AlertToast
        show={showAlert}
        alert={isReinviteSuccess ? "success" : "fail"}
        msg={alertMsg}
        close={closeToast}
      />
      <div className="user-table">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Role</th>
                <th>Email</th>
                <th>Project Limit</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item?.first_name + " " + item?.last_name}</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {item?.role}
                    </td>
                    <td>{item?.email}</td>
                    <td>
                      {item?.role_id === 3 ||
                      item?.role_id === 4 ||
                      item?.role_id === 5 ||
                      item?.role_id === 7 ||
                      item?.role_id === 8
                        ? item?.project_limit
                        : "NA"}
                    </td>
                    <td>
                      <Dropdown drop="start">
                        <Dropdown.Toggle id="dropdown-basic">
                          <UilEllipsisH color="#7c828d" size="18" />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={(e) => sendFormData(item)}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <UilPen
                              size="16"
                              color="#7c828d"
                              className="me-2"
                            />
                            Edit
                          </Dropdown.Item>

                          <Dropdown.Item
                            onClick={() => sendReinviteData(item)}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <UilMessage
                              size="16"
                              color="#7c828d"
                              className="me-2"
                            />
                            Reinvite
                          </Dropdown.Item>

                          <Dropdown.Item
                            onClick={() => sendDeleteData(item)}
                            style={{
                              color: "#e04f44",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <UilTrash
                              size="16"
                              color="#e04f44"
                              className="me-2"
                            />
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <UserForm
          show={show}
          toggle={toggleModal}
          form={"isEdit"}
          data={formData}
        />
        <DeleteModal
          show={showDeleteModal}
          toggle={toggleDeleteModal}
          head={"User"}
          data={deleteData}
          trigger={deleteUser}
        />
        <ReinviteModal
          show={showreinviteModal}
          toggle={toggleReiniviteModal}
          data={reinviteData}
          trigger={reinviteUser}
        />
      </div>
    </>
  );
}

export default UserTable;
